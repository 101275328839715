import { PACK } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

export const InheritedIcon = () => (
  <SimpleTooltip
    text={`${PACK.CAPITALIZED_SINGULAR} created and inherited from a subsidiary higher in the organisation`}
  >
    <i className='fal fa-sitemap me-2'></i>
  </SimpleTooltip>
);
