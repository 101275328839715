import { SURVEY } from '@constants/terminology';
import { SurveyType } from '@g17eco/types/survey';
import { Option } from '@g17eco/molecules/select/SelectFactory';

export type SurveyTypeOptions = 'all' | SurveyType.Default | SurveyType.Aggregation;

export const getSurveyTypeOptions = <T extends SurveyTypeOptions>(hasAllOption: boolean): Option<T>[] => {
  const options = [
    { value: SurveyType.Default as T, label: SURVEY.CAPITALIZED_PLURAL },
    { value: SurveyType.Aggregation as T, label: `Combined ${SURVEY.PLURAL}` },
  ];

  return (hasAllOption ? [{ value: 'all', label: `All ${SURVEY.SINGULAR} types` }, ...options] : options) as Option<T>[];
};
