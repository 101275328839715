import { OnChangeValue } from 'react-select';
import { ViewValues } from '../../survey-overview-sidebar/viewOptions';
import { QUESTION } from '@constants/terminology';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';

type ScopeTypeOption = Option<ViewValues>;

interface SurveyCardsFilterProps {
  options: ScopeTypeOption[];
  selectedView: ViewValues;
  setSelectedView: (value: ViewValues) => void;
}

export const SurveyCardsFilter = ({ options, selectedView, setSelectedView }: SurveyCardsFilterProps) => {
  const value = options.find(option => option.value === selectedView);
  return (
    <div className='col-4' data-testid='survey-scopes-switcher'>
      <SelectFactory<ViewValues>
        selectType={SelectTypes.SingleSelect}
        placeholder={`Select View ${QUESTION.CAPITALIZED_PLURAL} By`}
        options={options}
        value={value ?? null}
        onChange={(option: OnChangeValue<ScopeTypeOption, false>) => setSelectedView(option?.value ?? selectedView)}
        isSearchable={false}
      />
    </div>
  );
};
