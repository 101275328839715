import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import {
  InsightDashboard,
  InsightDashboardFilters,
  InsightDashboardItemType,
  InsightDashboardSettingKeys,
  InsightDashboardTitle,
  InsightDashboardActions,
  MetricStatusOption,
} from '@g17eco/types/insight-custom-dashboard';
import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { Sidebar } from '@g17eco/molecules/sidebar';
import { TimeRangeSelector } from '@g17eco/molecules/time-range-selector';
import { DeleteConfirmation, ConfirmationModal } from '@g17eco/molecules/confirm-modal';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { EditorTemplate } from '@features/custom-dashboard/item-editor/EditorTemplate';
import { CollapseButton, CollapseContent, CollapsePanel } from '@components/collapse-panel';
import { DashboardDivider } from '@g17eco/atoms';
import { TIME_RANGE_OPTIONS } from '@utils/date';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { DateRangeType } from '@g17eco/types/common';
import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';
import {
  DEFAULT_FILTERS,
  getTimeFramePeriod,
  getTimeFrameType,
  isDashboardSettingHidden,
  metricPrivacyOptions,
  defaultMetricStatusOptions,
  surveyStatusOptions,
  surveyTypeOptions,
} from './utils';
import { isESGDashboard } from '@routes/custom-dashboard/shared-dashboard/utils';
import { generateGridDashboardItem } from '@routes/custom-dashboard/utils';
import { QUESTION, SURVEY } from '@constants/terminology';
import { CustomDashboardContext } from '../context/CustomDashboardWrapper';
import { generateErrorToast, generateToast } from '@components/toasts';
import { useToggle } from '@hooks/useToggle';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { getCurrentInitiativeTree } from '@selectors/initiative';

interface SwitchGroupProps {
  filterKey: keyof InsightDashboard['filters'];
  label: string;
  checked: boolean | undefined;
  handleToggle: (key: keyof InsightDashboard['filters']) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const SwitchGroup = ({ filterKey, label, checked, handleToggle }: SwitchGroupProps) => {
  return (
    <FormGroup switch className='d-flex align-items-center gap-2'>
      <Input type='switch' role='switch' checked={checked} onChange={handleToggle(filterKey)} />
      <Label className='text-md' check>
        {label}
      </Label>
    </FormGroup>
  );
};

interface DropdownGroupProps {
  label: string;
  children: React.ReactNode;
}
const DropdownGroup = ({ label, children }: DropdownGroupProps) => {
  return (
    <FormGroup switch className='d-flex justify-content-between align-items-center gap-2'>
      <Label className='text-md' check>
        {label}
      </Label>
      {children}
    </FormGroup>
  );
};

interface Props {
  isOpenSidebar: boolean;
  toggleSidebar: () => void;
  dashboard: Pick<InsightDashboard, 'title' | 'filters' | 'type' | 'items' | 'initiativeId'>;
  handleSave: (dashboard: Partial<InsightDashboard>, keepEditing?: boolean) => void;
  hideOptions?: InsightDashboardSettingKeys[];
  availablePeriods: DataPeriods[];
  handleDelete?: () => void;
  metricStatusOptions?: MetricStatusOption[];
}

export const DashboardSettingsSidebar = ({
  isOpenSidebar,
  toggleSidebar,
  dashboard,
  hideOptions = [],
  availablePeriods,
  handleSave,
  handleDelete = () => {},
  metricStatusOptions = defaultMetricStatusOptions,
}: Props) => {
  const { title, filters, initiativeId } = dashboard;
  const [titleInput, setTitle] = useState(title);
  const [filtersInput, setFiltersInput] = useState<InsightDashboard['filters']>(DEFAULT_FILTERS);
  const [isDuplicating, toggleDuplicate, setDuplicate] = useToggle(false);
  const [isConfirmingDelete, toggleDelete] = useToggle(false);

  // TODO: This component is not always wrapped in context provider, should refactor
  const { duplicateDashboard } = useContext(CustomDashboardContext);

  // TODO: Should put these in the state of the context once refactor is done
  const userIsStaff = useAppSelector(isStaff);
  const currentInitiativeTree = useAppSelector(getCurrentInitiativeTree);

  const subsidiaryOptions = useMemo(() => {
    if (!currentInitiativeTree.length) {
      return [];
    }
    return currentInitiativeTree.map((i) => ({
      label: i.name,
      value: i._id,
    }));
  }, [currentInitiativeTree]);

  useEffect(() => {
    setFiltersInput({ ...DEFAULT_FILTERS, ...filters });
  }, [filters, initiativeId]);

  const handleChange = (update: Partial<InsightDashboard['filters']>) => {
    setFiltersInput((filters) => ({ ...filters, ...update }));
  }

  const handleToggle = (key: keyof InsightDashboard['filters']) => (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ [key]: { enabled: e.currentTarget.checked } });
  };

  const handleChangeTimeFrame = (dateRange: DateRangeType, period: string | number) => {
    handleChange({ timeFrame: { type: getTimeFrameType(period), ...dateRange } });
  };

  const getUpdatedDashboardItems = () => {
    if (isESGDashboard(dashboard.type)) {
      return dashboard.items;
    }
    // switch sdgContributionChart on, add sdgContribution chart item to dashboard
    if (!dashboard.filters.sdgContribution?.enabled && filtersInput.sdgContribution?.enabled) {
      const item = generateGridDashboardItem({ type: InsightDashboardItemType.SDGContributionChart }, dashboard.items);
      return [...dashboard.items, item];
    }
  };

  const handleClickSave = () => {
    toggleSidebar();
    const updatedItems = getUpdatedDashboardItems();
    handleSave({ title: titleInput, filters: filtersInput, items: updatedItems }, true);
  };

  const handleDuplicateDashboard = () => {
    setDuplicate(true);
    duplicateDashboard()
      .then(() => {
        generateToast({
          color: 'info',
          title: <><i className='fal fa-clone text-sm mr-2'></i>Dashboard duplicated</>,
          message: `${title} has been duplicated successfully.`,
        });
        toggleSidebar();
      })
      .catch((error) => {
        generateErrorToast(error);
      })
      .finally(() => {
        setDuplicate(false);
      });
  };

  const handleDeleteDashboard = () => {
    handleDelete();
    toggleSidebar();
  }

  const isSubsidiariesEmpty = !!filtersInput.initiativeIds && filtersInput.initiativeIds.length === 0;

  return (
    <Sidebar header='Dashboard settings' isOpen={isOpenSidebar} toggle={toggleSidebar} direction='end'>
      <EditorTemplate isDisabled={isSubsidiariesEmpty} handleCancel={toggleSidebar} handleSubmit={handleClickSave}>
        {isDashboardSettingHidden(hideOptions) ? null : (
          <>
            <CollapsePanel className='py-3' collapsed={false}>
              <CollapseButton>
                <span className='ml-2'>Dashboard settings</span>
              </CollapseButton>

              <CollapseContent>
                {hideOptions.includes(InsightDashboardTitle) ? null : (
                  <div className='d-flex align-items-center gap-3 mt-3'>
                    <Label className='flex-grow-0 text-md mb-0 col-3'>Title</Label>
                    <Input
                      type='text'
                      className='flex-grow-1 text-md'
                      value={titleInput ?? ''}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder='Enter a dashboard title'
                    />
                  </div>
                )}
                {!userIsStaff || hideOptions.includes(InsightDashboardFilters.InitiativeIds) ? null : (
                  <div className='d-flex align-items-center gap-3 mt-3'>
                    <SimpleTooltip
                      className='flex-grow-0 text-md col-3'
                      text='This dropdown filters the data by subsidiary. If left untouched, all subsidiary data is aggregated.'
                    >
                      <Label className='mb-0'>Subsidiaries</Label>
                    </SimpleTooltip>
                    <div className='w-100'>
                      <SelectFactory
                        selectType={SelectTypes.MultipleSelect}
                        options={subsidiaryOptions}
                        onChange={(initiativeIds: string[]) => handleChange({ initiativeIds })}
                        values={filtersInput.initiativeIds ?? [dashboard.initiativeId]}
                        placeholder={'Select subsidiaries'}
                        menuPlacement='auto'
                      />
                      {isSubsidiariesEmpty ? (
                        <p className='position-absolute text-danger'>No subsidiaries selected</p>
                      ) : null}
                    </div>
                    {/* TODO: Implement styles for validation of react-select */}
                  </div>
                )}
                <div className='d-flex flex-column gap-2 mt-4'>
                  {hideOptions.includes(InsightDashboardFilters.ShareWithSubsidiaries) ? null : (
                    <SwitchGroup
                      filterKey={InsightDashboardFilters.ShareWithSubsidiaries}
                      checked={filtersInput.shareWithSubsidiaries?.enabled}
                      label='Share dashboard with children'
                      handleToggle={handleToggle}
                    />
                  )}
                  {hideOptions.includes(InsightDashboardFilters.BaselinesTargets) ? null : (
                    <SwitchGroup
                      filterKey={InsightDashboardFilters.BaselinesTargets}
                      checked={filtersInput.baselinesTargets?.enabled}
                      label='Display targets and baselines'
                      handleToggle={handleToggle}
                    />
                  )}
                  <SwitchGroup
                    filterKey={InsightDashboardFilters.InitiativeInfo}
                    checked={filtersInput.initiativeInfo?.enabled}
                    label='Display company details heading'
                    handleToggle={handleToggle}
                  />
                  <SwitchGroup
                    filterKey={InsightDashboardFilters.SdgContribution}
                    checked={filtersInput.sdgContribution?.enabled}
                    label='Display SDG chart'
                    handleToggle={handleToggle}
                  />
                </div>
              </CollapseContent>
            </CollapsePanel>

            {hideOptions.includes(InsightDashboardActions) ? null : (
              <div className='w-100 d-flex gap-3 mb-4'>
                <Button
                  color='warning'
                  className='flex-grow-1 d-flex align-items-center text-sm text-ThemeTextWhite'
                  onClick={toggleDuplicate}
                  disabled={isDuplicating}
                >
                  <i className='fal fa-clone text-sm mr-2'></i>Duplicate dashboard
                </Button>
                <Button
                  color='danger'
                  className='flex-grow-1 d-flex align-items-center text-sm'
                  onClick={toggleDelete}
                  disabled={isConfirmingDelete}
                >
                  <i className='fal fa-trash text-sm mr-2'></i>Delete dashboard
                </Button>
              </div>
            )}
            <DashboardDivider className='mt-2 mb-1' />
          </>
        )}

        <CollapsePanel className='py-3' collapsed={false}>
          <CollapseButton>
            <span className='ml-2'>{SURVEY.CAPITALIZED_SINGULAR} settings</span>
          </CollapseButton>

          <CollapseContent>
            <div className='d-flex flex-column gap-2 mt-3'>
              {hideOptions.includes(InsightDashboardFilters.TimeFrame) ? null : (
                <DropdownGroup label='Timeframe'>
                  <TimeRangeSelector
                    timeRange={getTimeFramePeriod(filtersInput.timeFrame?.type ?? DEFAULT_FILTERS.timeFrame.type)}
                    dateRange={{
                      startDate: filtersInput.timeFrame?.startDate,
                      endDate: filtersInput.timeFrame?.endDate,
                    }}
                    timeRangeOptions={TIME_RANGE_OPTIONS}
                    styleProps={{}}
                    onChangeDateRange={handleChangeTimeFrame}
                  />
                </DropdownGroup>
              )}

              <DropdownGroup label={`${SURVEY.CAPITALIZED_SINGULAR} type`}>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  options={surveyTypeOptions}
                  value={surveyTypeOptions.find(
                    (op) => op.value === (filtersInput.surveyType ?? DEFAULT_FILTERS.surveyType)
                  )}
                  onChange={(op) => handleChange({ surveyType: op?.value })}
                />
              </DropdownGroup>

              {hideOptions.includes(InsightDashboardFilters.Period) ? null : (
                <DropdownGroup label={`${SURVEY.CAPITALIZED_SINGULAR} period`}>
                  <SurveyPeriodDropdown
                    period={filtersInput.period}
                    availablePeriods={availablePeriods}
                    setPeriod={(value) => handleChange({ period: value })}
                  />
                </DropdownGroup>
              )}

              {hideOptions.includes(InsightDashboardFilters.Survey) ? null : (
                <DropdownGroup label={`${SURVEY.CAPITALIZED_SINGULAR} status`}>
                  <SelectFactory
                    selectType={SelectTypes.SingleSelect}
                    options={surveyStatusOptions}
                    value={surveyStatusOptions.find((op) => op.value === filtersInput.survey)}
                    onChange={(op) => handleChange({ survey: op?.value })}
                  />
                </DropdownGroup>
              )}
            </div>
          </CollapseContent>
        </CollapsePanel>

        <DashboardDivider className='mt-2 mb-1' />

        <CollapsePanel className='py-3' collapsed={true}>
          <CollapseButton>
            <span className='ml-2'>{QUESTION.CAPITALIZED_SINGULAR} settings</span>
          </CollapseButton>

          <CollapseContent>
            <div className='d-flex flex-column gap-2 mt-3'>
              <DropdownGroup label={`${QUESTION.CAPITALIZED_SINGULAR} status`}>
                <SelectFactory
                  selectType={SelectTypes.SingleSelect}
                  options={metricStatusOptions}
                  value={metricStatusOptions.find((op) => op.value === filtersInput.utrv)}
                  onChange={(op) => handleChange({ utrv: op?.value })}
                />
              </DropdownGroup>

              {hideOptions.includes(InsightDashboardFilters.Privacy) ? (
                <></>
              ) : (
                <DropdownGroup label={`${QUESTION.CAPITALIZED_SINGULAR} privacy`}>
                  <SelectFactory
                    selectType={SelectTypes.SingleSelect}
                    options={metricPrivacyOptions}
                    value={metricPrivacyOptions.find((op) => op.value === filtersInput.privacy)}
                    onChange={(op) => handleChange({ privacy: op?.value })}
                  />
                </DropdownGroup>
              )}
            </div>
          </CollapseContent>
        </CollapsePanel>

        {isDuplicating ? (
          <ConfirmationModal
            title='Duplicate dashboard'
            content='Are you sure you would like to duplicate this dashboard?'
            isOpen={isDuplicating}
            handleCancel={toggleDuplicate}
            confirmButton={
              <Button color='warning' onClick={handleDuplicateDashboard}>
                Duplicate
              </Button>
            }
          />
        ) : null}

        {isConfirmingDelete ? (
          <DeleteConfirmation title='Delete dashboard' toggle={toggleDelete} handleConfirm={handleDeleteDashboard} />
        ) : null}
      </EditorTemplate>
    </Sidebar>
  );
};
