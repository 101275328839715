import { useTextCollapse } from '@components/company-profile/useTextCollapse';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import './styles.scss';

interface Props {
  children: React.ReactNode;
  defaultHeight?: number;
}

export const ExpandableParagraph = (props: Props) => {
  const { children, defaultHeight } = props;

  const [currentHeight, setHeight] = useState(0);
  const [isHidden, setHidden] = useState(true);
  const paragraphRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (paragraphRef.current) {
      setHeight(paragraphRef.current.scrollHeight || 0);
    }
  }, []);

  const { height, className, isTruncated } = useTextCollapse({
    isHidden,
    height: currentHeight,
    defaultHeight,
  });

  const HiddenBtn = () => {
    const text = isHidden ? 'Read more' : 'Read less';
    const icon = isHidden ? 'fa-angle-down' : 'fa-angle-up';
    return (
      <Button color='link-secondary' onClick={() => setHidden((prev) => !prev)}>
        <div className='text-sm py-1'>
          <i className={`fa ${icon} mr-1 text-sm`} />
          {text}
        </div>
      </Button>
    );
  };

  return (
    <div className='expandable-paragraph'>
      <div ref={paragraphRef} className={className} style={{ height }}>
        {children}
      </div>
      {isTruncated ? <HiddenBtn /> : null}
    </div>
  );
};
