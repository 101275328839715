import { useParams } from 'react-router-dom';
import { formatDateUTC } from '@utils/date';
import { RouteParams, TemplateTableStats } from '../types';
import { TemplateDropdownOptions } from './TemplateDropdownOptions';
import { useGetTemplatesQuery } from '@api/survey-templates';
import { Table, ColumnDef } from '@g17eco/molecules/table';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { naturalSort } from '@utils/index';
import { SURVEY } from '@constants/terminology';

export const TemplateTable = () => {
  const { initiativeId } = useParams<RouteParams>();
  const query = useGetTemplatesQuery(initiativeId);

  const columns: ColumnDef<TemplateTableStats>[] = [
    {
      accessorKey: 'name',
      header: 'Templates',
    },
    {
      accessorKey: 'type',
      header: 'Template type',
    },
    {
      header: 'Level created',
      accessorFn: (row) => row.initiative.name,
    },
    {
      header: 'Last edited',
      accessorFn: (row) => formatDateUTC(row.lastUpdated),
      sortingFn: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
    },
    {
      header: 'Last used',
      accessorFn: (row) => formatDateUTC(row.lastUsed ?? ''),
      sortingFn: (a, b) => naturalSort(a.original.lastUsed ?? '', b.original.lastUsed ?? ''),
    },
    {
      id: 'settings',
      header: '',
      cell: ({ row }) => {
        if (row.original.initiativeId === initiativeId) {
          return <TemplateDropdownOptions template={row.original} />;
        }

        return (
          <SimpleTooltip
            text={`${SURVEY.CAPITALIZED_SINGULAR} template has been created at a higher subsidiary. To edit or use this template navigate to ${row.original.initiative.name}`}
          >
            <i className='fal fa-info-circle' color='secondary' />
          </SimpleTooltip>
        );
      },
      meta: {
        cellProps: { style: { width: 50 }, className: 'text-center' },
      },
    },
  ];

  return <Table className='mt-3' responsive={true} pageSize={10} columns={columns} data={query.data ?? []} />;
};
