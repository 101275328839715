import IconButton from '@components/button/IconButton';
import { DashboardSection } from '@components/dashboard';
import { SURVEY } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules/alert';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { WorkInProgress } from '@g17eco/molecules/work-in-progress';
import { IN_PROGRESS_JOB_STATUSES, INVALID_JOB_STATUSES, JobStatus } from '@g17eco/types/background-jobs';
import { Button } from 'reactstrap';
import { InitiativePlain } from '@g17eco/types/initiative';
import { isDoubleMaterialitySurvey } from '@apps/materiality-tracker/utils';
import { AssessmentType } from '@apps/materiality-tracker/api/materiality-assessment';

interface Props {
  pptxReportJobStatus: JobStatus | undefined;
  scoreJobStatus: JobStatus | undefined;
  onExcelDownload: () => void;
  onPPTXDownload: () => void;
  onDocxDownload: () => Promise<void>;
  regenerateScores: () => void;
  initiative: Pick<InitiativePlain, '_id' | 'name'>;
  assessmentType?: AssessmentType;
}
export const AssessmentInsightsOverview = (props: Props) => {
  const {
    initiative,
    pptxReportJobStatus,
    scoreJobStatus,
    onExcelDownload,
    onPPTXDownload,
    regenerateScores,
    onDocxDownload,
    assessmentType = AssessmentType.FinancialMateriality,
  } = props;

  const isDoubleMateriality = isDoubleMaterialitySurvey({ assessmentType });
  const title = `${isDoubleMateriality ? 'Double Materiality' : 'Financial Materiality'} Assessment ${SURVEY.SINGULAR}`;

  if (!scoreJobStatus) {
    return null;
  }

  if (INVALID_JOB_STATUSES.includes(scoreJobStatus)) {
    return (
      <BasicAlert type='danger'>There was an error calculating the assessment scores. Please try again.</BasicAlert>
    );
  }

  const isGeneratingScore = IN_PROGRESS_JOB_STATUSES.includes(scoreJobStatus);

  if (isGeneratingScore) {
    return (
      <WorkInProgress
        title={`Your materiality ${SURVEY.SINGULAR} is currently generating and you will be notified once it is completed`}
      />
    );
  }

  if (scoreJobStatus === JobStatus.Completed) {
    return (
      <DashboardSection>
        <h5 className='m-0 mb-2 fw-bold'>{title}</h5>
        <div>
          This {SURVEY.SINGULAR} serves as a foundation for <strong>{initiative.name}’s</strong> sustainability journey,
          guiding the development of a robust sustainability strategy and facilitating transparent reporting on ESG
          performance.
        </div>
        <div className='w-100 d-flex gap-2 justify-content-end mt-4'>
          <SimpleTooltip text='Coming soon'>
            <Button color='transparent'>
              <i className='fal fa-circle-nodes mr-2' /> Export to Company Tracker
            </Button>
          </SimpleTooltip>
          <SimpleTooltip text={isDoubleMateriality ? 'Coming soon' : ''}>
            <Button disabled={isDoubleMateriality} color='secondary' onClick={onExcelDownload}>
              <i className='fal fa-file-excel mr-2' />
              All results
            </Button>
          </SimpleTooltip>
          <SimpleTooltip text={isDoubleMateriality ? 'Coming soon' : ''}>
            <Button disabled={isDoubleMateriality} color='primary' onClick={onDocxDownload}>
              <i className='fal fa-file-word mr-2' />
              Detailed report
            </Button>
          </SimpleTooltip>
          {pptxReportJobStatus && IN_PROGRESS_JOB_STATUSES.includes(pptxReportJobStatus) ? (
            <Button color='primary' disabled={true}>
              <i className='fal fa-circle mr-2' />
              Generating report
            </Button>
          ) : (
            <SimpleTooltip text={isDoubleMateriality ? 'Coming soon' : ''}>
              <Button
                color='primary'
                onClick={onPPTXDownload}
                disabled={pptxReportJobStatus !== JobStatus.Completed || isDoubleMateriality}
              >
                <i className='fal fa-file-powerpoint mr-2' />
                External report
              </Button>
            </SimpleTooltip>
          )}

          <SimpleTooltip
            text={
              isDoubleMateriality
                ? 'Coming soon'
                : isGeneratingScore
                ? 'Scores are being calculated. Cannot regenerate until complete'
                : 'Regenerate scores'
            }
          >
            <IconButton
              data-testid={'btn-regenerate'}
              onClick={regenerateScores}
              outline={false}
              color='transparent'
              className='text-ThemeAccentDark'
              icon='fal fa-repeat'
              disabled={isDoubleMateriality || isGeneratingScore}
            />
          </SimpleTooltip>
        </div>
      </DashboardSection>
    );
  }
};
