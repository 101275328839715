import { useCreatePortfolioInsightDashboardMutation } from '@api/portfolio-insight-dashboards';
import { InsightsSidebar } from './InsightsSidebar';
import { useSiteAlert } from '@hooks/useSiteAlert';
import Loader from '@components/loader';
import { DashboardSettingsSidebar } from '@routes/custom-dashboard/dashboard-settings/DashboardSettingsSidebar';
import { DEFAULT_FILTERS } from '@routes/custom-dashboard/dashboard-settings/utils';
import {
  GridDashboardItem,
  InsightDashboard,
  InsightDashboardActions,
  InsightDashboardFilters,
  InsightDashboardItemType,
  InsightDashboardType,
} from '@g17eco/types/insight-custom-dashboard';
import { generateGridDashboardItem } from '@routes/custom-dashboard/utils';
import { InsightDashboardOption } from '../../utils/sidebar';

interface PortfolioInsightsSidebarProps {
  portfolioId: string;
  currentPage: string;
  options: InsightDashboardOption[];
  openSettingsSidebar: boolean;
  toggleSettingsSidebar: () => void;
  handleClickOption: (item: InsightDashboardOption) => void;
  handleNavigateCustom: (dashboardId: string) => void;
}

export const PortfolioInsightsSidebar = ({
  portfolioId,
  currentPage,
  options,
  openSettingsSidebar,
  toggleSettingsSidebar,
  handleClickOption,
  handleNavigateCustom
}: PortfolioInsightsSidebarProps) => {
  const { addSiteError } = useSiteAlert();

  const [createInsightDashboard, { isLoading }] = useCreatePortfolioInsightDashboardMutation();

  const handleSave = async (changes: Partial<InsightDashboard>) => {
    if (!portfolioId) {
      return;
    }

    const items: GridDashboardItem[] = [];
    if (changes.filters?.sdgContribution?.enabled) {
      const item = generateGridDashboardItem({ type: InsightDashboardItemType.SDGContributionChart }, items);
      items.push(item);
    }

    await createInsightDashboard({
      initiativeId: portfolioId,
      ...changes,
      items,
      title: changes.title ?? 'Custom Dashboard',
    })
      .unwrap()
      .then((dashboard) => {
        handleNavigateCustom(dashboard._id);
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <InsightsSidebar options={options} currentPage={currentPage} handleClickOption={handleClickOption} />
      <DashboardSettingsSidebar
        isOpenSidebar={openSettingsSidebar}
        toggleSidebar={toggleSettingsSidebar}
        dashboard={{
          title: 'Custom Dashboard',
          initiativeId: portfolioId,
          filters: DEFAULT_FILTERS,
          type: InsightDashboardType.Custom,
          items: [],
        }}
        handleSave={handleSave}
        hideOptions={[
          InsightDashboardFilters.ShareWithSubsidiaries,
          InsightDashboardFilters.BaselinesTargets,
          InsightDashboardFilters.Period,
          InsightDashboardFilters.Survey,
          InsightDashboardFilters.Privacy,
          InsightDashboardActions,
          InsightDashboardFilters.InitiativeIds,
        ]}
        availablePeriods={[]}
      />
    </>
  );
};
