import IconButton from '@components/button/IconButton';
import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';
import { getChartTitleByType } from '../utils/dashboard-utils';
import { InsightDashboardItemChartType } from '@g17eco/types/insight-custom-dashboard';
import { MetricRemoveFn } from '@features/custom-dashboard';
import { ExtendedUtrVariable } from '../../../routes/custom-dashboard/types';
import { QuestionsMapUtr } from '../types';
import { ColumnDef } from '@tanstack/react-table';
import { Table } from '@g17eco/molecules/table';

type MetricsTableRow = ExtendedUtrVariable & Pick<UniversalTrackerBlueprintMin, 'valueLabel'>;

interface Props {
  metrics: ExtendedUtrVariable[];
  questionsMap: Map<string, QuestionsMapUtr>;
  handleRemove: MetricRemoveFn;
  chartType: InsightDashboardItemChartType;
}

export const MetricsTable = ({ metrics, questionsMap, handleRemove, chartType }: Props) => {
  const data: MetricsTableRow[] = metrics.map((metric) => {
    const valueLabel = getChartTitleByType({ chartType, utrVariable: metric, questionsMap });
    return {
      ...metric,
      valueLabel,
    };
  });

  const tableColumns: ColumnDef<MetricsTableRow>[] = [
    {
      accessorKey: 'valueLabel',
      header: () => 'Metric name',
      meta: {
        cellProps: {
          className: 'flex-grow-1',
        },
      },
      cell: ({ row }) => {
        return row.original.name || row.original.valueLabel;
      },
    },
    {
      accessorKey: 'action-buttons',
      header: () => '',
      meta: {
        cellProps: {
          className: 'flex-grow-0',
        },
      },
      cell: ({ row }) => {
        return (
          <IconButton
            className='border-0'
            color='danger'
            onClick={() => handleRemove(row.original, row.index)}
            icon='fal fa-trash'
          />
        );
      },
      enableSorting: false,
    },
  ];

  return <Table responsive={true} showHeader={false} columns={tableColumns} data={data} pageSize={5} />;
};
