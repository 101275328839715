import { AppFlavour, getDefaultConfig } from '../../config/app-config';
import { blueprintDefaultUnitConfig, blueprintDefaultUnitConfigSgx, UnitConfig } from '@models/surveyData';
import { FieldOptions, UnitOptionType } from './types';

export const SurveyUnitsAndCurrencyText: UnitOptionType = {
  area: {
    title: 'Area',
  },
  length: {
    title: 'Length',
  },
  mass: {
    title: 'Mass',
  },
  volume: {
    title: 'Volume',
  },
  time: {
    title: 'Time',
  },
  energy: {
    title: 'Energy',
  },
  co2Emissions: {
    title: 'CO2 equivalent',
  },
  partsPer: {
    title: 'Concentration / Parts Per',
  },
  numberScale: {
    title: 'Financial',
  },
  currency: {
    title: 'Currency',
    tooltip:
      'Default currency type set during onboarding, and cannot be updated in life, as G17Eco does not support currency conversion. Please contact us if you have further questions.',
  },
};

export const defaultFieldOptions: FieldOptions = {
  currency: {
    disabled: true,
  },
};

export const getDefaultUnitConfig = (additionalUnitConfig: Partial<UnitConfig>) => {
  // @TODO @CTL Probably should load from company settings or use location currency
  const defaults =
    getDefaultConfig().flavour === AppFlavour.SINGAPORE ? blueprintDefaultUnitConfigSgx : blueprintDefaultUnitConfig;

  const defaultUnitConfig: UnitConfig = {
    ...defaults,
    ...additionalUnitConfig,
  };

  return defaultUnitConfig;
};
