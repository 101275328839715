import NoDocuments from '@g17eco/images/no-documents-in-library.svg';
import { BasicAlert } from '@g17eco/molecules/alert';
import { ColumnDef, Table } from '@g17eco/molecules/table';
import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { DeleteModal } from '@g17eco/molecules/confirm-modal';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { useDeleteDocumentMutation, useGetDocumentsQuery, useUpdateDocumentMutation } from '@api/documents';
import { useMemo, useState } from 'react';
import { DocumentItem } from '@g17eco/types/document';
import { DocumentFilters } from './DocumentFilters';
import { generateDocumentsFlexSearchMap } from '@features/admin-dashboard/utils';
import { Checkbox, CheckboxState } from '@g17eco/atoms';
import { DATE, formatDateUTC } from '@utils/date';
import IconButton from '@components/button/IconButton';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DocumentToolbar } from './DocumentToolbar';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { documentOptions } from './utils';
import FileGrid from '@components/files/file-grid';

export type FilterParams = {
  searchText: string;
  type: string;
};

const DetailModal = ({isOpen, document, toggle}: {isOpen: boolean, document?: DocumentItem, toggle: () => void}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>View details</ModalHeader>
      <ModalBody>
        <FileGrid files={[document]}/>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const DocumentTable = ({ initiativeId }: { initiativeId: string }) => {
  const [filters, setFilters] = useState<FilterParams>({ searchText: '', type: '' });
  const [deletingDocumentId, setDeletingDocumentId] = useState<string>();
  const [selectedDocument, setSelectedDocument] = useState<DocumentItem>();
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentItem[]>([]);
  const isDocumentSelected = (docId: string) => selectedDocuments.some((doc) => doc._id === docId);
  const { addSiteAlert, addSiteError } = useSiteAlert();
  const getDocumentsQuery = useGetDocumentsQuery(initiativeId);
  const { data: listDocuments } = getDocumentsQuery;
  const [deleteDocument] = useDeleteDocumentMutation();
  const [updateDocument, { isLoading: isUpdating }] = useUpdateDocumentMutation();

  const columns: ColumnDef<DocumentItem>[] = [
    {
      id: 'multi-select-action',
      meta: {
        cellProps: { style: { width: 20 }, className: 'text-center' },
      },
      header: '',
      cell: (c) => {
        const document = c.row.original;
        const status = isDocumentSelected(document._id) ? CheckboxState.Checked : CheckboxState.Unchecked;
        return (
          <Checkbox
            status={status}
            onChange={() => {
              const newSelectedDocuments =
                status === CheckboxState.Checked
                  ? selectedDocuments.filter((q) => q._id !== document._id)
                  : [...selectedDocuments, document];

              return setSelectedDocuments(newSelectedDocuments);
            }}
          />
        );
      },
    },
    {
      accessorKey: 'ownerSubType',
      header: 'Type',
      meta: {
        headerProps: {
          style: {
            width: 230,
          },
        },
      },
      cell: ({ row }) => {
        return (
          <div data-testid='document-type-dropdown'>
            <SelectFactory
              className='w-100 border-none'
              selectType={SelectTypes.SingleSelect}
              placeholder='Select a type'
              options={documentOptions}
              value={documentOptions.find(({ value }) => value === row.original.ownerSubType)}
              isDisabled={isUpdating}
              onChange={(op) => {
                handleUpdateDocument({ ...row.original, ownerSubType: op?.value });
              }}
              isSearchable={false}
              menuPlacement='bottom'
              isMenuPortalTargetBody
              isTransparent={true}
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'title',
      header: 'File name',
      cell: ({ row }) => {
        const limit = 40;
        return (
          <SimpleTooltip text={row.original.title?.length > limit ? row.original.title : ''}>
            <Input
              type='text'
              className='text-truncate'
              plaintext={true}
              disabled={isUpdating}
              defaultValue={row.original.title}
              onBlur={(e) => {
                if (e.target.value !== row.original.title) {
                  handleUpdateDocument({ ...row.original, title: e.target.value });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateDocument({ ...row.original, title: e.currentTarget.value });
                }
              }}
            />
          </SimpleTooltip>
        );
      },
    },
    { accessorKey: 'metadata.extension', header: 'Format' },
    {
      accessorKey: 'created',
      header: 'Upload date',
      cell: ({ row }) => formatDateUTC(row.original.created, DATE.YEAR_MONTH_DATE),
    },
    {
      id: 'action-buttons',
      header: '',
      meta: {
        headerProps: {
          style: {
            width: 50,
          },
        },
      },
      cell: ({ row }) => (
        <div className='d-flex gap-1'>
          <IconButton
            outline={false}
            icon='fal fa-eye'
            color='transparent'
            className='text-ThemeTextPlaceholder'
            onClick={() => setSelectedDocument(row.original)}
          />
          <IconButton
            outline={false}
            icon='fal fa-trash'
            color='transparent'
            className='text-ThemeDangerMedium'
            onClick={() => setDeletingDocumentId(row.original._id)}
          />
        </div>
      ),
    },
  ];

  const filteredRows: DocumentItem[] = useMemo(() => {
    const { searchText, type } = filters;
    let rows = listDocuments ?? [];
    if (searchText) {
      const searchIndex = generateDocumentsFlexSearchMap(listDocuments);
      const result = searchIndex.search(searchText);
      const matchedIds = new Set(result.map((item) => item.result).flat());
      rows = rows.filter((doc) => matchedIds.has(doc._id));
    }
    if (type) {
      rows = rows.filter((doc) => doc.ownerSubType === type);
    }
    return rows;
  }, [filters, listDocuments]);

  const handleChangeFilters = (key: keyof { searchText: string; type: string }, value?: string) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUpdateDocument = (data: DocumentItem) => {
    updateDocument({ initiativeId, documentId: data._id, data })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: (
            <div className='text-center'>
              <i className='fal fa-check-circle mr-2' />
              Document has been updated
            </div>
          ),
        });
      })
      .catch((error) => {
        addSiteError(error);
      });
  };

  const handleDeleteDocument = (deletingDocumentId?: string) => {
    if (!deletingDocumentId) {
      return;
    }
    deleteDocument({ initiativeId, documentId: deletingDocumentId })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: (
            <div className='text-center'>
              <i className='fal fa-check-circle mr-2' />
              Document deleted
            </div>
          ),
        });
      })
      .catch((error) => {
        addSiteError(error);
      })
      .finally(() => {
        setDeletingDocumentId(undefined);
        setSelectedDocuments([]);
      });
  };

  return (
    <>
      {listDocuments?.length !== 0 && <DocumentFilters filters={filters} onChangeFilters={handleChangeFilters} />}
      <QueryWrapper
        query={getDocumentsQuery}
        onSuccess={() => (
          <div data-testid='manage-documents-table'>
            <Table
              pageSize={10}
              columns={columns}
              data={filteredRows}
              className='mt-3'
              responsive
              noData={<BasicAlert type='secondary'>No documents found</BasicAlert>}
            />
          </div>
        )}
        onNoData={() => (
          <div className='text-center'>
            <h1 className='text-ThemeTextPlaceholder mb-5'>No documents uploaded yet!</h1>
            <img src={NoDocuments} alt='no documents in library' />
          </div>
        )}
      />
      <DocumentToolbar
        initiativeId={initiativeId}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
      />
      <DetailModal isOpen={!!selectedDocument} document={selectedDocument} toggle={() => setSelectedDocument(undefined)} />
      <DeleteModal
        title='document'
        content='This will permanently delete the item. Continue?'
        isOpen={!!deletingDocumentId}
        handleDelete={() => {
          handleDeleteDocument(deletingDocumentId);
        }}
        handleCancel={() => setDeletingDocumentId(undefined)}
      />
    </>
  );
};
