import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useToggle } from '../../../hooks/useToggle';
import { LockAction } from './questionInterfaces';
import { QUESTION, SURVEY } from '@constants/terminology';
import { SubmitButton } from '@components/button/SubmitButton';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface QuestionLockModalProps {
  handleLockAction: (action: LockAction) => Promise<void>;
  isLocked: boolean;
  isDisabled: boolean;
}

const getDisabledButtonTooltipText = (isLocked: boolean) => {
  const action = isLocked ? 'unlocked' : 'locked';
  return `Question cannot be ${action} whilst ${SURVEY.SINGULAR} is completed`;
}

export const QuestionLockButton = ({ handleLockAction, isLocked, isDisabled }: QuestionLockModalProps) => {
  const [isOpen, toggle] = useToggle(false);

  const handleProceed = async () => {
    return handleLockAction(isLocked ? LockAction.Open : LockAction.Close).finally(() => {
      toggle();
    });
  };

  return (
    <>
      <SimpleTooltip text={isDisabled ? getDisabledButtonTooltipText(isLocked) : ''}>
        <Button color='link' onClick={toggle} disabled={isDisabled}>
          {isLocked ? `Unlock ${QUESTION.SINGULAR}` : `Lock ${QUESTION.SINGULAR}`}
        </Button>
      </SimpleTooltip>
      <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
        <ModalHeader className='text-ThemeWarningMedium' toggle={toggle}>
          <span className='text-ThemeWarningExtradark'>Restatement of data</span>
        </ModalHeader>
        <ModalBody>
          You are unlocking a {QUESTION.SINGULAR} that has been assured.
          <br /><br />
          If you restate any of the core information, then you must include an explanation in the Further explanation
          section.
          <br />
          The {QUESTION.SINGULAR} will be marked as 'Restated', not 'Assured'.
          <br />
          You can still view the Assured data in the {QUESTION.SINGULAR}'s Provenance section.
        </ModalBody>
        <ModalFooter className='pt-0'>
          <Button color='transparent' onClick={toggle}>
            Cancel
          </Button>
          <SubmitButton color={'warning'} onClick={handleProceed}>
            Proceed
          </SubmitButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
