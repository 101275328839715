import { RichTextEditor, useGuardEditorState, putCursorAtTheEnd } from '@features/rich-text-editor';
import { TextData } from '@features/custom-dashboard/types';
import { InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';
import { $createParagraphNode, $createTextNode, $getRoot, SerializedEditorState } from 'lexical';
import { forwardRef, useEffect } from 'react';
import { $createHeadingNode } from '@lexical/rich-text';

interface Props {
  item: Pick<TextData, 'text' | 'editorState' | 'type'>;
  handleChange: ({ text, editorState }: { text: string; editorState: SerializedEditorState }) => void;
}

export const FreeText = forwardRef<HTMLDivElement, Props>(({ item, handleChange }: Props, ref) => {
  const { text, editorState, type } = item;
  const { editor, guardEditorState } = useGuardEditorState(editorState);

  useEffect(() => {
    if (editorState) {
      editor.setEditorState(guardEditorState);
      putCursorAtTheEnd(editor);
      return;
    }

    // In case edit old item, we translate text to rich text.
    if (text) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();

        const node = type === InsightDashboardItemType.Headline ? $createHeadingNode('h2') : $createParagraphNode();
        node.append($createTextNode(text));
        root.append(node);
      });

      putCursorAtTheEnd(editor);
      return;
    }

    // We rely on the passed in key to handle or refresh state,
    // we only want to init once as editor is uncontrolled component
    // @TODO refactor state init to support state init and editor init at the same time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return (
    <div ref={ref}>
      <RichTextEditor
        placeholder='Enter text here...'
        handleChange={({ editorState, plainText }) => {
          handleChange({
            text: plainText,
            editorState: editorState.toJSON(),
          });
        }}
        autoFocus
      />
    </div>
  );
});
