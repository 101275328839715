import { useState } from 'react';
import { Button, Input, ModalBody, ModalFooter } from 'reactstrap';
import { UserChanges } from './ArchiveSubsidiaryModal';
import { getFullName } from '../../../../utils/user';
import './ConfirmationStep.scss';
import { ArchiveSubsidiaryMutationParams, useArchiveSubsidiaryMutation } from '../../../../api/organisation-map';
import { useHistory } from 'react-router-dom';
import { generateUrl } from '../../../util';
import { ROUTES } from '../../../../constants/routes';
import Loader from '../../../../components/loader';
import { MinReportingLevel } from '../../types';
import { ChildrenTree } from '@g17eco/types/tree';

interface ConfirmationStepProps {
  selectedInitiative: MinReportingLevel;
  userChanges: UserChanges;
  treeRootData: ChildrenTree;
  handleToggle: () => void;
  handleUpdate: () => void;
}

const CONFIRM_TEXT = 'ARCHIVE';

export const ConfirmationStep = (props: ConfirmationStepProps) => {
  const [inputText, setInputText] = useState('');
  const history = useHistory();

  const {
    selectedInitiative,
    treeRootData,
    userChanges: { reassignedInitiative, reassignedUsers, removedUsers },
    handleToggle,
    handleUpdate,
  } = props;

  const [archiveSubsidiary, { isLoading }] = useArchiveSubsidiaryMutation();

  const handleArchive = () => {
    const data: ArchiveSubsidiaryMutationParams = {
      initiativeId: selectedInitiative.initiativeId,
      reassignedInitiativeId: reassignedInitiative?.initiativeId,
      reassignedUserIds: reassignedUsers.map((user) => user._id),
      removedUserIds: removedUsers.map((user) => user._id),
    };
    archiveSubsidiary(data).then(() => {
      history.push(generateUrl(ROUTES.NAVIGATE_BY_MAP, { initiativeId: treeRootData.id }));
      handleToggle();
      handleUpdate();
    });
  };

  return (
    <>
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <p>You have chosen to archive <span className='text-strong'>{selectedInitiative.name || ''}</span></p>
            <p>The following users will be removed from your organisation:</p>
            <ul className='ps-4'>
              {removedUsers.map((user) => (
                <li key={user._id}>
                  <span className='text-strong'>{getFullName(user)}</span> ({selectedInitiative.name})
                </li>
              ))}
            </ul>
            <p>The following users will be reassigned:</p>
            <ul className='ps-4'>
              {reassignedUsers.map((user) => (
                <li key={user._id}>
                  <span className='text-strong'>{getFullName(user)}</span> ({selectedInitiative.name} <i className='fal fa-arrow-right' />{' '}
                  {reassignedInitiative?.name})
                </li>
              ))}
            </ul>
            <p>If you still want to proceed, type {CONFIRM_TEXT} into the field below to continue.</p>
            <Input
              className='confirm-text__input'
              type='text'
              placeholder={CONFIRM_TEXT}
              onChange={(e) => setInputText(e.target.value)}
              required
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='transparent' disabled={isLoading} onClick={handleToggle}>
          Cancel
        </Button>
        <Button
          color='danger'
          disabled={inputText !== CONFIRM_TEXT || isLoading}
          onClick={handleArchive}
        >
          Archive
        </Button>
      </ModalFooter>
    </>
  );
};
