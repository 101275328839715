import { Button, Col, FormGroup, Label } from 'reactstrap';
import { CustomReportTemplateType, Form } from './types';
import { PreviewBtn } from './PreviewBtn';

const options = [
  {
    label: 'Default',
    value: CustomReportTemplateType.Default,
    icon: 'fa-table-columns',
  },
  {
    label: 'Default (transposed)',
    value: CustomReportTemplateType.Transposed,
    icon: 'fa-table-rows',
  },
  {
    label: 'Tabular',
    value: CustomReportTemplateType.Tabular,
    icon: 'fa-table',
  },
];

export const TemplateType = ({
  form,
  onChangeTemplateType,
}: {
  form: Form;
  onChangeTemplateType: (type: CustomReportTemplateType) => void;
}) => {
  const { templateType, columns } = form;
  return (
    <>
      <FormGroup row className='mt-4'>
        <Label sm={2}>Template type*</Label>
        <Col sm={10}>
          <div className='d-flex align-items-stretch gap-2'>
            {options.map((op) => (
              <Button key={op.value} onClick={() => onChangeTemplateType(op.value)} active={templateType === op.value}>
                <i className={`fal ${op.icon} text-ThemeIconSecondary mr-1`} />
                {op.label}
              </Button>
            ))}
            <PreviewBtn columns={columns} templateType={templateType} />
          </div>
        </Col>
      </FormGroup>
    </>
  );
};
