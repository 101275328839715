import { User } from '@api/survey-users';
import { SURVEY } from '@constants/terminology';
import { SurveyUserRoles } from '@constants/users';
import { ColumnDef, Table } from '@g17eco/molecules/table';
import { getDelegatedUtrvsCount, hasAtLeastOneRoleLevel, isAssignedToAll, RoleLevel } from '@utils/delegation-users';
import { SurveyDelegationProps } from './types';
import { getFullName } from '@utils/user';

type Props = Pick<SurveyDelegationProps, 'utrvIds'> & {
  users: User[];
};

const getQuestionCount = ({
  userRole,
  user,
  utrvIds,
}: {
  userRole: SurveyUserRoles;
  user: User;
  utrvIds: string[];
}) => {
  if (isAssignedToAll(userRole, user, utrvIds.length)) {
    return 'All';
  }

  return getDelegatedUtrvsCount(userRole, user) || '-';
};

export const UsersTable = ({ utrvIds, users }: Props) => {
  const tableColumns: ColumnDef<User>[] = [
    {
      header: 'User',
      meta: {
        cellProps: {
          className: 'dont_translate',
        },
      },
      accessorFn: (user) => {
        return getFullName(user, user.email);
      },
    },
    {
      id: 'verifyingMetricsCount',
      header: () => {
        return <i className='fa fa-user-edit text-primary' />;
      },
      accessorFn: (user) => {
        return getQuestionCount({
          user,
          userRole: SurveyUserRoles.Stakeholder,
          utrvIds,
        });
      },
    },
    {
      id: 'contributingMetricsCount',
      header: () => {
        return <i className='fa fa-user-check text-success' />;
      },
      accessorFn: (user) => {
        return getQuestionCount({
          user,
          userRole: SurveyUserRoles.Verifier,
          utrvIds,
        });
      },
    },
    {
      id: 'type',
      header: 'Type',
      accessorFn: (user) => {
        return hasAtLeastOneRoleLevel([RoleLevel.Initiative, RoleLevel.Survey], SurveyUserRoles.Admin, user)
          ? `${SURVEY.CAPITALIZED_SINGULAR} admin`
          : 'User';
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorFn: (user) => {
        return user.active ? 'Active' : 'Pending';
      },
    },
  ];
  return (
    <div className='mt-3'>
      <Table columns={tableColumns} data={users} />
    </div>
  );
};
