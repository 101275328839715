import { useState } from 'react';
import { ExtendedQuestionsMapUtr, MetricSelectorProps } from '../types';
import { UtrVariable } from '@routes/summary/insights/utils/constants';
import { DEFAULT_METRIC_UNIT } from '@constants/utr';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { QuestionData, defaultExtendedUtrVariable } from '@routes/custom-dashboard/utils';
import { isValidUtrVariable } from '../utils/dashboard-utils';
import { InsightDashboardItemType } from '../../../types/insight-custom-dashboard';
import { MetricsTable } from './MetricsTable';
import { Button, Input } from 'reactstrap';
import { QuestionSelectingFilter, removeMetric } from '@features/custom-dashboard';
import { QUESTION, SURVEY } from '@constants/terminology';
import { SDGTargetDropdown } from './SDGTargetDropdown';
import { ExtendedUtrVariable } from '../../../routes/custom-dashboard/types';
import { useGetInitiativeBlueprintQuestionsQuery } from '../../../api/admin-dashboard';
import { IntegrationSourceSelector } from '@routes/custom-dashboard/items/integrations/IntegrationSourceSelector';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

const METRIC_LIMIT = 22;
const MAX_LENGTH_LEGEND = 50;
const DISSAGREGATED_DISABLE_MESSAGE = `Disaggregated chart type cannot display multiple ${QUESTION.PLURAL}`;

enum View {
  CombineMetrics,
  TrackMetric,
}

interface Props extends MetricSelectorProps {
  questionsMap: Map<string, ExtendedQuestionsMapUtr>;
  togglePreviewModal: () => void;
  helpText: JSX.Element;
  isDisaggregated?: boolean;
}

const hasMetrics = (metrics: UtrVariable[] | undefined): metrics is UtrVariable[] => {
  return metrics !== undefined && metrics.length > 0;
};

const getLockedUnit = ({
  metrics,
  questionsMap,
}: {
  metrics: UtrVariable[] | undefined;
  questionsMap: Map<string, ExtendedQuestionsMapUtr>;
}) => {
  // ! since unit can be either undefined, empty string or string,
  // ! default to empty string will treat undefined unit and empty string unit to be the same
  if (!metrics || metrics.length === 0) {
    return DEFAULT_METRIC_UNIT;
  }
  // first metric unit will be used as locked unit
  const [firstMetric] = metrics;
  const question = questionsMap.get(firstMetric.code);
  return question ? getMetricUnitDesc(question, firstMetric.valueListCode) : DEFAULT_METRIC_UNIT;
};

export const MultipleMetricsSelector = (props: Props) => {
  const {
    initiativeId,
    chartData,
    questionsMap,
    changeChartData,
    togglePreviewModal,
    helpText,
    isDisaggregated,
  } = props;
  const [view, setView] = useState<View>(View.CombineMetrics);
  const [questionData, setQuestionData] = useState<ExtendedUtrVariable>(defaultExtendedUtrVariable);
  const { data: blueprintQuestions = [] } = useGetInitiativeBlueprintQuestionsQuery({ initiativeId });

  const { metrics } = chartData;
  const currentMetricCount = metrics.length;
  // Locked unit is calculated from utr unit and sdg number scale
  const lockedUnit = getLockedUnit({ metrics, questionsMap });
  const isUTRChart = chartData.type === InsightDashboardItemType.Chart;
  const showMetrics = hasMetrics(metrics);
  const isDisabledByAggregated = isDisaggregated && currentMetricCount > 0;

  const openAddMetric = () => setView(View.TrackMetric);

  const closeAddMetric = () => setView(View.CombineMetrics);

  const handleClose = () => {
    setQuestionData(defaultExtendedUtrVariable);
    closeAddMetric();
  };

  const handleAdd = () => {
    if (!isValidUtrVariable(questionData)) {
      return;
    }
    const newMetrics = chartData.metrics ? [...chartData.metrics, questionData] : [questionData];

    changeChartData({ ...chartData, metrics: newMetrics });
    handleClose();
  };

  const handleSelectQuestion = (utr: QuestionData) => {
    const { code, ...rest } = utr;

    if (!code) {
      setQuestionData({ ...rest, code: '', name: '' });
      return;
    }

    if (!isUTRChart) {
      return setQuestionData({ ...rest, code, name: '' });
    }

    const name = blueprintQuestions.find((question) => question.code === code)?.valueLabel || '';
    setQuestionData({ ...rest, code, name });
  };

  const getContent = () => {
    switch (chartData.type) {
      case InsightDashboardItemType.Chart:
        return (
          <>
            <p className='mt-3 text-medium'>Select data-point from {SURVEY.SINGULAR} scope</p>
            <QuestionSelectingFilter
              lockedUnit={lockedUnit}
              questionData={questionData}
              initiativeId={initiativeId}
              handleSelect={handleSelectQuestion}
            />
            <Input
              type='text'
              className='text-md mt-3'
              disabled={!questionData.code}
              maxLength={MAX_LENGTH_LEGEND}
              value={questionData.name ?? ''}
              onChange={(e) => setQuestionData((prev) => ({ ...prev, name: e.target.value }))}
              placeholder='Legend name'
            />
          </>
        );
      case InsightDashboardItemType.Integration:
        return <IntegrationSourceSelector questionData={questionData} changeQuestionData={handleSelectQuestion} />;
      case InsightDashboardItemType.SDGTracker:
        return <SDGTargetDropdown questionData={questionData} changeQuestionData={handleSelectQuestion} />;
      default:
        break;
    }
  };

  const getButtonText = () => {
    switch (chartData.type) {
      case InsightDashboardItemType.SDGTracker:
        return 'Track a SDG';
      case InsightDashboardItemType.Integration:
        return `Track an integration ${QUESTION.SINGULAR}`;
      case InsightDashboardItemType.Chart:
      default:
        return `Track a ${QUESTION.SINGULAR}`;
    }
  };

  switch (view) {
    case View.CombineMetrics:
      return (
        <>
          <div className='mt-3 text-medium'>{`Which ${QUESTION.SINGULAR} would you like to track`}</div>
          {showMetrics ? (
            <MetricsTable
              metrics={metrics}
              questionsMap={questionsMap}
              handleRemove={(utrVariable, index) => {
                changeChartData({
                  ...chartData,
                  metrics: removeMetric(metrics, utrVariable, index),
                });
              }}
              chartType={chartData.type ?? InsightDashboardItemType.Chart}
            />
          ) : null}
          {currentMetricCount < METRIC_LIMIT ? (
            <>
              <div className='d-flex gap-2 align-items-center'>
                <Button color='link' className='p-0' onClick={openAddMetric} disabled={isDisabledByAggregated}>
                  <i className='fal fa-plus me-2' />
                  {getButtonText()}
                </Button>
                {isDisabledByAggregated ? (
                  <SimpleTooltip text={DISSAGREGATED_DISABLE_MESSAGE} className='text-ThemeIconSecondary'>
                    <i className='fal fa-info-circle' />
                  </SimpleTooltip>
                ) : null}
              </div>
              {currentMetricCount === 0 ? (
                <p className='text-xs text-ThemeIconSecondary'>{`Selectable ${QUESTION.SINGULAR} will be locked once first unit selected`}</p>
              ) : null}
            </>
          ) : (
            <p className='text-sm text-ThemeIconSecondary fst-italic'>{`Charts limited to ${METRIC_LIMIT} ${QUESTION.PLURAL}.`}</p>
          )}
          {showMetrics ? (
            <div className='d-flex mt-3 justify-content-between'>
              <div className='me-auto'>{helpText}</div>
              <div className='w-100 d-flex justify-content-end'>
                <Button color='secondary' onClick={togglePreviewModal}>
                  <i className='fal fa-eye mr-2' />
                  Preview
                </Button>
              </div>
            </div>
          ) : null}
        </>
      );
    case View.TrackMetric:
      return (
        <>
          {getContent()}
          <div className='d-flex mt-3'>
            <div className='me-auto'>{helpText}</div>
            <div className='w-100 d-flex justify-content-end'>
              <Button color='link-secondary' onClick={handleClose} className='mr-3'>
                Cancel
              </Button>
              <Button color='primary' disabled={!questionData.code} onClick={handleAdd}>
                {`Add ${QUESTION.SINGULAR}`}
              </Button>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};
