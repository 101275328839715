import { ReadonlyRichTextEditor, useGuardEditorState } from '@features/rich-text-editor';
import { GridDashboardTextItem, InsightDashboardItemType, TextSubType } from '@g17eco/types/insight-custom-dashboard';
import classNames from 'classnames';

const commonClassnames = 'w-100 h-100 d-flex align-items-start text-break overflow-auto';

type Props = {
  item: Pick<GridDashboardTextItem, 'type' | 'subType' | 'text' | 'editorState'>;
  isEditing?: boolean;
};

export const TextWidget = ({ item, isEditing }: Props) => {
  const { type, subType, text, editorState } = item;

  if (subType === TextSubType.Metric) {
    return <TextArea text={text} isEditing={isEditing} />;
  }

  if (editorState) {
    return <RichText editorState={editorState} isEditing={isEditing} />;
  }

  switch (type) {
    case InsightDashboardItemType.Headline:
      return <Headline text={text} isEditing={isEditing} />;
    case InsightDashboardItemType.Text:
      return <TextArea text={text} isEditing={isEditing} />;
    default:
      return null;
  }
};

const TextArea = ({ text, isEditing }: Pick<Props, 'isEditing'> & Pick<GridDashboardTextItem, 'text'>) => {
  return (
    <div
      className={classNames(
        { 'background-ThemeBgExtralight': isEditing },
        commonClassnames,
        'text-ThemeTextMedium p-3'
      )}
    >
      {text}
    </div>
  );
};

/** @deprecated */
const Headline = ({ text, isEditing }: Pick<Props, 'isEditing'> & Pick<GridDashboardTextItem, 'text'>) => {
  return (
    <h2
      className={classNames(
        { 'background-ThemeBgExtralight': isEditing },
        commonClassnames,
        'text-ThemeHeadingLight px-3 m-0'
      )}
    >
      {text}
    </h2>
  );
};

const RichText = ({
  editorState,
  isEditing,
}: Pick<Props, 'isEditing'> & Pick<GridDashboardTextItem, 'editorState'>) => {
  const { guardEditorState } = useGuardEditorState(editorState);
  return (
    <div className={classNames({ 'background-ThemeBgExtralight': isEditing }, 'w-100 h-100 overflow-auto')}>
      <ReadonlyRichTextEditor editorState={guardEditorState} key={JSON.stringify(guardEditorState.toJSON())} />
    </div>
  );
};
