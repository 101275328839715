import { UserRowData, ValidatedResult } from '@api/bulk-onboarding-import';
import { USER_ROLE_LABEL_MAP } from '@constants/user';
import { Table, ColumnDef } from '@g17eco/molecules/table';

export interface ReviewDataProps extends ValidatedResult {
  validRowCount: number;
  userLimit: number;
}

export const ReviewData = (props: ReviewDataProps) => {
  const { validatedData, validRowCount } = props;

  const columns: ColumnDef<UserRowData>[] = [
    {
      accessorKey: 'email',
      header: () => 'Email address',
    },
    {
      accessorKey: 'initiativeName',
      header: () => 'Subsidiary',
    },
    {
      accessorKey: 'permissions',
      header: () => 'Permissions',
      cell: ({ row }) => {
        return <span>{row.original.permissions.map((p) => USER_ROLE_LABEL_MAP[p]).join(', ')}</span>;
      },
    },
    {
      accessorKey: 'message',
      header: () => 'Error',
    },
  ];

  return (
    <div>
      <p>{validRowCount} users to invite</p>
      <Table columns={columns} data={validatedData} pageSize={10} />
    </div>
  );
};
