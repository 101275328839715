import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useGoBack } from '../hooks/useGoBack';
import { useAppDispatch } from '../../../reducers';
import { TemplateContext, TemplateContextLoadedProps } from '../TemplateContainer';
import Dashboard, { DashboardSection } from '../../dashboard';
import { TemplateDashboardHeader } from '../partials/TemplateDashboardHeader';
import {
  HideField,
  OnChangeReportingProps,
  SurveyReportingSettings,
} from '../../survey-configuration/partials/SurveyReportingSettings';
import { SubsidiarySelectionTable } from '../../downloads/partials/SubsidiarySelectionTable';
import { BulkAggregatedSurveysFormData } from '../types';
import { updateFn } from '../../form/common';
import { useBulkSurveysCreateMutation } from '../../../api/survey-templates';
import { SiteAlertColors, addSiteAlert } from '../../../slice/siteAlertsSlice';
import { generateUrl } from '../../../routes/util';
import { ROUTES } from '../../../constants/routes';
import { TemplateSettingsHeader } from '../partials/TemplateSettingsHeader';
import { aggregatedRequiredFields, hasEmptyFields } from '../utils';
import Loader from '../../loader';
import { SURVEY } from '@constants/terminology';
import { createEffectiveDate } from '@components/survey-configuration/utils';

const aggregatedReportingTitles = {
  type: `Combined ${SURVEY.SINGULAR} duration`,
  lastMonth: `Combined ${SURVEY.SINGULAR} last month`,
  firstMonth: `Combined ${SURVEY.SINGULAR} first month`,
};

export const AggregatedBulkSurveysCreate = () => {
  const history = useHistory();
  const { initiativeId = '' } = useParams<{ initiativeId: string }>();
  const goBack = useGoBack(initiativeId);
  const dispatch = useAppDispatch();
  const { templateData } = useContext(TemplateContext) as TemplateContextLoadedProps;
  const [bulkSurveysCreate, { isLoading }] = useBulkSurveysCreateMutation();
  const [form, setForm] = useState<BulkAggregatedSurveysFormData>({});
  const updateForm: updateFn = (update) => setForm({ ...form, [update.code]: update.value });

  const handleChange = ({ option, code }: OnChangeReportingProps) => {
    return updateForm({ code, value: option?.value });
  };

  const isDisabled = hasEmptyFields(form, aggregatedRequiredFields);

  const handleSubmit = () => {
    if (!form.period) {
      return;
    }
    const effectiveDate = createEffectiveDate({ ...form, period: form.period }) ?? new Date();
    return bulkSurveysCreate({
      ...form,
      effectiveDate,
      _id: templateData._id,
      surveyName: templateData.surveyName,
      period: form.period,
    })
      .unwrap()
      .then((response) => {
        history.push(
          generateUrl(ROUTES.SURVEY_TEMPLATES_HISTORY, {
            initiativeId,
            templateId: templateData._id,
            historyId: response.data._id,
          })
        );
      })
      .catch((e) =>
        dispatch(
          addSiteAlert({
            content: e.message,
            color: SiteAlertColors.Danger,
          })
        )
      );
  };

  return (
    <Dashboard>
      <TemplateSettingsHeader />
      <DashboardSection header={<TemplateDashboardHeader title={`Combined ${SURVEY.SINGULAR} template`} />}>
        {isLoading ? (
          <Loader relative />
        ) : (
          <>
            <div className='mt-4 position-relative'>
              <h5>{SURVEY.CAPITALIZED_SINGULAR} name</h5>
              <div>{templateData.surveyName}</div>
            </div>
            <SurveyReportingSettings
              form={form}
              onChange={handleChange}
              reportingTitles={aggregatedReportingTitles}
              hideFields={[HideField.BlueprintVersion]}
            />
            <div className='mt-4'>
              <h5>Create combined {SURVEY.SINGULAR} on the following subsidiaries</h5>
            </div>
            <SubsidiarySelectionTable
              isDisabled
              selectedIds={templateData.reportingLevels}
              initiativeId={initiativeId}
            />
            <div className='mt-4 text-right'>
              <Button color='link' onClick={goBack} className='mr-3'>
                Cancel
              </Button>
              <Button color='primary' className='submit-button' disabled={isDisabled} onClick={handleSubmit}>
                Create {SURVEY.PLURAL}
              </Button>
            </div>
          </>
        )}
      </DashboardSection>
    </Dashboard>
  );
};
