import { useEffect } from 'react';
import { ButtonGradient } from '../../apps/common/components/ButtonGradient/ButtonGradient';
import { useToggle } from '@hooks/useToggle';
import { AssistantOffcanvas } from './AssistantOffcanvas';
import { FeatureStability } from '@g17eco/molecules/feature-stability';
import { useConnectionContext } from './connections/ConnectionContext';
import { AnswerProps } from './ai-assistant/types';
import { Button } from 'reactstrap';

interface Props extends AnswerProps {
  canAccessAITools: boolean;
}

export const AssistantBtn = ({ utr, utrv, canAccessAITools }: Props) => {
  const [open, toggle] = useToggle(false);

  const { removeConnection } = useConnectionContext();

  useEffect(() => {
    if (document) {
      const questionContainerElement = document.getElementById('question-container');
      if (open) {
        questionContainerElement?.classList?.add('has-sidebar-left');
      } else {
        questionContainerElement?.classList?.remove('has-sidebar-left');
      }
    }
  }, [open]);

  const handleToggle = () => {
    removeConnection();
    toggle();
  };

  return (
    <>
      {canAccessAITools ? (
        <ButtonGradient outline onClick={handleToggle} key={`assistant-btn-${open ? 'open' : 'close'}`}>
          <i className='fal fa-sparkles mr-2' />
          Assistant
          <FeatureStability stability={'beta'} />
        </ButtonGradient>
      ) : (
        <Button outline color='secondary' onClick={handleToggle} key={`assistant-btn-${open ? 'open' : 'close'}`}>
          <i className='fal fa-diagram-nested mr-2' />
          Connections
        </Button>
      )}
      <AssistantOffcanvas utrv={utrv} utr={utr} open={open} toggle={handleToggle} canAccessAITools={canAccessAITools} />
    </>
  );
};
