import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useSiteAlert } from '@hooks/useSiteAlert';
import G17Client from '@services/G17Client';
import { Tab } from '@components/utr-modal/ContentTabs';
import { ShowAs } from '@reducers/universal-tracker-modal';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface Props {
  initiativeId: string;
  utrId: string;
  canAccessCustomDashboards: boolean;
  canDrawChart: boolean;
  disabledTargetBaselineAddingText: string;
  handleChangeTab: (navId: Tab['navId']) => void;
}

export const UtrModalActions = (props: Props) => {
  const {
    initiativeId,
    utrId,
    canAccessCustomDashboards,
    canDrawChart,
    disabledTargetBaselineAddingText,
    handleChangeTab,
  } = props;

  const { addSiteError } = useSiteAlert();

  const downloadUtrvHistory = async () => {
    if (!initiativeId) {
      return;
    }
    return G17Client.downloadUtrvHistory(initiativeId, utrId).catch((e) => {
      addSiteError(e);
    });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle outline color='transparent' className='border-ThemeNeutralsLight px-2'>
        <i className={'fal fa-bars'}></i>
      </DropdownToggle>
      <DropdownMenu container={'.modal-content'} className='border-ThemeBorderDefault'>
        <SimpleTooltip text={disabledTargetBaselineAddingText}>
          <DropdownItem
            disabled={!!disabledTargetBaselineAddingText || !canDrawChart}
            onClick={() => handleChangeTab(ShowAs.TargetBaseline)}
          >
            <i className='fal fa-bullseye-arrow text-ThemeIconSecondary' /> Add target or baseline
          </DropdownItem>
        </SimpleTooltip>

        {canAccessCustomDashboards ? (
          <DropdownItem onClick={() => handleChangeTab(ShowAs.AddToDashboard)}>
            <i className='fal fa-chart-line text-ThemeIconSecondary' /> Add to dashboard
          </DropdownItem>
        ) : null}
        <DropdownItem onClick={downloadUtrvHistory}>
          <i className='fal fa-file-excel text-ThemeIconSecondary pe-1' /> Excel download
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
