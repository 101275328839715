import Dashboard, { DashboardRow, DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { CTAdminBreadcrumbs } from '../breadcrumbs/CTAdminBreadcrumbs';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { Route, Switch, useParams } from 'react-router-dom';
import { CreateOrEditWorkgroup } from './CreateOrEditWorkgroup';
import { WorkgroupsTable } from './WorkgroupsTable';
import { getCreatingUrl } from './utils';
import { useAppSelector } from '@reducers/index';
import { isRootOrg } from '@selectors/initiative';
import NotAuthorised from '@routes/not-authorised';
import { BreadcrumbItemType } from '@g17eco/molecules/breadcrumbs';

const getBreadcrumbs = (initiativeId: string, workgroupId?: string) => {
  const breadcrumbs: BreadcrumbItemType[] = [
    {
      label: 'Manage Users',
      url: generateUrl(ROUTES.MANAGE_USERS, { initiativeId }),
    },
  ];

  if (!workgroupId) {
    breadcrumbs.push({ label: 'Workgroups' });
  } else {
    breadcrumbs.push(
      { label: 'Workgroups', url: generateUrl(ROUTES.MANAGE_WORKGROUPS, { initiativeId }) },
      { label: workgroupId === 'create' ? 'Create' : 'Edit' }
    );
  }

  return breadcrumbs;
};

export const ManageWorkgroupsRoute = () => {
  const { initiativeId, workgroupId } = useParams<{ initiativeId: string; workgroupId?: string }>();
  const isRootOrganization = useAppSelector(isRootOrg);

  return (
    <Dashboard>
      <DashboardRow>
        <CTAdminBreadcrumbs initiativeId={initiativeId} breadcrumbs={getBreadcrumbs(initiativeId, workgroupId)} />
      </DashboardRow>
      <Switch>
        <Route path={getCreatingUrl(initiativeId)}>
          {isRootOrganization ? <CreateOrEditWorkgroup initiativeId={initiativeId} /> : <NotAuthorised />}
        </Route>
        <Route path={`${generateUrl(ROUTES.MANAGE_WORKGROUPS, { initiativeId })}/:workgroupId`}>
          {isRootOrganization ? (
            <CreateOrEditWorkgroup initiativeId={initiativeId} workgroupId={workgroupId} />
          ) : (
            <NotAuthorised />
          )}
        </Route>
        <Route>
          <DashboardSectionTitle title={'Manage workgroups'} />
          <DashboardSection>
            <WorkgroupsTable initiativeId={initiativeId} readOnly={!isRootOrganization} />
          </DashboardSection>
        </Route>
      </Switch>
    </Dashboard>
  );
};
