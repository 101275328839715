import React from 'react';
import { DownloadGroup } from '../../types/download';
import { DownloadButton } from '../button/DownloadButton';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface DownloadSettingOptionProps {
  options: DownloadGroup[];
  isDisabled?: boolean;
}

export const InfoIconWithTooltip = ({ text }: { text: string }) => {
  return (
    <SimpleTooltip text={text}>
      <i className='fa fa-info-circle text-ThemeTextLight ml-2' />
    </SimpleTooltip>
  );
};

export const loadingText = <span className={'ml-2'}>Downloading</span>;

export const DownloadSettingOptions = ({ options, isDisabled }: DownloadSettingOptionProps) => {
  return (
    <>
      <h5 className='fw-bold mt-3'>Download as...</h5>
      {options.map((report, i) => {
        return (
          <div key={i} className='mb-3'>
            {!report.title && !report.tooltip ? null : (
              <div className='d-flex mt-3 align-items-baseline'>
                {report.title ? <h6 className='text-ThemeTextDark'>{report.title}</h6> : null}
                {report.tooltip ? <InfoIconWithTooltip text={report.tooltip} /> : null}
              </div>
            )}
            <div className='d-flex flex-column align-items-start'>
              {report.options.map((option, index) => (
                <DownloadButton
                  key={index}
                  color='link'
                  className={option.icon ? 'ml-3 pl-0' : 'pl-0'}
                  onClick={option.handler}
                  loadingText={loadingText}
                  disabled={isDisabled}
                >
                  {option.icon ? <i className={`${option.icon} mr-2 fs-6`} /> : null}
                  {option.type}
                </DownloadButton>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};
