import { DATE, formatDateUTC } from '@utils/date';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { MinimalSurveyData } from './types';
import { OnChangeValue } from 'react-select';

interface Props {
  selectedSurvey: Pick<MinimalSurveyData, '_id'>;
  surveys: MinimalSurveyData[];
  onClickItem: (item: OnChangeValue<Option<string>, false>) => void;
}

export const AssessmentDropdown = (props: Props) => {
  const { selectedSurvey, surveys, onClickItem } = props;

  const options: Option<string>[] = surveys.map((survey) => ({
    value: survey._id,
    label: `${formatDateUTC(survey.effectiveDate, DATE.MONTH_YEAR_SHORT)} Assessment`,
  }));

  const getFormattedOption = () => {
    const selectedOption = options.find((op) => op.value === selectedSurvey._id);
    if (!selectedOption) {
      return null;
    }
    return {
      label: <span className='text-ThemeAccentExtradark text-xl fw-semibold'>{selectedOption.label}</span>,
      value: selectedOption?.value,
    };
  };

  return (
    <SelectFactory
      selectType={SelectTypes.SingleSelect}
      options={options}
      value={getFormattedOption()}
      onChange={onClickItem}
      isSearchable={false}
      isTransparent
    />
  );
};
