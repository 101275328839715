import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ReportTypes } from '../../../types/statsTypes';
import { TimeRangeSelector } from '@g17eco/molecules/time-range-selector';
import { SettingsSection } from '../../settings-sidebar';
import { StaffFilter } from '../hooks/useStaffFilters';
import { AppCode } from '../../../types/app';
import { SURVEY } from '@constants/terminology';

export enum ProductFilter {
  SGXESGenome = AppCode.SGXESGenome,
  CompanyTracker = 'company_tracker',
  CompanyTrackerPro = 'company_tracker_pro',
  CompanyTrackerEnterprise = 'company_tracker_enterprise',
  CompanyTrackerStarter = 'company_tracker_starter',
  PortfolioTracker = 'portfolio_tracker',
  PortfolioTrackerExchange = 'portfolio_tracker_exchange',
}

export const PRODUCT_TYPES: { label: string; code: string }[] = [
  { code: ProductFilter.SGXESGenome, label: 'ESGenome' },
  { code: ProductFilter.CompanyTracker, label: 'Standard' },
  { code: ProductFilter.CompanyTrackerPro, label: 'Pro' },
  { code: ProductFilter.CompanyTrackerEnterprise, label: 'Enterprise' },
  { code: ProductFilter.CompanyTrackerStarter, label: 'Starter' },
  { code: ProductFilter.PortfolioTracker, label: 'Portfolio' },
  { code: ProductFilter.PortfolioTrackerExchange, label: 'Exchange' },
];

interface StaffFilterProps extends Omit<StaffFilter, 'extraFilterApiParams'> {
  reportType: ReportTypes;
}

export const StaffFilters = (props: StaffFilterProps) => {
  const {
    timeRange,
    dateRange,
    setDateRange,
    filters,
    handleSetShowStaff,
    handleSetProductType,
    handleSearchReferralCode,
    clearFilters,
    reportType,
  } = props;
  return (
    <SettingsSection
      title={'Filters'}
      icon='fa-filter'
      buttons={[
        {
          icon: 'fa-times-circle',
          tooltip: 'Clear filters',
          onClick: clearFilters,
        },
      ]}
    >
      <>
        <FormGroup check className='mx-1 my-3'>
          <Input
            name='showStaff'
            type='checkbox'
            checked={filters.showStaff}
            onChange={(e) => handleSetShowStaff(e.target.checked)}
          />
          <Label for='showStaff' onClick={(e) => handleSetShowStaff(!filters.showStaff)}>
            Include staff companies
          </Label>
        </FormGroup>
        <Label for='fromDate'>{`${reportType === ReportTypes.Company ? 'Company' : SURVEY.CAPITALIZED_SINGULAR} creation date:`}</Label>
        <TimeRangeSelector
          timeRange={timeRange}
          dateRange={dateRange}
          onChangeDateRange={(range) => setDateRange(range)}
          styleProps={{ datePicker: 'start-0' }}
        />
        <Label for='productType' className='mx-1 my-3'>
          Product:
        </Label>

        {PRODUCT_TYPES.map((productType, index) => {
          return (
            <FormGroup check className='mx-1' key={productType.code}>
              <Input
                name={productType.code}
                type='checkbox'
                checked={filters.productCodes.includes(productType.code)}
                onChange={(e) => handleSetProductType(productType.code)}
              />
              <Label for='productType'>{productType.label}</Label>
            </FormGroup>
          );
        })}
        <FormGroup className='mx-1 my-3'>
          <Label for='referralCode'>Referral code used:</Label>
          <Input
            type='text'
            placeholder='Enter a referral code'
            onChange={handleSearchReferralCode}
            value={filters.referralCode}
          />
        </FormGroup>
      </>
    </SettingsSection>
  );
};
