import { Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasProps } from 'reactstrap';
import { DashboardDivider } from '@g17eco/atoms';
import { useEffect, useState } from 'react';
import IconButton from '@components/button/IconButton';
import './styles.scss';
import classNames from 'classnames';
import { useChatbotVisibilityControl } from '@hooks/useChatbotVisibilityControl';

interface Props extends OffcanvasProps {
  header: string;
  isOpen: boolean;
  toggle: () => void;
  children?: JSX.Element | null;
  className?: string;
}

export const Sidebar = (props: Props) => {
  const { isOpen, toggle, children, header, ...offcanvasProps } = props;
  // This is used to delay the state in offCanvas to allow the animation to finish
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useChatbotVisibilityControl({ isHidden: isOpen });

  useEffect(() => {
    if (isOpen) {
      setIsSidebarOpen(isOpen);
      return;
    }
    // if closing then need to wait for animation to finish
    // timeout should match transition duration in css
    const timeoutId = setTimeout(() => {
      setIsSidebarOpen(isOpen);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return (
    <Offcanvas
      {...offcanvasProps}
      isOpen={isSidebarOpen}
      toggle={toggle}
      className={classNames('visible', isOpen ? 'offcanvas-open' : 'offcanvas-close', offcanvasProps.className)}
    >
      <OffcanvasHeader
        toggle={toggle}
        close={
          <IconButton color={'secondary'} icon={'fa-light fa-sidebar-flip'} onClick={toggle} className='border-0' />
        }
      >
        {header}
      </OffcanvasHeader>
      <DashboardDivider className='mx-4' />
      <OffcanvasBody>{children}</OffcanvasBody>
    </Offcanvas>
  );
};
