import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { type JSX, useCallback, useEffect, useState } from 'react';
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  $createParagraphNode,
  FORMAT_ELEMENT_COMMAND,
  $isElementNode,
  COMMAND_PRIORITY_CRITICAL,
} from 'lexical';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import { $setBlocksType } from '@lexical/selection';
import { $createHeadingNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { Button, ButtonProps, Popover, PopoverBody } from 'reactstrap';
import { useToggle } from '@hooks/useToggle';
import { getSelectedNode } from '../utils';

const ToolbarIconButton = ({
  icon,
  onClick,
  isActive,
  id,
  caret,
}: Pick<ButtonProps, 'icon' | 'onClick' | 'id'> & { isActive: boolean; caret?: boolean }) => {
  const iconColor = isActive ? 'text-ThemeTextDark' : 'text-ThemeIconSecondary';
  return (
    <Button
      id={id}
      outline={false}
      color='transparent'
      onClick={onClick}
      className={isActive ? 'active' : ''}
      size='sm'
    >
      <i className={`fal ${icon} ${iconColor}`} />
      {caret ? <i className={`ms-1 fal fa-caret-down ${iconColor}`} /> : null}
    </Button>
  );
};

const HEADING_TAGS = ['h1', 'h3', 'h5'] as const;
const BLOCK_FORMATS = ['paragraph', ...HEADING_TAGS] as const;
type BlockFormat = (typeof BLOCK_FORMATS)[number];
const isBlockFormat = (format: string): format is BlockFormat => {
  return BLOCK_FORMATS.includes(format as BlockFormat);
};
const ELEMENT_FORMATS = ['left', 'center', 'right'] as const;
type ElementFormat = (typeof ELEMENT_FORMATS)[number];

const icon: Record<BlockFormat | ElementFormat, string> = {
  h1: 'h1',
  h3: 'h2',
  h5: 'h3',
  paragraph: 'text-size',
  left: 'align-left',
  center: 'align-center',
  right: 'align-right',
};
const getIcon = (blockType: BlockFormat | ElementFormat) => {
  return `fal fa-${icon[blockType] ?? icon['paragraph']}`;
};

const blockFormatDropdownId = 'block-formatting-dropdown';
const BlockFormatDropDown = ({ editor, blockType }: { blockType: string; editor: LexicalEditor }): JSX.Element => {
  const [popoverOpen, toggle] = useToggle(false);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
    toggle();
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if (!selection) {
          return;
        }
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
    toggle();
  };

  return (
    <>
      <ToolbarIconButton
        id={blockFormatDropdownId}
        icon={isBlockFormat(blockType) ? getIcon(blockType) : getIcon('paragraph')}
        onClick={toggle}
        isActive={popoverOpen}
        caret
      />
      <Popover
        isOpen={popoverOpen}
        toggle={toggle}
        placement='bottom'
        target={blockFormatDropdownId}
        trigger='legacy'
        hideArrow
        delay={0}
      >
        <PopoverBody className='editor__toolbar__submenu p-1'>
          <ToolbarIconButton icon='fal fa-text-size' onClick={formatParagraph} isActive={blockType === 'paragraph'} />
          {HEADING_TAGS.map((headingType) => (
            <ToolbarIconButton
              key={headingType}
              icon={getIcon(headingType)}
              onClick={() => formatHeading(headingType as HeadingTagType)}
              isActive={blockType === headingType}
            />
          ))}
        </PopoverBody>
      </Popover>
    </>
  );
};

const elementFormatDropdownId = 'element-formatting-dropdown';
const ElementFormatDropdown = ({ editor, value }: { editor: LexicalEditor; value: ElementFormat }) => {
  const [popoverOpen, toggle] = useToggle(false);
  const handleClick = (elementFormat: ElementFormat) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, elementFormat);
    toggle();
  };

  return (
    <>
      <ToolbarIconButton
        id={elementFormatDropdownId}
        icon={getIcon(value)}
        onClick={toggle}
        isActive={popoverOpen}
        caret
      />
      <Popover
        isOpen={popoverOpen}
        toggle={toggle}
        placement='bottom'
        target={elementFormatDropdownId}
        trigger='legacy'
        hideArrow
        delay={0}
      >
        <PopoverBody className='editor__toolbar__submenu p-1'>
          {ELEMENT_FORMATS.map((format) => (
            <ToolbarIconButton
              key={format}
              icon={getIcon(format)}
              onClick={() => handleClick(format)}
              isActive={value === format}
            />
          ))}
        </PopoverBody>
      </Popover>
    </>
  );
};

const Divider = () => (
  <div
    className='border border-left mx-1'
    style={{ height: '15px', borderColor: 'var(--theme-NeutralsLight) !important' }}
  />
);

export const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const [blockType, setBlockType] = useState<string>('paragraph');
  const [elementFormat, setElementFormat] = useState<ElementFormat>('left');

  const [{ isBold, isItalic, isUnderline }, setFormat] = useState({
    isBold: false,
    isItalic: false,
    isUnderline: false,
  });

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setFormat({
        isBold: selection.hasFormat('bold'),
        isItalic: selection.hasFormat('italic'),
        isUnderline: selection.hasFormat('underline'),
      });

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      const format = ($isElementNode(node) ? node.getFormatType() : parent?.getFormatType()) as ElementFormat;
      setElementFormat(ELEMENT_FORMATS.includes(format) ? format : 'left');
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, updateToolbar]);

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <div className='toolbar z-auto d-flex justify-content-end align-items-center p-1 sticky-top background-ThemeBgExtralight'>
      <BlockFormatDropDown blockType={blockType} editor={editor} />
      <Divider />
      <ToolbarIconButton
        icon='fa-bold'
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        isActive={isBold}
      />
      <ToolbarIconButton
        icon='fa-italic'
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        isActive={isItalic}
      />
      <ToolbarIconButton
        icon='fa-underline'
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        isActive={isUnderline}
      />
      <Divider />
      <ToolbarIconButton icon='fa-list-ul' onClick={formatBulletList} isActive={blockType === 'ul'} />
      <ToolbarIconButton icon='fa-list-ol' onClick={formatNumberedList} isActive={blockType === 'ol'} />
      <Divider />
      <ElementFormatDropdown editor={editor} value={elementFormat} />
    </div>
  );
};
