import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { OrderingDirection } from '@g17eco/types/common';
import { ColumnCode, OrderingColumn } from '@g17eco/types/custom-report';
import { DIRECTION_LABEL } from '@utils/sort';

const getOptions = (columnCode: ColumnCode) =>
  (columnCode === 'columnLabel'
    ? Object.values(OrderingDirection)
    : [OrderingDirection.Asc, OrderingDirection.Desc]
  ).map((option) => ({
    label: DIRECTION_LABEL[option],
    value: option,
  }));

interface Props {
  column: OrderingColumn;
  onChange: (value: OrderingDirection) => void;
  isDisabled?: boolean;
  isTransparent?: boolean;
}
export const OrderingDirectionSelect = ({
  column: { code, direction },
  onChange,
  isDisabled,
  isTransparent,
}: Props) => {
  const options = getOptions(code);

  return (
    <div style={{ width: '120px' }} className='ml-2'>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        isSearchable={false}
        options={options}
        value={options.find((option) => option.value === direction)}
        onChange={(op) => (op ? onChange(op.value) : undefined)}
        isMenuPortalTargetBody
        isDisabled={isDisabled}
        isFlexibleSize
        isTransparent={isTransparent}
      />
    </div>
  );
};
