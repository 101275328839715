import { ArchiveProgress, ArchiveStep } from './ArchiveSubsidiaryModal';
import { UserManagementStep } from './UserManagementStep';
import { InitiativePlain } from '../../../../types/initiative';
import { WarningStep } from './WarningStep';
import { ConfirmationStep } from './ConfirmationStep';
import { MinReportingLevel } from '../../types';
import { ChildrenTree } from '@g17eco/types/tree';

interface ArchiveSubsidiaryStepProps {
  archiveProgress: ArchiveProgress;
  handleChange: (changes: Partial<ArchiveProgress>) => void;
  selectedInitiative: MinReportingLevel;
  initiativeTreeList: InitiativePlain[];
  treeRootData: ChildrenTree;
  handleToggle: () => void;
  handleUpdate: () => void;
}

export const ArchiveSubsidiaryModalContent = (props: ArchiveSubsidiaryStepProps) => {
  const { archiveProgress, selectedInitiative, handleChange, handleToggle, handleUpdate, initiativeTreeList, treeRootData } = props;
  switch (archiveProgress.step) {
    case ArchiveStep.UserManagement:
      return (
        <UserManagementStep
          selectedInitiative={selectedInitiative}
          initiativeTreeList={initiativeTreeList}
          handleChange={handleChange}
          handleToggle={handleToggle}
        />
      );
    case ArchiveStep.Confirmation:
      return (
        <ConfirmationStep
          selectedInitiative={selectedInitiative}
          userChanges={archiveProgress.userChanges}
          treeRootData={treeRootData}
          handleToggle={handleToggle}
          handleUpdate={handleUpdate}
        />
      );
    case ArchiveStep.Warning:
    default:
      return <WarningStep handleChange={handleChange} handleToggle={handleToggle} />;
  }
};
