import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';

export enum MaterialityAssessmentUtrCodes {
  NumStaff = 'materiality-2024/num-staff',
  AnnualSales = 'materiality-2024/annual-sales',
  CapitalEmployed = 'materiality-2024/capital-employed',
  OperationTime = 'materiality-2024/operation-time',
  Sector = 'materiality-2024/sector',
}

export const materialityContextUtrCodes = [
  MaterialityAssessmentUtrCodes.NumStaff,
  MaterialityAssessmentUtrCodes.AnnualSales,
  MaterialityAssessmentUtrCodes.CapitalEmployed,
  MaterialityAssessmentUtrCodes.OperationTime,
  MaterialityAssessmentUtrCodes.Sector,
];

export const isMaterialityQuestionReadOnly = (utr: Pick<UniversalTrackerPlain, 'code'>) => {
  return (materialityContextUtrCodes as string[]).includes(utr.code);
};
