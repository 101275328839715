import { Button, Spinner } from 'reactstrap';
import {
  BackgroundJob,
  JobStatus,
  BulkImportTask,
  Task,
  TaskStatus,
  TaskType,
  ValidationError,
} from '@g17eco/types/background-jobs';
import { DATE, formatDateNonUTC } from '@utils/date';
import { ProgressLoader } from '@g17eco/atoms';
import { SURVEY } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

export const isProcessingImportData = (job: BackgroundJob, latestTask: Task) => {
  const { type, status } = latestTask;
  if (type !== TaskType.ImportData) {
    return false;
  }
  if (status === TaskStatus.Processing) {
    return true;
  }
  // Job is about to start and import task in pending, treat as processing
  return job.status === JobStatus.WaitForRun && status === TaskStatus.Pending;
};

const uploadingHint = (
  <span className='text-center'>
    Your request is currently being processed. Please be patient as depending on the file size being imported, this
    process could take upwards of 10 minutes.
    <br />
    <br />
    We will send you a notification once your data is ready to be imported
  </span>
);

interface ImportActionsProps {
  job: BackgroundJob;
  latestTask: BulkImportTask;
  isLoading: boolean;
  runBulkImportJob: (jobId: string) => void;
  deleteBulkImportJob: (jobId: string) => void;
  handleShowIssues: (errors: ValidationError[]) => void;
}

const ViewErrorsButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => (
  <Button
    disabled={disabled}
    type='button'
    color='warning'
    onClick={onClick}
    className='d-flex align-items-center'
    size='sm'
  >
    <i className='fal fa-triangle-exclamation mr-1 text-sm' /> View errors
  </Button>
);

const ReviewButton = (props: Pick<ImportActionsProps, 'latestTask' | 'handleShowIssues'>) => {
  const { data, type, status } = props.latestTask;
  if (type !== TaskType.ProcessFile || status !== TaskStatus.Error) {
    return null;
  }

  const errors = data.errors;
  if (!errors || !errors.length) {
    return null;
  }

  return (
    <SimpleTooltip text={'The following issues occurred when processing this file.'}>
      <ViewErrorsButton onClick={() => props.handleShowIssues(errors)} />
    </SimpleTooltip>
  );
};

export const ImportActions = ({
  job,
  latestTask,
  isLoading,
  runBulkImportJob,
  deleteBulkImportJob,
  handleShowIssues,
}: ImportActionsProps) => {
  if (job.deletedDate) {
    return (
      <div className='d-flex align-items-center gap-3'>
        <div className='text-ThemeTextMedium'>
          {job.status === JobStatus.Error ? 'FAILED' : 'DELETED'}:{' '}
          {formatDateNonUTC(job.deletedDate, DATE.DEFAULT_SPACES_WITH_TIME)}
        </div>
        <ReviewButton latestTask={latestTask} handleShowIssues={handleShowIssues} />
      </div>
    );
  }

  if (job.completedDate) {
    return (
      <div className='d-flex align-items-center gap-3 text-ThemeTextMedium'>
        COMPLETED: {formatDateNonUTC(job.completedDate, DATE.DEFAULT_SPACES_WITH_TIME)}
        <i className='fal fa-circle-check text-ThemeSuccessMedium text-lg' />
      </div>
    );
  }

  const { type, status } = latestTask;

  switch (true) {
    case type === TaskType.UploadFile || type === TaskType.ProcessFile: {
      return (
        <div className='d-flex gap-3 text-ThemeTextMedium'>
          <div className='text-md'>Uploading file</div>
          <ProgressLoader />
          <SimpleTooltip text={uploadingHint}>
            <i className='fa-solid fa-circle-info text-lg'></i>
          </SimpleTooltip>
        </div>
      );
    }
    case isProcessingImportData(job, latestTask): {
      return (
        <div className='loading-spinner'>
          <Spinner color='primary'>Loading...</Spinner>
        </div>
      );
    }
    case type === TaskType.ImportData && status === TaskStatus.Pending: {
      const { data } = latestTask;
      const hasErrors = data.errors && data.errors.length > 0;

      return (
        <div className='d-flex gap-3'>
          <SimpleTooltip
            text={`You file has been uploaded to the system. Click here to import ${SURVEY.SINGULAR} data.`}
          >
            <Button
              type='button'
              color='primary'
              disabled={isLoading}
              onClick={() => runBulkImportJob(job._id)}
              size='sm'
            >
              Import data
            </Button>
          </SimpleTooltip>
          <SimpleTooltip
            text={
              hasErrors ? 'You may encounter the following issues when importing this file.' : 'No issues have found.'
            }
          >
            <ViewErrorsButton onClick={() => handleShowIssues(data.errors)} disabled={!hasErrors} />
          </SimpleTooltip>
          <SimpleTooltip text='Cancel import and delete file.'>
            <Button
              type='button'
              color='danger'
              outline
              className='px-2'
              onClick={() => deleteBulkImportJob(job._id)}
              size='sm'
            >
              <i className='fa-solid fa-trash'></i>
            </Button>
          </SimpleTooltip>
        </div>
      );
    }
    default:
      return null;
  }
};
