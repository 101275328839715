import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { generateUrl } from '../../../routes/util';
import { NavCode, TemplateTableStats } from '../types';
import { useDeleteTemplateMutation, useDuplicateTemplateMutation } from '../../../api/survey-templates';
import { useDispatch } from 'react-redux';
import { addSiteAlert, DEFAULT_TIMEOUT, SiteAlertColors } from '../../../slice/siteAlertsSlice';
import { AnalyticsEvents } from '../../../services/analytics/AnalyticsEvents';
import { getAnalytics } from '../../../services/analytics/AnalyticsService';
import { Actions } from '../constants';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface TemplateOptionsDropdownProps {
  template: TemplateTableStats;
  tooltip?: string;
}

const analytics = getAnalytics();

export const TemplateDropdownOptions = ({ template, tooltip }: TemplateOptionsDropdownProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteTemplate] = useDeleteTemplateMutation();
  const [duplicateTemplate] = useDuplicateTemplateMutation();
  const { initiativeId = '' } = useParams<{ initiativeId: string }>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);
  const isOpen = selectedTemplateId === template._id;

  const dropdownActions = {
    [Actions.Duplicate]: {
      handler: duplicateTemplate,
      message: `Template ${template.name} duplicated.`,
      event: AnalyticsEvents.SurveyTemplateDuplicated,
    },
    [Actions.Delete]: {
      handler: deleteTemplate,
      message: `Template ${template.name} removed.`,
      event: AnalyticsEvents.SurveyTemplateDeleted,
    },
  };

  const handleToggleOptionsMenu = (templateId: string) => {
    if (selectedTemplateId === templateId) {
      return setSelectedTemplateId(undefined);
    }
    setSelectedTemplateId(templateId);
  };

  const handleGoTo = (page: NavCode) => {
    return history.push(
      `${generateUrl(ROUTES.SURVEY_TEMPLATES_VIEW, {
        initiativeId,
        templateId: selectedTemplateId,
        page,
      })}`
    );
  };

  const handleTemplate = async (mode: Actions) => {
    if (!selectedTemplateId) {
      return;
    }
    const { handler, message, event } = dropdownActions[mode];
    try {
      await handler(selectedTemplateId).unwrap();
      await analytics.track(event, {
        templateId: selectedTemplateId,
      });
      dispatch(
        addSiteAlert({
          content: message,
          color: SiteAlertColors.Success,
          timeout: DEFAULT_TIMEOUT,
        })
      );
    } catch (error) {
      return dispatch(
        addSiteAlert({
          content: error.message,
          color: SiteAlertColors.Danger,
        })
      );
    }
  };

  return (
    <Dropdown isOpen={isOpen} toggle={() => handleToggleOptionsMenu(template._id)}>
      <SimpleTooltip text={tooltip ?? 'Access this template\'s configuration, units or scope'}>
        <DropdownToggle color='transparent' outline className='px-2'>
          <i className='fa-solid fa-bars' />
        </DropdownToggle>
      </SimpleTooltip>
      <DropdownMenu>
        <DropdownItem onClick={() => handleGoTo(NavCode.BulkSurveysCreate)}>
          <i className='fa-light fa-layer-group mr-2' />
          Use template
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => handleGoTo(NavCode.History)}>
          <i className='fa-solid fa-clock-rotate-left mr-2' />
          View history
        </DropdownItem>
        <DropdownItem onClick={() => handleGoTo(NavCode.Configuration)}>
          <i className='fa-solid fa-pencil mr-2' />
          Edit
        </DropdownItem>
        <DropdownItem onClick={() => handleTemplate(Actions.Duplicate)}>
          <i className='fa-solid fa-copy mr-2' />
          Duplicate
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => handleTemplate(Actions.Delete)}>
          <i className='fa-solid fa-trash text-ThemeDangerMedium mr-2' />
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
