import { ColumnDef, Table } from '@g17eco/molecules/table';
import { Column, ColumnCode } from '@g17eco/types/custom-report';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getColumns, getColumnsMap, isTabularTemplate, isTransposedTemplate } from './utils';
import { CustomReportTemplateType } from './types';
import classnames from 'classnames';
import { useToggle } from '@hooks/useToggle';
import { SURVEY } from '@constants/terminology';

type PreviewRow = Partial<Record<ColumnCode, string>>;
const defaultPreviewData: PreviewRow[] = [
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel: 'Name of the organization',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: 'Organization name',
    subsidiaryCode: 'subsidiary-code',
    subsidiaryName: 'Subsidiary Name',
    subsidiaryHierarchy: 'Root Org / Subsidiary Name',
    surveyName: `Name of the ${SURVEY.SINGULAR}`,
  },
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel: 'Reporting period start date',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: '2024-01-10',
    subsidiaryCode: 'subsidiary-code',
    subsidiaryName: 'Subsidiary Name',
    subsidiaryHierarchy: 'Root Org / Subsidiary Name',
    surveyName: `Name of the ${SURVEY.SINGULAR}`,
  },
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel: 'Reporting period end date',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: '2025-01-10',
    subsidiaryCode: 'subsidiary-code',
    subsidiaryName: 'Subsidiary Name',
    subsidiaryHierarchy: 'Root Org / Subsidiary Name',
    surveyName: `Name of the ${SURVEY.SINGULAR}`,
  },
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel:
      'The organization has reported in accordance with the GRI Standards for the reporting period mentioned above',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: 'Yes',
    subsidiaryCode: 'subsidiary-code',
    subsidiaryName: 'Subsidiary Name',
    subsidiaryHierarchy: 'Root Org / Subsidiary Name',
    surveyName: `Name of the ${SURVEY.SINGULAR}`,
  },
];

const transposedPreviewData = [
  ...defaultPreviewData.map((row) => ({ ...row, row: '1' })),
  ...defaultPreviewData.map((row) => ({ ...row, row: '2' })),
];

const tabularPreviewData = [
  {
    assuranceStatus: '',
    code: 'gri/2020/303-5/a',
    note: '',
    mapping: '',
    instructions:
      'Water consumption measures water used by an organization such that it is no longer available for use by the ecosystem or local community in the reporting period.',
    valueLabel: 'Total water consumption from all areas in megaliters.',
    standard: 'GRI 2019',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Total Water Consumption',
    valueType: 'number',
    unit: 'm3',
    updatedDate: '01/10/2024',
    input: '82.123',
    subsidiaryCode: 'subsidiary-code',
    subsidiaryName: 'Subsidiary Name',
    subsidiaryHierarchy: 'Root Org / Subsidiary Name',
    surveyName: `Name of the ${SURVEY.SINGULAR}`,
  },
  {
    assuranceStatus: '',
    code: 'gri/2020/102-41',
    note: '',
    mapping: '',
    instructions:
      'Collective bargaining refers to all negotiations which take place between one or more employers or employers organizations, on the one hand, and one or more workers organizations (trade unions).  Therefore, a collective bargaining agreement represents a form of joint decision-making concerning the organization’s operations. Collective agreements can be made at various levels and for categories and groups of workers. Collective agreements can be at the level of the organization; at the industry level, in countries where that is the practice; or at both.',
    valueLabel: 'Percentage of total employees covered by collective bargaining agreements.',
    standard: 'GRI 2019',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'percentage',
    unit: '%',
    updatedDate: '01/10/2024',
    input: '100',
    subsidiaryCode: 'subsidiary-code',
    subsidiaryName: 'Subsidiary Name',
    subsidiaryHierarchy: 'Root Org / Subsidiary Name',
    surveyName: `Name of the ${SURVEY.SINGULAR}`,
  },
];

interface Props {
  columns: Column[];
  templateType: CustomReportTemplateType;
}

export const PreviewBtn = ({ columns, templateType }: Props) => {
  const [isOpen, toggle] = useToggle();

  const tableColumns: ColumnDef<PreviewRow>[] = (columns.length > 0 ? columns : getColumns(templateType)).map(
    (col) => ({
      header: getColumnsMap(templateType)[col.code].header,
      cell: ({ row }) => row.original[col.code],
      meta: {
        cellProps: {
          className: 'text-truncate',
          style: { maxWidth: 150 },
        },
      },
    })
  );

  const renderPreviewTable = () => {
    const className = 'overflow-scroll';
    if (isTabularTemplate(templateType)) {
      return (
        <>
          {tabularPreviewData.map((rowData, index) => (
            <Table
              key={index}
              columns={tableColumns}
              data={[rowData]}
              className={classnames(className, { 'mb-4': index !== tabularPreviewData.length - 1 })}
            />
          ))}
        </>
      );
    }

    const data = isTransposedTemplate(templateType) ? transposedPreviewData : defaultPreviewData;

    return <Table columns={tableColumns} data={data} className={className} />;
  };

  return (
    <>
      <Button color='link-secondary' onClick={toggle} key={`template-builder-preview-${isOpen}`} className='ms-3'>
        <i className='fa fa-eye mr-2' />
        Preview examples
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} backdrop='static' keyboard>
        <ModalHeader toggle={toggle}>Custom report preview</ModalHeader>
        <ModalBody>{renderPreviewTable()}</ModalBody>
        <ModalFooter>
          <Button color='link-secondary' onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
