import { DelegatedUsers, User } from '@api/survey-users';
import { UserRoles } from '@constants/user';
import { SurveyUserRoles } from '@constants/users';
import { CurrentUserData } from '@reducers/current-user';
import { InitiativePermissions } from '@services/permissions/InitiativePermissions';
import { SurveyPermissions } from '@services/permissions/SurveyPermissions';

export enum RoleLevel {
  Initiative = 'initiative',
  Survey = 'survey',
  Utrv = 'utrv',
}

export const UTRV_DELEGATE_ROLES = [SurveyUserRoles.Stakeholder, SurveyUserRoles.Verifier] as const;
export const SURVEY_DELEGATE_ROLES = [
  SurveyUserRoles.Admin,
  SurveyUserRoles.Stakeholder,
  SurveyUserRoles.Verifier,
] as const;

export type SurveyDelegateRole = (typeof SURVEY_DELEGATE_ROLES)[number];

export const hasAtLeastOneRoleLevel = (levels: RoleLevel[], userRole: SurveyUserRoles, user: User) => {
  if (userRole === SurveyUserRoles.Monitor) {
    // Not support this role yet. Currently care about other roles only.
    return false;
  }

  const keyMap: Record<SurveyDelegateRole, 'canContributeRoles' | 'canVerifyRoles' | 'canManageRoles'> = {
    [SurveyUserRoles.Stakeholder]: 'canContributeRoles',
    [SurveyUserRoles.Verifier]: 'canVerifyRoles',
    [SurveyUserRoles.Admin]: 'canManageRoles',
  };
  const key = keyMap[userRole];

  return levels.some((level) => {
    switch (level) {
      case RoleLevel.Initiative:
        return user.initiativeRoles?.some((r) => InitiativePermissions[key].includes(r as UserRoles));
      case RoleLevel.Survey:
        return user.surveyRoles?.some((r) => SurveyPermissions[key].includes(r as SurveyUserRoles));
      case RoleLevel.Utrv:
        return user.roles?.some((r) => SurveyPermissions[key].includes(r as SurveyUserRoles));
      default:
        return false;
    }
  });
};

export const getDelegatedUtrvsCount = (userRole: SurveyUserRoles, user: User) => {
  return user.count?.[userRole === SurveyUserRoles.Stakeholder ? 'contributed' : 'verified'] ?? 0;
};

export const isAssignedToAll = (userRole: SurveyUserRoles, user: User, utrvsLength: number) => {
  if (!UTRV_DELEGATE_ROLES.includes(userRole as (typeof UTRV_DELEGATE_ROLES)[number])) {
    return false;
  }

  const isAssignedToAll = getDelegatedUtrvsCount(userRole, user) === utrvsLength;
  return hasAtLeastOneRoleLevel([RoleLevel.Initiative, RoleLevel.Survey], userRole, user) || isAssignedToAll;
};

export const filterUsersByRole = (users: DelegatedUsers, userRole: SurveyUserRoles) => {
  if (userRole === SurveyUserRoles.Monitor) {
    // Not support this role yet. Currently care about other roles only.
    return [];
  }

  const keyMap: Record<SurveyDelegateRole, 'contributors' | 'verifiers' | 'admins'> = {
    [SurveyUserRoles.Stakeholder]: 'contributors',
    [SurveyUserRoles.Verifier]: 'verifiers',
    [SurveyUserRoles.Admin]: 'admins',
  };
  const key = keyMap[userRole];
  return users[key];
};

export const filterUsersByRoleAndLevels = ({
  users,
  role,
  roleLevels,
}: {
  users: User[];
  role: SurveyUserRoles;
  roleLevels: RoleLevel[];
}) => {
  return users.filter((user) => hasAtLeastOneRoleLevel(roleLevels, role, user));
};

export const putCurrentUserToTop = (
  userRole: SurveyUserRoles,
  users: User[],
  currentUser: CurrentUserData | undefined
) => {
  const currentUserIndex = users.findIndex(
    (user) =>
      user._id === currentUser?._id && hasAtLeastOneRoleLevel([RoleLevel.Initiative, RoleLevel.Survey], userRole, user)
  );

  return currentUserIndex < 0
    ? users
    : [users[currentUserIndex], ...users.slice(0, currentUserIndex), ...users.slice(currentUserIndex + 1)];
};
