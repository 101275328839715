import { useMemo, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import G17Client from '../../../services/G17Client';
import { Organization, UniversalTrackerValueAssurance } from '../../../types/assurance';
import { BlockingLoader } from '../../loader/BlockingLoader';
import { renderSuggestion } from '../../utrv-comments/utils';
import { useGetUtrvCommentsQuery } from '../../../api/utrv-comments';
import { BasicAlert } from '@g17eco/molecules/alert';

interface DisputeButtonProps {
  utrvId: string;
  assurancePortfolioId: string;
  handleReload: () => void;
  assuranceUtrv?: UniversalTrackerValueAssurance;
  organization: Organization;
  isDisabled: boolean;
}

export const DisputeButton = ({
  utrvId,
  assurancePortfolioId,
  assuranceUtrv,
  handleReload,
  organization,
  isDisabled,
}: DisputeButtonProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitData, setSubmitData] = useState('');
  const { data: utrvComments } = useGetUtrvCommentsQuery(utrvId);
  const users = useMemo(() => {
    if (!utrvComments) {
      return [];
    }
    return utrvComments.users;
  }, [utrvComments]);

  const toggleModal = () => setIsOpen((prevState) => !prevState);
  const formValid = submitData.length > 0;

  const mentionUsers: SuggestionDataItem[] = useMemo(
    () => users.map((user) => ({ id: user._id, display: `${user.firstName} ${user.surname}` })),
    [users]
  );

  const handleSubmit = async () => {
    if (!assuranceUtrv) {
      return;
    }
    setIsLoading(true);
    const text = `Answer disputed by ${organization.name} : ${submitData}`;
    await G17Client.disputeQuestions(assurancePortfolioId, { questions: [assuranceUtrv._id], text })
      .then(() => {
        setSubmitData('');
        setIsLoading(false);
        toggleModal();
        handleReload();
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button disabled={isDisabled} outline className='p-2 px-3 mr-2' onClick={toggleModal}>
        Dispute answer
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static'>
        {isLoading ? (
          <div className='loader-wrapper'>
            <BlockingLoader />
          </div>
        ) : null}
        <ModalHeader toggle={toggleModal}>Dispute provided answer</ModalHeader>
        <ModalBody>
          <BasicAlert type={'danger'}>{errorMessage}</BasicAlert>
          <strong>Reason for dispute</strong>
          <div className='mt-1 card-text'>
            <MentionsInput
              className='mentions-input'
              value={submitData}
              onChange={(e) => setSubmitData(e.target.value)}
              singleLine={false}
            >
              <Mention
                trigger='@'
                data={mentionUsers}
                className='mention'
                renderSuggestion={renderSuggestion}
                displayTransform={(id, display) => ` @${display} `}
                appendSpaceOnAdd={true}
              />
            </MentionsInput>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='link' onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={!formValid || isLoading} onClick={handleSubmit}>
            Submit dispute
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
