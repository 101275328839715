import React from 'react';
import { Chart } from 'react-google-charts';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, DataSource } from './GenerateCharts';
import { getDataAsPercentageTableSum } from '@routes/summary/insights/utils/helpers';

const WIDTH = 300;
const HEIGHT = 300;

export interface BoardDiversityDataSource extends DataSource {
  disabilityTotal?: number;
  minorityTotal?: number;
  otherTotal?: number;
}

export const BoardDiversity = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/405-1/a';
  const male = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'number_members',
    percentageColumn: 'male',
  });
  const female = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'number_members',
    percentageColumn: 'female',
  });
  const disabilityTotal = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'number_members',
    percentageColumn: 'disability',
  });
  const minorityTotal = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'number_members',
    percentageColumn: 'minority',
  });

  const totalGender = male + female;
  const totalDiversity = disabilityTotal + minorityTotal;

  const reportData: BoardDiversityDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    disabilityTotal: disabilityTotal,
    minorityTotal: minorityTotal,
    otherTotal: totalGender > totalDiversity ? totalGender - totalDiversity : 0,
  };
  const total = disabilityTotal + minorityTotal + (reportData.otherTotal ?? 0);
  const disabilityPercent = !total ? 0 : (100 * disabilityTotal) / total;
  const minorityPercent = !total ? 0 : (100 * minorityTotal) / total;
  const otherPercent = !total ? 0 : (100 * (reportData.otherTotal ?? 0)) / total;

  const chartData = [
    ['Group', 'Count'],
    ['Disability', disabilityPercent],
    ['Minority', minorityPercent],
    ['Other', otherPercent],
  ];

  return (
    <Chart
      chartType='PieChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        chartArea: {
          top: 50,
          bottom: 20,
          left: 20,
          right: 20,
        },
        legend: {
          position: 'top',
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'boardDiversity', data: reportData, handler: updateData })]}
    />
  );
});
