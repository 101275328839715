import Chart from 'react-google-charts';
import { ChartDataLoaded, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { getChartOptions } from '@routes/summary/insights/utils/chartStyles';
import { chartTooltipOptions, ChartType } from '@routes/summary/insights/utils/constants';
import {
  getVAxisTitle,
} from './multi-utrs-chart/utils';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { useEffect, useState } from 'react';
import { getChartMaxValue } from '@utils/charts';
import { BaseHistoricalUtr, DataProps } from '../types';

type Props<T> = Pick<GridDashboardChartItem, 'calculation' | 'variables' | 'unitText'> & {
  utrsData: T[];
  overrideOptions?: ReactGoogleChartProps['options'];
  getChartData: (props: DataProps<T>) => Promise<ChartDataLoaded>;
};

export const LineChart = <T extends BaseHistoricalUtr>({
  getChartData,
  utrsData,
  variables,
  calculation,
  unitText,
  overrideOptions,
}: Props<T>) => {
  const [data, setData] = useState<ChartDataLoaded | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation }).then((data) => setData(data));
    }
    return () => {
      setData(undefined);
    };
  }, [calculation, getChartData, utrsData, variables]);

  if (!calculation || !data?.chartData.length) {
    return null;
  }

  const vAxisTitle = getVAxisTitle({ unitText, calculation, utrsData });
  const vAxisMaxValue = getChartMaxValue(data?.chartData);

  return (
    <div className='w-100 h-100'>
      <Chart
        chartType={ChartType.LineChart}
        data={data.chartData}
        width={'100%'}
        height={'100%'}
        options={{
          tooltip: chartTooltipOptions,
          ...(overrideOptions ??
            getChartOptions({
              chartType: ChartType.LineChart,
              vAxisTitle,
              vAxisMaxValue,
            })),
        }}
      />
    </div>
  );
};
