import { FilterToggle } from '@g17eco/molecules/filter-toggle';
import { DebouncedSearchBox } from '@g17eco/molecules/search';
import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';

import { useToggle } from '@hooks/useToggle';
import { documentOptions } from './utils';
import { FilterParams } from './DocumentTable';

export const DocumentFilters = ({
  filters,
  onChangeFilters,
}: {
  filters: FilterParams;
  onChangeFilters: (key: keyof FilterParams, value?: string) => void;
}) => {
  const [showFilters, toggleShowFilters] = useToggle(false);

  return (
    <>
      <div className='d-flex gap-3 align-items-center mb-2'>
        <DebouncedSearchBox
          placeholder='Search for file...'
          onTextChange={(value) => onChangeFilters('searchText', value)}
          classNames={{ wrapper: 'flex-fill' }}
        />
        <FilterToggle toggleFilters={toggleShowFilters} label='Filters' />
      </div>
      {showFilters && (
        <div className='row'>
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            options={documentOptions}
            value={documentOptions.find(({ value }) => value === filters.type)}
            onChange={(op) => onChangeFilters('type', op?.value)}
            isClearable={true}
          />
        </div>
      )}
    </>
  );
};
