import { FormScheduledDate, ScheduledDate } from '@models/surveyData';
import { getDateTime, getFutureDate, getUTCEndOf, isAfter, isBefore, isSame, setDate } from '@utils/date';
import { ConfigFormData, ErrorState } from './types';

export enum ERRORS {
  NO_ERROR = 0,
  REPORT_CONFLICT = 1,
  REPORT_LIMIT = 2,
}

export const requiredFields: (keyof ConfigFormData)[] = ['period', 'year', 'month', 'initiativeId'];

export const editRequiredFields: (keyof ConfigFormData)[] = [
  'verificationRequired',
  'evidenceRequired',
  'noteRequired',
];

export const isConfirmButtonDisabled = (form: ConfigFormData, errorState: ErrorState) => {
  if (!isValidDeadlineScheduledDates(form)) {
    return true;
  }
  if (errorState.error !== ERRORS.NO_ERROR || errorState.fields.length > 0) {
    return true;
  }
  return false;
};

export const createEffectiveDate = (form: ConfigFormData) => {
  if (form.year && form.month !== undefined) {
    const date = setDate(
      {
        year: form.year,
        month: form.month,
        isUtc: true
      }
    );
    return getUTCEndOf('month', date);
  }
};

export const getScheduledDates = (items: ScheduledDate[] = []) => items.map((item) => item.date);

export const toFormScheduledDates = (scheduledDates: ScheduledDate[] | undefined): FormScheduledDate[] | undefined => {
  return scheduledDates?.map((scheduledDate) => ({ ...scheduledDate, status: 'original' }));
};

export const compareScheduledDates = (
  dates: ScheduledDate[] | undefined = [],
  compareDates: ScheduledDate[] | undefined = []
) => {
  if (dates.length === 0 && compareDates.length === 0) {
    return true; // Both empty therefore equal;
  }

  if (dates.length !== compareDates.length) {
    return false; // Different amount of items, must be different
  }

  const currentItems = getScheduledDates(dates);
  const compareItems = getScheduledDates(compareDates);
  return currentItems.every((date) => {
    const matchedCurrentItems = currentItems.filter((currentDate) => isSame(date, currentDate, 'day'));
    const matchedCompareItems = compareItems.filter((compareDate) => isSame(date, compareDate, 'day'));
    return matchedCurrentItems.length === matchedCompareItems.length; // every date in currentItems must have same number of its exist in compareItems;
  });
};

export const isValidDeadlineScheduledDates = (form: Pick<ConfigFormData, 'deadlineDate' | 'scheduledDates'>) => {
  // Reminder the earliest day should be tomorrow
  if (form.deadlineDate) {
    const minReminderDate = getDateTime(getFutureDate(1), { hour: 0, minute: 0, second: 0 });
    const maxReminderDate = getDateTime(form.deadlineDate, { hour: 24, minute: 59, second: 0 });
    if (form.scheduledDates && form.scheduledDates.length > 0) {
      return form.scheduledDates.every(({ date, idempotencyKey, status }) => {
          if (idempotencyKey && status === 'original') {
            // Skip validation for existing scheduled dates, as they are already validated
            // and otherwise will become invalid due to the relative time comparison.
            return true;
          }
          return !isBefore(minReminderDate, date) && !isAfter(maxReminderDate, date);
        }
      );
    }
    return !isBefore(minReminderDate, form.deadlineDate);
  }

  return !form.scheduledDates || form.scheduledDates.length === 0;
};
