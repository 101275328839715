import { HistoricalUtrs } from '@api/insights';
import { GridDashboardTableItem, TableWidgetColumnType } from '@g17eco/types/insight-custom-dashboard';
import { extractVariables, tryCalculation } from '@utils/formula';
import { getActualUtrsData, getDisplayNumericValue } from './utils';
import { DATE, formatDateUTC, sortEffectiveDateAsc } from '@utils/date';
import './TableWidget.scss';
import { getVariablesWithValues } from '@routes/custom-dashboard/items/charts/multi-utrs-chart/utils';
import { truncate } from '@utils/string';
import { SparklineCell } from './SparklineCell';
import { ColumnDef } from '@tanstack/react-table';
import { Table } from '@g17eco/molecules/table';

const NUMBER_OF_COLS = 4;
const VALUE_WIDTH = 120;
const UNIT_WIDTH = 80;
const SPARKLINE_WIDTH = 160;

type TableWidgetProps = Pick<GridDashboardTableItem, 'calculation' | 'variables' | 'config'> & {
  title?: string;
  utrsData: HistoricalUtrs[];
  onClickUtrValueLabel: (utrCode: string) => void;
};

export type TableWidgetData = {
  name: string;
  utrCode: string;
  valueListCode: string | undefined;
  unitText: string;
  [k: string]: string | number | undefined;
};

const getChartData = ({
  utrsData,
  variables,
  calculation,
  title,
  onClickUtrValueLabel,
  config: { hiddenColumns },
}: Required<TableWidgetProps>) => {
  const actualUtrsData = getActualUtrsData(utrsData);
  const mergedEffectiveDates = actualUtrsData.reduce((acc, utrData) => {
    const effectiveDates = utrData.utrvs
      .filter((utrv) => !acc.includes(utrv.effectiveDate))
      .map((utrv) => utrv.effectiveDate);
    return acc.concat(effectiveDates);
  }, [] as string[]);
  const latestEffectiveDates = mergedEffectiveDates.sort(sortEffectiveDateAsc).slice(0, NUMBER_OF_COLS);

  const columns: ColumnDef<TableWidgetData>[] = [
    {
      id: 'name',
      header: () => <span className='text-capitalize'>{title}</span>,
      meta: {
        headerProps: {
          className: 'w-100 text-left',
        },
        cellProps: {
          className: 'flex-grow-1',
          style: {
            width: 'unset',
          },
        },
      },
      cell: ({ row }) => (
        <div
          className='text-left cell__value-label'
          color='link'
          onClick={() => onClickUtrValueLabel(row.original.utrCode)}
        >
          {truncate(row.original.name, 125)}
        </div>
      ),
      enableSorting: false,
    },
    {
      id: TableWidgetColumnType.Unit,
      header: () => 'Unit',
      meta: {
        cellProps: {
          className: 'flex-grow-0 text-center ',
          style: {
            width: UNIT_WIDTH,
            maxWidth: UNIT_WIDTH,
            minWidth: UNIT_WIDTH,
          },
        },
      },
      cell: ({ row }) => row.original.unitText,
      enableSorting: false,
    },
    ...latestEffectiveDates.map(
      (effectiveDate, index): ColumnDef<TableWidgetData> => ({
        id: `${effectiveDate}-${index}`,
        header: () => formatDateUTC(effectiveDate, DATE.MONTH_YEAR_SHORT),
        meta: {
          cellProps: {
            className: 'flex-grow-0 text-center text-truncate',
            style: {
              maxWidth: VALUE_WIDTH,
              width: VALUE_WIDTH,
              minWidth: VALUE_WIDTH,
            },
          },
        },
        cell: ({ row }) => row.original[effectiveDate] || '',
        enableSorting: false,
      })
    ),
    {
      id: TableWidgetColumnType.Sparkline,
      header: () => 'Sparkline',
      meta: {
        cellProps: {
          className: 'flex-grow-0 text-center',
          style: {
            width: SPARKLINE_WIDTH,
            maxWidth: SPARKLINE_WIDTH,
            minWidth: SPARKLINE_WIDTH,
          },
        },
      },
      cell: ({ row }) => <SparklineCell rowOrginal={row.original} utrsData={utrsData} />,
      enableSorting: false,
    },
  ];

  const filteredColumns = hiddenColumns ? columns.filter((c) => c.id ? !hiddenColumns.includes(c.id) : true) : columns;

  const data: TableWidgetData[] = calculation.values.map((value) => {
    const variableLetters = extractVariables(value.formula ?? '');
    const utrCode = variableLetters.length !== 1 ? '' : variables[variableLetters[0]].code;
    const valueListCode = variableLetters.length !== 1 ? '' : variables[variableLetters[0]].valueListCode;
    const utrData = actualUtrsData.find((data) => data.utr.code === utrCode);

    const dataByColumns = utrData
      ? latestEffectiveDates.reduce((acc, effectiveDate) => {
          const utrv = utrData.utrvs.find((utrv) => utrv.effectiveDate === effectiveDate);

          const variablesWithValues = getVariablesWithValues({
            utrsData: actualUtrsData,
            variables,
            utrvs: utrv ? [utrv] : [],
            fallback: '',
          });
          const calculationValue = tryCalculation({
            formula: value.formula || '',
            variables: variablesWithValues,
            fallback: '',
            infinityFallback: '',
          });
          acc[effectiveDate] = getDisplayNumericValue(calculationValue);
          return acc;
        }, {} as Record<string, string | number>)
      : {};

    return {
      name: value.name,
      utrCode,
      valueListCode,
      unitText: utrData?.utr.unit || '',
      ...dataByColumns,
    };
  });

  return {
    filteredColumns,
    data,
  };
};

export const TableWidget = (props: TableWidgetProps) => {
  const { utrsData, variables, calculation, title = '', onClickUtrValueLabel, config = {} } = props;

  if (!utrsData.length || !variables || !calculation) {
    return null;
  }

  const { filteredColumns, data } = getChartData({
    utrsData,
    variables,
    calculation,
    title,
    onClickUtrValueLabel,
    config,
  });

  return (
    <div className='w-100 h-100 table-widget__container'>
      <Table columns={filteredColumns} data={data} />
    </div>
  );
};
