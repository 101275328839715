import { useBulkUpdateDocumentMutation } from '@api/documents';
import { FloatingToolbar } from '@components/floating-toolbar';
import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { DocumentItem, DocumentSubType } from '@g17eco/types/document';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { documentOptions } from './utils';
import { SelectedCountLabel } from '../../../../molecules/selected-count-label';

interface DocumentToolbarProps {
  initiativeId: string;
  selectedDocuments: DocumentItem[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<DocumentItem[]>>;
}
export const DocumentToolbar = ({ initiativeId, selectedDocuments, setSelectedDocuments }: DocumentToolbarProps) => {
  const [bulkUpdateDocument, { isLoading: isBulkUpdating }] = useBulkUpdateDocumentMutation();
  const { addSiteAlert, addSiteError } = useSiteAlert();

  const handleBulkUpdateDocument = (data?: DocumentSubType) => {
    bulkUpdateDocument({
      initiativeId,
      data: {
        documentIds: selectedDocuments.map((doc) => doc._id),
        ownerSubType: data,
      },
    })
      .unwrap()
      .then((response) => {
        addSiteAlert({
          content: (
            <div className='text-center'>
              <i className='fal fa-check-circle mr-2' />
              {response.modifiedCount} document types assigned
            </div>
          ),
        });
      })
      .catch((error) => {
        addSiteError(error);
      })
      .finally(() => setSelectedDocuments([]));
  };

  return (
    <FloatingToolbar
      handleClose={() => setSelectedDocuments([])}
      items={[
        <SelectFactory
          key={selectedDocuments.length}
          selectType={SelectTypes.SingleSelect}
          placeholder='Select a type'
          options={documentOptions}
          isDisabled={isBulkUpdating}
          onChange={(op) => {
            handleBulkUpdateDocument(op?.value);
          }}
          isSearchable={false}
          menuPlacement='top'
        />,
        <SelectedCountLabel count={selectedDocuments.length} />,
      ]}
      isOpen={selectedDocuments.length > 0}
    />
  );
};
