import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SelectedTagIdsType,
  useCreateCustomTagMutation,
  useDeleteCustomTagsMutation,
  useGetCustomTagsQuery,
  useUpdateCustomTagsMutation,
} from '../../api/metric-groups';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { Tag } from '../../types/metricGroup';
import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { AddTagList } from './AddTagList';
import { ManageTags } from './ManageTags';
import './style.scss';
import { BasicAlertParams } from '@g17eco/molecules/alert';

export interface TagContainerProps {
  selectedQuestions: BulkActionUtr[];
  handleReload: (props?: { reloadSurvey?: boolean }) => Promise<void | undefined>;
}

export interface Message {
  content: string;
  type: BasicAlertParams['type'];
}

export const TagContainer = (props: TagContainerProps) => {
  const { handleReload, selectedQuestions } = props;
  const { initiativeId = '' } = useParams<{ initiativeId: string }>();

  const { addSiteError } = useSiteAlert();
  const { data: tags = [], error, isFetching } = useGetCustomTagsQuery(initiativeId, { skip: !initiativeId });
  const [updateCustomTags, { isLoading: isUpdating }] = useUpdateCustomTagsMutation();
  const [createCustomTag] = useCreateCustomTagMutation();
  const [deleteCustomTags] = useDeleteCustomTagsMutation();

  const [isManageTag, setManageTag] = useState(false);
  const [message, setMessage] = useState<Message | undefined>();

  const selectedUtrIds = useMemo(() => {
    return selectedQuestions.map((question) => question._id);
  }, [selectedQuestions]);

  const addMessage = (content: string, type?: BasicAlertParams['type']) => {
    setMessage({ content, type: type || SiteAlertColors.Success });
    setTimeout(() => {
      setMessage(undefined);
    }, 2000);
  };

  const handleAddTag = (tag: Tag['groupName']) => {
    return createCustomTag({ initiativeId, groupName: tag })
      .then(() => {
        const content = `${tag} has been added`;
        addMessage(content);
      })
      .catch((e) => {
        addMessage(e.message, SiteAlertColors.Danger);
      });
  };

  const handleDeleteTags = (ids: Tag['_id'][]) => {
    deleteCustomTags({ initiativeId, ids })
      .then(() => {
        const content = 'Tags have been deleted successfully!';
        addMessage(content);
      })
      .catch((e) => {
        addMessage(e.message, SiteAlertColors.Danger);
      });
  };

  const handleAddUtrsToTags = (selectedTagIds: SelectedTagIdsType) => {
    updateCustomTags({ initiativeId, updatedTagIds: selectedTagIds, utrIds: selectedUtrIds })
      .unwrap()
      .then(() => {
        handleReload({ reloadSurvey: true });
      })
      .catch((e) => {
        addSiteError(e.message);
        handleReload();
      });
  };

  if (isManageTag) {
    return (
      <ManageTags
        tags={tags}
        initiativeId={initiativeId}
        message={message}
        handleAddTag={handleAddTag}
        handleDeleteTags={handleDeleteTags}
        toggleManageTag={() => setManageTag(false)}
      />
    );
  }
  return (
    <AddTagList
      tags={tags}
      isLoading={isFetching || isUpdating}
      tagListError={error}
      selectedUtrIds={selectedUtrIds}
      openManageTags={() => setManageTag(true)}
      handleAddUtrsToTags={handleAddUtrsToTags}
    />
  );
};
