import Select, { Props as BaseSelectProps, GroupBase, OptionProps, components } from 'react-select';
import { DropdownIndicator, ClearIndicator, Option, getStyles, SelectStyleProps } from './SelectFactory';
import { SimpleTooltip } from '../simple-tooltip';

export type SelectProps<T = string | null> = BaseSelectProps<Option<T> | null, false, GroupBase<Option<T> | null>> &
  SelectStyleProps;

const CustomOption = <T = string | null>(props: OptionProps<Option<T> | null, false, GroupBase<Option<T> | null>>) => {
  const { tooltip, id } = props.data ?? {};
  const componentWithId = id ? (
    <div id={id}>
      <components.Option {...props} />
    </div>
  ) : (
    <components.Option {...props} />
  );

  return tooltip ? (
    <SimpleTooltip placement='bottom' text={tooltip}>
      {componentWithId}
    </SimpleTooltip>
  ) : (
    componentWithId
  );
};

export const SingleSelect = <T = string | null>(props: SelectProps<T>) => {
  const {
    components,
    styles,
    size,
    isTransparent,
    isFlexibleSize,
    hasOptionDivider,
    showDropdownIndicator = true,
    isMenuPortalTargetBody = false,
    showCount=false,
    minWidth,
    backgroundColor,
    isInvalid,
    ...rest
  } = props;
  return (
    <Select
      components={{
        DropdownIndicator: () => (
          <DropdownIndicator
            current={props.value !== null ? 1 : 0}
            limit={rest.options?.length}
            showCount={showCount}
            showDropdownIndicator={showDropdownIndicator}
          />
        ),
        ClearIndicator,
        Option: CustomOption,
        ...components,
      }}
      styles={{
        ...getStyles<Option<T> | null>({
          size,
          isMulti: false,
          isTransparent,
          hasOptionDivider,
          isFlexibleSize,
          isMenuPortalTargetBody,
          minWidth,
          backgroundColor,
          isInvalid
        }),
        ...styles,
      }}
      menuPortalTarget={isMenuPortalTargetBody ? document.body : null}
      {...rest}
    />
  );
};
