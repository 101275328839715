import { Button } from 'reactstrap';
import './styles.scss';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

export interface ActionsToolbarProps {
  handleClose: () => void;
  items: React.ReactNode[];
  isOpen: boolean;
}

export const FloatingToolbar = (props: ActionsToolbarProps) => {
  const { isOpen, items = [], handleClose } = props;

  const className = isOpen ? 'toolbar--show' : 'toolbar--hide';

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={`floating-toolbar ${className}`}>
      <div className='px-5 py-3 d-flex gap-4'>
        <div className='d-flex gap-2 flex-wrap'>
          {items.filter(Boolean).map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
        <div className='d-flex align-items-center'>
          <SimpleTooltip text='Cancel'>
            <Button color='link' onClick={handleClose}>
              <i className='fa fa-times text-ThemeTextMedium' />
            </Button>
          </SimpleTooltip>
        </div>
      </div>
    </div>
  );
};
