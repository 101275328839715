import {
  Document,
  ImageRun,
  SectionType,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TableLayoutType,
  Header,
  AlignmentType,
  VerticalPositionRelativeFrom,
  VerticalPositionAlign,
  UnderlineType,
} from 'docx';
import { blueStyles, numberingStyles } from '../styles';
import {
  title,
  heading,
  heading2,
  heading3,
  arrayToTableRow,
  paragraph,
  imageWrapper,
  footer,
  pagebreak,
  Styles,
  spacer,
  bulletList,
  getBorders,
  spacedParagraph,
  infoParagraph,
  convertImgToBase64URL,
} from '../document-structure';
import { formatDate } from '../../../utils/date';
import { SurveyInitiative } from '../../../types/survey';
import { sdgReportData, SDGReportGoal } from '../reportApi';
import { getImgGoalsB64, getImgGoalsB64Inverted } from '../../sdg-icon/utils';
import { DataSource, DataSources } from '.';
import { PoliciesDataSource } from './data/Policies';
import { ReportOptions } from '../../../types/reportData';
import { ScorecardTarget } from '../../../types/scorecard';
import { RequiredTargetFlagType, REQUIRED_TARGET_FLAGS } from './data/TargetRequiredFlags';
import { Blueprints } from '../../survey-configuration/types';
import { getAbsoluteContribution } from '../../charts/sdg-score-chart';
import { QUESTION, SURVEY } from '@constants/terminology';
import SDGCircle from '../../../images/reports/sdg_circle.png';
import tickIcon from '../../../images/reports/tick_icon.png';
import timesIcon from '../../../images/reports/times_icon.png';
import { SafeTextRun } from '@utils/docx';
import { formatNumber } from '@utils/number';

const STYLES = {
  TABLE_WIDTH: 9000,
  TABLE_HEADER_SHADING: {
    fill: '5189BD'
  },
  TABLE_BORDER_COLOUR: 'DDDDDD',
  LINE_SPACING: 300
};

const MARGINS_SM = {
  left: 75,
  right: 75,
  top: 75,
  bottom: 75,
};
const MARGINS_MD = {
  left: 150,
  right: 150,
  top: 150,
  bottom: 150,
};

const NO_DATA_TEXT = `Measurement unavailable (no ${QUESTION.PLURAL} for this target have been answered)`;

const NOT_REQUIRED_TARGET_TEXT = 'This target is not materially relevant for your organisation';

const IS_REQUIRED_TARGET = 'Y';

export const SDGImpactReportGenerator = async (survey: SurveyInitiative, dataSources: DataSources, options?: ReportOptions): Promise<Document> => {

  const data = await sdgReportData(survey._id, options);
  const periodCovered = formatDate(survey.effectiveDate, 'MMMM YYYY');

  const highMaterialitySDGs = Object.keys(data.materiality).filter(k => data.materiality[Number(k)] === 100);
  const mediumMaterialitySDGs = Object.keys(data.materiality).filter(k => data.materiality[Number(k)] === 66);

  const highMaterialityGoals = data.goals.filter(d => highMaterialitySDGs.includes(d.goal));

  const [sdgCircleBase64, tickIconBase64, timesIconBase64] = await Promise.all([
    convertImgToBase64URL(SDGCircle),
    convertImgToBase64URL(tickIcon),
    convertImgToBase64URL(timesIcon),
  ]);

  const getImageRunFromBase64 = (base64Img: string, width: number, styles?: Styles) => {
    return imageWrapper(
      new ImageRun({
        data: base64Img,
        transformation: {
          width: width,
          height: width,
        }
      }),
      styles
    )
  }

  const imgGoalsB64 = await getImgGoalsB64();
  const imgGoalsB64Inverted = await getImgGoalsB64Inverted();

  const getSDGGoalImage = (code: string, width: number = 100, outline: boolean = false, styles?: Styles) => {
    const img = outline ? imgGoalsB64Inverted[Number(code) - 1] : imgGoalsB64[Number(code) - 1];
    if (!img) {
      return paragraph(`Fail for ${code}`);
    }
    return getImageRunFromBase64(img, width, styles);
  }

  // const getSDGTargetImage = async (code: string, width: number = 100, outline: boolean = false, styles?: Styles) => {
  //   const img = await getSDGImgAsBase64(code, outline);
  //   if (!img) {
  //     const goal = code.split('.')[0];
  //     return getSDGGoalImage(goal, width, outline, styles);
  //   }
  //   return getImageRunFromBase64(img, width, styles);
  // }

  const totalSdgContribution = data.contribution.actual ? Math.round(data.contribution.actual) : 0;

  const sdgOverviewPage = [
    heading('SDG OVERVIEW'),
    new Paragraph({
      children: [
        new SafeTextRun('The UN set out 17 Sustainable Development Goals to achieve by 2030 – covering people, suppliers, environmental, and community issues. '),
        new SafeTextRun({
          text: survey.initiative.name,
          bold: true,
          style: 'bold'
        }),
        new SafeTextRun(' is taking responsibility for delivering on those goals')
      ],
      spacing: {
        line: STYLES.LINE_SPACING
      }
    }),
    spacer(),
    new Paragraph({
      children: [
        new SafeTextRun(`The overall ${survey.initiative.name} contribution to all 17 Goals is `),
        new SafeTextRun({
          text: `${totalSdgContribution}%`,
          color: totalSdgContribution > 0 ? '3BB056' : totalSdgContribution < 0 ? 'FF0000' : '000000'
        }),
      ]
    }),
    spacer(720),
    imageWrapper(
      new ImageRun({
        data: Uint8Array.from(atob(sdgCircleBase64), c => c.charCodeAt(0)),
        transformation: {
          width: 240,
          height: 240,
        },
        floating: {
          horizontalPosition: {
            offset: 2600000
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PARAGRAPH,
            align: VerticalPositionAlign.CENTER,
            offset: 3000000
          },
        },
      })
    ),
    new Paragraph({
      children: [
        new SafeTextRun({
          text: `${totalSdgContribution}%`,
          color: totalSdgContribution > 0 ? '3BB056' : totalSdgContribution < 0 ? 'FF0000' : '000000',
          size: 52
        }),
      ],
      alignment: AlignmentType.CENTER,
      spacing: {
        before: 1200
      }
    }),
    spacer(1600),
    new Paragraph({
      children: [
        new SafeTextRun({
          text: 'You can view all the goals at ',
          size: 15
        }),
        new SafeTextRun({
          text: 'www.globalgoals.org',
          size: 15,
          color: '0000FF',
          underline: {
            type: UnderlineType.SINGLE
          }
        })
      ],
      alignment: AlignmentType.CENTER
    }),
    spacer(),

    infoParagraph('SDG contribution is the effort an organisation is making through internal and external initiatives, as well as across the value chain, towards the successful delivery of the SDGs. Our maximum contribution is 25%, at present since Company Tracker – our tool for measuring contribution – is a cut down version of a larger set of metrics (found under Company Tracker Pro).'),
    pagebreak(),
  ]

  const sdgGoalGrid = () => {

    const isMaterial = (id: string) => highMaterialitySDGs.includes(id) || mediumMaterialitySDGs.includes(id);

    const cell = (id: number) => {
      const goal = data.goals[id];
      const code = goal.contribution.sdgCode;
      return new TableCell({
        children: [
          isMaterial(`${id + 1}`) ? getSDGGoalImage(code, 90) : getSDGGoalImage(code, 90, true)
        ],
        margins: MARGINS_SM,
        borders: getBorders('FFFFFF'),
      });
    }

    return new Table({
      rows: [
        new TableRow({
          children: [0, 1, 2, 3, 4, 5].map(cell)
        }),
        new TableRow({
          children: [6, 7, 8, 9, 10, 11].map(cell)
        }),
        new TableRow({
          children: [12, 13, 14, 15, 16].map(cell)
        })
      ],
      width: {
        size: STYLES.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
      columnWidths: Array.from({ length: 2 }, (_) => Math.floor(STYLES.TABLE_WIDTH / 6)),
    });
  }

  const materialityCount = highMaterialitySDGs.length + mediumMaterialitySDGs.length;

  const keyFocusAreasPage = [
    heading2('KEY FOCUS AREAS'),
    spacer(),
    spacedParagraph(`While we can impact all 17 global Sustainable Development Goals, there are ${materialityCount} which are most material to the industry sector.`),
    spacer(),
    sdgGoalGrid(),
    pagebreak(),
  ]

  const topSDGs = [...highMaterialityGoals].sort((a, b) => {
    const av = a.contribution.actual || 0;
    const bv = b.contribution.actual || 0;
    return av < bv ? 1 : av > bv ? -1 : 0
  });

  const sdgInfoTable = (goals: SDGReportGoal[]) => {
    const colWidth = Math.floor(STYLES.TABLE_WIDTH / 2);
    return new Table({
      rows: goals
        .sort((a, b) => Number(a.goal) > Number(b.goal) ? 1 : Number(a.goal) < Number(b.goal) ? -1 : 0)
        .map(d => {
          const code = d.goal;
          const sdgGoalLogo = new TableCell({
            children: [getSDGGoalImage(code, 80)],
            margins: MARGINS_SM,
            borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
          })

          const description = new TableCell({
            children: [
              new Paragraph({
                children: [
                  new SafeTextRun({
                    text: d.contribution.officialName,
                    size: 26,
                    color: STYLES.TABLE_HEADER_SHADING.fill,
                  })
                ],
                spacing: {
                  line: STYLES.LINE_SPACING
                }
              }),
              new Paragraph({
                children: [
                  new SafeTextRun({
                    text: d.contribution.description ?? '',
                    size: 20,
                  })
                ],
                spacing: {
                  before: 200,
                  after: 200,
                  line: STYLES.LINE_SPACING
                }
              }),
            ],
            margins: MARGINS_SM,
            borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
          })

          return new TableRow({
            children: [
              sdgGoalLogo,
              description
            ]
          });
        }),
      columnWidths: Array.from({ length: 2 }, (_) => colWidth),
      width: {
        size: STYLES.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
    });
  }

  const highMaterialityGoalsPage = [
    heading2('HIGH MATERIALITY'),
    spacer(),
    spacedParagraph(`Of these ${materialityCount}, ${highMaterialityGoals.length} have been identified as having the most importance or highest materiality for our industry sector.`),
    spacer(),
    sdgInfoTable(topSDGs),
    pagebreak(),
  ]

  const round = (number: number, dp: number = 0) => {
    return number.toFixed(dp);
  }

  // const topTargetImages = await Promise.all(topSDGs[0].contribution.targets.map(t => getSDGTargetImage(t.sdgCode, 80)));

  const topSdgTargetTable = () => {
    if (!topSDGs[0]) {
      return spacer();
    }

    const headerRow = [
      'Target',
      // '',
      'Description',
      'Contribution'
    ].map(t => new Paragraph({
      children: [
        new SafeTextRun({
          text: t,
          color: 'FFFFFF',
          size: 24
        })
      ],
      alignment: AlignmentType.CENTER
    }));

    const totalWidth = STYLES.TABLE_WIDTH;
    const colWidth = Math.floor(totalWidth / headerRow.length);

    const getTargetText = (t: ScorecardTarget) => {
      if (t.actual !== undefined) {
        return `${getAbsoluteContribution(t)}%`;
      }

      const surveyVersion = survey.sourceName ?? Blueprints.Blueprint2022;
      const target = REQUIRED_TARGET_FLAGS.find((item) => item?.target === t.sdgCode);
      if (target?.[surveyVersion as keyof RequiredTargetFlagType] === IS_REQUIRED_TARGET) {
        return NO_DATA_TEXT;
      }
      return NOT_REQUIRED_TARGET_TEXT;
    };

    const textCell = (text: string) => new TableCell({
      children: [
        new Paragraph({
          children: [
            new SafeTextRun({
              text: text,
              size: 20
            })
          ]
        })
      ],
      margins: MARGINS_SM,
      borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
    });

    return new Table({
      rows: [
        arrayToTableRow(headerRow, {
          style: 'bold',
          shading: STYLES.TABLE_HEADER_SHADING,
          margins: MARGINS_SM,
          borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
        }),
        ...topSDGs[0].contribution.targets.map((t, i) => {
          return new TableRow({
            children: [
              textCell(t.sdgCode),
              textCell(t.name),
              textCell(getTargetText(t))
            ]
          });
        })
      ],
      columnWidths: Array.from({ length: headerRow.length }, (_) => colWidth),
      width: {
        size: totalWidth,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT
    });

  }

  const [topSDG] = topSDGs;
  const keyStrengthPage = !topSDG ? [] : [
    heading2('KEY STRENGTH'),
    spacer(),
    topSDGs[0] ? getSDGGoalImage(topSDGs[0].goal, 250, true, { alignment: AlignmentType.CENTER }) : spacer(),
    spacer(),
    heading3(`SDG ${topSDGs[0].contribution.sdgCode}:`),
    heading3('Ensure availability and sustainable management of water and sanitation for all'),
    spacer(),
    spacedParagraph(`Our contribution to this goal is: ${Math.round(topSDGs[0].contribution.actual ?? 0)}%`),
    spacer(),
    spacedParagraph(`This is the Sustainable Development Goal which combines high materiality for our industry and where ${survey.initiative.name} is strongest and makes the most contribution.`),
    spacer(),
    spacedParagraph(`This SDG is made up of several targets and the table below shows ${survey.initiative.name}’s net contribution to each target.`),
    pagebreak(),
    topSdgTargetTable(),
    spacer(),
    infoParagraph('Net contributions: Company Tracker is tracking how much above or below a baseline an organization is contributing to in each of the Goal’s sub-targets. This is the most accurate reading of your company\'s contribution to the SDGs. If you are impacting negatively, what this means is that you are taking out of society, the economy and / or the environment more than you are putting back in. Therefore, setting some KPIs, policies and initiatives that find ways to give more than you are consuming is recommended - particularly on the SDGs which are highly material to your sector.'),
    pagebreak(),
  ]

  const lastSdg = topSDGs.slice(-1)[0];

  const potentialRiskAreaPage = !lastSdg ? [] : [
    heading2('POTENTIAL RISK AREA'),
    spacer(),
    sdgInfoTable([lastSdg]),
    spacer(),
    spacedParagraph(`Goal ${lastSdg.goal} is one area of high materiality where we are not showing a strong contribution. The maximum achievable score with the Company Tracker ${SURVEY.SINGULAR} for this goal is 25%. Our score is ${round(lastSdg.contribution.actual ?? 0)}%.`),
    pagebreak(),
  ]

  const getChart = (dataSource: DataSource) => {
    if (!dataSource.chart) {
      return paragraph('No data');
    }

    return new ImageRun({
      data: Uint8Array.from(atob(dataSource.chart), c => c.charCodeAt(0)),
      transformation: {
        width: dataSource.width ?? 100,
        height: dataSource.height ?? 100,
      }
    });
  }

  const genderMalePc = formatNumber(dataSources.genderSplitPc.maleEmployeesPc ?? 0);
  const genderFemalePc = formatNumber(dataSources.genderSplitPc.femaleEmployeesPc ?? 0);
  const genderRatio = formatNumber(dataSources.genderSplitPc.genderRatio ?? 0);
  const genderSplit = getChart(dataSources.genderSplit);
  const genderSplitPc = getChart(dataSources.genderSplitPc);
  const livingWagePc = formatNumber(dataSources.livingWage.employeesPc ?? 0);

  const socialPage = [
    heading('SOCIAL'),
    heading2('GENDER'),
    imageWrapper([genderSplit, genderSplitPc], { alignment: AlignmentType.CENTER }),
    spacer(),
    spacedParagraph(`Within ${survey.initiative.name}, the gender split is ${genderMalePc}% male and ${genderFemalePc}% female.`),
    spacer(),
    spacedParagraph(`In addition, the turnover rate of women to men is ${genderRatio} meaning women are ${genderRatio} times more likely to leave the organization in any given time period than men.`),

    pagebreak(),

    heading2('LIVING WAGE'),
    spacer(),
    spacedParagraph(`${survey.initiative.name} pays ${livingWagePc}% of its employees at least the national living wage.`),
    spacer(),
    spacedParagraph('A living wage affords a decent standard of living for both full and part-time workers and their families. In many regions, the living wage is higher than the legal minimum wage or poverty-line wage. Living wage calculations exclude overtime pay as well as productivity bonuses and allowances, unless they are guaranteed payments.'),
    pagebreak(),
  ];

  const ghgEmissions = formatNumber(dataSources.ghgEmissions.totalEmissions ?? 0);
  const ghgChart = getChart(dataSources.ghgEmissions);

  const environmentalPage = [
    heading('ENVIRONMENTAL'),
    spacedParagraph('Greenhouse gases are gases that trap heat in the earth’s atmosphere, causing climate change. They include carbon dioxide (co2), methane and nitrous oxide.'),

    spacer(200),
    spacedParagraph(ghgEmissions.toLocaleString(), { alignment: AlignmentType.CENTER, textRun: { size: 40, color: '1a4791' } }),
    spacedParagraph('metric tonnes', { alignment: AlignmentType.CENTER, textRun: { color: 'adcdf2' } }),
    spacedParagraph('co2', { alignment: AlignmentType.CENTER, textRun: { color: 'adcdf2' } }),
    spacer(500),

    spacedParagraph('There are three different categories of emissions:'),
    spacer(),
    bulletList('Scope 1 – the emissions that a company makes directly e.g. company vehicles, boilers.'),
    spacer(),
    bulletList('Scope 2 – the emissions a company makes indirectly e.g. energy purchase, such as electricity.'),
    spacer(),
    bulletList('Scope 3 – the emissions that are associated with the organisation e.g. business related travel and energy use of staying in hotels, emissions related to purchased goods from suppliers, staff commuting.'),
    spacer(),
    imageWrapper(ghgChart, { alignment: AlignmentType.CENTER }),
    pagebreak(),
  ];

  const bonusesPc = dataSources.bonuses.bonusesPc;
  const bonusesChart = getChart(dataSources.bonuses);

  const boardGenderChart = getChart(dataSources.boardGender);
  const boardDiversityChart = getChart(dataSources.boardDiversity);

  const economicPage = [
    heading('ECONOMIC'),
    heading2('REMUNERATION'),

    ...(
      dataSources.payGap.countries?.map(c => [
        spacer(),
        heading3((c.name && c.name!=='UNDEFINED') ? c.name : ''),
        spacedParagraph(`The highest paid individual is paid ${formatNumber(1 / c.payRatio)} times the average of the rest of the ${(c.name && c.name!=='UNDEFINED') ? c.name : ''} employees`),
        imageWrapper(getChart(c), { alignment: AlignmentType.CENTER }),
        pagebreak(),
      ]) ?? [spacer()]).flat(),

    heading2('GENDER PAY GAP'),
    ...(
      dataSources.genderPayGap.employeeCategories?.map(c => [
        spacer(),
        heading3(c.name),
        spacedParagraph(`On average women are paid ${round(c.payRatio)}% of what men are paid - in '${c.name}' roles.`),
        imageWrapper(getChart(c), { alignment: AlignmentType.CENTER }),
        pagebreak(),
      ]) ?? [spacer()]).flat(),

    heading2('BONUSES & BOARD'),
    spacedParagraph('Linking bonuses to sustainable outcomes is one way to align business goals with beneficial Social, Economic and Environmental outcomes. In addition, having a diverse board can also directly impact social and diversity initiatives and metrics.'),
    heading3('BONUSES'),
    spacedParagraph(`${survey.initiative.name} has paid senior management ${bonusesPc}% in bonuses that are explicitly linked to sustainable goals.`),
    imageWrapper(bonusesChart, { alignment: AlignmentType.CENTER }),

    pagebreak(),
    heading3('BOARD DIVERSITY'),
    imageWrapper([boardGenderChart, boardDiversityChart], { alignment: AlignmentType.CENTER }),
    pagebreak(),
  ];

  const icon = (icon: string) => {
    return new ImageRun({
      data: Uint8Array.from(atob(icon), c => c.charCodeAt(0)),
      transformation: {
        width: 20,
        height: 20,
      }
    });
  };

  const policiesCell = (title: string, id: keyof PoliciesDataSource) => {
    const status = dataSources.policies[id] === true;
    return [
      new TableCell({
        children: [
          paragraph(title),
        ],
        margins: MARGINS_MD,
        borders: getBorders('ffffff')
      }),
      new TableCell({
        children: [
          imageWrapper(
            icon(status ? tickIconBase64 : timesIconBase64)
          )
        ],
        margins: MARGINS_MD,
        borders: getBorders('ffffff')
      })
    ];
  }

  const policiesPage = [
    heading('POLICIES & PRACTICES'),
    spacedParagraph(`The table below shows a number of policies or best practices that help align organisations with the UN Sustainable Development Goals and corporate social responsibility best practices – and how ${survey.initiative.name} is currently aligned with these policies and best practices.`),
    spacer(),
    new Table({
      rows: [
        arrayToTableRow(['Policy/Best practice', 'Status', '', ''], {
          style: 'bold',
          shading: {
            fill: 'e6e8ed'
          },
          margins: MARGINS_MD,
          borders: getBorders(STYLES.TABLE_BORDER_COLOUR)
        }),
        new TableRow({
          children: [
            ...policiesCell('Diversity Policy', 'diversityPolicy'),
            ...policiesCell('Sustainability reporting', 'sustainabilityReporting'),
          ]
        }),
        new TableRow({
          children: [
            ...policiesCell('Mental Health policy', 'mentalHealthPolicy'),
            ...policiesCell('Bonuses linked to Sustainability', 'bonusesLinkedToSustainability'),
          ]
        }),
        new TableRow({
          children: [
            ...policiesCell('Bribery/corruption policy', 'briberyCorruptionPolicy'),
            ...policiesCell('Human Trafficking supplier policy', 'humanTrafficingPolicy'),
          ]
        }),
      ],
      width: {
        size: STYLES.TABLE_WIDTH,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT
    })
  ];

  const document = new Document({
    styles: {
      paragraphStyles: blueStyles
    },
    numbering: {
      config: numberingStyles
    },
    sections: [
      {
        properties: { type: SectionType.NEXT_PAGE, },
        headers: { default: new Header({ children: [] }), },
        footers: { default: footer() },
        children: [
          imageWrapper(
            new ImageRun({
              data: Uint8Array.from(atob(sdgCircleBase64), c => c.charCodeAt(0)),
              transformation: {
                width: 700,
                height: 700,
              },
              floating: {
                horizontalPosition: {
                  offset: 4000000
                },
                verticalPosition: {
                  offset: -1800000
                },
              },
            })
          ),
          new Paragraph({
            text: '',
            spacing: {
              before: 7000
            }
          }),
          heading2(periodCovered),
          heading2(survey.initiative.name),
          title('SDG'),
          title('REPORT'),
          pagebreak(),
        ],
      },
      {
        properties: {
          type: SectionType.NEXT_PAGE,
        },
        headers: {
          default: new Header({
            children: [new Paragraph({
              text: 'G17.ECO',
              alignment: AlignmentType.LEFT,
            })],
          }),
        },
        footers: {
          default: footer()
        },
        children: [
          ...sdgOverviewPage,
          ...keyFocusAreasPage,
          ...highMaterialityGoalsPage,
          ...keyStrengthPage,
          ...potentialRiskAreaPage,
          ...socialPage,
          ...environmentalPage,
          ...economicPage,
          ...policiesPage,
        ]
      },
    ]
  });

  return document;
}
