export enum SupportedMeasureUnits {
  time = 'time',
  area = 'area',
  mass = 'mass',
  volume = 'volume',
  energy = 'energy',
  currency = 'currency',
  co2Emissions = 'co2Emissions',
  partsPer = 'partsPer',
  numberScale = 'numberScale',
  length = 'length',
}

export enum NumberScale {
  Single = 'single',
  Hundreds = 'hundreds',
  Thousands = 'thousands',
  Millions = 'millions',
  Billions = 'billions',
}
