import { DelegatedUsers, useAddSurveyUserMutation, User, useRemoveSurveyUserMutation } from '@api/survey-users';
import { InfoIcon } from '@components/common/InfoIcon';
import { Menu } from '@components/menu/Menu';
import { QUESTION, SURVEY } from '@constants/terminology';
import { SurveyUserRoles } from '@constants/users';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { StaffIcon } from '@g17eco/molecules/staff-icon';
import { Table } from '@g17eco/molecules/table';
import { useToggle } from '@hooks/useToggle';
import {
  filterUsersByRole,
  filterUsersByRoleAndLevels,
  hasAtLeastOneRoleLevel,
  RoleLevel,
  SurveyDelegateRole,
} from '@utils/delegation-users';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { UserText } from '../UserText';
import { SurveyDelegationSearchModal } from '@components/search-modal';
import { SurveyDelegationProps } from './types';
import { useAppSelector } from '@reducers/index';
import { FeaturePermissions } from '@services/permissions/FeaturePermissions';
import { generateErrorToast, generateToast } from '@components/toasts';
import { getFullName } from '@utils/user';

const roleConfig: Record<SurveyDelegateRole, { tabLabel: string; instruction: string; icon: string; label: string }> = {
  [SurveyUserRoles.Stakeholder]: {
    tabLabel: 'Contributors',
    instruction: `${SURVEY.CAPITALIZED_SINGULAR} contributors are able to submit values for all ${QUESTION.PLURAL} in this ${SURVEY.SINGULAR} only.`,
    icon: 'fa-user-edit text-ThemeAccentMedium',
    label: 'contributor',
  },
  [SurveyUserRoles.Verifier]: {
    tabLabel: 'Verifiers',
    instruction: `${SURVEY.CAPITALIZED_SINGULAR} verifiers are able to verify values for all ${QUESTION.PLURAL} in this ${SURVEY.SINGULAR} only.`,
    icon: 'fa-user-check text-ThemeSuccessMedium',
    label: 'verifier',
  },
  [SurveyUserRoles.Admin]: {
    tabLabel: `${SURVEY.CAPITALIZED_SINGULAR} admins`,
    instruction: `${SURVEY.CAPITALIZED_SINGULAR} administrators are able to manage ${SURVEY.SINGULAR} settings, scope, assurance and delegation. One there own these users do not have access to higher functionality such as to manage users, data share or billing.`,
    icon: 'fa-user-cog text-ThemeHeadingLight',
    label: `${SURVEY.SINGULAR} administrator`,
  },
};

const Action = ({ userRole, user, handleRemoveUser, isReadOnly }: UserRowProps) => {
  if (hasAtLeastOneRoleLevel([RoleLevel.Survey], userRole, user)) {
    return (
      <Button
        color='link'
        className='mx-1 p-0 ml-auto mr-2'
        onClick={() => handleRemoveUser({ role: userRole, userId: user._id })}
        disabled={isReadOnly}
      >
        <i className='fas fa-times text-ThemeDangerMedium' />
      </Button>
    );
  }

  if (hasAtLeastOneRoleLevel([RoleLevel.Initiative], userRole, user)) {
    return (
      <div className='ml-auto mr-2'>
        <InfoIcon text='User delegated at the system level cannot be removed here' />
      </div>
    );
  }

  return null;
};

type UserRowProps = Pick<SurveyDelegationProps, 'isReadOnly'> & {
  userRole: SurveyDelegateRole;
  user: User;
  handleRemoveUser: ({ role, userId }: { role: SurveyDelegateRole; userId: string }) => void;
};

const UserRow = (props: UserRowProps) => {
  const { user } = props;
  return (
    <div className='d-flex align-items-center'>
      <UserText user={user} />
      {user.isStaff ? <StaffIcon /> : null}
      <Action {...props} />
    </div>
  );
};

type AddBtnProps = Pick<SurveyDelegationProps, 'isReadOnly'> & {
  userRole: SurveyDelegateRole;
  onClick: () => void;
  canAccessVerification: boolean;
};

const AddBtn = ({ userRole, onClick, isReadOnly, canAccessVerification }: AddBtnProps) => {
  const isDisabledDueToVerificationAccess = userRole !== SurveyUserRoles.Stakeholder && !canAccessVerification;
  const disabled = isReadOnly || isDisabledDueToVerificationAccess;
  const tooltip = isDisabledDueToVerificationAccess ? 'Upgrade to access verification' : '';

  return (
    <SimpleTooltip text={tooltip}>
      <Button outline size='sm' onClick={onClick} disabled={disabled}>
        <i className={`fa ${roleConfig[userRole].icon} mr-2`} />
        Add a {roleConfig[userRole].label}
      </Button>
    </SimpleTooltip>
  );
};

type Props = Pick<SurveyDelegationProps, 'surveyId' | 'isReadOnly'> & {
  users?: DelegatedUsers;
};

export const ManageDelegatesBtn = ({ users, surveyId, isReadOnly }: Props) => {
  const canAccessVerification = useAppSelector(FeaturePermissions.canAccessVerification);
  const [isOpen, toggle] = useToggle();
  const [isSearchModalOpen, toggleSearchModal] = useToggle();
  const [role, setRole] = useState<SurveyDelegateRole>(SurveyUserRoles.Stakeholder);
  const usersByRole = users ? filterUsersByRole(users, role) : [];
  const filteredUsers = filterUsersByRoleAndLevels({
    role,
    users: usersByRole,
    roleLevels: [RoleLevel.Initiative, RoleLevel.Survey],
  });

  const [addUser] = useAddSurveyUserMutation();
  const handleAddUser = ({ userId, email }: { userId: string; email?: string }) => {
    return addUser({ surveyId, role, userId, onboardingEmail: email })
      .unwrap()
      .then(() => {
        toggleSearchModal();
        generateToast({
          color: 'success',
          message: 'User added successfully',
        });
      })
      .catch(() => {
        generateErrorToast({ message: 'Failed to add user' });
      });
  };

  const [removeUser] = useRemoveSurveyUserMutation();
  const handleRemoveUser: UserRowProps['handleRemoveUser'] = ({ role, userId }) => {
    return removeUser({ surveyId, role, userId })
      .unwrap()
      .then(() => {
        generateToast({
          color: 'success',
          message: 'User removed successfully',
        });
      })
      .catch(() => {
        generateErrorToast({ message: 'Failed to remove user' });
      });
  };

  return (
    <>
      <Button color='secondary' onClick={toggle} className='ms-auto'>
        Manage delegated users
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} backdrop='static' returnFocusAfterClose={false}>
        <ModalHeader toggle={toggle}>{SURVEY.CAPITALIZED_SINGULAR} delegation</ModalHeader>
        <ModalBody>
          <Menu
            color='primary'
            items={(Object.keys(roleConfig) as SurveyDelegateRole[]).map((value) => ({
              active: role === value,
              onClick: () => setRole(value),
              className: 'text-nowrap',
              label: roleConfig[value].tabLabel,
            }))}
            className='mb-3'
          />
          <p>{roleConfig[role].instruction}</p>
          <Table
            data={filteredUsers}
            columns={[
              {
                id: 'name',
                header: () => <span className='text-capitalize'>{roleConfig[role].label}</span>,
                cell: ({ row }) => (
                  <UserRow
                    userRole={role}
                    user={row.original}
                    handleRemoveUser={handleRemoveUser}
                    isReadOnly={isReadOnly}
                  />
                ),
                accessorFn: (user) => getFullName(user, user.email),
              },
            ]}
          />
          <div className='d-flex justify-content-end'>
            <AddBtn
              userRole={role}
              onClick={toggleSearchModal}
              isReadOnly={isReadOnly}
              canAccessVerification={canAccessVerification}
            />
          </div>
        </ModalBody>
      </Modal>
      <SurveyDelegationSearchModal
        isOpen={isSearchModalOpen}
        handleSubmit={handleAddUser}
        toggle={toggleSearchModal}
        ignoredIds={filteredUsers.map((u) => u._id)}
        surveyId={surveyId}
        title={`Add a ${roleConfig[role].label}`}
      />
    </>
  );
};
