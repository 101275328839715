import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { TIME_RANGE_OPTIONS } from '../../../utils/date';
import { getDateRange } from '@g17eco/molecules/time-range-selector';
import { DateRangeType } from '@g17eco/types/common';
import { PRODUCT_TYPES } from '../partials/StaffFilters';
import { ExtraProps } from './useReportData';

interface CompanyFilters {
  showStaff: boolean;
  productCodes: string[];
  referralCode: string;
}

export interface StaffFilter {
  timeRange: string | number;
  dateRange: DateRangeType;
  setDateRange: (range: DateRangeType) => void;
  filters: CompanyFilters;
  handleSetShowStaff: (param: boolean) => void;
  handleSetProductType: (code: string) => void;
  handleSearchReferralCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearFilters: () => void;
  extraFilterApiParams: ExtraProps;
}

const initialFilters = {
  showStaff: false,
  productCodes: [PRODUCT_TYPES[0].code, PRODUCT_TYPES[1].code, PRODUCT_TYPES[2].code],
  referralCode: '',
};

export const useStaffFilters = () => {
  const [filters, setFilters] = useState(initialFilters);

  const [timeRange, setTimeRange] = useState(TIME_RANGE_OPTIONS[2].value);
  const [dateRange, setDateRange] = useState<DateRangeType>(getDateRange(Number(TIME_RANGE_OPTIONS[2].value)));
  const handleSetShowStaff = (checked: boolean) => {
    setFilters((prev) => ({ ...prev, showStaff: !!checked }));
  };

  const [debouncedReferralCode] = useDebounce(filters.referralCode, 1000);

  const extraFilterApiParams = useMemo(
    () => ({
      showStaff: filters.showStaff,
      productCodes: filters.productCodes,
      referralCode: debouncedReferralCode,
    }),
    [filters.showStaff, filters.productCodes, debouncedReferralCode]
  );

  const handleSetProductType = (productCode: string) => {
    const productCodes = filters.productCodes.includes(productCode)
      ? filters.productCodes.filter((code) => code !== productCode)
      : [...filters.productCodes, productCode];

    return setFilters((prev) => ({ ...prev, productCodes }));
  };

  const handleSearchReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, referralCode: e.target.value }));
  };

  const clearFilters = () => {
    setFilters(initialFilters);
    setTimeRange(TIME_RANGE_OPTIONS[2].value);
  };

  return {
    timeRange,
    dateRange,
    setDateRange,
    filters,
    debouncedReferralCode,
    handleSetShowStaff,
    handleSetProductType,
    handleSearchReferralCode,
    clearFilters,
    extraFilterApiParams,
  };
};
