import { useToggle } from '@hooks/useToggle';
import { Toolbar } from './Toolbar';
import { SurveyDelegationProps } from './types';
import { UsersTable } from './UsersTable';
import { DelegatedUsers, useGetSurveyUsersQuery, User } from '@api/survey-users';
import { hasAtLeastOneRoleLevel, RoleLevel, SURVEY_DELEGATE_ROLES } from '@utils/delegation-users';
import { QueryWrapper } from '@components/query/QueryWrapper';

const getUniqueUsers = ({
  users,
  platformDelegatesIncluded,
}: {
  users?: DelegatedUsers;
  platformDelegatesIncluded: boolean;
}): User[] => {
  if (!users) {
    return [];
  }

  const usersMap = new Map<string, User>();

  Object.values(users)
    .flat()
    .forEach((user) => {
      const isExplicitlyDelegate = SURVEY_DELEGATE_ROLES.some((role) =>
        hasAtLeastOneRoleLevel([RoleLevel.Utrv, RoleLevel.Survey], role, user)
      );
      if (!platformDelegatesIncluded && !isExplicitlyDelegate) {
        return;
      }
      usersMap.set(user._id, user);
    });

  return Array.from(usersMap.values());
};

export const SurveyDelegation = ({ view, onChangeView, utrvIds, surveyId, isReadOnly }: SurveyDelegationProps) => {
  const [platformDelegatesIncluded, toggle] = useToggle(true);

  return (
    <QueryWrapper
      query={useGetSurveyUsersQuery({
        surveyId,
        utrvIds,
      })}
      onSuccess={(data) => {
        const users = getUniqueUsers({ users: data, platformDelegatesIncluded });

        return (
          <>
            <Toolbar
              view={view}
              onChangeView={onChangeView}
              platformDelegatesIncluded={platformDelegatesIncluded}
              onTogglePlatformDelegatesIncluded={toggle}
              users={data}
              surveyId={surveyId}
              isReadOnly={isReadOnly}
            />
            <UsersTable utrvIds={utrvIds} users={users} />
          </>
        );
      }}
    />
  );
};
