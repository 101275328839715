import { NumberAnswer, TextAnswer, UnsettledAnswer } from './BaseTypeAnswers';
import { QUESTION } from '@constants/terminology';
import { TableColumnType, UtrValueType } from '@g17eco/types/universalTracker';
import { DashboardDivider } from '@g17eco/atoms';
import { MultiInputSelect } from './MultiInputSelect';
import { SUPPORTED_COLUMN_TYPES, SUPPORTED_UTR_TYPES } from '../constants';
import { PreviousAnswers } from '../PreviousAnswers';
import { useState } from 'react';
import { isNumericString } from '@utils/string';
import { AnswerDataProps } from '../Answer';
import { getAnswerTooltip } from '../utils';
import { isSingleRowTableType } from '@utils/universalTracker';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface PlaceholderProps {
  hasPreviousUtrvs: boolean;
}

const MultiInputAnswerPlaceholder = ({ hasPreviousUtrvs }: PlaceholderProps) => {
  return (
    <div className='mt-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <h6 className='my-0 text-ThemeHeadingDark'>AI Draft / Estimate:</h6>
          {
            <SimpleTooltip text={getAnswerTooltip(hasPreviousUtrvs)}>
              <i className='fa-light fa-circle-info ml-1 text-ThemeIconSecondary' />
            </SimpleTooltip>
          }
        </div>
        <div className='mb-0 loading-placeholder h-md col-4 rounded-pill' />
      </div>
      <div className='mt-2 d-flex justify-content-end gap-1 align-items-center'>
        <div className={'loading-placeholder background-ThemeBgLight col-6 h-sm rounded-pill'} />
        <div className='d-flex align-items-center justify-content-end mb-0 loading-placeholder h-md col-2 rounded-pill'>
          <div className={'loading-placeholder background-ThemeBgLight col-6 h-sm rounded-pill mr-2'} />
        </div>
      </div>
    </div>
  );
};

export const MultiInputAnswerData = ({
  utr,
  column,
  utrv,
  table,
  predictedAnswers,
  ...props
}: Omit<MultiInputAnswerProps, 'previousUtrvs'> & { column: string }) => {
  switch (utr.valueType) {
    case UtrValueType.TextValueList: {
      const inputAnswer = utrv.valueData?.data?.[column] ?? '';
      const predictedAnswer = predictedAnswers[column];
      return <TextAnswer inputAnswer={inputAnswer} predictedAnswer={predictedAnswer} {...props} />;
    }
    case UtrValueType.NumericValueList: {
      let inputAnswer;
      let predictedAnswer;
      if (column === 'total') {
        inputAnswer = utrv.value;
        predictedAnswer = Object.values(predictedAnswers).reduce<number>(
          (acc, val) => (isNumericString(val) ? acc + Number(val) : acc),
          0
        );
      } else {
        inputAnswer = utrv.valueData?.data?.[column];
        predictedAnswer = predictedAnswers[column];
      }
      return <NumberAnswer inputAnswer={inputAnswer} predictedAnswer={predictedAnswer} utr={utr} {...props} />;
    }
    case UtrValueType.Table: {
      const tableColumn = utr.valueValidation?.table?.columns.find((col) => col.code === column);
      const columnType = tableColumn?.type;
      if (!columnType || !SUPPORTED_COLUMN_TYPES.includes(columnType)) {
        return <p className='text-ThemeTextDark'>This {QUESTION.SINGULAR} is not yet supported</p>;
      }

      // If single row table then get direct from utr.valueData
      // If multi row table then get from editing table variable
      const inputValue = isSingleRowTableType(utr)
        ? utrv.valueData?.input?.table?.[0]?.find((col) => col.code === column)?.value
        : table.rows[table.editRowId]?.data?.find((col) => col.code === column)?.value;

      const inputAnswer = inputValue && Array.isArray(inputValue) ? inputValue.join(', ') : inputValue;
      const predictedAnswer = predictedAnswers[column];

      // Value list column may have a numeric value mapped with the code of an option,
      // but we want to display the option name
      const isValueListColumn = columnType === TableColumnType.Number && tableColumn.listId;

      if (columnType === TableColumnType.Text || isValueListColumn) {
        return <TextAnswer inputAnswer={inputAnswer} predictedAnswer={predictedAnswer} {...props} />;
      }

      if (columnType === TableColumnType.Number || columnType === TableColumnType.Percentage) {
        const convertedAnswer = isNumericString(inputAnswer) ? Number(inputAnswer) : undefined;
        return (
          <NumberAnswer
            inputAnswer={convertedAnswer}
            predictedAnswer={predictedAnswer}
            utr={utr}
            selectedColumnCode={column}
            {...props}
          />
        );
      }
      return null;
    }

    default:
      return <UnsettledAnswer />;
  }
};

interface MultiInputAnswerProps extends Omit<AnswerDataProps, 'predictedData'> {
  predictedAnswers: { [key: string]: string | number };
}

export const MultiInputAnswer = (props: MultiInputAnswerProps) => {
  const { utr, utrv, table, predictedAnswers, isFetching, targetValue, previousUtrvs } = props;
  const [column, setColumn] = useState<string>('');
  const hasPreviousUtrvs = Boolean(previousUtrvs.length);

  return (
    <>
      <div>
        <MultiInputSelect isDisabled={isFetching} utr={utr} handleChangeColumn={setColumn} />
        {isFetching || !column ? <MultiInputAnswerPlaceholder hasPreviousUtrvs={hasPreviousUtrvs} /> : null}
        {predictedAnswers && column ? (
          <div className='mt-3'>
            <MultiInputAnswerData
              utr={utr}
              utrv={utrv}
              table={table}
              column={column}
              hasPreviousUtrvs={hasPreviousUtrvs}
              predictedAnswers={predictedAnswers}
              targetValue={targetValue}
              isFetching={isFetching}
            />
          </div>
        ) : null}
      </div>
      <DashboardDivider className='' />
      {hasPreviousUtrvs && SUPPORTED_UTR_TYPES.includes(utr.valueType as UtrValueType) && targetValue ? (
        <>
          <PreviousAnswers
            utrv={utrv}
            utr={utr}
            previousUtrvs={previousUtrvs}
            predictedAnswer={predictedAnswers[column]}
            isFetching={isFetching}
          />
          <DashboardDivider className='my-3' />
        </>
      ) : null}
    </>
  );
};
