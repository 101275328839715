import React from 'react';
import { Input, FormGroup, Row, Col } from 'reactstrap';
import { DataScopeAccess, DataShareScopeView, DataShareWithRequester } from '../../../types/dataShare';
import { canAccessData, getScopeAccess, getScopeOptions, getScopeViews } from '../../../utils/dataShare';
import { MetricGroup } from '../../../types/metricGroup';
import { DataShareTarget, TOOLTIP, Title } from './DataShareForm';
import { getStandardsFrameworksValues } from '../../../constants/standards-frameworks';
import { DashboardSection } from '../../dashboard';
import { useAppSelector } from '../../../reducers';
import { ShareNote } from './DataSharePermissions';
import { SURVEY } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface MandatoryDataShareFormProps {
  dataShare: DataShareWithRequester;
  metricGroups: MetricGroup[];
}

const MandatoryDataShareForm = ({ dataShare, metricGroups }: MandatoryDataShareFormProps) => {
  const dataScope = dataShare.dataScope;
  const accessData = canAccessData(dataScope);
  const shareAllPacks = getScopeAccess(dataScope) === DataScopeAccess.Full;
  const sharePacks = getScopeOptions(dataScope);
  const shareScopeViews = getScopeViews(dataScope);

  const standardsFrameworksValues = getStandardsFrameworksValues();
  const metricGroupsValues = metricGroups.map((item) => ({ label: item.groupName, value: item._id }));

  const getPack = (code: string) => {
    const found = [...standardsFrameworksValues, ...metricGroupsValues].find((item) => item.value === code);
    return found;
  };

  return (
    <div className='datashare-details'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex flex-column'>
          <Title>Organisation</Title>
          <div className='mt-2 text-md text-ThemeTextDark'>{dataShare.requester.name}</div>
          <div className='text-xs text-ThemeTextMedium'>
            {dataShare.requester.name} receives data from completed {SURVEY.PLURAL}.
            <br />
            This is part of the sponsorship agreement.
          </div>
        </div>
        <div className='d-flex align-items-start gap-4 mt-4'>
          <FormGroup switch className='d-flex text-muted'>
            <Input
              id={`${dataShare._id}-can-access-data`}
              type='switch'
              className='mr-2'
              checked={accessData}
              disabled
              readOnly
            />
            <label htmlFor={`${dataShare._id}-can-access-data`}>Can access data</label>
          </FormGroup>
          <FormGroup switch className='d-flex text-muted'>
            <Input
              id={`${dataShare._id}-${DataShareScopeView.Messaging}`}
              type='switch'
              className='mr-2'
              checked={shareScopeViews.includes(DataShareScopeView.Messaging)}
              disabled
              readOnly
            />
            <label htmlFor={`${dataShare._id}-${DataShareScopeView.Messaging}`} className='d-flex flex-column'>
              <span className='text-md'>Can direct message</span>
              <span className='text-xs'>(DMs will go to all Adminstrators)</span>
            </label>
          </FormGroup>
        </div>
      </div>

      <div className='w-100'>
        <div className='d-flex justify-content-center mt-3'>
          <div className='advanced-settings-divider' />
        </div>

        <Row className='pt-4'>
          <Col xs={12} md={5} className='d-flex flex-column text-ThemeTextMedium'>
            <Title>Access to</Title>
            <div className='d-flex align-items-center mt-2 text-muted'>
              <Input
                id={`${dataShare._id}-${DataShareTarget.CompletedSurveys}`}
                type='radio'
                name={`${dataShare._id}-shareTarget`}
                className='mr-2'
                checked
                disabled
                readOnly
              />
              <label htmlFor={`${dataShare._id}-${DataShareTarget.CompletedSurveys}`}>
                Data from Completed {SURVEY.PLURAL}
                <SimpleTooltip text={TOOLTIP.completedSurveys}>
                  <i className='fa-light fa-circle-info ml-1' />
                </SimpleTooltip>
              </label>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <Title>Include data from</Title>
            {!shareAllPacks ? (
              <div className='mt-2 text-muted'>
                {sharePacks.map((pack) => (
                  <div key={pack.code} className='d-flex gap-2 mt-1'>
                    <Input type='checkbox' checked disabled className='share-scope-checkbox' />
                    <label>{getPack(pack.code)?.label}</label>
                  </div>
                ))}
              </div>
            ) : null}
          </Col>

          <Col xs={12} md={3} className='d-flex flex-column'>
            <Title>Access to pages</Title>
            <FormGroup switch className='d-flex align-items-center mt-2 text-muted'>
              <Input
                id={`${dataShare._id}-${DataShareScopeView.Survey}`}
                type='switch'
                className='mr-2'
                checked={shareScopeViews.includes(DataShareScopeView.Survey)}
                disabled
                readOnly
              />
              <label htmlFor={`${dataShare._id}-${DataShareScopeView.Survey}`}>{SURVEY.CAPITALIZED_SINGULAR} overview</label>
            </FormGroup>
            <FormGroup switch className='d-flex align-items-center mt-2 text-muted'>
              <Input
                id={`${dataShare._id}-${DataShareScopeView.Insights}`}
                type='switch'
                className='mr-2'
                checked={shareScopeViews.includes(DataShareScopeView.Insights)}
                disabled
                readOnly
              />
              <label htmlFor={`${dataShare._id}-${DataShareScopeView.Insights}`}>
                Insights
                <SimpleTooltip text={TOOLTIP.insights}>
                  <i className='fa-light fa-circle-info ml-1' />
                </SimpleTooltip>
              </label>
            </FormGroup>
            <FormGroup switch className='d-flex align-items-center mt-2 text-muted'>
              <Input
                id={`${dataShare._id}-${DataShareScopeView.Downloads}`}
                type='switch'
                className='mr-2'
                checked={shareScopeViews.includes(DataShareScopeView.Downloads)}
                disabled
                readOnly
              />
              <label htmlFor={`${dataShare._id}-${DataShareScopeView.Downloads}`}>Downloads</label>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

interface MandatoryDataSharePermissionsProps {
  dataShares: DataShareWithRequester[];
}

export const MandatoryDataSharePermissions = (props: MandatoryDataSharePermissionsProps) => {
  const { dataShares } = props;
  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);

  return (
    <DashboardSection
      className='data-share-permissions'
      classes={{
        whiteBoxContainer: 'border-0',
      }}
    >
      <React.Fragment>
        {dataShares.map((dataShare) => (
          <MandatoryDataShareForm key={dataShare._id} dataShare={dataShare} metricGroups={metricGroups} />
        ))}

        <ShareNote />
      </React.Fragment>
    </DashboardSection>
  );
};
