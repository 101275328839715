import GridLayout, { Layout } from 'react-grid-layout';
import { GridDashboardItem } from '../../types/insight-custom-dashboard';
import { GridItem, GridItemProps, UtrvHistoryModalProps } from './GridItem';
import './styles.scss';
import { COLUMNS, GRID_WIDTH, MARGIN, ROW_HEIGHT, getLayoutByItems, isSDGContributionType, isSpaceType } from './utils';
import { useCallback, useMemo } from 'react';
import { generateGridBackground } from './gridLayoutUtils';
import { DataFilters } from './types';

type GridLayoutDashboardProps = Pick<
  GridItemProps,
  | 'utrsData'
  | 'isEditing'
  | 'onItemRemove'
  | 'handleClickEdit'
  | 'handleClickDuplicate'
  | 'readOnly'
  | 'hideQuestionReference'
  | 'initiativeId'
  | 'survey'
  | 'actionBtn'
  | 'integrationsData'
  | 'NoDataView'
  | 'scorecard'
  | 'initiativeIds'
> & {
  handleOpenUtrvHistoryModal?: (params: UtrvHistoryModalProps) => void;
  gridItems: GridDashboardItem[];
  onLayoutChange?: (layout: Layout[]) => void;
  onItemResize?: (newItemSize: Layout) => void;
  classes?: {
    container?: string;
  };
  dataFilters?: DataFilters;
  dashboardRef?: React.ForwardedRef<HTMLDivElement>;
  pageBreaks?: number[];
  onEditItem?: (id: string) => void;
};

const cellSize = (() => {
  const marginSlotsCount = COLUMNS - 1;
  const [horizontalMargin] = MARGIN;
  const totalHorizontalMargin = marginSlotsCount * horizontalMargin;
  const freeSpace = GRID_WIDTH - totalHorizontalMargin;
  return {
    width: freeSpace / COLUMNS,
    height: ROW_HEIGHT,
  };
})();

export const GridLayoutDashboard = (props: GridLayoutDashboardProps) => {
  const {
    isEditing,
    gridItems,
    utrsData,
    onLayoutChange,
    onItemRemove,
    onItemResize,
    handleClickEdit,
    handleClickDuplicate,
    integrationsData,
    handleOpenUtrvHistoryModal = () => {},
    readOnly = false,
    hideQuestionReference = false,
    initiativeId,
    initiativeIds,
    actionBtn,
    survey,
    scorecard,
    classes = { container: 'ps-3' },
    dataFilters,
    dashboardRef,
    pageBreaks,
    onEditItem
  } = props;

  const layout = getLayoutByItems(gridItems);

  const renderItem = useCallback(
    (item: GridDashboardItem) => (
      <div key={item._id}>
        <GridItem
          key={item._id}
          utrsData={utrsData}
          integrationsData={integrationsData}
          item={item}
          isEditing={isEditing}
          onItemRemove={onItemRemove}
          handleClickEdit={isSDGContributionType(item) || isSpaceType(item) ? undefined : handleClickEdit}
          handleClickDuplicate={handleClickDuplicate}
          handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
          readOnly={readOnly}
          hideQuestionReference={hideQuestionReference}
          initiativeId={initiativeId}
          initiativeIds={initiativeIds}
          survey={survey}
          NoDataView={props.NoDataView}
          actionBtn={actionBtn}
          scorecard={isSDGContributionType(item) ? scorecard : undefined}
          dataFilters={dataFilters}
          onEditItem={onEditItem}
        />
      </div>
    ),
    [
      actionBtn,
      handleClickEdit,
      handleClickDuplicate,
      handleOpenUtrvHistoryModal,
      hideQuestionReference,
      initiativeId,
      integrationsData,
      isEditing,
      onItemRemove,
      props.NoDataView,
      readOnly,
      scorecard,
      survey,
      utrsData,
      dataFilters,
      initiativeIds,
      onEditItem,
    ]
  );

  const height = useMemo(() => {
    let lowestLayoutCellPoint = layout.length > 0 ? Math.max(...layout.map((l) => l.y + l.h)) : 0;
    if (isEditing) {
      lowestLayoutCellPoint += Math.ceil(window.innerHeight / cellSize.height);
    }
    const [_horizontalMargin, verticalMargin] = MARGIN;
    return (cellSize.height + verticalMargin) * lowestLayoutCellPoint;
  }, [layout, isEditing]);

  const background = useMemo(
    () => (isEditing ? generateGridBackground({ cellSize, margin: MARGIN, cols: COLUMNS, gridWidth: GRID_WIDTH }) : ''),
    [isEditing]
  );

  const style = useMemo(
    () => ({
      width: GRID_WIDTH,
      height,
      background: background,
    }),
    [height, background]
  );

  const children = useMemo(() => gridItems.map(renderItem), [gridItems, renderItem]);

  return (
    <div>
      <div
        className={`grid-container position-relative ${classes.container || ''}`}
        ref={dashboardRef}
        data-testid='grid-container'
        // 32px to represent the padding, must be in pixels as rem doesn't work
        style={{ width: `calc(${GRID_WIDTH}px + 32px)` }}
      >
        <GridLayout
          className='layout position-relative mb-5'
          containerPadding={[0, 0]}
          cols={COLUMNS}
          width={GRID_WIDTH}
          rowHeight={ROW_HEIGHT}
          margin={MARGIN}
          onResize={(currentLayout, oldItem, newItem) => onItemResize?.(newItem)}
          layout={getLayoutByItems(gridItems)}
          isDraggable={isEditing}
          isResizable={isEditing}
          onLayoutChange={onLayoutChange}
          style={style}
        >
          {children}
        </GridLayout>
        {pageBreaks && pageBreaks.length
          ? pageBreaks.map((breakPoint, index) => (
              <div
                data-html2canvas-ignore
                className='ms-3'
                key={index}
                style={{
                  width: GRID_WIDTH,
                  position: 'absolute',
                  top: breakPoint,
                  left: 0,
                  right: 0,
                  borderTop: '2px dashed red',
                }}
              />
            ))
          : null}
      </div>
    </div>
  );
};
