import { Button } from 'reactstrap';
import { useAppConfig } from '../../../../hooks/app/useAppSettings';
import { SubscriptionService } from '../../../../services/SubscriptionService';
import { useAppSelector } from '../../../../reducers';
import { formatDate } from '../../../../utils/date';
import { hasSubscriptionSetup } from '../../../../selectors/initiative';
import G17Client from '../../../../services/G17Client';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetInitiativeSponsorshipsQuery } from '../../../../api/initiative-sponsorships';
import { useSiteAlert } from '../../../../hooks/useSiteAlert';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

export const SubscriptionDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const appConfig = useAppConfig();
  const { addSiteError } = useSiteAlert();
  const { data, loaded } = useAppSelector((state) => state.globalData);
  const organization = loaded ? data.organization : undefined;
  const hasSubscriptions = useAppSelector(hasSubscriptionSetup);
  const { data: sponsorships } = useGetInitiativeSponsorshipsQuery(organization?._id ?? skipToken);

  const subsidisedBy =
    sponsorships && sponsorships.length > 0 ? `Subsidised by ${sponsorships.map(({ name }) => name).join(', ')}.` : '';

  if (!appConfig || !loaded) {
    return null;
  }

  const subscription = SubscriptionService.getBestValidSubscription(
    appConfig.productCode,
    organization?.calculatedSubscriptions
  );
  const hasActiveSubscription = SubscriptionService.hasActive(appConfig.productCode, organization?.calculatedSubscriptions);

  const canManageSubs = hasSubscriptions && SubscriptionService.canManageSubscriptions({ appConfig });

  const handleManageSubscription = () => {
    if (!organization) {
      return;
    }
    G17Client.createCustomerPortal({ initiativeId: organization._id, returnUrl: location.pathname })
      .then((s) => window.open(s.url, '_blank', ''))
      .catch((e) => addSiteError(e));
  };

  return (
    <div className='border border-2 rounded-1 border-ThemeAccentLight'>
      <div className='d-flex justify-content-between align-items-center p-3 background-ThemeBgMedium'>
        <h4 className='d-flex align-items-center m-0'>
          {appConfig.name}{' '}
          {subscription ? (
            <span className='h6 ml-2 my-0'>(renewal date {formatDate(new Date(subscription.periodEnd * 1000))})</span>
          ) : null}
        </h4>
        <i className='fa-solid fa-circle-check text-lg text-ThemeInfoDark' />
      </div>
      {subsidisedBy || hasSubscriptions ? (
        <div className='w-100 d-flex justify-content-between align-items-center p-3'>
          {subsidisedBy ? (
            <div className='flex-grow-1 text-left text-md text-ThemeHeadingLight'>{subsidisedBy}</div>
          ) : null}
          <div className='flex-grow-1 text-right'>
            {hasSubscriptions && organization?._id ? (
              <SimpleTooltip text={hasActiveSubscription ? 'Active subscription is enabled' : undefined}>
                <Button
                  type='button'
                  color='secondary'
                  outline
                  className='text-sm mr-3'
                  disabled={hasActiveSubscription}
                  onClick={() => history.push(generateUrl(ROUTES.REFERRALS, { initiativeId: organization._id }))}
                >
                  Add referral code
                </Button>
              </SimpleTooltip>
            ) : null}
            {canManageSubs ? (
              <Button type='button' color='primary' outline className='text-sm' onClick={handleManageSubscription}>
                Manage subscription
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
