import { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { InitiativeBenchmarking } from '@g17eco/types/initiative';
import IconButton from '@components/button/IconButton';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { TimeRangeSelector } from '@g17eco/molecules/time-range-selector';
import { DateRangeType } from '@g17eco/types/common';
import { FilterSurveyPacks } from '../partials/FilterSurveyPacks';
import {
  getAnswered,
  getEffectiveDate,
  getPercentNa,
  getPercentNr,
  getPercentPrivate,
  getProgress,
  getTotalQuestions,
  getUnanswered,
} from './utils';
import { useIndustrySector } from '@components/industry-sector-selector/useIndustrySector';
import { RequestScope } from '@actions/api';
import { exportToExcel } from '@components/downloads/util/exportToExcel';
import { QUESTION, SURVEY } from '@constants/terminology';

const rowHeaders = [
  'Company name',
  SURVEY.CAPITALIZED_PLURAL,
  `Latest ${SURVEY.SINGULAR}`,
  `${QUESTION.CAPITALIZED_PLURAL} in scope`,
  'Unanswered',
  'Answered',
  '% Private',
  '% N/A',
  '% N/R',
];

export interface Filters {
  sector?: string;
  industry?: string | null;
  marketCap?: string;
  surveyPacks?: string[];
}

interface LatestSurveyFiltersProps {
  filteredCompanies: InitiativeBenchmarking[];
  filters: Filters;
  dateRange: DateRangeType;
  changeCheckedCompletedSurveys: (checked: boolean) => void;
  changeFilters: (filters: Filters) => void;
  changeDateRange: (dateRange: DateRangeType) => void;
}

const LatestSurveyFilters = (props: LatestSurveyFiltersProps) => {
  const { filteredCompanies, filters, dateRange, changeCheckedCompletedSurveys, changeFilters, changeDateRange } = props;
  const { sectorOptions, industryOptions } = useIndustrySector(filters.sector);

  const handleCheckboxCompletedSurveys = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    changeCheckedCompletedSurveys(checked);
  };

  const handleChangeFilters = (name: string, option: Option | RequestScope[]) => {
    const newFilters = {
      ...filters,
      [name]: Array.isArray(option) ? option.map(option => option.code) : option.value,
    };
    changeFilters(newFilters);
  };

  const handleChangeSectorIndustryFilters = (name: string, option: Option | null) => {
    let newFilters = { ...filters };
    if (name === 'sector') {
      // reset industry if sector change
      newFilters = { ...newFilters, industry: null };
    }
    newFilters = { ...newFilters, [name]: option?.value ? option?.label : null };

    changeFilters(newFilters);
  };

  const getRowsData = () => {
    const rowValues = filteredCompanies.map((company: InitiativeBenchmarking) => {
      return [
        company.name,
        getEffectiveDate(company).display,
        getProgress(company).display,
        getTotalQuestions(company),
        getUnanswered(company),
        getAnswered(company),
        getPercentPrivate(company).display,
        getPercentNa(company).display,
        getPercentNr(company).display,
      ];
    });
    return { rowHeaders, rowValues };
  };

  const downloadLatestSurveys = () => {
    const { rowHeaders, rowValues } = getRowsData();
    exportToExcel({ headers: rowHeaders, values: rowValues, fileName: 'benchmarking-latest-surveys' });
  };

  return (
    <div className='latest-survey-filters'>
      <div className='d-flex justify-content-between align-items-center gap-3 filters-wrapper'>
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          placeholder='Sector'
          isClearable
          className='flex-grow-1'
          options={sectorOptions}
          value={sectorOptions.find((item) => item.label === filters.sector) ?? null}
          onChange={(option) => handleChangeSectorIndustryFilters('sector', option)}
        />
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          placeholder='Industry'
          isClearable
          className='flex-grow-1'
          options={industryOptions}
          value={industryOptions.find((item) => item.label === filters.industry) ?? null}
          onChange={(option) => handleChangeSectorIndustryFilters('industry', option)}
          isDisabled={!filters.sector}
        />
        <div className='flex-grow-1'>
          <SimpleTooltip text='Filter currently unavailable'>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              placeholder='Market cap'
              options={[]}
              onChange={(option) => (option ? handleChangeFilters('marketCap', option) : undefined)}
              isDisabled
            />
          </SimpleTooltip>
        </div>
        <div className='flex-grow-1'>
          <FilterSurveyPacks
            selectedScopes={filters.surveyPacks}
            handleSurveyPackFilter={(option: RequestScope[]) => handleChangeFilters('surveyPacks', option)}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center gap-3 mt-5'>
        <FormGroup check key='completedSurvey'>
          <Input
            type='checkbox'
            id='completedSurvey'
            name='completedSurvey'
            onChange={handleCheckboxCompletedSurveys}
          />
          <Label check for='completedSurvey'>
            Completed {SURVEY.PLURAL}
          </Label>
        </FormGroup>
        <TimeRangeSelector
          dateRange={dateRange}
          onChangeDateRange={(range) => changeDateRange(range)}
        />
        <IconButton
          onClick={downloadLatestSurveys}
          outline={false}
          color='transparent'
          className='text-ThemeAccentDark'
          icon='fal fa-file-excel'
        />
      </div>
    </div>
  );
};

export default LatestSurveyFilters;
