import { RowDataInfo, RowStatus, ValidationRow } from '../../question/questionInterfaces';

interface StatusDetails {
  tooltip: string;
  icon: string;
  testId?: string;
}

const status: Record<string, StatusDetails | undefined> = {
  [RowStatus.removed]: {
    tooltip: 'Row marked for deletion',
    icon: 'fas fa-trash-alt',
  },
  [RowStatus.edited]: {
    tooltip: 'Row have been edited',
    icon: 'fas fa-pencil-alt',
  },
  [RowStatus.added]: {
    tooltip: 'Row not yet saved',
    icon: 'far fa-save',
  }
};

const getStatus = (original: Omit<RowDataInfo, 'data'>, editRowId: number) => {
  if (!original) {
    return '';
  }
  const { id, isRemoved, rowStatus, isEdited, hasChanged } = original;
  if (isRemoved) {
    return RowStatus.removed;
  }

  if (rowStatus === RowStatus.added) {
    return RowStatus.added;
  }

  if (isEdited || hasChanged || editRowId === id) {
    return RowStatus.edited;
  }

  return '';
}

export const getControlIcon = (original: RowDataInfo, editRowId: number, validations:  ValidationRow[] | undefined) => {
  const statusDetails = status[getStatus(original, editRowId)];
  if (statusDetails) {
    return statusDetails;
  }

  if (original?.id !== undefined && validations) {
    const validation = validations.find(v => v.id === original.id);
    if (validation?.items.length) {
      return {
        tooltip: 'Row has validation issues',
        icon: 'fas fa-exclamation-triangle text-ThemeDangerMedium',
        testId: `row-validation-${original.id}`
      } satisfies StatusDetails;
    }
  }

  return undefined;
};
export const getClasses = (original: Omit<RowDataInfo, 'data'>, editRowId: number) => `status_${(getStatus(original, editRowId))}`;
