import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadInitiativeTree } from '../../actions';
import { currentInitiative } from '../../selectors/initiative';
import { useAppSelector } from '../../reducers';
import { reloadCurrentUser } from '../../actions/user';
import { useHistory, useParams } from 'react-router-dom';
import { SiteAlertColors, addSiteAlert } from '../../slice/siteAlertsSlice';
import G17Client from '../../services/G17Client';
import { FeaturePermissions } from '../../services/permissions/FeaturePermissions';
import { getInitiativeTree, getInitiativeTypeTree } from '../../selectors/initiativeTree';
import { InitiativePermissions } from '../../services/permissions/InitiativePermissions';
import { Button } from 'reactstrap';
import NotAuthorised from '../not-authorised';
import { ErrorComponent } from '@features/error';
import { SubsidiaryModal } from './partials/subsidiary-modal/SubsidiaryModal';
import { MoveSubsidiaryModal } from './partials/subsidiary-modal/MoveSubsidiaryModal';
import * as d3 from 'd3';
import { findRootParentInTree } from '../../utils/initiative';
import { generateUrl } from '../util';
import { ROUTES } from '../../constants/routes';
import { BUTTON_WIDTH, DROPDOWN_SIZE, NODE_HEIGHT, NODE_HTML_WIDTH, REM_TO_PIXEL, SPACING } from './constants';
import { isOrganisation } from './utils';
import {
  MinReportingLevel,
  OrgMapModal,
  SelectedInitiative,
  SelectionSVG,
  SelectionTreeNode,
  SelectionTreeNodePath,
  TreeNode,
} from './types';
import { TopMessage } from './partials/TopMessage';
import { InitiativeStructureContainerProps } from './InitiativeStructureContainer';
import { CurrentUserData } from '../../reducers/current-user';
import { ArchiveSubsidiaryModal } from './partials/archive-subsidiary-modal/ArchiveSubsidiaryModal';
import { BaseOrgMapProps, useBaseOrgMap } from './hooks/useBaseOrgMap';
import { InitiativePlain, RootInitiativeData } from '../../types/initiative';
import { ExpandActions } from './partials/ExpandActions';

interface InitiativeStructureFoldersProps extends InitiativeStructureContainerProps {
  currentUser: CurrentUserData;
  archivedInitiatives: InitiativePlain[];
  rootOrg?: RootInitiativeData;
  expandedNodes?: string[];
  setExpandedNodes?: React.Dispatch<React.SetStateAction<string[]>>;
}

interface OrgMapState {
  loading: boolean;
  showModal: OrgMapModal | undefined;
  initiativeSelected: SelectedInitiative | undefined;
  firstRunAnimation: boolean;
}

const defaultOrgMapState = {
  loading: false,
  showModal: undefined,
  initiativeSelected: undefined,
  firstRunAnimation: true,
};

export const InitiativeStructureFolders = (props: InitiativeStructureFoldersProps) => {
  const [orgMapState, setOrgMapState] = useState<OrgMapState>(defaultOrgMapState);
  const setState = (newState: Partial<OrgMapState>) => {
    setOrgMapState((prev) => ({ ...prev, ...newState }));
  };

  const {
    rootOrg,
    isPortfolioTracker,
    onClick,
    customTree,
    currentUser,
    archivedInitiatives,
    expandedNodes = [],
    setExpandedNodes = () => {},
  } = props;

  const initiative = useAppSelector(currentInitiative);
  const limitReportingLevels = useAppSelector(FeaturePermissions.getLimitReportingLevels);
  const initiativeTreeList = useAppSelector(getInitiativeTypeTree);
  const showPermissionDenied = useAppSelector((state) => state.authentication.showPermissionDenied);
  const defaultTree = useAppSelector(getInitiativeTree);
  // Restricted to root org manager only?
  const canManageRootOrg = rootOrg ? InitiativePermissions.canManageInitiative(currentUser, rootOrg._id) : false;

  const dispatch = useDispatch();
  const history = useHistory();
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const canManage = initiative ? InitiativePermissions.canManageInitiative(currentUser, initiative._id) : false;
  const canAddMoreLevels =
    !canManage || isPortfolioTracker
      ? false
      : initiativeTreeList.length + archivedInitiatives.length < limitReportingLevels;
  const canEditLevels = limitReportingLevels <= 1 || !canManage || isPortfolioTracker ? false : true;
  const initiativeTree = customTree ?? defaultTree;
  const showOrgMapAnimation = !isPortfolioTracker && limitReportingLevels <= 1;
  const isArchivedEnabled = !isPortfolioTracker && limitReportingLevels > 1 && canManageRootOrg;

  const getTreeFilterRoot = () => {
    const defaultTreeRoot = initiativeTree.children[0];

    if (isPortfolioTracker && portfolioId) {
      const rootInitiative = findRootParentInTree(initiativeTree, portfolioId);
      return rootInitiative ?? defaultTreeRoot;
    }

    if (rootOrg) {
      const foundTreeRoot = initiativeTree.children.find((initiative) => rootOrg?._id === initiative.id);
      return foundTreeRoot ?? defaultTreeRoot;
    }

    return defaultTreeRoot;
  };

  const treeRootData = getTreeFilterRoot();

  const onMapClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLDivElement;
    if (target.matches('#map_dropdown') || target.matches('#map_dropdown i')) {
      return;
    }
    onCloseDropdownMenu();
  };

  const runOrgMapAnimation = (nodeEnter: SelectionTreeNode, linkEnter: SelectionTreeNodePath) => {
    ['.mindmap-text-wrap', '.expand-line', '.expand-container'].forEach((className, index) => {
      nodeEnter.select(className).style('opacity', 0);
      nodeEnter
        .select(className)
        .style('animation', (node) => `nodeAnimation ${index + 0.2}s ${node.depth * 0.8}s forwards`);
    });
    linkEnter.style('opacity', 0);
    linkEnter.style('animation', (node) => `linkAnimation 0.2s ${node.depth * 0.8}s forwards`);
    setState({ firstRunAnimation: false });
  };

  const baseOrgMapProps: BaseOrgMapProps = {
    initiativeTree,
    currentId: isPortfolioTracker ? portfolioId : initiative?._id,
    getTreeFilterRoot,
    onMapClick,
    actionButtons: !isPortfolioTracker
      ? {
          html: canManage
            ? `<button id="map_dropdown" class="btn-link py-1 px-2 mr-2">
            <i class=\'fal fa-caret-down\' id="map_dropdown_icon"></i>
            </button>`
            : '',
          onClick: (svg: SelectionSVG) => (event: React.MouseEvent<HTMLElement, MouseEvent>, d: TreeNode) => {
            const target = event.target as HTMLElement;
            if (!d.data.id || d.data.disabled) {
              return;
            }
            const [initiativeId] = d.data.id.split('_');
            if (target.id === 'initiativename') {
              onNodeClick(initiativeId);
            }
            if (target.id === 'map_dropdown' || target.id === 'map_dropdown_icon') {
              openDropdownMenu({ treeNode: d, svg });
            }
          },
        }
      : undefined,
    runOrgMapAnimation: orgMapState.firstRunAnimation && showOrgMapAnimation ? runOrgMapAnimation : undefined,
    expandedNodes,
    setExpandedNodes,
  };
  const {
    component,
    handleReset,
    handleZoom,
    addToExpandedNodes,
    zoomInDisabled,
    zoomOutDisabled,
    getInitiativeStructure,
  } = useBaseOrgMap(baseOrgMapProps);

  const toggle = () => {
    setState({
      showModal: undefined,
      initiativeSelected: undefined,
    });
  };

  const reloadAfterArchive = () => {
    if (!rootOrg) {
      return;
    }
    dispatch(loadInitiativeTree({ organization: rootOrg, forceRefresh: true, blocking: false }));
    dispatch(reloadCurrentUser());
  };

  const handleUpdate = () => {
    if (!rootOrg) {
      return;
    }
    dispatch(loadInitiativeTree({ organization: rootOrg, forceRefresh: true, blocking: false }));
  };

  const handleBackButton = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    history.goBack();
  };

  const handleInterestButton = async () => {
    if (!initiative) {
      return;
    }
    const formData = {
      title: 'I am interested in the organisation map',
      message: 'I am interested in the organisation map',
      company: initiative.name,
    };

    setState({ loading: true });

    return G17Client.userInterest(formData)
      .then(() => {
        setState({ loading: false });
        dispatch(
          addSiteAlert({
            content: 'Thanks. We will be in contact with more information soon.',
            timeout: 5000,
            color: SiteAlertColors.Success,
          })
        );
      })
      .catch((e) => {
        setState({ loading: false });
        dispatch(
          addSiteAlert({
            content: 'There was a problem sending the request. Please try again.',
            color: SiteAlertColors.Danger,
          })
        );
      });
  };

  const onCloseDropdownMenu = () => {
    const dropdowns = document.getElementsByClassName('node_dropdown_menu');
    for (const dropdown of dropdowns) {
      dropdown.remove();
    }
  };

  const onNodeClick = onClick
    ? onClick
    : (initiativeId?: string) => {
        history.push(generateUrl(ROUTES.SUMMARY, { initiativeId }));
      };

  const handleEdit = (initiativeId: string, name: string) => {
    setState({ showModal: OrgMapModal.EditSubsidiary, initiativeSelected: { initiativeId, name } });
  };
  const handleAdd = (initiativeId: string) => {
    setState({ showModal: OrgMapModal.AddSubsidiary, initiativeSelected: { initiativeId } });
  };

  const handleMoveSubsidiary = (initiativeId: string) => {
    setState({ showModal: OrgMapModal.MoveSubsidiary, initiativeSelected: { initiativeId } });
  };

  const handleArchive = (initiativeId: string, name: string) => {
    setState({ showModal: OrgMapModal.ArchiveSubsidiary, initiativeSelected: { initiativeId, name } });
  };

  const openDropdownMenu = ({ treeNode, svg }: { treeNode: TreeNode; svg: SelectionSVG }) => {
    const dropdown = d3.select('.node_dropdown_menu');
    if (dropdown.size()) {
      return dropdown.remove();
    }
    const tags = treeNode.data.tags ?? [];
    const [initiativeId] = treeNode.data.id.split('_');
    // It now includes cases where a subsidiary acts as a root org map when users have access to certain subsidiaries but not the root organization.
    const isRootOrgMap = isOrganisation(tags) || treeRootData.id === initiativeId;
    const x = Number(NODE_HEIGHT.replace('rem', '')) * REM_TO_PIXEL + treeNode.x - BUTTON_WIDTH;
    const y =
      Number(NODE_HTML_WIDTH.replace('rem', '')) * REM_TO_PIXEL + treeNode.y - BUTTON_WIDTH - SPACING;
    const editButton = canEditLevels
      ? '<li id="map_edit" class="px-2 py-1"><i class=\'mr-2 fal fa-pencil\'></i>Rename</li>'
      : '';
    const addButton = canAddMoreLevels
      ? '<li id="map_add" class="px-2 py-1"><i class=\'mr-2 fal fa-plus\'></i>Create child</li>'
      : '';
    const moveButton = canEditLevels
      ? `<li id="map_move" class="px-2 py-1 ${isRootOrgMap ? 'disabled' : ''}" title="${
          isRootOrgMap ? 'Root subsidiary cannot be moved' : ''
        }"><i class="mr-2 fal fa-arrows-turn-to-dots"></i>Move</li>`
      : '';
    const archiveButton = canEditLevels
      ? `<li id="map_archive" class="px-2 py-1 ${isRootOrgMap ? 'disabled' : ''}" title="${
          isRootOrgMap ? 'Root subsidiary cannot be archived' : ''
        }"><i class="mr-2 fal fa-trash-undo text-ThemeDangerMedium"></i>Archive</li>`
      : '';

    const options = [addButton, editButton, moveButton, archiveButton].filter((option) => option);

    if (!options.length) {
      return;
    }

    svg
      .append('g')
      .attr('class', 'node_dropdown_menu')
      .attr('transform', `translate(${y},${x})`)
      .append('svg:foreignObject')
      .attr('height', DROPDOWN_SIZE.OPTION_HEIGHT * options.length + SPACING * 2)
      .attr('width', DROPDOWN_SIZE.WIDTH)
      .attr('class', 'dropdown_menu')
      .html(() => {
        return `<div><ul class='px-0 py-1'>${options.join('')}</ul></div>`;
      })
      .on('click', function (event) {
        const isDisabled = event.target.classList.contains('disabled');
        if (isDisabled) return;

        if (event.target.id === 'map_edit') {
          handleEdit(initiativeId, treeNode.data.name);
          return;
        }
        if (event.target.id === 'map_add') {
          handleAdd(initiativeId);
          addToExpandedNodes(treeNode.data.id);
          return;
        }
        if (event.target.id === 'map_move') {
          handleMoveSubsidiary(initiativeId);
        }

        if (event.target.id === 'map_archive') {
          handleArchive(initiativeId, treeNode.data.name);
          return;
        }
      });
  };

  const navigateToArchived = () => {
    if (initiative) {
      history.push(generateUrl(ROUTES.NAVIGATE_BY_ARCHIVED, { initiativeId: initiative._id }));
    }
  };

  if (showPermissionDenied) {
    return <NotAuthorised />;
  }

  if (initiativeTree.children.length === 0) {
    return <ErrorComponent />
  }

  return (
    <>
      <SubsidiaryModal
        isOpen={[OrgMapModal.AddSubsidiary, OrgMapModal.EditSubsidiary].some(
          (modal) => modal === orgMapState.showModal
        )}
        isEdit={orgMapState.showModal === OrgMapModal.EditSubsidiary}
        initiativeSelected={orgMapState.initiativeSelected}
        toggle={toggle}
        handleUpdate={handleUpdate}
      />
      <MoveSubsidiaryModal
        isOpen={orgMapState.showModal === OrgMapModal.MoveSubsidiary}
        initiativeId={orgMapState.initiativeSelected?.initiativeId}
        initiativeTree={initiativeTree}
        initiativeTreeList={initiativeTreeList}
        toggle={toggle}
        handleUpdate={handleUpdate}
      />
      <ArchiveSubsidiaryModal
        isOpen={orgMapState.showModal === OrgMapModal.ArchiveSubsidiary}
        selectedInitiative={orgMapState.initiativeSelected as MinReportingLevel}
        treeRootData={treeRootData}
        initiativeTreeList={initiativeTreeList}
        toggle={toggle}
        handleUpdate={reloadAfterArchive}
      />
      <div className='mindmap-wrapper'>
        <div className='backButton'>
          <Button type='button' color='link' onClick={handleBackButton}>
            <i className='fas fa-arrow-left mr-2'></i>
            Back
          </Button>
        </div>

        <TopMessage
          canManage={canManage}
          isPortfolioTracker={isPortfolioTracker}
          showOrgMapAnimation={showOrgMapAnimation}
          isLoading={orgMapState.loading}
          handleInterestButton={handleInterestButton}
        />

        <div className='mindmap-menu'>
          <div className='row d-flex'>
            <div className='col col-auto'>
              <button
                disabled={zoomInDisabled}
                onClick={() => handleZoom(2)}
                className='fa-light fa-magnifying-glass-plus'
              ></button>
              <button
                disabled={zoomOutDisabled}
                onClick={() => handleZoom(0.5)}
                className='fa-light fa-magnifying-glass-minus'
              ></button>
              Zoom
            </div>

            <div className='col col-auto'>
              <div className='row d-flex'>
                <button onClick={() => handleReset()} className='col col-auto d-flex gap-2'>
                  Reset
                  <span className='fa-light fa-expand'></span>
                </button>
                <ExpandActions
                  getInitiativeStructure={getInitiativeStructure}
                  initiativeList={initiativeTreeList}
                  expandedNodes={expandedNodes}
                  setExpandedNodes={setExpandedNodes}
                />
              </div>
            </div>

            {isArchivedEnabled && (
              <Button color='danger' className='archived__button col col-auto px-2 py-1' onClick={navigateToArchived}>
                Archived
              </Button>
            )}
          </div>

          {isArchivedEnabled ? (
            <div className='d-flex flex-column text-end mt-3'>
              <div>Limit: {limitReportingLevels}</div>
              <div>Active: {initiativeTreeList.length}</div>
              <div>Remaining: {limitReportingLevels - initiativeTreeList.length}</div>
              <div>Archived: {archivedInitiatives.length}</div>
            </div>
          ) : null}
        </div>

        <div className=' dont_translate'>{component}</div>

        {showOrgMapAnimation ? (
          <div className='details text-center'>
            This organisation map allows you to gather data from business subsidiaries and divisions and aggregate the
            data up to head office level
          </div>
        ) : null}
      </div>
    </>
  );
};
