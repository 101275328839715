import { HistoricalUtrs } from '@api/insights';
import { ValueRole, CalculationType } from '@g17eco/types/insight-custom-dashboard';
import { createExpression, FormulaVariables, tryCalculation } from '@utils/formula';
import {
  getActualUtrvsGroupByDate,
  getLatestActualUtrvs,
  getVariablesWithValues,
} from '@routes/custom-dashboard/items/charts/multi-utrs-chart/utils';
import { ChartDataResponse } from '@routes/custom-dashboard/items/charts/PieChart';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { UniversalTrackerModalServiceUtrv } from '@reducers/universal-tracker-modal';
import { UtrVariables } from '@routes/summary/insights/utils/constants';
import { CHART_DECIMAL_PLACES, CHART_COLORS } from '@routes/custom-dashboard/utils';
import { getDecimalAsNumber } from '@utils/number';
import { isNumericString } from '@utils/string';
import { DataProps } from '@routes/custom-dashboard/items/types';

export const getColumnChartData = async (props: DataProps<HistoricalUtrs>) => {
  const { utrsData, variables, calculation } = props;

  const fallback = 0;

  let chartData: (string | number | { role: ValueRole })[][] = [];
  switch (calculation.type) {
    default:
    case CalculationType.Stages: {
      return { chartData: [] };
    }
    case CalculationType.Formula: {
      // This must be historical utr data as we don't support integration data
      const utrvsGroupByDate = getActualUtrvsGroupByDate(utrsData) as { [key: string]: HistoricalUtrs['utrvs'] };
      const [calculationValue] = calculation.values;
      const calculationName = calculationValue.role || calculationValue.name;

      const map = new Map<string, { _id: string; name: string }>();
      Object.values(utrvsGroupByDate)
        .flat()
        .forEach(({ initiative }) => {
          if (initiative?._id && !map.has(initiative._id)) {
            map.set(initiative._id, initiative);
          }
        });
      const initiatives = Array.from(map.values());

      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];

        const variablesWithValuesPerInitiative = initiatives.map((initiative) => {
          const utrsDataByInitiative = utrsData.map((data) => {
            return {
              ...data,
              utrvs: data.utrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
            };
          });
          return getVariablesWithValues({
            utrsData: utrsDataByInitiative,
            variables,
            utrvs: utrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
            fallback: '',
          });
        });

        return [
          date,
          ...variablesWithValuesPerInitiative.map((variables) => {
            return tryCalculation({
              formula: calculationValue.formula ?? '',
              variables,
              fallback,
            });
          }),
        ];
      });
      chartData.unshift(['Time', ...initiatives.map((initiative) => `${calculationName} (${initiative.name})`)]);

      return { chartData };
    }
  }
};

export const getPieChartData = async ({
  utrsData,
  variables,
  calculation,
}: DataProps<HistoricalUtrs>): Promise<ChartDataResponse> => {
  if (calculation.type === CalculationType.Stages) {
    return { chartData: [] };
  }

  const [calculationValue] = calculation.values;
  const calculationName = calculationValue.role || calculationValue.name;

  const {
    latestActualUtrvs = [],
    effectiveDate,
    period,
  } = getLatestActualUtrvs(utrsData) as {
    latestActualUtrvs: HistoricalUtrs['utrvs'];
    effectiveDate: string;
    period: DataPeriods | undefined;
  };

  const map = new Map<string, { _id: string; name: string }>();
  Object.values(latestActualUtrvs).forEach(({ initiative }) => {
    if (initiative?._id && !map.has(initiative._id)) {
      map.set(initiative._id, initiative);
    }
  });
  const initiatives = Array.from(map.values());

  const data = initiatives.map((initiative) => {
    const utrsDataByInitiative = utrsData.map((data) => {
      return {
        ...data,
        utrvs: data.utrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
      };
    });

    return [
      `${calculationName} (${initiative.name})`,
      tryCalculation({
        formula: calculationValue.formula ?? '',
        variables: getVariablesWithValues({
          utrsData: utrsDataByInitiative,
          variables,
          utrvs: latestActualUtrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
          fallback: '',
        }),
        fallback: 0,
      }),
    ];
  });

  const chartData = [['Key', 'Value'], ...data];

  return { chartData, effectiveDate, period };
};

const getVariablesWithValuesPerInitiative = ({
  utrvs,
  initiatives,
  variables,
  utrsData,
  fallback,
}: {
  utrvs: UniversalTrackerModalServiceUtrv[];
  variables: UtrVariables;
  utrsData: HistoricalUtrs[];
  fallback?: string | number;
  initiatives: {
    _id: string;
    name: string;
  }[];
}) => {
  return initiatives.map((initiative) => {
    const utrsDataByInitiative = utrsData.map((data) => {
      return {
        ...data,
        utrvs: data.utrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
      };
    });
    return getVariablesWithValues({
      utrsData: utrsDataByInitiative,
      variables,
      utrvs: utrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
      fallback,
    });
  });
};

export const getLineChartData = async ({ utrsData, variables, calculation }: DataProps<HistoricalUtrs>) => {
  const utrvsGroupByDate = getActualUtrvsGroupByDate(utrsData) as { [key: string]: HistoricalUtrs['utrvs'] };
  const [calculationValue] = calculation.values;
  const calculationName = calculationValue.role || calculationValue.name;
  const map = new Map<string, { _id: string; name: string }>();
  Object.values(utrvsGroupByDate)
    .flat()
    .forEach(({ initiative }) => {
      if (initiative?._id && !map.has(initiative._id)) {
        map.set(initiative._id, initiative);
      }
    });
  const initiatives = Array.from(map.values());
  const fallback = 0;

  let chartData: (string | number)[][] = [];
  switch (calculation.type) {
    case CalculationType.Sum:
    case CalculationType.Percentage:
    case CalculationType.Stages:
    default: {
      return { chartData: [] };
    }
    case CalculationType.Formula:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const variablesWithValuesPerInitiative = getVariablesWithValuesPerInitiative({
          initiatives,
          utrsData,
          utrvs: utrvsGroupByDate[date],
          variables,
          fallback: '',
        });
        return [
          date,
          ...variablesWithValuesPerInitiative.map((variables) => {
            return tryCalculation({
              formula: calculationValue.formula || '',
              variables,
              fallback,
              infinityFallback: null,
            });
          }),
        ];
      });
      chartData.unshift(['Time', ...initiatives.map((initiative) => `${calculationName} (${initiative.name})`)]);
      return { chartData };
  }
};
export const getBarChartData = async ({ utrsData, variables, calculation }: DataProps<HistoricalUtrs>) => {
  const { headers = [] } = calculation;

  const chartDataHeaders = headers.map((header) => {
    if ('name' in header) {
      return header.name;
    }

    if ('role' in header) {
      return { role: header.role };
    }

    return '';
  });

  if (calculation.type === CalculationType.Stages) {
    return { chartData: [] };
  }

  const [calculationValue] = calculation.values;
  const calculationName = calculationValue.role || calculationValue.name;

  const {
    latestActualUtrvs = [],
    effectiveDate,
    period,
  } = getLatestActualUtrvs(utrsData) as {
    latestActualUtrvs: HistoricalUtrs['utrvs'];
    effectiveDate: string;
    period: DataPeriods | undefined;
  };

  const map = new Map<string, { _id: string; name: string }>();
  Object.values(latestActualUtrvs).forEach(({ initiative }) => {
    if (initiative?._id && !map.has(initiative._id)) {
      map.set(initiative._id, initiative);
    }
  });

  const data = Array.from(map.values()).map((initiative, index) => {
    const utrsDataByInitiative = utrsData.map((data) => {
      return {
        ...data,
        utrvs: data.utrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
      };
    });

    const variablesWithValues = getVariablesWithValues({
      utrsData: utrsDataByInitiative,
      variables,
      utrvs: latestActualUtrvs.filter((utrv) => utrv.initiative?._id === initiative._id),
      fallback: '',
    });

    const variablesWithFormattedValues = Object.entries(variablesWithValues).reduce<FormulaVariables>(
      (acc, [key, value]) => {
        acc[key] = isNumericString(value) ? getDecimalAsNumber(value, CHART_DECIMAL_PLACES) : value;
        return acc;
      },
      {}
    );

    const name = `${calculationName} (${initiative.name})`;
    const tooltipFormula = `${name} | ${calculationValue.formula}`;

    return [
      name,
      tryCalculation({ formula: calculationValue.formula ?? '', variables: variablesWithValues, fallback: 0 }),
      createExpression(tooltipFormula, variablesWithFormattedValues, 0),
      CHART_COLORS[index] ?? '',
    ];
  });

  return { chartData: [chartDataHeaders, ...data], effectiveDate, period,  };
};
