import { BlueprintContributions } from '@g17eco/types/survey';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { DefaultBlueprintCode } from '@components/survey-configuration/types';

const TAGS = ['blueprints'];

export const blueprintsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBlueprints: builder.query<BlueprintContributions, string | undefined>({
        transformResponse,
        query: (sourceName = DefaultBlueprintCode) => ({
          url: `/blueprints/${sourceName}`,
          method: 'get',
        }),
        providesTags: TAGS,
      }),
    }),
  });

export const { useGetBlueprintsQuery } = blueprintsApi;
