import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetTemplateByIdQuery } from '../../api/survey-templates';
import { UserMin } from '../../constants/users';
import { NonStaticGroupConfig } from '../../model/surveyData';
import { useAppSelector } from '../../reducers';
import { MetricGroup } from '../../types/metricGroup';
import { BlueprintContributions, SurveySettings } from '../../types/survey';
import { Breadcrumb, ScopeQuestion, ScopeQuestionOptionalValue, UNSDGMap } from '../../types/surveyScope';
import { LG_BREAKPOINT } from '../../utils';
import queryString from 'query-string';
import Loader from '../loader';
import {
  createQueryFilters,
  HandleChangeSettingsType,
  InitialFilters,
  initialFilters,
} from '../survey-container/SurveyContainer';
import { ViewValues } from '../survey-overview-sidebar/viewOptions';
import { CardGridViewMode } from '../survey-scope/CardGrid';
import { BreadcrumbsRouteParams } from '../survey-settings-toolbar/breadcrumbPath';
import { createFilterUrl, useBreadcrumbs } from '../survey-settings-toolbar/useBreadcrumbs';
import { TemplateTableStats } from './types';
import { isSurveyIdValid } from '../../utils/surveyData';
import { useScopeFilters } from '../survey/utils/useScopeFilters';
import { generateUrl } from '../../routes/util';
import { ROUTES } from '../../constants/routes';
import { getCurrentUser, isUserManagerByInitiativeId } from '../../selectors/user';
import { MaterialityObject } from '../survey/utils/filters';
import { useDispatch, useSelector } from 'react-redux';
import { loadUNSDGMap } from '../../actions/common';
import { getQuestionListMap } from '../../selectors/survey';
import { getAsScopeQuestionNoValue, loadTemplateBlueprintQuestions } from '../../slice/blueprintQuestions';
import { loadCustomMetricGroupsByInitiativeId } from '../../actions/initiative';
import { useAppSettings } from '../../hooks/app/useAppSettings';
import { useGetBlueprintsQuery } from '@api/blueprints';
import { DefaultBlueprintCode } from '@components/survey-configuration/types';

interface TemplateContainerProps {
  children: any;
}

interface TemplateBreadcrumbsRouteParams extends Omit<BreadcrumbsRouteParams, 'surveyId'> {
  templateId: string;
  historyId?: string
}

const isMobile = window.innerWidth <= LG_BREAKPOINT;

const initialSidebar: SurveySettings = {
  groupBy: ['sdg'],
  viewLayout: isMobile ? CardGridViewMode.list : CardGridViewMode.gallery,
  ...initialFilters,
};

const queryOption: {
  [key: string]: 'bracket';
} = { arrayFormat: 'bracket' };

const filterKeys = Object.keys(initialFilters) as (keyof InitialFilters)[];

const setInitialFilters = (searchParams: string) => {
  const query = queryString.parse(searchParams, queryOption);
  // Only allow valid keys
  Object.keys(query).forEach((k) => {
    if (!filterKeys.includes(k as keyof InitialFilters)) {
      delete query[k];
    }
  });
  return query;
};

export interface TemplateContextUnloadedProps {
  isLoaded: boolean;
  disabled: boolean;
  isReadOnly: boolean;
  templateData: TemplateTableStats | object;
  sidebarSettings: SurveySettings;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[], groupBy?: string) => void;
  questions: (ScopeQuestion | ScopeQuestionOptionalValue)[];
  UNSDGMap?: UNSDGMap;
  blueprint?: BlueprintContributions;
  metricGroups: MetricGroup[];
  users: UserMin[];
  isUserManager: boolean;
  materiality?: MaterialityObject;
  handleChangeSettings: HandleChangeSettingsType;
}

export interface TemplateContextLoadedProps extends TemplateContextUnloadedProps {
  isLoaded: true;
  templateData: TemplateTableStats;
  UNSDGMap: UNSDGMap;
  blueprint: BlueprintContributions;
  selectedView: ViewValues;
  setSelectedView: (view: ViewValues) => void;
}

type TemplateContextProps = TemplateContextUnloadedProps | TemplateContextLoadedProps;

const initialState: TemplateContextUnloadedProps = {
  isLoaded: false,
  disabled: false,
  isReadOnly: false,
  templateData: {},
  sidebarSettings: initialSidebar,
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  questions: [],
  metricGroups: [],
  users: [],
  isUserManager: false,
  handleChangeSettings: () => {},
};

export const TemplateContext = React.createContext<TemplateContextProps>(initialState);

export const TemplateContainer = (props: TemplateContainerProps) => {
  const params = useParams<TemplateBreadcrumbsRouteParams>();
  const {
    initiativeId = '',
    templateId = '',
    page = '',
    historyId = '',
  } = params;

  const { canViewAllPacks } = useAppSettings();
  const [selectedView, setSelectedView] = useState<ViewValues>(ViewValues.StandardsAndFrameworks);
  const metricGroupsState = useAppSelector((state) => state.customMetricGroups);
  const originalQuestionList = useSelector(getQuestionListMap);
  const baseScopeQuestion = useSelector(getAsScopeQuestionNoValue);
  const initiativeState = useAppSelector((state) => state.initiative);
  const UNSDGMapState = useAppSelector((state) => state.UNSDGMap);
  const { loaded: blueprintQuestionsLoaded } = useAppSelector((state) => state.blueprintQuestions);
  const isUserManager = useAppSelector((state) => {
    const currentUser = getCurrentUser(state);
    return !!currentUser && isUserManagerByInitiativeId(state, initiativeId);
  });

  const { data: blueprint = {}, isFetching: isFetchingBlueprint } = useGetBlueprintsQuery(DefaultBlueprintCode);

  const dispatch = useDispatch();
  const history = useHistory();

  const getView = (view: string) => {
    if (!view) {
      return canViewAllPacks ? ViewValues.AllPacks : ViewValues.QuestionPacks;
    }
    if (view === ViewValues.AllPacks && !canViewAllPacks) {
      return ViewValues.QuestionPacks;
    }
    return view;
  };

  const view = getView(params.view);
  const combinedCustomGroups: NonStaticGroupConfig[] = [];
  const templateState = useGetTemplateByIdQuery(templateId, { skip: !templateId });
  const templateData = templateState.data?._id ? templateState.data : undefined;

  const [sidebarSettings, setSettings] = useState(() => ({
    ...initialSidebar,
    groupBy: [view],
    ...setInitialFilters(history.location.search),
  }));

  const isValidId = isSurveyIdValid(templateId);

  const breadcrumbs = useBreadcrumbs({ ...params, surveyId: templateId }, [], combinedCustomGroups);
  const setBreadcrumbs = ([first, second, leaf, leafChild]: Breadcrumb[], groupBy?: string) => {
    const route = historyId ? ROUTES.SURVEY_TEMPLATES_HISTORY : ROUTES.SURVEY_TEMPLATES_VIEW;
    const baseRoute = generateUrl(route, { initiativeId, templateId, historyId, page });
    const path = createFilterUrl({
      baseRoute,
      surveyId: templateId,
      initiativeId: templateData?.initiativeId ?? initiativeId,
      page,
      view: groupBy ? groupBy : view,
      cardCategory: first?.cardCategory,
      cardGroup: first?.cardGroup,
      cardSubCategory: second?.cardCategory,
      cardSubGroup: second?.cardGroup,
      leafCategory: leaf?.cardCategory,
      leafGroup: leaf?.cardGroup,
      leafChildCategory: leafChild?.cardCategory,
      leafChildGroup: leafChild?.cardGroup,
    });
    history.push({ pathname: path, search: history.location.search });
  };

  const materiality = initiativeState.data?.materiality ?? {};
  const questions = useScopeFilters({
    page,
    view: view as ViewValues,
    breadcrumbs,
    blueprint,
    metricGroups: metricGroupsState.data,
    materiality,
    originalQuestionList,
    baseScopeQuestion,
  });

  useEffect(() => {
    dispatch(loadUNSDGMap());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadTemplateBlueprintQuestions(templateId));
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch(loadCustomMetricGroupsByInitiativeId(initiativeId));
  }, [dispatch, initiativeId]);

  if (
    templateState.isLoading ||
    !blueprintQuestionsLoaded ||
    !templateData ||
    !initiativeState.loaded ||
    !UNSDGMapState.loaded ||
    !metricGroupsState.loaded ||
    isFetchingBlueprint
  ) {
    return <Loader />;
  }

  const handleChangeSettings = (key: keyof SurveySettings, setting: any, route?: string) => {
    const update: SurveySettings = { ...sidebarSettings, [key]: setting };
    setSettings(update);
    if (key === 'groupBy') {
      const baseRoute = route ?? generateUrl(ROUTES.SURVEY_TEMPLATES_VIEW, { initiativeId, templateId, page });
      history.push({
        pathname: createFilterUrl({
          baseRoute,
          surveyId: templateId,
          initiativeId: params.initiativeId,
          page,
          view: setting[0],
        }),
        search: history.location.search,
      });
    } else if (filterKeys.includes(key as keyof InitialFilters)) {
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify(createQueryFilters(filterKeys, update), queryOption),
      });
    }
  };

  const UNSDGMap = UNSDGMapState.data;
  const metricGroups = metricGroupsState.data;

  if (!isValidId) {
    history.push(generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId }));
  }

  const contextValue: TemplateContextLoadedProps = {
    isLoaded: true,
    disabled: false,
    isReadOnly: false,
    templateData,
    sidebarSettings,
    breadcrumbs,
    metricGroups,
    setBreadcrumbs,
    questions,
    UNSDGMap,
    blueprint,
    users: [],
    isUserManager,
    materiality,
    handleChangeSettings,
    selectedView,
    setSelectedView,
  };

  return <TemplateContext.Provider value={contextValue}>{props.children}</TemplateContext.Provider>;
};
