import { AggregationMode, DataPeriods } from '@g17eco/types/universalTracker';
import { Scope } from '@models/surveyData';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { ApiResponse } from '@g17eco/types/api';
import { SurveyInitiative } from '@g17eco/types/survey';

const TAGS = ['aggregated-surveys'];

interface UpdateAllParams {
  initiativeId: string;
}

interface AggregatedSurveyConfig {
  name: string;
  surveyIds: string[];
  period?: DataPeriods;
  scope?: Scope;
  aggregationMode?: AggregationMode;
}

interface CreateAggregatedSurveyParams extends AggregatedSurveyConfig {
  initiativeId: string;
}

const getUrl = ({ initiativeId }: { initiativeId: string; dashboardId?: string }) =>
  `/initiatives/${initiativeId}/aggregated-survey`;

export const aggregatedSurveysApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateAllAggregatedSurveys: builder.mutation<any, UpdateAllParams>({
        invalidatesTags: TAGS,
        transformResponse,
        query: ({ initiativeId }) => ({
          url: `${getUrl({ initiativeId })}/update-all`,
          method: 'post',
          data: { initiativeId },
        }),
      }),
      updateAggregatedSurvey: builder.mutation<ApiResponse, CreateAggregatedSurveyParams & { surveyId: string }>({
        transformResponse,
        query: ({ initiativeId, surveyId, ...data }) => ({
          url: `${getUrl({ initiativeId })}/${surveyId}/update-aggregate-survey-config`,
          method: 'patch',
          data,
        }),
      }),
      createAggregatedSurvey: builder.mutation<SurveyInitiative, CreateAggregatedSurveyParams>({
        transformResponse,
        query: ({ initiativeId, ...data }) => ({
          url: `${getUrl({ initiativeId })}/aggregate`,
          method: 'post',
          data,
        }),
      }),
    }),
  });

export const {
  useUpdateAllAggregatedSurveysMutation,
  useCreateAggregatedSurveyMutation,
  useUpdateAggregatedSurveyMutation,
} = aggregatedSurveysApi;
