import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { useGetDefaultSurveyConfigQuery } from '../../api/default-survey-config';
import { useCreateTemplateMutation, useGetTemplateByIdQuery, useUpdateTemplateMutation } from '../../api/survey-templates';
import { ROUTES } from '../../constants/routes';
import { blueprintDefaultUnitConfig, UnitConfig } from '../../model/surveyData';
import { generateUrl } from '../../routes/util';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { getAnalytics } from '../../services/analytics/AnalyticsService';
import Dashboard, { DashboardSection } from '../dashboard';
import { updateFn } from '../form/common';
import { NavCode, TemplateMessage } from './constants';
import { useGoBack } from './hooks/useGoBack';
import { TemplateDashboardHeader } from './partials/TemplateDashboardHeader';
import { TemplateNav } from './partials/TemplateNav';
import { TemplateSettings } from './partials/TemplateSettings';
import { TemplateSettingsHeader } from './partials/TemplateSettingsHeader';
import { TemplateConfigFormData } from './types';
import { SurveyType } from '../../types/survey';
import { isSame } from '../../utils/date';
import {
  compareScheduledDates,
  isValidDeadlineScheduledDates,
  toFormScheduledDates
} from '../survey-configuration/utils';
import { BasicAlert } from '@g17eco/molecules/alert';
import { LoadingPlaceholder } from '@g17eco/molecules/loader-container';

const analytics = getAnalytics();

const initialState: TemplateConfigFormData = {
  type: SurveyType.Default,
  verificationRequired: true,
  evidenceRequired: true,
  noteRequired: false,
  isPrivate: false,
  initiativeId: '',
  name: '',
  unitConfig: undefined,
  useInitiativeSettings: true,
};

export const TemplateConfiguration = () => {
  const { initiativeId = '', templateId = '' } = useParams<{ initiativeId: string; templateId: string }>();
  const [state, setState] = useState({
    isLoading: false,
    message: '',
    error: false,
  });
  const history = useHistory();
  const [form, setForm] = useState<TemplateConfigFormData>({ ...initialState, initiativeId });
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const { data: currentTemplate, isLoading } = useGetTemplateByIdQuery(templateId, { skip: !templateId });
  const updateForm: updateFn = (update) => {
    if (update.code === 'deadlineDate' && !update.value) {
      setForm({ ...form, deadlineDate: undefined, scheduledDates: [] });
      return;
    }
    setForm({ ...form, [update.code]: update.value });
  }
  const setError = (e: Error) => setState({ ...state, error: true, isLoading: false, message: e.message });
  const isCreating = !templateId;
  const goBack = useGoBack(initiativeId);

  const { data: defaultSurveyConfig, isLoading: isLoadingSurveyConfig } = useGetDefaultSurveyConfigQuery({
    initiativeId,
  });

  useEffect(() => {
    if (!isCreating || isLoadingSurveyConfig || !defaultSurveyConfig) {
      return;
    }

    const { unitConfig, verificationRequired, isPrivate, evidenceRequired, noteRequired, noteInstructions } = defaultSurveyConfig;
    setForm((prev) => ({
      ...prev,
      verificationRequired,
      isPrivate,
      evidenceRequired,
      noteRequired,
      unitConfig,
      noteInstructions,
      noteInstructionsEditorState: defaultSurveyConfig.noteInstructionsEditorState,
    }));
  }, [isCreating, isLoadingSurveyConfig, defaultSurveyConfig]);

  const hasChanged = useMemo(() => {
    if (currentTemplate?.name !== form.name) {
      return true;
    }
    if (currentTemplate?.evidenceRequired !== form.evidenceRequired) {
      return true;
    }
    if (currentTemplate?.noteRequired !== form.noteRequired) {
      return true;
    }
    if (currentTemplate?.verificationRequired !== form.verificationRequired) {
      return true;
    }
    if (currentTemplate?.isPrivate !== form.isPrivate) {
      return true;
    }
    if (currentTemplate?.noteInstructions !== form.noteInstructions) {
      return true;
    }
    if (currentTemplate?.useInitiativeSettings !== form.useInitiativeSettings) {
      return true;
    }
    if (!isSame(currentTemplate?.deadlineDate, form.deadlineDate)) {
      return true;
    }
    if (!compareScheduledDates(currentTemplate?.scheduledDates, form.scheduledDates)) {
      return true;
    }
    const unitFields = Object.keys(blueprintDefaultUnitConfig) as (keyof UnitConfig)[];
    if (form.unitConfig) {
      const { unitConfig } = form;
      return unitFields.some((unitCode) => unitConfig[unitCode] !== currentTemplate.unitConfig?.[unitCode]);
    }
    return false;
  }, [form, currentTemplate]);

  const isConfirmBtnDisabled = () => {
    if (!form.name) {
      return true;
    }

    if (!isValidDeadlineScheduledDates(form)) {
      return true
    }

    return false;
  };

  const handleCreateSubmit = async () => {
    setState({ ...state, isLoading: true });
    try {
      const { data: createdTemplate } = await createTemplate(form).unwrap();
      if (createdTemplate) {
        await analytics.track(AnalyticsEvents.SurveyTemplateCreated, {
          templateId: createdTemplate._id,
          initiativeId: createdTemplate.initiativeId,
        });
        setState({ ...state, isLoading: false, message: TemplateMessage.Created });
        return history.push({
          pathname: generateUrl(ROUTES.SURVEY_TEMPLATES_VIEW, {
            initiativeId,
            templateId: createdTemplate._id,
            page: NavCode.Scope,
          }),
          search: history.location.search,
        });
      }
    } catch (e) {
      setError(e);
    }
  };

  const handleUpdateSubmit = async () => {
    setState({ ...state, isLoading: true });
    try {
      const { data: updatedTemplate } = await updateTemplate(form).unwrap();
      if (updatedTemplate) {
        await analytics.track(AnalyticsEvents.SurveyTemplateUpdated, {
          templateId: updatedTemplate._id,
          initiativeId: updatedTemplate.initiativeId,
        });
        setState({ ...state, isLoading: false, message: TemplateMessage.Updated });
      }
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    if (currentTemplate) {
      return setForm((prev) => ({
        ...prev,
        useInitiativeSettings: currentTemplate.useInitiativeSettings,
        ...currentTemplate,
        scheduledDates: toFormScheduledDates(currentTemplate.scheduledDates),
      }));
    }
  }, [currentTemplate]);

  return (
    <Dashboard>
      <TemplateSettingsHeader />
      <DashboardSection header={<TemplateDashboardHeader />}>
        <TemplateNav />
        <BasicAlert type={state.error ? 'danger' : 'success'}>{state.message}</BasicAlert>
        <LoadingPlaceholder className='mt-4' count={1} height={800} isLoading={isLoading || (!!templateId && !form._id)}>
          <Form className='survey-config-form'>
            <TemplateSettings templateId={templateId || 'new'} updateForm={updateForm} form={form} isCreating={isCreating} />
            <div className='text-right'>
              <Button color='link' onClick={goBack} className='mr-3'>
                Cancel
              </Button>
              <Button
                color='primary'
                className='submit-button'
                disabled={isConfirmBtnDisabled() || !hasChanged}
                onClick={isCreating ? handleCreateSubmit : handleUpdateSubmit}
                data-testid='survey-template-submit-btn'
              >
                {isCreating ? 'Create template' : 'Update template'}
              </Button>
            </div>
          </Form>
        </LoadingPlaceholder>
      </DashboardSection>
    </Dashboard>
  );
};
