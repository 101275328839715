import { PPTXReportItem, PPTXReportItemParams } from '@api/pptx-reports';
import IconButton from '@components/button/IconButton';
import { BasicAlert } from '@g17eco/molecules/alert';
import { ColumnDef, Table } from '@g17eco/molecules/table';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { PPTXTemplateSchemeLabelMap } from '@g17eco/types/download';
import { DATE, formatDateUTC } from '@utils/date';
import { naturalSort } from '@utils/index';

interface Props {
  data: PPTXReportItem[];
  handleDownload: (props: PPTXReportItemParams) => void;
  handleDelete?: (props: PPTXReportItemParams) => void;
}

export const PPTXReportsTable = (props: Props) => {
  const { data, handleDownload, handleDelete } = props;

  if (data.length === 0) {
    return <BasicAlert type='info'>There are no reports.</BasicAlert>;
  }

  const columns: ColumnDef<PPTXReportItem>[] = [
    {
      header: 'Report',
      cell: ({ row }) => {
        const { exportedFilePath, status, templateScheme } = row.original;
        if (!exportedFilePath) {
          return `Sustainability Report - ${templateScheme} (${status})`;
        }
        const fileName = exportedFilePath.split('/').at(-1);
        return <div className='text-truncate'>{fileName}</div>;
      },
      meta: {
        cellProps: {
          style: {
            width: 350,
            maxWidth: 350,
          },
        },
      },
    },
    {
      header: 'Theme',
      meta: {
        headerProps: {
          style: {
            width: 100,
            minWidth: 100,
          },
        },
      },
      accessorFn: (row) => PPTXTemplateSchemeLabelMap[row.templateScheme] ?? '',
    },
    {
      header: 'Created date',
      meta: {
        headerProps: {
          style: {
            width: 118,
            minWidth: 118,
          },
        },
      },
      accessorFn: (row) => formatDateUTC(row.created),
      sortingFn: (a, b) => naturalSort(a.original.created, b.original.created)
    },
    {
      header: 'Time',
      meta: {
        headerProps: {
          style: {
            width: 76,
            minWidth: 76,
          },
        },
      },
      accessorFn: (row) => formatDateUTC(row.created, DATE.TIME_ONLY),
    },
    {
      header: 'Created by',
      meta: {
        cellProps: {
          style: {
            width: 210,
            maxWidth: 210,
          },
        },
      },
      cell: ({ row }) => {
        const { firstName, surname } = row.original.user;
        return (
          <div className='text-truncate'>
            {firstName} {surname}
          </div>
        );
      },
    },
    {
      header: 'Actions',
      enableSorting: false,
      meta: {
        headerProps: {
          style: {
            width: 70,
            minWidth: 70,
          },
        },
      },
      cell: ({ row }) => {
        const { _id: jobId, taskId, surveyId, exportedFilePath } = row.original;
        const isDisabled = !handleDelete;
        return (
          <div>
            <SimpleTooltip text={isDisabled ? 'Only Admins can delete reports' : ''}>
              <IconButton
                outline={false}
                color='transparent'
                className={isDisabled ? 'text-ThemeTextLight' : 'text-ThemeDangerMedium'}
                icon='fal fa-trash'
                disabled={isDisabled}
                onClick={() => handleDelete?.({ jobId, taskId, surveyId })}
              />
            </SimpleTooltip>
            <IconButton
              outline={false}
              color='transparent'
              className={exportedFilePath ? 'text-ThemeAccentDark' : 'text-ThemeTextLight'}
              icon='fal fa-cloud-arrow-down'
              disabled={!exportedFilePath}
              onClick={() => handleDownload({ jobId, taskId, surveyId })}
            />
          </div>
        );
      },
    },
  ];
  return <Table columns={columns} data={data} />;
};
