import { ChartSubType, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { UnsupportedWidget } from '@routes/custom-dashboard/items/charts/common/UnsupportedWidget';
import { HistoricalUtrs } from '@api/insights';
import { Button } from 'reactstrap';
import { getBarChartData, getColumnChartData, getLineChartData, getPieChartData } from './data-utils';
import { ColumnChart } from '@routes/custom-dashboard/items/charts/ColumnChart';
import { LineChart } from '@routes/custom-dashboard/items/charts/LineChart';
import { BarChart } from '@routes/custom-dashboard/items/charts/BarChart';
import { PieChart } from '@routes/custom-dashboard/items/charts/PieChart';


interface DisaggregatedChartWidgetProps {
  utrsData: HistoricalUtrs[];
  item: Pick<GridDashboardChartItem, 'subType' | 'variables' | 'calculation' | 'unitText' | 'note' | '_id'> & {
    type: string;
  };
  overrideOptions?: {
    pie?: ReactGoogleChartProps['options'];
    line?: ReactGoogleChartProps['options'];
    column?: ReactGoogleChartProps['options'];
    stacked?: ReactGoogleChartProps['options'];
    bar?: ReactGoogleChartProps['options'];
  };
  onEditItem?: (id: string) => void;
}

export const DisaggregatedChartWidget = (props: DisaggregatedChartWidgetProps) => {
  const { utrsData, item, overrideOptions = {}, onEditItem } = props;
  const { subType = ChartSubType.Line, variables, calculation, unitText } = item;

  const actionButton = onEditItem ? (
    <Button color='link' onClick={() => onEditItem(item._id)}>
      Change chart type
    </Button>
  ) : null;

  if (utrsData.length > 1) {
    // Not support yet
    return (
      <UnsupportedWidget
        item={item}
        actionButton={actionButton}
        unsupportedMessage='Disaggregated chart cannot display multiple utrs'
      />
    );
  }

  switch (subType) {
    case ChartSubType.Column:
      return (
        <ColumnChart
          getChartData={getColumnChartData}
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          unitText={unitText}
          overrideOptions={overrideOptions.column}
        />
      );
    case ChartSubType.FullPie:
    case ChartSubType.Pie:
    case ChartSubType.PieWithLegend:
      return (
        <PieChart
          getChartData={getPieChartData}
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          subType={subType}
          overrideOptions={overrideOptions.pie}
        />
      );
    case ChartSubType.Line:
      return (
        <LineChart
          getChartData={getLineChartData}
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          unitText={unitText}
          overrideOptions={overrideOptions.line}
        />
      );
    case ChartSubType.Bar:
      return (
        <BarChart
          getChartData={getBarChartData}
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          overrideOptions={overrideOptions.bar}
        />
      );
    case ChartSubType.SingleValue:
    case ChartSubType.SparkLine:
    case ChartSubType.Stacked:
    case ChartSubType.Table:
      return (
        <UnsupportedWidget
          item={item}
          actionButton={actionButton}
          unsupportedMessage='Chart type cannot display multiple subsidiaries'
        />
      );
    default:
      return null;
  }
};
