import { Layout } from 'react-grid-layout';
import {
  ChartSubType,
  GridDashboardItem,
  GridDashboardItemCreate,
  InsightDashboardItemType,
  GridDashboardItemBase,
  GridDashboardChartItemBase,
  GridDashboardSDGChartItemBase,
  GridDashboardTextItemBase,
  GridDashboardTableItemBase,
  GridDashboardChartItem,
  GridDashboardSDGChartItem,
  GridDashboardTextItem,
  GridDashboardTableItem,
  DashboardGridSize,
  InsightDashboardItemChartType,
  InsightDashboardTempItemType,
  GridDashboardTempItem,
  GridDashboardTempChartItem,
  Calculation,
  GridDashboardMediaItem,
  GridDashboardMediaItemBase,
  GridDashboardSDGContributionChartItem,
  GridDashboardSpaceItem,
  TextSubType,
} from '@g17eco/types/insight-custom-dashboard';
import {
  COLOUR1,
  COLOUR2,
  COLOUR3,
  COLOUR4,
  COLOUR5,
  SDG_CHART_COLORS,
  UtrVariable,
  UtrVariables,
} from '../summary/insights/utils/constants';
import { ExtendedUtrVariable } from './types';
import { MEDIA_TITLE_HEIGHT } from '@features/custom-dashboard';
import { generateObjectId } from '@utils/object-id';

export const DISABLED_TARGET_BASELINE_ADDING_TOOLTIP =
  'Targets and baselines cannot be set whilst Custom Dashboard is in Edit mode. Please save to set targets and baselines';

// Number of columns within the grid layout
export const COLUMNS = 12;
// Static height of eact row
export const ROW_HEIGHT = 10;
// Width of each column
export const COL_WIDTH = 72;
// Gap between columns or rows, used as margin in the grid layout
export const ITEM_GAP = 10;
export const MARGIN: [number, number] = [ITEM_GAP, ITEM_GAP];
/*
  Total width of gaps between each column: (COLUMNS - 1) * ITEM_GAP if containerPadding is 0
  Otherwise: (COLUMNS - 1) * ITEM_GAP + (containerPadding * 2)
*/
export const GRID_WIDTH = COLUMNS * COL_WIDTH + (COLUMNS - 1) * ITEM_GAP;

const DEFAULT_HEIGHT = 12;
const SPACING = 1;

export const CHART_COLORS = [COLOUR1, COLOUR2, COLOUR3, COLOUR4, COLOUR5, ...SDG_CHART_COLORS];

export const CHART_DECIMAL_PLACES = 2;

export const getLayoutByItems = (items: GridDashboardItem[]): Layout[] =>
  items.map((item) => {
    if (isTableType(item) || isSDGContributionType(item)) {
      return { ...item.gridSize, i: item._id, isResizable: false };
    }
    return { ...item.gridSize, i: item._id };
  });

type Item = GridDashboardItem | GridDashboardItemBase | GridDashboardTempItem;

export const isChartType = (item: Item): item is GridDashboardChartItem | GridDashboardChartItemBase => {
  return item.type === InsightDashboardItemType.Chart;
};

export const isSDGTrackerType = (item: Item): item is GridDashboardSDGChartItem | GridDashboardSDGChartItemBase => {
  return item.type === InsightDashboardItemType.SDGTracker;
};

export const isTempChartType = (item: Item): item is GridDashboardTempChartItem => {
  return item.type === InsightDashboardTempItemType.TempChart;
};

export const isTextOrHeadlineType = (item: Item): item is GridDashboardTextItem | GridDashboardTextItemBase => {
  return item.type === InsightDashboardItemType.Headline || item.type === InsightDashboardItemType.Text;
};

export const isTableType = (item: Item): item is GridDashboardTableItem | GridDashboardTableItemBase => {
  return item.type === InsightDashboardItemType.Table;
};

export const isMediaType = (item: Item): item is GridDashboardMediaItem | GridDashboardMediaItemBase => {
  return item.type === InsightDashboardItemType.Media;
};

export const isSDGContributionType = (item: GridDashboardItem): item is GridDashboardSDGContributionChartItem => {
  return item.type === InsightDashboardItemType.SDGContributionChart;
};

export const isSpaceType = (item: Item): item is GridDashboardSpaceItem => {
  return item.type === InsightDashboardItemType.Space;
};

export const isDisaggregatedRendering = ({ initiativeIds }: { initiativeIds?: string[] }) =>
  !!initiativeIds && initiativeIds.length > 1;

export const hasSDGContributionItem = (items: GridDashboardItem[]) => {
  return items.some((item) => item.type === InsightDashboardItemType.SDGContributionChart);
};

export const isDashboardItem = (
  item: GridDashboardItem | GridDashboardItemCreate | GridDashboardItemBase | GridDashboardTempItem
): item is GridDashboardItem => {
  return '_id' in item && !!item._id;
};

const TEXT_LIMIT_PER_ROW = {
  [InsightDashboardItemType.Headline]: 60,
  [InsightDashboardItemType.Text]: 130,
};

const PADDING_HEIGHT = {
  [InsightDashboardItemType.Headline]: 0,
  [InsightDashboardItemType.Text]: 32,
};

const LINE_HEIGHT = {
  [InsightDashboardItemType.Headline]: 33.6,
  [InsightDashboardItemType.Text]: 19.5,
};

// values are taken from css styles (pixels)
const TABLE_HEADER_HEIGHT = 50;
const TABLE_ROW_HEIGHT = 60;

export const calculateItemHeightFromPixelHeight = (pixelHeight: number) => {
  // Equation: rowsCount * ROW_HEIGHT + (rowsCount - 1) * ITEM_GAP = pixelHeight
  return Math.ceil((pixelHeight + ITEM_GAP) / (ROW_HEIGHT + ITEM_GAP));
};

export const calculateItemHeight = (item: GridDashboardItemCreate, width = COLUMNS) => {
  const { type } = item;

  switch (type) {
    case InsightDashboardItemType.Headline:
    case InsightDashboardItemType.Text: {
      // height of Free text is calculated inside TextEditor already.
      if (item.subType === TextSubType.Free && item.gridSize?.h) {
        return { h: item.gridSize.h };
      }

      const { text = '' } = item;

      const textLimitPerRow = (TEXT_LIMIT_PER_ROW[type] * width) / COLUMNS;
      const textLines = Math.ceil(text.length / textLimitPerRow);

      // Equation: rowsCount * ROW_HEIGHT + (rowsCount - 1) * ITEM_GAP = textLines * LINE_HEIGHT + PADDING_HEIGHT
      const textGridRowsCount = Math.ceil(
        (textLines * LINE_HEIGHT[type] + ITEM_GAP + PADDING_HEIGHT[type]) / (ROW_HEIGHT + ITEM_GAP)
      );
      return { h: textGridRowsCount };
    }
    case InsightDashboardItemType.Table: {
      const tableRowsCount = item.calculation?.values.length ?? 0;

      // Equation: rowsCount * ROW_HEIGHT + (rowsCount - 1) * ITEM_GAP = tableRowsCount * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT
      const tableGridRowsCount = Math.ceil(
        (tableRowsCount * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT + ITEM_GAP) / (ROW_HEIGHT + ITEM_GAP)
      );
      return {
        h: tableGridRowsCount,
        minH: tableGridRowsCount,
        maxH: tableGridRowsCount,
      };
    }
    case InsightDashboardItemType.Media: {
      const defaultGridSize = getDefaultGridSize(type);
      const hasTitle = Boolean(item.title);
      const ratio = item.files?.[0]?.ratio ?? 1;

      return {
        h: calculateItemHeightByRatio(width, ratio, hasTitle),
        minH: calculateItemHeightByRatio(defaultGridSize.minW, ratio, hasTitle),
        maxH: calculateItemHeightByRatio(defaultGridSize.maxW ?? 0, ratio, hasTitle),
      };
    }
    case InsightDashboardItemType.SDGContributionChart: {
      const height = DEFAULT_HEIGHT * 2 + SPACING;
      return {
        h: height,
        minH: height,
        maxH: height,
      };
    }
    case InsightDashboardItemType.Chart:
    case InsightDashboardItemType.SDGTracker:
      return {
        h: 17,
        minH: DEFAULT_HEIGHT,
      };
    case InsightDashboardItemType.Space:
      return {
        h: 5,
        minH: 1,
      };
    default:
      return {
        h: DEFAULT_HEIGHT,
        minH: DEFAULT_HEIGHT,
      };
  }
};

const calculateItemHeightByRatio = (width: number, ratio = 1, hasTitle = false) => {
  const realWidth = width * COL_WIDTH + ITEM_GAP * (width - 1);
  const realHeight = realWidth / ratio;

  const titleHeight = hasTitle ? MEDIA_TITLE_HEIGHT / (ROW_HEIGHT + ITEM_GAP) : 0;
  return (realHeight + ITEM_GAP) / (ROW_HEIGHT + ITEM_GAP) + titleHeight;
};

export type QuestionData = Partial<UtrVariable>;

export interface ChartData {
  title?: string;
  type?: InsightDashboardItemChartType;
  subType: ChartSubType;
  metrics: ExtendedUtrVariable[];
}

export const WidgetProps: { [key: string]: { label: string; icon: string; isStaffFeature?: boolean } } = {
  [InsightDashboardItemType.Text]: {
    label: 'text',
    icon: 'fal fa-input-text',
  },
  [InsightDashboardItemType.Table]: {
    label: 'table',
    icon: 'fal fa-table',
  },
  [InsightDashboardTempItemType.TempChart]: {
    label: 'chart',
    icon: 'fal fa-chart-mixed',
  },
  [InsightDashboardItemType.Media]: {
    label: 'media',
    icon: 'fal fa-photo-film',
  },
  [InsightDashboardItemType.Space]: {
    label: 'space',
    icon: 'fal fa-grip-lines',
    isStaffFeature: true,
  },
};

export const getGridSize = (updatingItem: GridDashboardItemCreate, items: GridDashboardItem[]): DashboardGridSize => {
  const defaultGridSize = getDefaultGridSize(updatingItem.type);
  const itemHeights = calculateItemHeight(updatingItem, defaultGridSize.w);

  return { ...defaultGridSize, ...getOrdinate(defaultGridSize.w, items), ...itemHeights };
};

export const getDefaultGridSize = (type: InsightDashboardItemType | 'default') => {
  switch (type) {
    case InsightDashboardItemType.Chart:
    case InsightDashboardItemType.SDGTracker:
      return {
        w: COLUMNS / 2,
        h: 17,
        minW: COLUMNS / 3,
        minH: DEFAULT_HEIGHT,
      };
    case InsightDashboardItemType.Media:
      return {
        w: COLUMNS / 2,
        minW: COLUMNS / 3,
        maxW: COLUMNS,
      };
    case InsightDashboardItemType.Headline:
    case InsightDashboardItemType.Text:
      return {
        w: COLUMNS,
        minW: COLUMNS / 3,
        minH: 3,
      };
    case InsightDashboardItemType.SDGContributionChart:
    case InsightDashboardItemType.Table:
      return {
        w: COLUMNS,
        minW: COLUMNS,
        maxW: COLUMNS,
      };
    case InsightDashboardItemType.Space:
      return {
        w: COLUMNS,
        minW: 2,
        maxW: COLUMNS,
      };
    default:
      return {
        x: 0,
        y: 0,
        w: COLUMNS / 3,
        h: DEFAULT_HEIGHT,
        minW: COLUMNS / 3,
        minH: DEFAULT_HEIGHT,
      };
  }
};

export const getOrdinate = (newItemWidth: number, items: GridDashboardItem[]) => {
  if (!items.length) {
    return { x: 0, y: 0 };
  }

  const finalItem = items.reduce((acc, current) => {
    return acc.y > current.gridSize.y ? acc : current.gridSize;
  }, items[0].gridSize);

  const hasRowEnoughSpace = finalItem.x + finalItem.w + newItemWidth <= COLUMNS;
  const x = hasRowEnoughSpace ? finalItem.x + finalItem.w : 0;
  const y = hasRowEnoughSpace ? finalItem.y : finalItem.y + finalItem.h;

  return { x, y };
};

const MAXIMUM_LENGTH = 22;
const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

export const transformVariables = (variables: UtrVariable[]) => {
  if (!variables) {
    return {};
  }

  if (variables.length > MAXIMUM_LENGTH) {
    return {};
  }
  const keys = ALPHABET.split('');

  return variables.reduce((acc, variable, index) => {
    acc[keys[index]] = {
      code: variable.code,
      valueListCode: variable.valueListCode,
      groupCode: variable.groupCode,
      subGroupCode: variable.subGroupCode,
      integrationCode: variable.integrationCode,
    };
    return acc;
  }, {} as UtrVariables);
};

export const getDisplayDashboardItemType = (type: InsightDashboardItemType) => {
  switch (type) {
    case InsightDashboardItemType.Chart:
      return 'Chart';
    case InsightDashboardItemType.Headline:
      return 'Headline';
    case InsightDashboardItemType.Text:
      return 'Text';
    case InsightDashboardItemType.SDGTracker:
      return 'SDG Tracker';
    case InsightDashboardItemType.Table:
      return 'Table';
    case InsightDashboardItemType.Integration:
      return 'Integration';
    case InsightDashboardItemType.Media:
      return 'Media';
    case InsightDashboardItemType.Space:
      return 'Space';
    default:
      return 'Item';
  }
};

export const hasUtrvHistoryModal = (item: GridDashboardItem) => {
  return isChartType(item) || isSDGTrackerType(item) || isTableType(item);
};

export const isSingleUtrChart = (subType: ChartSubType, calculation: Calculation) => {
  return (
    (subType === ChartSubType.SingleValue || subType === ChartSubType.SparkLine) && calculation.values.length === 1
  );
};

export const getFirstValueListCode = (item: GridDashboardItem) => {
  if (isChartType(item) || isSDGTrackerType(item)) {
    const { variables } = item;
    const valueListCodes = Object.values(variables).map(({ valueListCode }) => valueListCode);
    return valueListCodes[0];
  }
};

export const generateGridDashboardItem = (itemProps: GridDashboardItemCreate, items: GridDashboardItem[]) => {
  const item = {
    _id: generateObjectId(),
    ...itemProps,
    gridSize: getGridSize(itemProps, items),
  } as GridDashboardItem;
  return item;
};

export const defaultExtendedUtrVariable: ExtendedUtrVariable = { code: '' };

export const getFilteredUtrsData = <T extends { utr: { code: string } }>(utrsData: T[], variables: UtrVariables) => {
  const utrsCodes = Object.values(variables).map(({ code }) => code);

  return {
    filteredUtrsData: utrsData.filter((data) => utrsCodes.includes(data.utr.code)),
    variableUtrCodes: utrsCodes,
  };
};
