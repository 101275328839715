import { CreateWorkgroup, useCreateWorkgroupMutation, useUpdateWorkgroupMutation } from '@api/workgroups';
import { generateErrorToast, generateToast } from '@components/toasts';
import { ROUTES } from '@constants/routes';
import { Workgroup } from '@g17eco/types/workgroup';
import { generateUrl } from '@routes/util';
import classNames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Form as FormWrapper, FormGroup, Label, Button } from 'reactstrap';
import { getEditingUrl } from './utils';
import styleVariables from '../../../../css/variables.module.scss';
import Loader from '@components/loader';

type Props = {
  workgroup?: Workgroup;
  initiativeId: string;
};

const ICONS = ['user', 'user-pen', 'user-check', 'user-gear', 'users', 'user-tie'];
const COLORS = [
  styleVariables.IconSecondary,
  styleVariables.IconDark,
  styleVariables.IconPrimary,
  styleVariables.SuccessMedium,
  styleVariables.DangerMedium,
  styleVariables.WarningMedium,
];

const getForm = (workgroup?: Workgroup): CreateWorkgroup => {
  return {
    name: workgroup?.name ?? '',
    description: workgroup?.description,
    icon: workgroup?.icon ?? ICONS[0],
    color: workgroup?.color ?? COLORS[0],
  };
};

const Divider = () => {
  return <div className='border border-ThemeBorderDefault my-2' />;
};

export const Configuration = ({ workgroup, initiativeId }: Props) => {
  const history = useHistory();
  const [form, setForm] = useState<CreateWorkgroup>(() => getForm(workgroup));
  const [isChanged, setIsChanged] = useState(false);

  const [createWorkgroup, { isLoading: isCreating }] = useCreateWorkgroupMutation();
  const [updateWorkgroup, { isLoading: isUpdating }] = useUpdateWorkgroupMutation();

  const onChangeForm = (changes: Partial<CreateWorkgroup>) => {
    setForm((state) => {
      return {
        ...state,
        ...changes,
      };
    });
    if (!isChanged) {
      setIsChanged(true);
    }
  };
  const disabled = !form.name || !isChanged || isCreating || isUpdating;

  const handleSave = () => {
    if (!workgroup?._id) {
      return createWorkgroup({
        initiativeId,
        data: form,
      })
        .unwrap()
        .then((workgroup) => {
          setIsChanged(false);
          generateToast({
            title: 'Workgroup created',
            color: 'success',
            message: 'Workgroup has been created',
          });
          history.push(getEditingUrl(initiativeId, workgroup._id));
        })
        .catch((error) => {
          generateErrorToast(error);
        });
    }

    return updateWorkgroup({
      initiativeId,
      workgroupId: workgroup._id,
      data: form,
    })
      .unwrap()
      .then(() => {
        setIsChanged(false);
        generateToast({
          title: 'Workgroup updated',
          color: 'success',
          message: 'Workgroup has been updated',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      });
  };

  return (
    <>
      {isCreating || isUpdating ? <Loader /> : null}
      <div className='d-flex gap-3 mt-4'>
        <FormWrapper className='flex-grow-1'>
          <FormGroup>
            <Label for='workgroupName'>
              <h6 className='text-ThemeHeadingDark'>Workgroup name*</h6>
            </Label>
            <Input
              id='workgroupName'
              type='text'
              placeholder='Enter workgroup name...'
              value={form.name}
              onChange={(e) => onChangeForm({ name: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for='workgroupDescription'>
              <h6 className='text-ThemeHeadingDark'>Workgroup description</h6>
            </Label>
            <Input
              id='workgroupDescription'
              type='textarea'
              placeholder='Enter description (optional)...'
              value={form.description}
              onChange={(e) => onChangeForm({ description: e.target.value })}
              rows={3}
            />
          </FormGroup>
        </FormWrapper>
        <Divider />
        <div className='d-flex flex-column gap-2 align-items-center'>
          <h6 className='text-ThemeHeadingDark'>Icon</h6>
          {ICONS.map((icon) => (
            <div
              key={icon}
              className={classNames(
                icon === form.icon ? 'workgroup__configuration__icon--active' : '',
                'workgroup__configuration__icon d-flex justify-content-center align-items-center cursor-pointer'
              )}
              onClick={() => onChangeForm({ icon })}
            >
              <i className={`fal fa-${icon}`} style={{ color: form.color }} />
            </div>
          ))}
        </div>
        <Divider />
        <div className='d-flex flex-column gap-2 align-items-center'>
          <h6 className='text-ThemeHeadingDark'>Icon color</h6>
          {COLORS.map((color) => (
            <div
              style={{ backgroundColor: color }}
              key={color}
              className={classNames(
                color === form.color ? 'workgroup__configuration__color--active' : '',
                'workgroup__configuration__color cursor-pointer'
              )}
              onClick={() => onChangeForm({ color })}
            />
          ))}
        </div>
      </div>
      <div className='d-flex justify-content-end mt-5'>
        <Button
          color='link-secondary'
          onClick={() => history.push(generateUrl(ROUTES.MANAGE_USERS, { initiativeId, page: 'workgroups' }))}
          className='mr-3'
        >
          Cancel
        </Button>
        <Button color='primary' disabled={disabled} onClick={handleSave}>
          <i className='fa-light fa-floppy-disk mr-2' />
          Save
        </Button>
      </div>
    </>
  );
};
