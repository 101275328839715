import { UserMin } from '@constants/users';
import { JobStatus } from '@g17eco/types/background-jobs';
import { PPTXReportScheme, PPTXTemplateScheme } from '@g17eco/types/download';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { ApiResponse } from '@g17eco/types/api';

export interface PPTXReportItem {
  _id: string;
  status: JobStatus;
  created: string;
  user: Pick<UserMin, '_id' | 'firstName' | 'surname'>;
  initiativeId: string;
  taskId: string;
  surveyId: string;
  exportedFilePath?: string;
  templateScheme: PPTXTemplateScheme;
}

interface PPTXReportList {
  generatingReport?: PPTXReportItem;
  latestReports: PPTXReportItem[];
  previousReports: PPTXReportItem[];
}

export interface PPTXReportItemParams {
  surveyId: string;
  jobId: string;
  taskId: string;
}

interface GenerateReportParams {
  surveyId: string;
  debug?: string;
  scheme: PPTXReportScheme;
}

const TAGS = ['pptx-reports'];

export const AIReportApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPPTXReports: builder.query<PPTXReportList, string>({
        transformResponse,
        query: (initiativeId) => {
          return {
            url: `/initiatives/${initiativeId}/reports/pptx`,
            method: 'get',
          };
        },
        providesTags: TAGS,
      }),
      generatePPTXReport: builder.mutation<ApiResponse<{ jobId: string; status: JobStatus }>, GenerateReportParams>({
        transformResponse,
        query: ({ surveyId, ...data }) => {
          const { debug, scheme } = data;
          return {
            url: `/surveys/${surveyId}/report/pptx`,
            params: { debug, templateScheme: scheme.template, colorScheme: scheme.color },
            method: 'post',
          };
        },
        invalidatesTags: TAGS,
      }),
      deletePPTXReport: builder.mutation<ApiResponse, PPTXReportItemParams>({
        transformResponse,
        query: ({ surveyId, jobId, taskId }) => {
          return {
            url: `/surveys/${surveyId}/background-report/${jobId}/${taskId}`,
            method: 'delete',
          };
        },
        invalidatesTags: TAGS,
      }),
    }),
  });

export const { useGetPPTXReportsQuery, useDeletePPTXReportMutation, useGeneratePPTXReportMutation } = AIReportApi;
