import { InitiativeCompany } from '../../../types/initiative';
import { ScopePackRowData } from '../../../types/portfolio';
import { getViewAccess } from '../../../utils/dataShare';
import { DATE, formatDate } from '../../../utils/date';

export const hasExchangeData = (company: InitiativeCompany) =>
  Boolean(company.exchangeSurveys && !!company.exchangeSurveys.length);

const percentDisplay = (value: number): string => {
  if (!value) return '0%';
  return `${(value * 100).toFixed(1)}%`;
};

const percentSort = (value: number): string => `${(value * 100).toFixed(1)}%`;

const getCompletedExchangeSurveys = (company: InitiativeCompany) => {
  return {
    display: String((company.exchangeSurveys ?? []).length),
    sort: String((company.exchangeSurveys ?? []).length),
  };
};

const getTotalPrivate = (company: InitiativeCompany): number => {
  return (company.exchangeSurveys ?? []).reduce((a, survey) => a + survey.private, 0);
};

const getTotalNr = (company: InitiativeCompany): number => {
  return (company.exchangeSurveys ?? []).reduce((a, survey) => a + survey.nr, 0);
};

const getTotalNa = (company: InitiativeCompany): number => {
  return (company.exchangeSurveys ?? []).reduce((a, survey) => a + survey.na, 0);
};

const getTotalQuestions = (company: InitiativeCompany): number => {
  return (company.exchangeSurveys ?? []).reduce((a, survey) => a + survey.total, 0);
};

const getAnsweredQuestions = (company: InitiativeCompany): number => {
  return (company.exchangeSurveys ?? []).reduce((a, survey) => a + survey.answered, 0);
};

export const getAnsweredRatio = (company: InitiativeCompany) => {
  const answered = getAnsweredQuestions(company);
  const total = getTotalQuestions(company);
  const str = total ? `${answered || '-'}/${total}` : '-';
  return {
    display: str,
    sort: str,
  };
};

const getPercentData = (company: InitiativeCompany, percent: number) => {
  return {
    display: hasExchangeData(company) ? percentDisplay(percent) : '-',
    sort: hasExchangeData(company) ? percentSort(percent) : '-',
  };
};

const getPercentPrivate = (company: InitiativeCompany) => {
  const percent = hasExchangeData(company) ? Number(getTotalPrivate(company) / getTotalQuestions(company)) : 0;
  return getPercentData(company, percent);
};

const getPercentNr = (company: InitiativeCompany) => {
  const percent = hasExchangeData(company) ? Number(getTotalNr(company) / getTotalQuestions(company)) : 0;
  return getPercentData(company, percent);
};

const getPercentNa = (company: InitiativeCompany) => {
  const percent = hasExchangeData(company) ? Number(getTotalNa(company) / getTotalQuestions(company)) : 0;
  return getPercentData(company, percent);
};

export const getLastCompletedSurvey = (company: InitiativeCompany) => {
  return (company?.exchangeSurveys ?? []).find((survey) => survey.completedDate);
};

export const getOnboardingDate = (company: InitiativeCompany) => {
  return { display: formatDate(company.created, DATE.DEFAULT_SPACES), sort: company.created };
};

export const getCompletedDate = (company: InitiativeCompany) => {
  const latestCompletedSurvey = getLastCompletedSurvey(company);

  if (!latestCompletedSurvey) {
    return { display: '', sort: '' };
  }

  return {
    display: formatDate(latestCompletedSurvey.completedDate, DATE.DEFAULT_SPACES),
    sort: latestCompletedSurvey.completedDate,
  };
};

export const getPackUsagePercentage = (utrv: ScopePackRowData, type: keyof ScopePackRowData) => {
  if (!utrv.totalUtrvs || !utrv[type]) {
    return 0;
  }

  return (100 * (type === 'nr' ? utrv.nr + utrv.na : (utrv[type] as number))) / utrv.totalUtrvs;
};

export const getPackUsagePercentageExportText = (utrv: ScopePackRowData, type: keyof ScopePackRowData) => {
  const value = getPackUsagePercentage(utrv, type);
  return value.toFixed(1).concat('%');
};

export const getAnsweredUtrvs = (utrv: ScopePackRowData) => {
  return utrv.updated + utrv.verified;
};

export enum ColumnType {
  General = 'general',
  SurveyPack = 'surveyPack',
  Custom = 'custom',
}

export enum GeneralColumns {
  CompletedReports = 'completedReports',
  Private = 'private',
  NA = 'na',
  NR = 'nr',
  OnboardingDate = 'onboardingDate',
  CompletedDate = 'completedDate',
  Weight = 'weight',
}

export interface Column {
  name: string;
  code: string;
  offset?: number;
  type: ColumnType;
}

export interface DownloadPortfolioProps {
  companies: InitiativeCompany[];
  columnsSetting: Column[];
  allMetrics: boolean;
  portfolio: Pick<InitiativeCompany, 'name'> | undefined;
}

export interface WeightedInitiativeCompany extends InitiativeCompany {
  weight: number;
}

export const GENERAL_COLUMNS: Column[] = [
  { name: 'Completed reports', code: GeneralColumns.CompletedReports, type: ColumnType.General },
  { name: '% Private', code: GeneralColumns.Private, type: ColumnType.General },
  { name: '% NA', code: GeneralColumns.NA, type: ColumnType.General },
  { name: '% NR', code: GeneralColumns.NR, type: ColumnType.General },
  { name: 'Account creation date', code: GeneralColumns.OnboardingDate, type: ColumnType.General },
  { name: 'Completed Date', code: GeneralColumns.CompletedDate, type: ColumnType.General },
  { name: 'Weighting', code: GeneralColumns.Weight, type: ColumnType.General },
];

interface FunctionResult {
  display: string;
  sort: string;
}
type GeneralColumnsFn = (company: InitiativeCompany) => FunctionResult;
export const getGeneralColumnsFn = (code: string): GeneralColumnsFn => {
  switch (code) {
    case GeneralColumns.CompletedReports:
      return getCompletedExchangeSurveys;
    case GeneralColumns.Private:
      return getPercentPrivate;
    case GeneralColumns.NR:
      return getPercentNr;
    case GeneralColumns.NA:
      return getPercentNa;
    case GeneralColumns.OnboardingDate:
      return getOnboardingDate;
    case GeneralColumns.CompletedDate:
      return getCompletedDate;
    default:
      return () => ({ display: '', sort: '' });
  }
};

export const addOffset = (columns: Column[]) => {
  return columns.map((c, i) => ({ ...c, offset: i }));
};

export const sortColumns = (columns: Column[]) => {
  return columns.sort((a, b) => (a.offset ?? 0) - (b.offset ?? 0));
};

export const getPackColumns = (columns: Column[]) => {
  return columns.filter((col) => col.type === ColumnType.SurveyPack);
};

export const ALL_METRICS_CODE = 'all_metrics';

export const getDynamicColumns = (columns: Column[]) => {
  return columns.filter((col) => col.type === ColumnType.SurveyPack || col.code === ALL_METRICS_CODE);
};

export const getExportRowHeaders = ({
  columnsSetting,
}: Pick<DownloadPortfolioProps, 'columnsSetting'>) => {
  return ['Access', 'Company Name', ...columnsSetting.map((col) => col.name)];
};

export const getExportRowValues = (
  props: Pick<DownloadPortfolioProps, 'companies' | 'columnsSetting'>
) => {
  const { companies, columnsSetting } = props;

  const rowValues = companies.map((company) => {
    const tableColumns = [getViewAccess(company), company.name];
    columnsSetting.forEach((col: Column) => {
      if (col.type === ColumnType.General) {
        tableColumns.push(getGeneralColumnsFn(col.code)(company).display);
      }

      if (col.type === ColumnType.SurveyPack) {
        const lastCompletedSurvey = getLastCompletedSurvey(company);
        const data = lastCompletedSurvey?.[col.code];
        const count = data ? ` ${data.answered}/${data.total}` : '-';
        tableColumns.push(count);
      }

      if (col.code === ALL_METRICS_CODE) {
        tableColumns.push(getAnsweredRatio(company).display);
      }
    });

    return tableColumns;
  });

  return rowValues;
};
export { getViewAccess };
