import React from 'react';
import { Button, ButtonProps, DropdownItem } from 'reactstrap';
import { FlagProperties } from '../../../services/G17Client';
import { BulkActionToolbarSelectedUtrv } from './BulkActionToolbar';
import { QUESTION } from '@constants/terminology';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { checkIsFieldRequired } from '../utils';
import { hasOverriddenUtrvConfig } from '@features/question-configuration/utils';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

export const countQuestions = (questions: Pick<BulkActionToolbarSelectedUtrv, '_id'>[]) => {
  return new Set(questions.map(q => q._id)).size;
}

interface BulkButtonProps extends React.PropsWithChildren<ButtonProps> {
  component?: typeof DropdownItem | typeof Button;
  tooltip: string;
}

export const BulkButton = (props: BulkButtonProps) => {
  const { component = Button, ...rest } = props;

  return <SimpleTooltip text={props.tooltip}>
    {React.createElement(component, {
      color: 'secondary',
      ...rest,
      className: props.className ?? 'my-1 ml-2'
    })}
  </SimpleTooltip>
}

export interface ToggleButtonProps {
  selectedQuestions: BulkActionToolbarSelectedUtrv[];
  field: keyof FlagProperties;
  action: string;
  reverseAction: string;
  icon?: string;
  reverseIcon?: string;
  handleBulkFlagChange: (changes: FlagProperties, ids: string[]) => void;
  className?: string;
  component?: typeof DropdownItem | typeof Button;
  rootInitiativeUtrMap?: Map<string, InitiativeUniversalTracker>;
}

const convertSelectedQuestions = ({
  selectedQuestions,
  rootInitiativeUtrMap,
  field,
}: Pick<ToggleButtonProps, 'selectedQuestions' | 'rootInitiativeUtrMap' | 'field'>) => {
  return selectedQuestions.reduce<{
    excludedOverriddenQuestions: BulkActionToolbarSelectedUtrv[];
    overriddenQuestions: BulkActionToolbarSelectedUtrv[];
  }>(
    (acc, question) => {
      const initiativeUtr = rootInitiativeUtrMap?.get(question.universalTrackerId);
      if (hasOverriddenUtrvConfig({ initiativeUtr, field })) {
        acc.overriddenQuestions.push(question);
      } else {
        acc.excludedOverriddenQuestions.push(question);
      }

      return acc;
    },
    { excludedOverriddenQuestions: [], overriddenQuestions: [] }
  );
};

const checkAllHasFlag = ({
  selectedQuestions,
  rootInitiativeUtrMap,
  field,
}: Pick<ToggleButtonProps, 'selectedQuestions' | 'rootInitiativeUtrMap' | 'field'>) => {
  if (!rootInitiativeUtrMap) {
    return selectedQuestions.every((v) => v[field] === true);
  }

  return selectedQuestions.every((question) => {
    const initiativeUtr = rootInitiativeUtrMap.get(question.universalTrackerId);
    return checkIsFieldRequired({ initiativeUtr, field, utrv: question })
  });
};

export const ToggledButton = (props: ToggleButtonProps) => {
  const { selectedQuestions, field, action, reverseAction, icon, reverseIcon, handleBulkFlagChange, className, rootInitiativeUtrMap } = props;
  const { overriddenQuestions, excludedOverriddenQuestions } = convertSelectedQuestions({ rootInitiativeUtrMap, selectedQuestions, field });
  // choose which type of questions to indicate the action
  const questionsToIndicate = excludedOverriddenQuestions.length > 0 ? excludedOverriddenQuestions : overriddenQuestions;
  const allHasFlag = checkAllHasFlag({ selectedQuestions: questionsToIndicate, rootInitiativeUtrMap, field });
  const isSelected = countQuestions(selectedQuestions) > 0;
  const currentAction = isSelected && allHasFlag ? action : reverseAction;
  const currentIcon = isSelected && allHasFlag ? icon : reverseIcon;
  const changes = { [field]: !allHasFlag } as FlagProperties;
  // disable the button if all selected questions have utrv config overrides
  const isDisabled = excludedOverriddenQuestions.length === 0;

  return (
    <BulkButton
      tooltip={
        isDisabled
          ? `Global override has been set for this ${QUESTION.SINGULAR} and cannot be changed here`
          : `Mark ${QUESTION.PLURAL} as ${currentAction.toLowerCase()}.`
      }
      component={props.component}
      onClick={() =>
        handleBulkFlagChange(
          changes,
          excludedOverriddenQuestions.map(({ _id }) => _id)
        )
      }
      className={className}
      disabled={isDisabled}
    >
      {currentIcon ? <i className={`${currentIcon} mr-2 text-ThemeIconSecondary`} /> : null}
      {currentAction}
    </BulkButton>
  );
};
