import { SurveyUserRoles } from '../../constants/users';
import { CurrentUserData } from '../../reducers/current-user';
import { InitiativePermissions } from '../../services/permissions/InitiativePermissions';
import { SurveyPermissions } from '../../services/permissions/SurveyPermissions';
import { UtrvPermissions } from '../../services/permissions/UtrvPermissions';
import { UniversalTrackerValuePlain } from '../../types/surveyScope';
import { DelegationModalSurvey } from './types';

export const hasUserRolePermission = (
  userRole: SurveyUserRoles,
  utrvs: Pick<UniversalTrackerValuePlain, '_id' | 'stakeholders' | 'initiativeId' | 'compositeData'>[],
  survey: DelegationModalSurvey,
  user: CurrentUserData | undefined,
  initiativeId: string
) => {
  if (!user) {
    return false;
  }

  if (SurveyPermissions.canManage(survey, user)) {
    return true
  }
  if (userRole === SurveyUserRoles.Stakeholder) {
    return UtrvPermissions.canContributeBulk(utrvs, survey, user)
  }

  if (userRole === SurveyUserRoles.Verifier) {
    return UtrvPermissions.canVerifyBulk(utrvs, survey, user)
  }
  return InitiativePermissions.canManageInitiative(user, initiativeId)
};
