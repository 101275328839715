import { DownloadSettingPropType } from './DownloadSettingModal';
import { InfoIconWithTooltip } from './DownloadSettingOptions';
import { SelectedGroup } from './util/downloadReportHandler';
import { PACK, SURVEY } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules/alert';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';

interface ScopeSelectProps extends Pick<DownloadSettingPropType, 'titleOptions' | 'handleChange' | 'requiredScope'> {
  hidden: boolean;
  isEmptyScope: boolean;
  selectedGroups: SelectedGroup[];
}

const defaultScopesTooltip = `Selected ${SURVEY.ADJECTIVE} ${PACK.PLURAL} will be included within the report. By default, SGX Core is always included in the sustainability report`;

export const ScopeSelect = (props: ScopeSelectProps) => {
  const { hidden, requiredScope, isEmptyScope, selectedGroups, titleOptions, handleChange } = props;
  if (hidden || selectedGroups.length === 0) {
    return null;
  }

  const options: Option[] = selectedGroups.map((item) => ({
    value: item.code,
    label: item.name,
    disabled: item.code === requiredScope,
  }));

  const values = selectedGroups.filter((item) => item.checked).map((item) => item.code);

  return (
    <>
      <div className='d-flex align-items-baseline'>
        <h6 className='text-ThemeTextDark mt-3'>
          {titleOptions?.scopes?.title ?? `Included ${SURVEY.ADJECTIVE} ${PACK.PLURAL}:`}
        </h6>
        <InfoIconWithTooltip text={titleOptions?.scopes?.tooltip ?? defaultScopesTooltip} />
      </div>
      <BasicAlert hide={!isEmptyScope} type={'warning'}>
        Please select at least one option
      </BasicAlert>
      <SelectFactory
        selectType={SelectTypes.MultipleSelect}
        options={options}
        onChange={(options) => handleChange?.({ key: 'scope', value: options as string[] })}
        values={values}
        placeholder={`Select ${PACK.PLURAL}`}
      />
    </>
  );
};
