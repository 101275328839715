import { SelectedGoal, SelectedTarget } from '.';
import variables from '../../css/variables.module.scss';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';

interface TargetSelectProps {
  selectedGoals: SelectedGoal[];
  selectedTargetCodes: SelectedTarget['value'][];
  handleOnChange: (values: SelectedTarget['value'][]) => void;
}

export const TargetSelect = (props: TargetSelectProps) => {
  const { selectedGoals, selectedTargetCodes, handleOnChange } = props;

  const options = selectedGoals.reduce((acc, goal) => {
    const { options, value, label } = goal;
    acc.push(
      {
        label: (
          <div className='fw-bold' style={{ color: variables[`sdg${value}`] }}>
            Goal {value}: {label}
          </div>
        ),
        value,
        isDisabled: true,
      },
      ...options
    );
    return acc;
  }, [] as Option<string>[]);

  return (
    <SelectFactory<string>
      selectType={SelectTypes.MultipleSelect}
      options={options}
      values={selectedTargetCodes}
      placeholder='SDG Targets'
      onChange={handleOnChange}
      menuPlacement='auto'
    />
  );
}
