import { useMemo, useState } from 'react';
import { Button, Badge } from 'reactstrap';
import { roles } from '../../constants/roles';
import { SurveyUserRoles } from '../../constants/users';
import { MassDelegation } from '../../types/survey';
import { RadioInput } from '../form/RadioInput';
import { Suggestion } from '../search/SearchComponent';
import UserSearch from '../search/UserSearch';
import Avatar from '@components/avatar';
import { DelegatedUsers, User } from '@api/survey-users';
import { hasAtLeastOneRoleLevel, RoleLevel } from '@utils/delegation-users';
import { DISABLED_DELEGATION_MESSAGE } from '@constants/user';

interface Props {
  stopDelegating: () => void;
  toggle: () => void;
  delegateCallback: ({ role, userId }: Pick<MassDelegation, 'role' | 'userId'>) => void;
  surveyId: string;
  role: SurveyUserRoles;
  delegatedUsers: DelegatedUsers;
  utrvsLength: number;
  canAccessVerification: boolean;
}

const bothRolesCode = 'both';
const explicitLevels = [RoleLevel.Utrv];
const explicitMessage = 'Already explicitly delegated';
const inheritedLevels = [RoleLevel.Initiative, RoleLevel.Survey];

const getRoleOptions = (canAccessVerification: boolean) => {
  const disabled = !canAccessVerification;
  const tooltip = disabled ? 'Upgrade to access verification' : '';
  return [
    { code: SurveyUserRoles.Stakeholder, name: roles[SurveyUserRoles.Stakeholder].shortName },
    { code: SurveyUserRoles.Verifier, name: roles[SurveyUserRoles.Verifier].shortName, disabled, tooltip },
    {
      code: bothRolesCode,
      name: `${roles[SurveyUserRoles.Stakeholder].shortName} and ${roles[SurveyUserRoles.Verifier].shortName}`,
      disabled,
      tooltip,
    },
  ];
};

const getExplicitDelegatedMessage = (user: User | undefined, role: SurveyUserRoles | typeof bothRolesCode) => {
  if (!user) {
    return '';
  }
  if (role !== bothRolesCode) {
    return hasAtLeastOneRoleLevel(explicitLevels, role, user) ? explicitMessage : '';
  }
  return hasAtLeastOneRoleLevel(explicitLevels, SurveyUserRoles.Stakeholder, user) &&
    hasAtLeastOneRoleLevel(explicitLevels, SurveyUserRoles.Verifier, user)
    ? explicitMessage
    : '';
};

const getInheritedDelegatedMessage = (user: User | undefined, role: SurveyUserRoles | typeof bothRolesCode) => {
  if (!user) {
    return '';
  }
  if (role !== bothRolesCode) {
    return hasAtLeastOneRoleLevel(inheritedLevels, role, user) ? `Platform ${roles[role].shortName}` : '';
  }
  return hasAtLeastOneRoleLevel(inheritedLevels, SurveyUserRoles.Stakeholder, user) &&
    hasAtLeastOneRoleLevel(inheritedLevels, SurveyUserRoles.Verifier, user)
    ? 'Platform contributor and verifier'
    : '';
};

export const Delegation = ({
  stopDelegating,
  toggle,
  delegateCallback,
  surveyId,
  role,
  delegatedUsers,
  canAccessVerification,
}: Props) => {
  const [users, setUsers] = useState<Suggestion[]>([]);
  const [selectingRole, setSelectingRole] = useState<SurveyUserRoles | typeof bothRolesCode>(role);

  const selectingRoles =
    bothRolesCode === selectingRole ? [SurveyUserRoles.Stakeholder, SurveyUserRoles.Verifier] : [selectingRole];

  const userMessagesMap = useMemo(
    () =>
      [...delegatedUsers.contributors, ...delegatedUsers.verifiers].reduce((acc, user) => {
        if (acc.has(user._id)) {
          return acc;
        }
        acc.set(user._id, {
          explicitMessage: getExplicitDelegatedMessage(user, selectingRole),
          inheritedMessage: getInheritedDelegatedMessage(user, selectingRole),
        });
        return acc;
      }, new Map<string, { explicitMessage: string; inheritedMessage: string }>()),
    [delegatedUsers.contributors, delegatedUsers.verifiers, selectingRole]
  );

  const handleUserSelected = (suggestion?: Suggestion) => {
    const userMessages = suggestion ? userMessagesMap.get(suggestion._id) : undefined;
    if (userMessages?.explicitMessage || suggestion?.delegateDisabled) {
      // Do nothing as user is already explicitly delegated or user can't be delegated
      return;
    }
    if (suggestion) {
      setUsers([...users, suggestion]);
    }
  };

  const onClose = () => {
    setUsers([]);
    stopDelegating();
  };

  const onChangeRole = ({ value }: { value: SurveyUserRoles }) => setSelectingRole(value);

  const onDelegate = () => {
    delegateCallback({ role: selectingRoles, userId: users.map(({ _id }) => _id) });
    onClose();
  };

  const customRender = (suggestion: Suggestion) => {
    const { explicitMessage, inheritedMessage } = userMessagesMap.get(suggestion._id) || {};
    const message =
      explicitMessage || inheritedMessage || (suggestion.delegateDisabled ? DISABLED_DELEGATION_MESSAGE : '') || '';

    return (
      <div className={`suggestion-item ${explicitMessage || suggestion.delegateDisabled ? 'disabled' : ''}`}>
        <Avatar width='30px'>{suggestion.profile ? <img alt='avatar' src={suggestion.profile} /> : <></>}</Avatar>
        <div className='name'>
          <div>
            <strong>{suggestion.name}</strong>
          </div>
          <div>
            <small>{suggestion.jobTitle}</small>
          </div>
          <div className='dont_translate'>
            <small>{suggestion.email}</small>
          </div>
        </div>
        {message ? <div className='message'>{message}</div> : null}
      </div>
    );
  }

  return (
    <>
      <Button color='link' onClick={onClose}>
        <i className='fa fa-chevron-left mr-2' />
        Back
      </Button>
      <p className='mt-3'>
        Search for the users you wish to delegate to. If you are unable to find the user below, please first ensure you
        have invited them to the platform via the manage users page:
      </p>

      <UserSearch
        name='stakeholder'
        placeholder='Type users name here...'
        ignoredIds={users.map(({ _id }) => _id)}
        surveyId={surveyId}
        inputClassName={'form-control'}
        handleValueChange={() => {}}
        handleSuggestionSelected={handleUserSelected}
        renderSuggestion={customRender}
      />
      {users.map((user) => (
        <Badge color='primary' className='mr-2 mt-2 background-ThemeInfoDark text-md p-2 dont_translate' tag='div' key={user._id}>
          {user.name} ({user.email})
        </Badge>
      ))}
      <h6 className='text-ThemeTextDark'>Delegate as:</h6>
      <RadioInput
        groupCode='delegation-role'
        value={selectingRole}
        options={getRoleOptions(canAccessVerification)}
        onChange={onChangeRole}
        className='d-flex'
        formGroupClassName='mr-5'
      />
      <div className='d-flex justify-content-end mt-5'>
        <Button color='link-secondary' onClick={toggle} className=''>
          Close
        </Button>
        <Button color='primary' onClick={onDelegate} disabled={!users.length}>
          Delegate
        </Button>
      </div>
    </>
  );
};
