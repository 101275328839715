import { EditorTemplate } from '../EditorTemplate';
import { Button } from 'reactstrap';
import { TextData, TextEditorProps, TextItem } from '../../types';
import { MutableRefObject, useRef, useState } from 'react';
import { GridDashboardItemCreate, TextSubType } from '@g17eco/types/insight-custom-dashboard';
import { FreeText } from './FreeText';
import classNames from 'classnames';
import { MetricText } from './MetricText';
import { calculateItemHeightFromPixelHeight, GRID_WIDTH, QuestionData } from '@routes/custom-dashboard/utils';
import { emptyUtrData, isValidUtrVariable } from '../../utils/dashboard-utils';
import { RichTextEditorContainer } from '@features/rich-text-editor';
import { SerializedEditorState } from 'lexical';

const getTextData = (item: TextItem): TextData => {
  return {
    type: item.type,
    subType: item.subType ?? TextSubType.Free,
    text: item.text ?? '',
    editorState: item.editorState,
    metric: Object.values(item.variables ?? {})?.[0] ?? emptyUtrData,
  };
};

const getItemHeight = (wrapperRef: MutableRefObject<HTMLDivElement | null>) => {
  if (!wrapperRef.current) {
    return;
  }

  const editorElement = wrapperRef.current.querySelector('.editor-input') as HTMLElement;
  if (!editorElement) {
    return;
  }

  // Get the computed style to extract padding values
  const computedStyle = window.getComputedStyle(editorElement);
  const { paddingTop, paddingBottom, paddingLeft, paddingRight } = computedStyle;

  // Calculate the content height and width excluding padding
  const editorHeight = editorElement.scrollHeight - parseFloat(paddingTop) - parseFloat(paddingBottom);
  const editorWidth = editorElement.scrollWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);

  // Calculate the item height which is the number of rows in grid.
  // Equation: rowsCount * ROW_HEIGHT + (rowsCount - 1) * ITEM_GAP = editorHeight * editorWidth / GRID_WIDTH
  return calculateItemHeightFromPixelHeight((editorHeight * editorWidth) / GRID_WIDTH);
};

const Tabs: Record<TextSubType, string> = {
  [TextSubType.Free]: 'Free text',
  [TextSubType.Metric]: 'Existing metric',
};

export const TextEditor = ({ handleCancel, updateItem, editingItem, initiativeId }: TextEditorProps) => {
  const [widgetChanges, setWidget] = useState<TextData>(getTextData(editingItem));
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const changeWidget = (key: keyof TextData, value: TextData[keyof TextData]) =>
    setWidget((widget) => ({ ...widget, [key]: value }));
  const handleChangeText = ({ text, editorState }: { text: string; editorState: SerializedEditorState }) => {
    changeWidget('text', text);
    changeWidget('editorState', editorState);
  };
  const handleChangeTab = (tab: TextSubType) => {
    changeWidget('subType', tab);
  };
  const handleSelectQuestion = (data: QuestionData) => {
    changeWidget('metric', data);
  };
  const handleSubmit = async () => {
    if (widgetChanges.subType === TextSubType.Free) {
      const itemHeight = getItemHeight(wrapperRef);
      updateItem({
        ...editingItem,
        type: widgetChanges.type,
        subType: widgetChanges.subType,
        text: widgetChanges.text.trim(),
        variables: undefined,
        editorState: widgetChanges.editorState,
        ...(itemHeight ? { gridSize: { h: itemHeight } } : {}),
      } as GridDashboardItemCreate);
    }

    if (widgetChanges.subType === TextSubType.Metric && isValidUtrVariable(widgetChanges.metric)) {
      updateItem({
        ...editingItem,
        type: widgetChanges.type,
        subType: widgetChanges.subType,
        variables: { a: widgetChanges.metric },
        text: undefined,
      } as GridDashboardItemCreate);
    }
  };

  const isDisabled =
    (widgetChanges.subType === TextSubType.Free && !widgetChanges.text) ||
    (widgetChanges.subType === TextSubType.Metric && !isValidUtrVariable(widgetChanges.metric));

  return (
    <EditorTemplate isDisabled={isDisabled} handleCancel={handleCancel} handleSubmit={handleSubmit}>
      <div className='my-3 d-flex justify-content-between align-items-center'>
        {(Object.keys(Tabs) as TextSubType[]).map((tab, index) => (
          <Button
            className={classNames('w-50', index ? '' : 'mr-2')}
            key={tab as string}
            color='primary'
            outline={widgetChanges.subType !== tab}
            onClick={() => handleChangeTab(tab)}
            active={widgetChanges.subType === tab}
          >
            {Tabs[tab]}
          </Button>
        ))}
      </div>

      {widgetChanges.subType === TextSubType.Free ? (
        <RichTextEditorContainer key={'_id' in editingItem ? editingItem._id : 'new-item'}>
          <FreeText item={widgetChanges} handleChange={handleChangeText} ref={wrapperRef} />
        </RichTextEditorContainer>
      ) : null}
      {widgetChanges.subType === TextSubType.Metric ? (
        <MetricText
          initiativeId={initiativeId}
          handleSelectQuestion={handleSelectQuestion}
          questionData={widgetChanges.metric}
        />
      ) : null}
    </EditorTemplate>
  );
};
