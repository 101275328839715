import { MaterialTopicDetails } from '@apps/materiality-tracker/components/assessment-insights/MaterialTopicDetails';
import { DashboardSection } from '@components/dashboard';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Input, InputProps } from 'reactstrap';
import { AssessmentInsightsChart } from './AssessmentInsightsChart';
import { NoData } from '@g17eco/molecules/no-data';
import { AssessmentData } from '@apps/materiality-tracker/api/materiality-assessment';
import { isDoubleMaterialityAssessmentData } from '@apps/materiality-tracker/utils';
import { AssessmentInsightsDoubleMaterialityChart } from './AssessmentInsightsDoubleMaterialityChart';

interface Range {
  start: number;
  end: number;
}

const DEFAULT_RANGE = {
  start: 1,
  end: 20,
};

const getValidRange = (topicLength: number) => {
  return {
    start: DEFAULT_RANGE.start,
    end: topicLength,
  };
};

const getDefaultRange = (topicLength: number) => {
  return {
    start: DEFAULT_RANGE.start,
    end: Math.min(topicLength, DEFAULT_RANGE.end),
  };
};

const checkIsValidNumber = ({ num, start, end }: { num: number } & Range) => {
  return num >= start && num <= end;
};

const filterDataWithRange = (data: AssessmentData[], range: Range) => {
  return data.slice(range.start - 1, range.end);
};

const NumberInput = (props: Partial<InputProps>) => {
  return <Input type='number' className='border-ThemeBorderDefault' {...props} style={{ maxWidth: 70 }} />;
};

export const AssessmentInsightsResult = (props: { data: AssessmentData[] }) => {
  const { data } = props;

  const defaultRange = getDefaultRange(data.length);
  const validRange = getValidRange(data.length);

  const [range, setRange] = useState<Range>(defaultRange);
  const [filteredData, setFilteredData] = useState<AssessmentData[]>(filterDataWithRange(data, defaultRange));
  const [selectedTopic, setSelectedTopic] = useState<AssessmentData>(data[0]);

  const selectedTopicPriority = data.findIndex((topic) => topic.code === selectedTopic.code) + 1;

  if (data.length === 0) {
    return (
      <DashboardSection>
        <NoData text='No assessment results currently available.' />
      </DashboardSection>
    );
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRange((prev) => ({ ...prev, [name]: value ? parseInt(value) : '' }));
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateDataOnRangeChanged(e.currentTarget.name);
    }
  };

  const updateRange = (key: string) => {
    const value = range[key as keyof Range];
    const isValidInput = checkIsValidNumber({ num: value, start: validRange.start, end: validRange.end });
    const isValidRange = range.start < range.end;
    let newRange = { ...range };

    if (!isValidInput || !isValidRange) {
      newRange = { ...range, [key]: validRange[key as keyof Range] };
      setRange(newRange);
    }

    return newRange;
  };

  const updateDataOnRangeChanged = (key: string) => {
    const newRange = updateRange(key);
    const newFilteredData = filterDataWithRange(data, newRange);
    if (newFilteredData.every((topic) => topic.code !== selectedTopic.code)) {
      setSelectedTopic(newFilteredData[0]);
    }
    setFilteredData(newFilteredData);
  };

  const updateDataOnColumnSelected = (selectedColumnIndex: number) => {
    setSelectedTopic(filteredData[selectedColumnIndex]);
  };

  return (
    <DashboardSection>
      <div className='mb-3'>
        <div className='d-flex justify-content-end align-items-center gap-2'>
          <div style={{ minWidth: 'fit-content' }}>Display topics between</div>
          <NumberInput
            name='start'
            value={range.start}
            onChange={onInputChange}
            onKeyDown={onInputKeyDown}
            onBlur={() => updateDataOnRangeChanged('start')}
          />
          <span>-</span>
          <NumberInput
            name='end'
            value={range.end}
            onChange={onInputChange}
            onKeyDown={onInputKeyDown}
            onBlur={() => updateDataOnRangeChanged('end')}
          />
        </div>
        <div className='text-right text-ThemeHeadingLight'>end. {data.length}</div>
      </div>
      {isDoubleMaterialityAssessmentData(filteredData) ? (
        <AssessmentInsightsDoubleMaterialityChart
          data={filteredData}
          selectedTopicCode={selectedTopic.code}
          columnClickHandler={updateDataOnColumnSelected}
        />
      ) : (
        <AssessmentInsightsChart
          data={filteredData}
          selectedTopicCode={selectedTopic.code}
          columnClickHandler={updateDataOnColumnSelected}
        />
      )}
      <MaterialTopicDetails data={selectedTopic} priority={selectedTopicPriority} />
    </DashboardSection>
  );
};
