import { BackgroundJob, JobStatus, TaskStatus } from '@g17eco/types/background-jobs'

export const finalStatuses = [
  JobStatus.Completed,
  JobStatus.Error,
  JobStatus.Deleted,
]
export const isFinishedJob = (job: BackgroundJob) => finalStatuses.includes(job.status);

export const isProcessingJob = (job: BackgroundJob) => !finalStatuses.includes(job.status);

export const canRetryJob = (job: BackgroundJob) => {
  const isPending = job.status === JobStatus.Pending;
  const hasFailedTask = job.tasks.some(t => t.status === TaskStatus.Error)
  return isPending && hasFailedTask;
}