import { ReactElement } from 'react';
import Dashboard, { DashboardRow, DashboardSection } from '@components/dashboard';
import { NavTab, Navigation } from '@components/company-settings/Navigation';
import './style.scss';
import { InitiativeData } from '@g17eco/types/initiative';
import { useHistory } from 'react-router-dom';
import { generateUrl } from '@routes/util';
import { RouteInterfaceMin } from '@g17eco/types/routes';
import { AdminBreadcrumbsProps } from '@g17eco/molecules/breadcrumbs';

interface Props {
  children: ReactElement;
  initiative: InitiativeData;
  BreadCrumbsComponent: (props: AdminBreadcrumbsProps) => JSX.Element;
  page: string;
  navTabs: NavTab[];
  settingRoute: RouteInterfaceMin;
}

export const AccountSettingsContainer = ({
  initiative,
  BreadCrumbsComponent,
  page,
  navTabs,
  settingRoute,
  children,
}: Props) => {
  const history = useHistory();
  const onClick = (code: string) => {
    history.push({
      pathname: generateUrl(settingRoute, { initiativeId: initiative._id, page: code }),
      search: history.location.search,
    });
  };

  return (
    <Dashboard className='company-settings__wrapper'>
      <DashboardRow>
        <BreadCrumbsComponent breadcrumbs={[{ label: 'Account Settings' }]} initiativeId={initiative._id} />
      </DashboardRow>
      <DashboardRow>
        <h3 className='pl-2'>Account settings</h3>
      </DashboardRow>
      <DashboardSection>
        <Navigation navTabs={navTabs} page={page} onClick={onClick} />
        <div className='mt-4'>{children}</div>
      </DashboardSection>
    </Dashboard>
  );
};
