import { SelectOption } from '@g17eco/types/select';
import { GroupBase, StylesConfig, components, ClearIndicatorProps } from 'react-select';
import { CreatableSelect, CreatableSelectProps } from './CreatableSelect';
import { LabelSelect, LabelSelectProps } from './LabelSelect';
import { MultipleSelect, MultipleSelectProps } from './MultipleSelect';
import { SelectProps, SingleSelect } from './SingleSelect';
import { CreatableMultipleSelect, CreatableMultipleSelectProps } from './CreatableMultipleSelect';

export enum SelectTypes {
  LabelSelect = 'labelSelect',
  SingleSelect = 'singleSelect',
  MultipleSelect = 'multipleSelect',
  CreatableSelect = 'creatableSelect',
  CreatableMultipleSelect = 'creatableMultipleSelect',
}

export type Option<T = string | null> = Pick<SelectOption<T>, 'label' | 'searchString' | 'value'> & {
  isDisabled?: boolean;
  tooltip?: string | JSX.Element;
  id?: string;
};

type ExtendedMultipleSelectProps<T = string | null> = MultipleSelectProps<T> & {
  selectType: SelectTypes.MultipleSelect;
};

type ExtendedSingleSelectProps<T = string | null> = SelectProps<T> & {
  selectType: SelectTypes.SingleSelect;
};

type ExtendedLabelSelectProps = LabelSelectProps & {
  selectType: SelectTypes.LabelSelect;
};

type ExtendedCreatableSelectProps<T = string | null> = CreatableSelectProps<T> & {
  selectType: SelectTypes.CreatableSelect;
};

type ExtendedCreatableMultipleSelectProps<T = string | null> = CreatableMultipleSelectProps<T> & {
  selectType: SelectTypes.CreatableMultipleSelect;
};

type Props<T = string | null> = (
  | ExtendedMultipleSelectProps<T>
  | ExtendedSingleSelectProps<T>
  | ExtendedLabelSelectProps
  | ExtendedCreatableSelectProps<T>
  | ExtendedCreatableMultipleSelectProps<T>
) & { key?: React.Key | null };

export interface SelectStyleProps {
  size?: SelectSize;
  isTransparent?: boolean;
  hasOptionDivider?: boolean;
  isFlexibleSize?: boolean;
  showDropdownIndicator?: boolean;
  isMenuPortalTargetBody?: boolean;
  indicateHasReachedLimit?: boolean;
  showCount?: boolean;
  backgroundColor?: 'transparent' | 'var(--theme-BgExtralight)';
  minWidth?: number;
  isInvalid?: boolean;
}

export const DropdownIndicator = ({
  showCount,
  current,
  limit,
  showDropdownIndicator,
  indicateHasReachedLimit,
}: {
  current: number;
  limit?: number;
  showCount: boolean;
} & Pick<SelectStyleProps, 'indicateHasReachedLimit' | 'showDropdownIndicator'>) => {
  if (!showDropdownIndicator) {
    return <HiddenDropdownIndicator />;
  }

  const icon = <i className='fa-light fa-caret-down mx-2' />;
  if (!showCount || !limit) {
    return icon;
  }

  const color = indicateHasReachedLimit && current === limit ? 'text-ThemeWarningDark' : '';

  return (
    <div className='d-flex align-items-center'>
      <span className={`text-sm ml-1 ${color}`}>({current + '/' + limit})</span>
      {icon}
    </div>
  );
};

export const HiddenDropdownIndicator = () => <span className='mx-1'></span>;

export const ClearIndicator = <T = string | null,>(props: ClearIndicatorProps<T>) => {
  return (
    <components.ClearIndicator {...props}>
      <i className='fal fa-xmark p-0 ml-3 text-md' />
    </components.ClearIndicator>
  );
};

export const SelectFactory = <T = string | null,>(props: Props<T>) => {
  switch (props.selectType) {
    case SelectTypes.MultipleSelect:
      return <MultipleSelect {...props} />;
    case SelectTypes.SingleSelect:
      return <SingleSelect {...props} />;
    case SelectTypes.LabelSelect:
      return <LabelSelect {...props} />;
    case SelectTypes.CreatableSelect:
      return <CreatableSelect {...props} />;
    case SelectTypes.CreatableMultipleSelect:
      return <CreatableMultipleSelect {...props} />;
    default:
      return <></>;
  }
};

export const SELECT_SIZES = ['xl', 'lg', 'md', 'sm', 'xs'] as const;

type SelectSize = (typeof SELECT_SIZES)[number];

const SELECT_SIZE_MAP: { [key in SelectSize]: number } = {
  xl: 48,
  lg: 40,
  md: 32,
  sm: 28,
  xs: 24,
};

const getColor = ({
  isSelected,
  isDisabled,
  menuIsOpen,
  isTransparent,
}: {
  isSelected?: boolean;
  isDisabled: boolean;
  menuIsOpen?: boolean;
  isTransparent?: boolean;
}) => {
  if (menuIsOpen && isTransparent) {
    return 'var(--theme-TextDark)';
  }
  if (isSelected) {
    return 'white';
  }
  return isDisabled ? 'var(--theme-TextPlaceholder)' : 'var(--theme-TextMedium)';
};

export const getIconColor = ({
  isSelected,
  isDisabled,
  menuIsOpen,
  isTransparent,
}: {
  isSelected?: boolean;
  isDisabled: boolean;
  menuIsOpen?: boolean;
  isTransparent?: boolean;
}) => {
  if (menuIsOpen && isTransparent) {
    return 'var(--theme-TextDark)';
  }
  if (isSelected) {
    return 'white';
  }
  return isDisabled ? 'var(--theme-BgDisabled)' : 'var(--theme-IconSecondary)';
};

export const getBackgroundColor = ({ isSelected, isFocused }: { isSelected?: boolean; isFocused: boolean }) => {
  if (isSelected) {
    return 'var(--theme-AccentExtradark)';
  }

  return isFocused ? 'var(--theme-AccentExtralight)' : 'white';
};

const getBorderColor = ({
  isDisabled,
  isFocused,
  isTransparent = true,
  isInvalid = false
}: {
  isDisabled: boolean;
  isTransparent?: boolean;
  isFocused: boolean;
  isInvalid?: boolean;
}) => {
  if (isInvalid) {
    return 'var(--theme-DangerMedium)';
  }
  if (isFocused) {
    return 'var(--theme-NeutralsDark)';
  }
  if (isTransparent) {
    return 'transparent';
  }
  return isDisabled ? 'var(--theme-BgDisabled)' : 'var(--theme-NeutralsDark)';
};

export const getStyles = <T = Option | null,>({
  selectedOptions,
  isMulti,
  isTransparent = false,
  isFlexibleSize = false,
  showSelectAll = true,
  isMenuPortalTargetBody = false,
  size = 'md',
  hasOptionDivider = false,
  minWidth = 200,
  backgroundColor = 'transparent',
  isInvalid,
}: {
  selectedOptions?: T[];
  isMulti: boolean;
  showSelectAll?: boolean;
} & Pick<
  SelectStyleProps,
  | 'isFlexibleSize'
  | 'hasOptionDivider'
  | 'minWidth'
  | 'isTransparent'
  | 'size'
  | 'isMenuPortalTargetBody'
  | 'backgroundColor'
  | 'isInvalid'
>): StylesConfig<T, boolean, GroupBase<T>> => {
  return {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: isFlexibleSize ? 'fit-content' : SELECT_SIZE_MAP[size],
      height: SELECT_SIZE_MAP[size],
      minWidth: isFlexibleSize ? 'inherit' : `${minWidth}px`,
      boxShadow: 'none',
      fontWeight: 'normal',
      '&:hover': {
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isTransparent ? 'transparent' : 'var(--theme-NeutralsLight)',
        ...(isTransparent
          ? {
              color: 'var(--theme-TextDark)',
              i: {
                color: 'var(--theme-TextDark)',
              },
            }
          : {}),
      },
      color: getColor({ ...state, isTransparent }),
      backgroundColor: state.menuIsOpen && !isTransparent ? 'var(--theme-NeutralsLight)' : backgroundColor,
      borderColor: getBorderColor({ ...state, isTransparent, isInvalid }),
      i: {
        color: getIconColor({ ...state, isTransparent }),
      },
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      minWidth: isMulti ? 'fit-content' : isFlexibleSize ? 'max-content' : `${minWidth}px`,
      whiteSpace: 'normal',
      border: 'solid 1px var(--theme-NeutralsLight)',
      borderTop: 0,
      boxShadow: '0px 4px 8px 0 rgba(0, 0, 0, 0.1)',
      paddingBottom: 4,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuPortal: (provided) => ({ ...provided, ...(isMenuPortalTargetBody ? { zIndex: 1050 } : {}) }),
    placeholder: (provided) => ({
      ...provided,
      color: 'inherit',
      margin: 0,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
      color: 'var(--theme-IconSecondary)',
      '&:hover': {
        color: 'var(--theme-TextDark)',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
      marginRight: '10px',
      fontSize: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      paddingLeft: '8px',
    }),
    input: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    option: (provided, state) => {
      const isSelected =
        state.isSelected ||
        (selectedOptions &&
          selectedOptions.some((op) =>
            op !== null &&
            op !== undefined &&
            typeof op === 'object' &&
            state.data !== null &&
            typeof state.data === 'object' &&
            'value' in state.data &&
            'value' in op
              ? op.value === state.data.value
              : false
          ));
      const isDisabled = state.isDisabled;
      const isFocused = state.isFocused;

      return {
        ...provided,
        backgroundColor: getBackgroundColor({ isSelected, isFocused }),
        minHeight: SELECT_SIZE_MAP[size],
        display: 'flex',
        alignItems: 'center',
        borderColor: 'var(--theme-NeutralsLight)',
        borderStyle: 'solid',
        padding: '0 12px',
        borderWidth: hasOptionDivider ? '1px 0' : '0',
        color: getColor({ isSelected, isDisabled }),
        i: {
          color: getIconColor({ isSelected, isDisabled }),
        },
        img: {
          opacity: state.isDisabled ? 0.5 : 1,
        },
        '&:hover': {
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        },
        '&:first-of-type': {
          borderRadius: '4px 4px 0 0',
        },
        ...(isMulti && showSelectAll
          ? {
              '&:first-of-type': {
                borderBottom: '1px solid var(--theme-NeutralsLight)',
              },
            }
          : {}), // used to distinguish between select all and other options
      };
    },
    singleValue: (base) => ({
      ...base,
      margin: 0,
      color: 'inherit',
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: 'var(--theme-AccentExtradark)',
      color: 'white',
      borderRadius: '4px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'white',
      whiteSpace: 'normal',
      fontSize: '0.6875rem !important',
      i: {
        color: 'white',
        fontSize: '11px',
      },
      paddingTop: 0,
      paddingBottom: 0,
      padding: '0 2px 0 8px !important',
    }),
    multiValueRemove: (base) => ({
      ...base,
      i: {
        color: 'white',
        fontSize: '6px !important',
      },
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 4,
    }),
  };
};
