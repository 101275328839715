import { Option } from '@components/multiple-select';
import { SURVEY } from '@constants/terminology';
import { SurveyType } from '@g17eco/types/survey';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { getMonthNonJSOptions, getPeriodOptions } from '@utils/date';
import { getSurveyTypeOptions } from '@utils/survey';

export interface Filters<T = SurveyType> {
  surveyType: T[];
  period: DataPeriods[];
  month: string[];
  year: string[];
}

export enum SurveyFilters {
  Month = 'month',
  Year = 'year',
  Period = 'period',
  SurveyType = 'surveyType',
}

export const getInitialSurveyFilters = <T = SurveyType>(
  defaultFilter: Partial<Filters<T>> | undefined
): Filters<T> => ({
  surveyType: [],
  period: [],
  month: [],
  year: [],
  ...defaultFilter,
});

export const getSurveyFilters = ({
  surveyOptions = getSurveyTypeOptions(),
  yearOptions,
}: {
  surveyOptions?: Option[];
  yearOptions: Option[];
}) => ({
  [SurveyFilters.SurveyType]: {
    label: `${SURVEY.CAPITALIZED_SINGULAR} Type`,
    options: surveyOptions,
  },
  [SurveyFilters.Period]: {
    label: 'Period',
    options: getPeriodOptions(),
  },
  [SurveyFilters.Month]: {
    label: 'Month',
    options: getMonthNonJSOptions().map((option) => ({ ...option, value: String(option.value) })),
  },
  [SurveyFilters.Year]: {
    label: 'Year',
    options: yearOptions,
  },
});
