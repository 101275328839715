import { ApiResponse } from '../types/api';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import {
  NotificationPreferences,
  NotificationPreferencesUpdateRequest,
  NotificationSummariesUpdateRequest,
} from '@g17eco/types/notifications';
import { UserPreferencesResponse } from '@g17eco/types/user';
import {
  CreateApiKeyData,
  CreateApiKeyResponse,
  RevokeApiKeyResponse,
  UserApiKeyExtended
} from '@g17eco/types/user-api-key';

const tags = ['userPreferences', 'userNotificationPreferences', 'userApiKeys'];
const [userPreferencesTag, userNotificationPreferencesTag, userApiKeys] = tags;

export const userApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: tags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMagicBellToken: builder.query<string, void>({
        transformResponse,
        query: () => ({
          url: '/users/current/magic-bell/auth-token',
          method: 'get',
        }),
      }),
      getUserPreferences: builder.query<UserPreferencesResponse, void>({
        transformResponse,
        providesTags: [userPreferencesTag],
        query: () => ({
          url: '/users/current/preferences',
          method: 'get',
        }),
      }),
      getUserApiKeys: builder.query<UserApiKeyExtended[], void>({
        transformResponse,
        providesTags: [userApiKeys],
        query: () => ({
          url: '/keys/personal',
          method: 'get',
        }),
      }),
      createUserApiKey: builder.mutation<CreateApiKeyResponse, CreateApiKeyData>({
        transformResponse,
        invalidatesTags: [userApiKeys],
        query: (data) => ({
          url: '/keys/personal',
          method: 'post',
          data,
        }),
      }),
      revokeUserApiKey: builder.mutation<RevokeApiKeyResponse, { _id: string }>({
        transformResponse,
        invalidatesTags: [userApiKeys],
        query: ({ _id }) => ({
          url: `/keys/personal/${_id}/revoke`,
          method: 'post',
        }),
      }),
      getNotificationPreferences: builder.query<NotificationPreferences, void>({
        transformResponse,
        providesTags: [userNotificationPreferencesTag],
        query: () => ({
          url: '/users/current/notifications/preferences',
          method: 'get',
        }),
      }),
      setNotificationPreferences: builder.mutation<ApiResponse, NotificationPreferencesUpdateRequest>({
        invalidatesTags: [userNotificationPreferencesTag],
        transformResponse,
        query: (data) => ({
          url: '/users/current/notifications/preferences',
          method: 'put',
          data,
        }),
      }),
      updateNotificationSummaries: builder.mutation<ApiResponse, NotificationSummariesUpdateRequest>({
        invalidatesTags: [userNotificationPreferencesTag],
        transformResponse,
        query: (data) => ({
          url: '/users/current/notifications/preferences/summaries',
          method: 'put',
          data,
        }),
      }),
    }),
  });

export const {
  useGetUserPreferencesQuery,
  useGetUserApiKeysQuery,
  useCreateUserApiKeyMutation,
  useRevokeUserApiKeyMutation,
  useGetMagicBellTokenQuery,
  useGetNotificationPreferencesQuery,
  useSetNotificationPreferencesMutation,
  useUpdateNotificationSummariesMutation,
} = userApi;
