import { UtrvStatus } from '../../../constants/status';
import { QUESTION } from '@constants/terminology';
import { ScopeGroups } from '../../../model/surveyData';
import { CustomInitiatives, NewCustomReport, SurveyData, SurveyDataRange } from '../../../types/custom-report';
import { DownloadDisplayOptions, DownloadGroup, DownloadMultiScope, DownloadType, HandleDownload, HandleDownloadConfig, VisibilityStatus } from '../../../types/download';
import { MetricGroup } from '../../../types/metricGroup';
import { UtrvAssuranceStatus } from '../../../types/universalTrackerValue';
import { getDefaultDownloadScope } from '../../report-output/outputs';
import { DownloadSettingPropType } from '../DownloadSettingModal';
import { ExtraFeature, FeatureStability } from '@g17eco/molecules/feature-stability';
import { Option } from '@g17eco/molecules/select/SelectFactory';
import { handleRouteError } from '../../../logger';
import { PackOption } from '@constants/standards-frameworks';

export type DownloadXlsxOrCsv = (params: {
  type: string;
  code?: string;
  visibility?: VisibilityStatus;
  utrvStatus?: DownloadUtrvStatus;
  downloadDisplayOptions?: DownloadDisplayOptions;
  scopeType?: PackOption['scopeType'];
}) => Promise<void>;

export interface DownloadXhtml {
  (code?: string, visibility?: VisibilityStatus, utrvStatus?: DownloadUtrvStatus, config?: HandleDownloadConfig): void
}
export interface DownloadDocx {
  (code: string, downloadScope: DownloadMultiScope): Promise<void>;
}

interface DownLoadReportType {
  type: DownloadType;
  code: string;
  config?: HandleDownloadConfig;
  downloadSettings: DownloadSettingsType;
  downloadXlsxOrCsv?: DownloadXlsxOrCsv;
  downloadDocx?: DownloadDocx;
  downloadXhtml?: DownloadXhtml;
}

export enum DownloadUtrvStatusCombined {
  AllAnswered = 'allanswered',
  All = 'all',
  Assured = 'assured',
}
export type DownloadUtrvStatus = UtrvStatus | DownloadUtrvStatusCombined

export interface AssuranceUtrvCombinedStatus {
  statuses: UtrvStatus[];
  assuranceStatus?: UtrvAssuranceStatus[],
}

const UtrvStatuses = {
  [DownloadUtrvStatusCombined.AllAnswered]: [UtrvStatus.Verified, UtrvStatus.Updated],
  [DownloadUtrvStatusCombined.All]: [UtrvStatus.Verified, UtrvStatus.Updated, UtrvStatus.Created, UtrvStatus.Rejected],
  [UtrvStatus.Verified]: [UtrvStatus.Verified],
  [DownloadUtrvStatusCombined.Assured]: [UtrvAssuranceStatus.Completed, UtrvAssuranceStatus.CompletedOpen, UtrvAssuranceStatus.Restated],
}

export const convertToUtrvStatuses = (status?: DownloadUtrvStatus): AssuranceUtrvCombinedStatus => {
  if (!status) {
    return { statuses: [] };
  }
  if (status === DownloadUtrvStatusCombined.AllAnswered) {
    return { statuses: UtrvStatuses[DownloadUtrvStatusCombined.AllAnswered] };
  }
  if (status === DownloadUtrvStatusCombined.All) {
    return { statuses: UtrvStatuses[DownloadUtrvStatusCombined.All] };
  }
  if (status === DownloadUtrvStatusCombined.Assured) {
    return {
      statuses: UtrvStatuses[UtrvStatus.Verified],
      assuranceStatus: UtrvStatuses[DownloadUtrvStatusCombined.Assured],
    };
  }
  return { statuses: [status] };
};

export const getDisplayStatus = (
  survey?: Pick<SurveyData, 'statuses' | 'assuranceStatus'>
): DownloadUtrvStatus => {
  if (!survey) {
    return UtrvStatus.Verified;
  }
  const { statuses, assuranceStatus } = survey;
  switch (true) {
    case assuranceStatus && assuranceStatus.length > 0:
      return DownloadUtrvStatusCombined.Assured;
    case statuses && UtrvStatuses[DownloadUtrvStatusCombined.All].every((status) => statuses?.includes(status)):
      return DownloadUtrvStatusCombined.All;
    case statuses && UtrvStatuses[DownloadUtrvStatusCombined.AllAnswered].every((status) => statuses?.includes(status)):
      return DownloadUtrvStatusCombined.AllAnswered;
    case statuses && UtrvStatuses[UtrvStatus.Verified].every((status) => statuses?.includes(status)):
    default:
      return UtrvStatus.Verified;
  }
};

export interface SelectedGroup extends Pick<MetricGroup, 'universalTrackers'> {
  code: string
  name: string
  checked: boolean;
  type: ScopeGroups;
}

export interface DownloadSettingsType extends DownloadDisplayOptions {
  selectedScopes: SelectedGroup[];
  visibility: VisibilityStatus;
  status?: DownloadUtrvStatus;
  assuranceStatus?: DownloadUtrvStatus;
}

export const downloadReportHandler = async ({
  type,
  code,
  config,
  downloadSettings,
  downloadXlsxOrCsv,
  downloadXhtml,
  downloadDocx,
}: DownLoadReportType) => {
  const { status, assuranceStatus, visibility, displayMetricOverrides, displayUserInput, displayTag } = downloadSettings;
  const combinedStatus = assuranceStatus ?? status;
  const downloadDisplayOptions = { displayMetricOverrides, displayUserInput, displayTag };

  switch (type) {
    case DownloadType.Docx: {
      const downloadScope = getDefaultDownloadScope([code], { ...downloadSettings });
      return downloadDocx?.(code, { ...downloadScope, displayMetricOverrides });
    }
    case DownloadType.Xlsx:
    case DownloadType.Csv:
      return downloadXlsxOrCsv?.({
        type,
        code,
        visibility,
        utrvStatus: combinedStatus,
        downloadDisplayOptions,
        scopeType: config?.scopeType,
      });
    case DownloadType.Xhtml:
      return downloadXhtml?.(code, visibility, combinedStatus, config);
    default:
      handleRouteError(new Error('Unknown download type'), { type, code, config });
      return;
  }
};


export interface FormData extends Partial<Omit<NewCustomReport, 'assuranceStatus'>> {
  _id?: string;
  status: DownloadUtrvStatus;
}

export const getInitialState = (
  customReport?: Pick<CustomInitiatives, '_id' | 'name' | 'description'> & { survey: SurveyData | SurveyDataRange }
): FormData => {
  return {
    _id: customReport?._id,
    name: customReport?.name,
    description: customReport?.description,
    status: getDisplayStatus(customReport?.survey),
  };
};

const metricStatusLabelMap: { [key: string]: string } = {
  [DownloadUtrvStatusCombined.All]: `All ${QUESTION.CAPITALIZED_PLURAL} (including unanswered)`,
  [DownloadUtrvStatusCombined.AllAnswered]: `All answered ${QUESTION.PLURAL} (answered and verified)`,
  [UtrvStatus.Verified]: `Verified ${QUESTION.PLURAL} only (excludes assured)`,
};

export const getMetricStatusOptions = ({
  hasAssuredOption,
  isDisableAssuredOption,
  defaultOptions = [DownloadUtrvStatusCombined.All, UtrvStatus.Verified],
}: {
  hasAssuredOption: boolean;
  isDisableAssuredOption: boolean;
  defaultOptions?: (DownloadUtrvStatusCombined | UtrvStatus)[];
}) => {
  const options: Option[] = defaultOptions.map((value) => ({ value, label: metricStatusLabelMap[value] }));

  if (hasAssuredOption) {
    options.push({
      value: DownloadUtrvStatusCombined.Assured,
      label: `Assured ${QUESTION.PLURAL} (includes restated)`,
      isDisabled: isDisableAssuredOption,
      tooltip: isDisableAssuredOption ? `There are no assured ${QUESTION.PLURAL}` : '',
    });
  }

  return options;
};

export const privacyOptions: Option[] = [
  { value: VisibilityStatus.Include, label: `All ${QUESTION.PLURAL} (public & private)` },
  { value: VisibilityStatus.ExcludeData, label: `Public ${QUESTION.PLURAL} only` },
];

export enum DisplayOption {
  MetricOverrides = 'displayMetricOverrides',
  UserInput = 'displayUserInput',
  SystemDefault = 'displaySystemDefault'
}

const displayOptionLabelMap = {
  [DisplayOption.MetricOverrides]: `Use ${QUESTION.SINGULAR} override preferences`,
  [DisplayOption.UserInput]: 'Use user input',
  [DisplayOption.SystemDefault]: 'Use system default configuration',
};

export const getDisplaySelectOptions = ({
  hasInitiativeUtrs,
  defaultOptions = [],
}: {
  hasInitiativeUtrs: boolean;
  defaultOptions?: DisplayOption[];
}): Option<DisplayOption>[] => {
  return defaultOptions.map((value) => ({
    value,
    label: displayOptionLabelMap[value],
    isDisabled: value === DisplayOption.MetricOverrides && !hasInitiativeUtrs,
  }));
};


export const getDownloadDisplayOptions = (value: DisplayOption): Pick<DownloadDisplayOptions, 'displayMetricOverrides' | 'displayUserInput'> => {
  switch (value) {
    case DisplayOption.MetricOverrides:
      return { displayUserInput: true, displayMetricOverrides: true };
    case DisplayOption.UserInput:
      return { displayUserInput: true, displayMetricOverrides: false };
    case DisplayOption.SystemDefault:
    default:
      return { displayUserInput: false, displayMetricOverrides: false };
  }
}

export const getSelectedDisplayOption = ({
  displayMetricOverrides,
  displayUserInput,
}: Pick<DownloadDisplayOptions, 'displayMetricOverrides' | 'displayUserInput'>): DisplayOption => {
  if (!displayUserInput) {
    return DisplayOption.SystemDefault;
  }
  if (displayMetricOverrides) {
    return DisplayOption.MetricOverrides;
  }
  return DisplayOption.UserInput;
};

export const getDownLoadOptions = (
  props: Pick<DownloadSettingPropType, 'code' | 'disabledOptions' | 'titleOptions'> & {
    handleDownloadReport: HandleDownload;
    isStaff: boolean;
    canUsePPTX?: boolean;
  }
): DownloadGroup[] => {
  const { code, disabledOptions = [], titleOptions, handleDownloadReport, isStaff = false, canUsePPTX = false } = props;

  if (code === 'sdg') {
    return [
      {
        id: 'sdg',
        title: '',
        tooltip: '',
        options: [
          // @TODO: Are we missing original Word report here as well?
          {
            type: 'Download CSV report',
            handler: () => handleDownloadReport(DownloadType.Csv),
            icon: 'fa fa-file-csv',
          },
        ],
      },
    ];
  }

  if (code === 'csrd') {
    return [
      {
        id: 'xhtml',
        title: '',
        tooltip: '',
        options: [
          {
            type: 'Download ESRS compatible report',
            handler: () => handleDownloadReport(DownloadType.Xhtml),
            icon: 'fa fa-file-zipper',
          },
          {
            type: 'Download ESRS compatible report - Inline preview',
            handler: () => handleDownloadReport(DownloadType.Xhtml, { debug: true }),
            icon: 'fa fa-file-zipper',
          },
        ],
      },
    ];
  }

  const options: DownloadGroup[] = [
    {
      id: 'simple',
      title: titleOptions?.simple?.title ?? 'Data report',
      tooltip: '',
      options: [
        {
          type: 'Download Excel report',
          handler: () => handleDownloadReport(DownloadType.Xlsx),
          icon: 'fal fa-file-excel',
        },
        {
          type: 'Download CSV report',
          handler: () => handleDownloadReport(DownloadType.Csv),
          icon: 'fal fa-file-csv',
        },
      ],
    },
  ];

  options.unshift({
    id: 'word',
    title: titleOptions?.word?.title ?? 'Sustainability report',
    tooltip: titleOptions?.word?.tooltip ?? '',
    options: [
      {
        type: 'Download Word report',
        handler: () => handleDownloadReport(DownloadType.Docx),
        icon: 'fal fa-file-word',
      },
    ],
  });

  const showPPTX = isStaff || canUsePPTX;
  if (showPPTX && code && ['sgx_metrics', 'eesg_2024'].includes(code)) {
    options.unshift({
      id: 'pptx',
      title: (
        <span>
          BETA: AI Generated reports <FeatureStability feature={ExtraFeature.PPTXReport} />
        </span>
      ),
      tooltip: '',
      options: [{
        type: 'PowerPoint report generation page',
        handler: () => handleDownloadReport(DownloadType.Pptx),
      }],
    });
  }

  return disabledOptions.length === 0 ? options : options.filter((o) => !disabledOptions.includes(o.id));
};
