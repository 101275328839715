import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import { ActionMeta, SingleValue } from 'react-select';
import { QuestionWarning } from './QuestionWarning';
import { useGetInitiativeBlueprintQuestionsQuery } from '@api/admin-dashboard';
import { useGetOrganizationMetricGroupsQuery, useGetOrganizationUsedScopesQuery } from '@api/organization';
import { SelectFactory, SelectTypes, Option } from '@g17eco/molecules/select/SelectFactory';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { DEFAULT_METRIC_UNIT } from '@constants/utr';
import { useQuestionFilters } from './useQuestionFilters';
import { emptyUtrData } from '../utils/dashboard-utils';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { canAddTarget, isNumericTableColumnType } from '@utils/universalTracker';
import { useState } from 'react';
import { QuestionFilterType, SIMPLE_TYPES, ValidatingFunc } from './utils';

interface QuestionSelectingFilterProps {
  initiativeId: string;
  handleSelect: (data: QuestionData) => void;
  questionData?: QuestionData;
  lockedUnit?: string | null;
  validatingFunc?: ValidatingFunc;
}

// Still show disallowed option but disable it with reason.
const checkIsNumericInput: ValidatingFunc = (question, valueListCode) => {
  if (!valueListCode) {
    const isAllowed = canAddTarget(question);
    return { isAllowed: true, disableReason: isAllowed ? undefined : 'Not supported' };
  }

  const column = question.valueValidation?.table?.columns?.find((c) => c.code === valueListCode);
  if (!column) {
    return { isAllowed: false };
  }

  const isAllowed = isNumericTableColumnType(column.type);
  return { isAllowed: true, disableReason: isAllowed ? undefined : 'Not supported' };
};

export const QuestionSelectingFilter = ({
  initiativeId,
  handleSelect,
  questionData,
  lockedUnit = DEFAULT_METRIC_UNIT,
  validatingFunc = checkIsNumericInput,
}: QuestionSelectingFilterProps) => {
  const { data: blueprintQuestions = [] } = useGetInitiativeBlueprintQuestionsQuery({ initiativeId });
  const { data: usedPacks = { standards: {}, frameworks: {} } } = useGetOrganizationUsedScopesQuery(initiativeId);
  const { data: metricGroups = [] } = useGetOrganizationMetricGroupsQuery(initiativeId);
  const { packOptions, subPacks, filteredQuestions, questionInputs, filters, filterOption, handleChangeFilters } =
    useQuestionFilters({ initiativeId, questionData, blueprintQuestions, usedPacks, metricGroups, validatingFunc });
  const selectedQuestion = blueprintQuestions.find((q) => q.code === filters.question);
  const isSupportedType = selectedQuestion && validatingFunc(selectedQuestion);
  const [isMatchedUnit, setIsMatchedUnit] = useState(true);

  const onChangePack = (option: SingleValue<Option | null>, actionMeta: ActionMeta<Option | null>) => {
    const groupCode = option?.value ?? undefined;
    handleChangeFilters(QuestionFilterType.Pack, actionMeta, groupCode);
    handleSelect({ ...emptyUtrData, groupCode });
    setIsMatchedUnit(true);
  };

  const onChangeSubPack = (option: SingleValue<Option | null>, actionMeta: ActionMeta<Option | null>) => {
    const subGroupCode = option?.value ?? undefined;
    handleChangeFilters(QuestionFilterType.Subpack, actionMeta, subGroupCode);
    handleSelect({ ...emptyUtrData, groupCode: filters.pack, subGroupCode });
    setIsMatchedUnit(true);
  };

  const onChangeQuestion = (option: SingleValue<Option | null>, actionMeta: ActionMeta<Option | null>) => {
    const newQuestion = blueprintQuestions.find((q) => q.code === option?.value);
    // Handle clear
    if (!newQuestion) {
      handleChangeFilters(QuestionFilterType.Pack, actionMeta);
      handleSelect({ ...emptyUtrData });
      setIsMatchedUnit(true);
      return;
    }
    // Handle select
    handleChangeFilters(QuestionFilterType.Question, actionMeta, newQuestion.code);
    const isSimpleType = SIMPLE_TYPES.includes(newQuestion.valueType);

    const isMatched = lockedUnit === DEFAULT_METRIC_UNIT || getMetricUnitDesc(newQuestion) === lockedUnit;

    // non-simple type means matched unit logic will be determined by the valueListCode
    setIsMatchedUnit(!isSimpleType ? true : isMatched);

    if (isSimpleType && option?.value && isMatched) {
      handleSelect({
        ...emptyUtrData,
        code: newQuestion.code,
        groupCode: filters.pack,
        subGroupCode: filters.subPack,
      });
      return;
    }
    handleSelect({ ...emptyUtrData, groupCode: filters.pack, subGroupCode: filters.subPack });
  };

  const onChangeQuestionInput = (option: SingleValue<Option | null>, actionMeta: ActionMeta<Option | null>) => {
    const valueListCode = option?.value ?? undefined;
    handleChangeFilters(QuestionFilterType.Input, actionMeta, valueListCode);
    if (!option) {
      handleSelect({
        valueListCode: '',
      });
      return;
    }
    const newQuestion = blueprintQuestions.find((q) => q.code === filters.question);
    if (!newQuestion) {
      return;
    }

    const isMatched =
      lockedUnit === DEFAULT_METRIC_UNIT || getMetricUnitDesc(newQuestion, valueListCode) === lockedUnit;

    setIsMatchedUnit(isMatched);

    if (selectedQuestion && filters.question && isMatched) {
      return handleSelect({
        valueListCode,
        code: filters.question,
        groupCode: filters.pack,
        subGroupCode: filters.subPack,
      });
    }
    // Reset current selected metric
    return handleSelect({
      valueListCode: '',
    });
  };

  return (
    <>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        placeholder={`Select ${SURVEY.ADJECTIVE} ${PACK.SINGULAR}`}
        options={packOptions}
        value={packOptions.find((v) => v.value === filters.pack) ?? null}
        onChange={onChangePack}
        filterOption={filterOption}
        isClearable
      />
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        isDisabled={subPacks.length === 0}
        className='mt-3'
        placeholder={`Select sub-${PACK.SINGULAR} (optional)`}
        options={subPacks}
        value={subPacks.find((v) => v.value === filters.subPack) ?? null}
        onChange={onChangeSubPack}
        filterOption={filterOption}
        isClearable
      />
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        className='mt-3'
        placeholder={`Select ${QUESTION.SINGULAR}`}
        options={filteredQuestions}
        value={filteredQuestions.find((v) => v.value === filters.question) ?? null}
        onChange={onChangeQuestion}
        filterOption={filterOption}
        isClearable
      />
      <QuestionWarning isMatchedUnit={isMatchedUnit} />
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        isDisabled={questionInputs.length === 0 || !isSupportedType}
        className='mt-3'
        placeholder='Select input'
        options={questionInputs}
        value={questionInputs.find((v) => v.value === filters.input) ?? null}
        onChange={onChangeQuestionInput}
        filterOption={filterOption}
      />
    </>
  );
};
