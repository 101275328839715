import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { SubmissionInsightsContext } from './SubmissionInsightsContainer';
import { getCellUtrv, getDisplayColumn, hasDataShare } from './utils';
import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules/alert';
import { ColumnDef, Table } from '@g17eco/molecules/table';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import Loader from '@components/loader';
import { SubmissionInsightsRowValues } from '@g17eco/types/portfolio';
import NumberFormat from '@utils/number-format';
import { naturalSort } from '@utils/index';

export const SubmissionInsightsTable = () => {
  const { tableData, filters, isLoading, error, companies } = useContext(SubmissionInsightsContext);
  const { portfolioId } = useParams<{portfolioId: string}>();
  const history = useHistory();
  const handleClickCompany = (initiativeId: string) => {
    history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_COMPANY, { portfolioId: portfolioId, companyId: initiativeId }));
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <BasicAlert type={'danger'}>{error}</BasicAlert>;
  }

  const dynamicColumns: ColumnDef<SubmissionInsightsRowValues>[] = tableData.utrs.map((utr) => {
    return {
      id: utr._id,
      header: () => getDisplayColumn({ utr, alternativeCode: filters.scopeGroup?.code }),
      cell: (c) => {
        const utrv = getCellUtrv(c.row.original.utrvs, utr);
        return <NumberFormat value={utrv ? utrv[filters.metricView] : ''} emptyValue='-' />;
      },
      meta: {
        headerProps: {
          className: 'truncateTwoline',
          sortTooltip: `${utr.typeCode}: ${getDisplayColumn({ utr, alternativeCode: filters.scopeGroup?.code })}`
        }
      },
      sortingFn: (a, b) => {
        const valueA = getCellUtrv(a.original.utrvs, utr);
        const valueB = getCellUtrv(b.original.utrvs, utr);
        return naturalSort(
          valueA ? String(valueA[filters.metricView]) : '',
          valueB ? String(valueB[filters.metricView]) : ''
        );
      },
    };
  });

  const stickyColumnStyle = {
    style: {
      left: 0,
      width: 200,
      minWidth: 200,
    },
  }

  const columns: ColumnDef<SubmissionInsightsRowValues>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: () => <span className='text-capitalize'>{filters.view}</span>,
      meta: {
        headerProps: stickyColumnStyle,
        cellProps: stickyColumnStyle,
        sticky: 'left'
      },
      cell: (c) => {
        const { initiativeId, name } = c.row.original;
        return initiativeId ? (
          <Button className='btn btn-link' onClick={() => handleClickCompany(initiativeId)}>
            {name}
          </Button>
        ) : (
          name
        );
      },
      sortingFn: (a, b) => naturalSort(a.original.name, b.original.name),
    },
    ...dynamicColumns,
  ];

  const renderTable = () => {
    switch (true) {
      case companies.length === 0 || !hasDataShare(companies):
        return (
          <BasicAlert type='secondary' className='mt-2'>
            No data from companies with approved data-share available.
          </BasicAlert>
        );
      case dynamicColumns.length === 0:
        return (
          <BasicAlert type='secondary' className='mt-2'>
            This {PACK.SINGULAR} contains no numeric {QUESTION.PLURAL}. Please select a {SURVEY.ADJECTIVE} {PACK.SINGULAR} that contains one or more numeric
            {QUESTION.SINGULAR}.
          </BasicAlert>
        );
      default:
        return (
          <Table columns={columns} data={tableData.utrValues} sticky />
        );
    }
  };

  return (
    <div className='latest-survey-table'>
      <div className='table-wrapper mt-3'>
        {renderTable()}
      </div>
    </div>
  );
};
