import { FeatureCode } from '@g17eco/core';
import classNames from 'classnames';
import './styles.scss';
import { SimpleTooltip } from '../simple-tooltip';

export enum ExtraFeature {
  AutoAggregatedSurvey = 'autoAggregatedSurvey',
  MaterialityAssessmentTool = 'materialityAssessmentTool',
  StaffReports = 'staffReports',
  PreferredStandards = 'preferredStandards',
  CompanySponsorship = 'companySponsorship',
  CustomMetricImports = 'customMetricImports',
  CustomDashboardSidebar = 'customDashboardSidebar',
  PPTXReport = 'pptxReport',
  PartnerIntegration = 'partnerIntegration',
  ExportDashboardPdf = 'exportDashboardPdf',
}

type Feature = FeatureCode | ExtraFeature;
type Stability = 'internal' | 'experimental' | 'beta' | 'on-off';
interface BaseFeatureStabilityProps {
  color?: string;
  className?: string;
}

// Can directly pass in one-off stability or re-usable feature
type RequiredProps = { feature: Feature } | {  stability: Stability }

type FeatureStabilityProps = BaseFeatureStabilityProps & RequiredProps;

const InternalFeature: Feature[] = [
  ExtraFeature.StaffReports,
  ExtraFeature.PreferredStandards,
  ExtraFeature.CompanySponsorship,
  FeatureCode.CustomDashboards,
  ExtraFeature.CustomMetricImports
];
const ExperimentalFeature: Feature[] = [
  ExtraFeature.MaterialityAssessmentTool,
  ExtraFeature.PPTXReport,
  ExtraFeature.PartnerIntegration,
];

export const AIFeature: Feature[] = [
  FeatureCode.MetricAssistantAI,
  FeatureCode.DraftFurtherExplanationAI,
  FeatureCode.SDGInsightAI,
  FeatureCode.PPTXReportAI,
];

const BetaFeature: Feature[] = [
  ExtraFeature.AutoAggregatedSurvey,
  ExtraFeature.CustomDashboardSidebar,
  ExtraFeature.ExportDashboardPdf,
  ...AIFeature,
];

const OnOffFeature: Feature[] = [];


const getType = (props: FeatureStabilityProps): Stability | 'unknown' => {
  if ('feature' in props) {
    if (InternalFeature.includes(props.feature)) {
      return 'internal';
    }
    if (ExperimentalFeature.includes(props.feature)) {
      return 'experimental';
    }
    if (BetaFeature.includes(props.feature)) {
      return 'beta';
    }
    if (OnOffFeature.includes(props.feature)) {
      return 'on-off';
    }
    return 'unknown';
  }
  return props.stability;
}

const renderIcon = (stability: Stability) => {
  switch (stability) {
    case 'internal' :
      return (
        <SimpleTooltip text='Internal Feature'>
          <i className='fa-light fa-user-lock'></i>
        </SimpleTooltip>
      );
    case 'experimental':
      return (
        <SimpleTooltip text='Experimental Feature'>
          <i className='fa-light fa-flask-gear'></i>
        </SimpleTooltip>
      );
    case 'beta':
      return (
        <SimpleTooltip text='In Beta'>
          <i className='fa-light fa-flask'></i>
        </SimpleTooltip>
      );
    case 'on-off':
      return (
        <SimpleTooltip text='On/Off Feature'>
          <i className='fa-light fa-plug-circle-plus'></i>
        </SimpleTooltip>
      );
    default:
      return null;
  }
};

/**
 * Features should be used for functionality that is used multiple times and will
 * move from level to another for example, experimental -> beta -> stable
 */
export const FeatureStability = (props: FeatureStabilityProps) => {

  const { color = 'text-ThemeNeutralsMedium', className = 'ml-2' } = props;

  const stabilityCheck = getType(props);
  if (stabilityCheck === 'unknown') {
    return null
  }

  return <div className={classNames('d-inline-block feature-icon', color === 'inherit' ? '' : color, className)}>{renderIcon(stabilityCheck)}</div>;
};
