import { useHistory, useParams } from 'react-router-dom';
import { DATE, formatDate } from '@utils/date';
import { NavCode, TemplateHistory } from '../survey-templates/types';
import './styles.scss';
import { useBulkSurveysScopeUpdateMutation, useGetHistoryQuery } from '@api/survey-templates-history';
import { TemplateHistoryOptions } from './TemplateHistoryOptions';
import { BulkSurveysModal } from '../survey-templates-notifications/BulkSurveysModal';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import Loader from '../loader';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { JobStatus } from '@g17eco/types/background-jobs';
import { SURVEY } from '@constants/terminology';
import { Table, ColumnDef } from '@g17eco/molecules/table';

const getStatusText = (row: TemplateHistory) => {
  if (row.deletedDate) {
    return 'Deleted';
  }
  if (row.completedDate) {
    return 'Completed';
  }
  return '';
}

export const TemplateHistoryTable = () => {
  const { initiativeId, templateId, historyId } = useParams<{ initiativeId: string, templateId: string, historyId?: string }>();
  const query = useGetHistoryQuery(templateId, { skip: !templateId });
  const history = useHistory();
  const { addSiteAlert } = useSiteAlert();
  const [refreshSurveysScope, { isLoading }] = useBulkSurveysScopeUpdateMutation();

  const handleRefreshSurveysScope = async (refreshHistoryId: string) => {
    try {
      await refreshSurveysScope({ templateId, historyId: refreshHistoryId });
      addSiteAlert({
        content: 'Refresh scope successfully',
        timeout: 5000
      })
    } catch (e) {
      addSiteAlert({
        content: e.message,
        color: SiteAlertColors.Danger
      })
    }
  }

  const columns: ColumnDef<TemplateHistory>[] = [
    {
      header: 'Date used',
      accessorFn: (row) => formatDate(row.created, DATE.DEFAULT_SPACES_WITH_TIME),
    },
    {
      header: 'Last updated',
      accessorFn: (row) => (row.lastUpdated ? formatDate(row.lastUpdated, DATE.DEFAULT_SPACES_WITH_TIME) : ''),
    },
    {
      header: SURVEY.CAPITALIZED_PLURAL,
      accessorFn: (row) => row.results.length,
    },
    {
      header: 'Successes',
      accessorFn: (row) => row.results.filter((result) => result.status === JobStatus.Completed).length,
    },
    {
      header: 'Failures',
      accessorFn: (row) => row.results.filter((result) => result.status === JobStatus.Error).length,
    },
    {
      id: 'settings',
      header: '',
      cell: ({ row }) => (
        <TemplateHistoryOptions
          templateHistory={row.original}
          isLoading={isLoading}
          refreshSurveysScope={handleRefreshSurveysScope}
          disabled={Boolean(row.original.deletedDate)}
        />
      ),
    },
    {
      header: 'Status',
      accessorFn: (row: TemplateHistory) => getStatusText(row),
    },
  ];

  const handleClose = () => {
    query.refetch();

    history.push(
      `${generateUrl(ROUTES.SURVEY_TEMPLATES_VIEW, {
        initiativeId,
        templateId,
        page: NavCode.History,
      })}`
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Table
        className='mt-3'
        sortBy={[{ id: 'lastEdit', desc: false }]}
        responsive={true}
        pageSize={10}
        columns={columns}
        data={query.data ?? []}
      />
      <BulkSurveysModal isOpen={!!historyId} templateId={templateId} historyId={historyId} toggle={handleClose} />
    </>

  );
};
