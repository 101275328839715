import { FormGroup, Input, Label } from 'reactstrap';

interface ToggleProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  preLabel?: string;
  className?: { form?: string; input?: string; label?: string, preLabel?: string };
  disabled?: boolean;
  testId?: string;
}

export const Toggle = (props: ToggleProps) => {

  const { checked, onChange, label, preLabel, className = {}, testId, disabled = false } = props;
  const {
    form: formClassName = 'd-flex align-items-center pl-0',
    input: inputClassName = 'm-0',
    label: labelClassName = 'text-sm ml-1',
    preLabel: preLabelClassName = 'text-sm me-1',
  } = className;
  return (
    <FormGroup switch className={formClassName}>
      {preLabel ? <Label className={preLabelClassName} check>{preLabel}</Label> : null}
      <Input
        data-testid={testId}
        type='switch'
        role='switch'
        checked={checked}
        className={inputClassName}
        onChange={onChange}
        disabled={disabled}
      />
      {label ? (
        <Label className={labelClassName} check>
          {label}
        </Label>
      ) : null}
    </FormGroup>
  );
};
