import { GridDashboardChartItem, InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';
import { MultiUtrsChartWidget } from '../multi-utrs-chart/MultiUtrsChartWidget';
import { getChartPlaceholderData } from './utils';

type ChartData = Pick<GridDashboardChartItem, 'subType' | 'variables' | 'calculation' | 'unitText' | 'note'> & {
  type: string;
};

type Props = {
  item: ChartData;
  actionButton?: JSX.Element | null;
  unsupportedMessage: string;
};

const SUPPORTED_TYPES: string[] = [InsightDashboardItemType.Chart, InsightDashboardItemType.SDGTracker];

export const UnsupportedWidget = (props: Props) => {
  const { item, actionButton, unsupportedMessage } = props;

  const getChartPlaceholder = () => {
    if (!SUPPORTED_TYPES.includes(item.type)) {
      return null;
    }

    const mockData = getChartPlaceholderData(item.subType);
    if (!mockData) {
      return null;
    }

    const mockItem: ChartData = {
      ...item,
      variables: mockData.variables,
      calculation: mockData.calculation,
    };

    const placeholderChartStyles = {
      legend: 'none',
      hAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          color: 'transparent',
        },
      },
      vAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          color: 'transparent',
        },
      },
    };

    return (
      <MultiUtrsChartWidget
        readOnly={true}
        item={mockItem}
        utrsData={mockData.utrsData}
        initiativeId={''}
        altUnitText={''}
        overrideOptions={{
          pie: placeholderChartStyles,
          line: placeholderChartStyles,
          column: placeholderChartStyles,
          stacked: placeholderChartStyles,
          bar: placeholderChartStyles,
        }}
      />
    );
  };

  return (
    <div className='not-chart-data-container position-relative h-100 d-flex justify-content-center align-items-center text-ThemeTextMedium'>
      <div className='not-data-details text-center z-1'>
        <>
          <div className='fs-5 fw-bold'>{unsupportedMessage}</div>
        </>
        {actionButton ? <div className='text-center mt-3'>{actionButton}</div> : null}
      </div>
      <div className='not-data-chart-placeholder w-100 h-100 pe-none'>{getChartPlaceholder()}</div>
    </div>
  );
};
