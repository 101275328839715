import { DocumentSubType } from '@g17eco/types/document';

export const documentOptions: { label: string; value: DocumentSubType }[] = [
  {
    label: 'Spreadsheet',
    value: DocumentSubType.Spreadsheet,
  },
  {
    label: 'Guidelines',
    value: DocumentSubType.Guidelines,
  },
  {
    label: 'Sustainability report',
    value: DocumentSubType.SustainabilityReport,
  },
  {
    label: 'Assurance document',
    value: DocumentSubType.AssuranceDocument,
  },
  {
    label: 'Image',
    value: DocumentSubType.Image,
  },
  {
    label: 'Other',
    value: DocumentSubType.Other,
  },
];
