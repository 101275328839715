import classNames from 'classnames';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Label } from 'reactstrap';
import type { InputType } from 'reactstrap/types/lib/Input';
import { createMetricGroup, updateMetricGroup } from '../../actions/initiative';
import { GroupData } from '../../model/surveyData';
import { useAppSelector } from '../../reducers';
import { CustomMetricRouteParams } from '@features/custom-metrics';
import { isStaff } from '../../selectors/user';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { getAnalytics } from '../../services/analytics/AnalyticsService';
import { MetricGroup } from '../../types/metricGroup';
import { withProfile } from '../../utils/files';
import { ExtendedInputType, FieldProps, FormGenerator } from '../form/FormGenerator';
import Loader from '../loader';
import ProfilePicture, { PreviewFn } from '../profile/ProfilePicture';
import { ViewMode } from './constants';
import { CustomMetricContext } from './CustomMetricContainer';
import { StandardSelector } from './partials/StandardSelector';
import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

interface MetricGroupConfigurationProps {
  metricGroup?: MetricGroup;
  handleReload: () => Promise<void>;
  editUrl: string;
  handleChangeMetricGroup: (params: CustomMetricRouteParams) => void;
  readOnly: boolean;
  allowCancel?: boolean;
}

interface Form {
  _id?: string;
  file?: File;
  groupName: string;
  groupData?: GroupData;
  submitting: boolean;
  errored?: boolean;
  errorMessage: string;
}

const getInitialState: (metricGroup?: MetricGroup) => Form = (metricGroup) => {
  return {
    errorMessage: '',
    file: undefined,
    showForm: false,
    description: metricGroup?.description,
    groupName: metricGroup?.groupName ?? '',
    groupData: metricGroup?.groupData ?? {
      colour: '#adcdf2',
      link: '',
      icon: '',
    },
    submitting: false,
  };
};

const maxFileSize = 200000; // 200kb;

const style = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const metricGroupFields: FieldProps<Form>[] = [
  {
    code: 'groupName',
    type: 'text' as InputType,
    required: true,
    label: `${SURVEY.CAPITALIZED_ADJECTIVE} ${PACK.SINGULAR} name`,
    placeholder: `e.g. Internal custom ${SURVEY.SINGULAR}`,
    testId: 'metric-group-name-input'
  },
  {
    code: 'description',
    type: 'textarea' as InputType,
    required: false,
    label: 'Description',
    placeholder: 'Add description here...',
    testId: 'metric-group-description-input'
  },
  {
    parentCode: 'groupData',
    code: 'colour',
    type: ExtendedInputType['colour-picker'],
    required: false,
    label: `${PACK.CAPITALIZED_SINGULAR} colour`,
  },
];

export const MetricGroupConfiguration = (props: MetricGroupConfigurationProps) => {
  const { metricGroup, handleReload, editUrl, handleChangeMetricGroup, readOnly, allowCancel } = props;
  const [isDuplicateName, setDuplicateName] = useState<Boolean>(false);
  const [formData, setFormData] = useState<Form>(getInitialState(metricGroup));
  const [isSaving, setSaving] = useState(false);
  const [errorMessage, setMessage] = useState('');
  const { initiativeId, metricGroups } = useContext(CustomMetricContext);
  const history = useHistory();
  const isUserStaff = useAppSelector(isStaff);

  const isSubmitDisabled = isSaving || !formData.groupName;
  const isNewGroup = !metricGroup?._id;

  useEffect(() => {
    setFormData(getInitialState(metricGroup));
  }, [metricGroup]);

  const completeUpdate = async (error?: string) => {
    await handleReload();
    setSaving(false);
    if (error) {
      setMessage(error);
    }
    history.push(editUrl);
  };

  const handleCreateOrUpdateMetricGroup = async () => {
    const isNameDuplicate = metricGroups.some(
      (group) => group.groupName === formData.groupName && group._id !== metricGroup?._id
    );
    if (isNameDuplicate) {
      setDuplicateName(true);
      setSaving(false);
    } else {
      setDuplicateName(false);
      setSaving(true);
      try {
        if (metricGroup?._id) {
          await updateMetricGroup(initiativeId, metricGroup._id, withProfile(formData));
          await completeUpdate();
        } else {
          const newGroup = await createMetricGroup(initiativeId, withProfile(formData));
          await completeUpdate();
          const analytics = getAnalytics();
          await analytics.track(AnalyticsEvents.CustomMetricGroupCreated, {
            initiativeId,
            _id: newGroup._id,
          });
          handleChangeMetricGroup({ groupId: newGroup?._id ?? '', view: ViewMode.AssignQuestions });
        }
      } catch (e) {
        await completeUpdate(e.message);
      }
    }
  };

  const onChange = (e: React.ChangeEvent<any>) => {
    const code = e.target.name;
    const value = e.target.value;

    const newFormData: any = {
      ...formData,
      errorMessage: '',
    };

    const field = metricGroupFields.find((field) => field.code === code);
    if (!field) {
      return;
    }
    if (field.parentCode) {
      newFormData[field.parentCode][code] = value;
    } else {
      newFormData[code] = value;
    }
    setFormData(newFormData);
  };

  const renderPreview: PreviewFn = ({ defaultPicture, onClearFiles = () => {}, files, width = '100px' }) => {
    const file = Array.isArray(files) ? files[0] : undefined;

    const onRemove = (e: MouseEvent) => {
      e.stopPropagation();
      onClearFiles();
      setFormData({
        ...formData,
        file: undefined,
        groupData: {
          ...formData.groupData,
          icon: '',
        },
      });
    };
    return (
      <div style={style} className={'preview-container'}>
        <div className={'toggleButton'}>
          {file || defaultPicture ? (
            <>
              <SimpleTooltip delay={{ show: 0, hide: 1000 }} className={'toggle-tooltip'} text={'Remove'}>
                <i onClick={onRemove} className={'remove-icon fa evidence-tick'} />
              </SimpleTooltip>
              <img style={{ width, padding: '0.5rem' }} alt='avatar' src={file?.preview ?? defaultPicture} />
            </>
          ) : (
            <i className={'fa fa-cloud-upload-alt p-2'} />
          )}
        </div>
        <div className='upload-text-container px-3 py-2 ml-2'>
          Upload an image/logo for these {QUESTION.PLURAL} - must be under 200kb. Drag and drop image or
          <Button color={'link'} className={'ml-1'} size={'sm'}>
            select file
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='position-relative'>
        {isSaving && <Loader />}
        <div className={isSaving ? 'isSaving' : ''}>
          {errorMessage && <div className='alert alert-danger'>{errorMessage}</div>}
          <div className='mt-3'>
            {isDuplicateName && <div className='alert alert-danger'>This group name already exist</div>}
            <div className={classNames({ 'pe-none': readOnly })}>
              <FormGenerator fields={metricGroupFields} form={formData} updateForm={onChange} />
            </div>
            {!readOnly ? (
              <div className='logo-container mb-4'>
                <Label>{PACK.CAPITALIZED_SINGULAR} image</Label>
                <ProfilePicture
                  maxSize={maxFileSize}
                  previewRenderer={renderPreview}
                  defaultPicture={formData.groupData?.icon}
                  handleFilesAdded={([file], rejectedFiles) => {
                    const msg =
                      rejectedFiles.length > 0 ? rejectedFiles.map((f) => f.errors[0].message).join('\n') : '';
                    setMessage(msg);
                    setFormData({ ...formData, file });
                  }}
                />
              </div>
            ) : null}
            {isUserStaff ? (
              <StandardSelector
                onChange={(preferredAltCodes) =>
                  setFormData({
                    ...formData,
                    groupData: { ...formData.groupData, preferredAltCodes },
                  })
                }
                standardCodes={formData.groupData?.preferredAltCodes}
                selectProps={{ isDisabled: readOnly }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center mt-3 px-3'>
        <div className='flex-fill d-flex justify-content-end align-items-center'>
          {allowCancel !== false ? (
            <Button color='link' className='mr-3' onClick={() => handleChangeMetricGroup({ groupId: 'dashboard' })}>
              Cancel
            </Button>
          ) : null}
          {!readOnly ? (
            <Button color='primary' disabled={readOnly || isSubmitDisabled} onClick={handleCreateOrUpdateMetricGroup} data-testid='create-metric-group-btn'>
              {!isNewGroup ? 'Save' : `Create metric ${PACK.SINGULAR}`}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};
