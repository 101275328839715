import { CalculationIntegrationUtr, CalculationType, CalculationUtr } from '@g17eco/types/utrv-connections';
import { ConnectionProps, SurveyConnection, UtrvConnection, VariableData } from './types';
import { QUESTION } from '@constants/terminology';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { CSSProperties } from 'react';
import { UniversalTrackerPlain, UtrType } from '@g17eco/types/universalTracker';
import { getGroup } from '@g17eco/core';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { Link } from 'react-router-dom';
import { useToggle } from '@hooks/useToggle';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import { ScopeGroups, SurveyModelMinData } from '@models/surveyData';

type VariablesProps = Pick<ConnectionProps, 'connection' | 'utrs' | 'integrationUtrs' | 'initiativeId' | 'surveyScope'>;
type VariableProps = VariablesProps & {
  variable: VariableData;
};

const isCalculationIntegrationUtr = (
  utr: CalculationUtr | CalculationIntegrationUtr
): utr is CalculationIntegrationUtr => utr.type === UtrType.Generated;

const isSurveyConnection = (connection: UtrvConnection): connection is SurveyConnection =>
  Boolean('surveyId' in connection);

type PackInfoParams = Pick<VariableProps, 'connection' | 'variable'> & {
  variableUtr: CalculationUtr | CalculationIntegrationUtr;
  alternative: string | undefined;
};
type PackInfo =
  | {
      logo: string | undefined;
      shortName: string | undefined;
      colour?: string;
    }
  | undefined;

/**
 *
 * @param surveyScope the scope of the secondary connection utrv survey
 * @param alternatives the alternatives of the variable utr
 * @returns
 */
const getAlternative = ({
  surveyScope,
  alternatives,
}: Pick<UniversalTrackerPlain, 'alternatives'> & { surveyScope: SurveyModelMinData['scope'] }) => {
  if (!surveyScope || !alternatives) {
    return undefined;
  }
  const surveyPacks = [...surveyScope[ScopeGroups.Standards], ...surveyScope[ScopeGroups.Frameworks]];
  const alternativesInSurvey = Object.keys(alternatives).filter((pack) => surveyPacks.includes(pack));
  return alternativesInSurvey.length === 1 ? alternativesInSurvey[0] : undefined;
};

const getPackInfo = ({ variableUtr, connection, variable, alternative }: PackInfoParams): PackInfo => {
  if (connection.group) {
    return {
      logo: connection.group.icon,
      shortName: connection.group.name,
      colour: connection.group.colour,
    };
  }

  if (isCalculationIntegrationUtr(variableUtr)) {
    return variableUtr.provider
      ? { logo: variableUtr.provider.icon ?? variableUtr.provider.logo, shortName: variableUtr.provider.shortName }
      : undefined;
  }
  const groupCode = alternative ? alternative : variable.groupCode ?? variableUtr.type;
  const standard = getGroup('standards', groupCode) || getGroup('frameworks', groupCode);

  return standard ? { logo: standard.src, shortName: standard.shortName } : undefined;
};

const iconStyle: CSSProperties = {
  backgroundColor: '#4E7AE8FF',
  width: '16px',
  minWidth: '16px',
  height: '16px',
  borderRadius: '4px',
};

const RenderGroupImage = ({ packInfo }: { packInfo: PackInfo }) => {
  if (!packInfo) {
    return null;
  }

  const name = <div className='me-auto text-nowrap text-ThemeTextDark'>{packInfo.shortName}</div>;

  if (packInfo.logo) {
    return (
      <>
        <img src={packInfo.logo} alt={packInfo.shortName} height='16px' className='mr-1' />
        {name}
      </>
    );
  }

  if (packInfo.colour) {
    return (
      <>
        <i className='mr-1' style={iconStyle} />
        {name}
      </>
    );
  }

  return name;
};

const getMetricPath = ({
  connection,
  variable,
  initiativeId,
}: Pick<VariableProps, 'connection' | 'variable' | 'initiativeId'>) => {
  if (!isSurveyConnection(connection)) {
    return;
  }

  const surveyPath = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
    initiativeId,
    surveyId: connection.surveyId,
    page: 'question',
  });
  return `${surveyPath}/${variable.utrvId}`;
};

const VariableInfo = ({ connection, variable, utrs, integrationUtrs, initiativeId, surveyScope }: VariableProps) => {
  const variableUtr = (variable.integrationCode ? integrationUtrs : utrs).find((utr) => utr.code === variable.code);
  if (!variableUtr) {
    return null;
  }

  const inputTitle = variableUtr.valueValidation?.table?.columns.find(
    (column) => column.code === variable.valueListCode
  )?.name;

  const metricPath = getMetricPath({ connection, variable, initiativeId });
  const alternative = getAlternative({ surveyScope, alternatives: variableUtr.alternatives });
  const packInfo = getPackInfo({ variableUtr, connection, variable, alternative });
  const metricName = alternative ? variableUtr.alternatives?.[alternative].name : variableUtr.name;

  return (
    <div className='mt-1'>
      <div className='d-flex align-items-center'>
        <RenderGroupImage packInfo={packInfo} />
        <p className='flex-grow-1 text-right my-0 ms-3 text-truncate text-ThemeTextDark text-sm'>
          <SimpleTooltip text={metricName}>
            {metricPath ? <Link to={metricPath}>{metricName}</Link> : metricName}
          </SimpleTooltip>
        </p>
      </div>
      {inputTitle ? (
        <>
          <p className='mb-0 mt-1 text-ThemeTextMedium text-sm text-right'>{inputTitle}</p>
        </>
      ) : null}
    </div>
  );
};

const VariableCollapse = ({
  connection,
  name,
  variable,
  utrs,
  integrationUtrs,
  initiativeId,
}: VariableProps & {
  name: string;
}) => {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <div className='mt-2 d-flex justify-content-end align-items-center cursor-pointer' onClick={toggle}>
        <p className='mb-0 mr-1 text-ThemeTextMedium text-sm'>
          {QUESTION.CAPITALIZED_SINGULAR} {name.toUpperCase()}
        </p>
        <i
          className={classNames('fa-light text-ThemeIconSecondary', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        <VariableInfo
          connection={connection}
          variable={variable}
          utrs={utrs}
          integrationUtrs={integrationUtrs}
          initiativeId={initiativeId}
        />
      </Collapse>
    </>
  );
};

// {a} + {b} -> Metric A + Metric B
const transformFormula = (formula: string) => {
  return formula
    .toUpperCase()
    .replace(/{/g, ` ${QUESTION.CAPITALIZED_SINGULAR} `)
    .replace(/}/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
};

export const Variables = ({ connection, utrs, integrationUtrs, initiativeId, surveyScope}: VariablesProps) => {
  switch (connection.type) {
    case CalculationType.Direct: {
      return (
        <VariableInfo
          connection={connection}
          variable={connection.variables[connection.direct]}
          utrs={utrs}
          integrationUtrs={integrationUtrs}
          initiativeId={initiativeId}
          surveyScope={surveyScope}
        />
      );
    }
    case CalculationType.Formula: {
      const variables = connection.variables;

      return (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='text-sm my-0 mt-2 text-ThemeTextMedium'>Calculation:</label>
            <p className='mb-0 text-ThemeTextDark text-sm'>{transformFormula(connection.formula)}</p>
          </div>
          {Object.keys(variables).map((key) => (
            <VariableCollapse
              key={key}
              name={key}
              variable={variables[key]}
              utrs={utrs}
              connection={connection}
              integrationUtrs={integrationUtrs}
              initiativeId={initiativeId}
            />
          ))}
        </>
      );
    }
    case CalculationType.Stages:
    default: {
      return <div>Not support yet</div>;
    }
  }
};
