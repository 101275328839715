import { DateRangeType } from '@g17eco/types/common';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { Button } from 'reactstrap';

const DEFAULT_DATERANGE = {
  selection: {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
};

export const getDateRange = (months: number | string) => {
  if (typeof months !== 'number') {
    return {};
  }
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - months);
  return { startDate, endDate: new Date() };
};

type DateRangePickerType = {
  dateRange: DateRangeType;
  callbackClose: () => void;
  callbackSubmit: (dateRange: DateRangeType) => void;
  className?: string;
};

export function DateRangePicker(props: DateRangePickerType) {
  const { dateRange, callbackClose, callbackSubmit, className = 'end-0' } = props;
  const initialState = dateRange.endDate ? { selection: {...dateRange, key: 'selection'} } : DEFAULT_DATERANGE;
  const [state, setState] = useState(initialState);

  const handleClose = () => {
    setState(DEFAULT_DATERANGE);
    callbackClose();
  };

  return (
    <div className={`d-flex flex-column p-2 calendar-wrapper ${className}`}>
      <DateRange
        onChange={(item) => setState({ ...state, ...item })}
        scroll={{ enabled: true }}
        ranges={[state.selection]}
        editableDateInputs={true}
      />
      <div className='btn-wrapper pr-2 pt-2'>
        <Button size='sm' color='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button size='sm' onClick={() => callbackSubmit(state.selection)}>
          Apply
        </Button>
      </div>
    </div>
  );
}
