import { Link, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { SEO } from '../../apps/common/components/SEO';
import Logo from '../../images/logo.svg';
import Footer from '@components/footer';
import { gql, useQuery } from '@apollo/client';
import './help.scss';
import GraphQlClient from '@services/graphql/GraphQlClient';
import { BasicAlert } from '@g17eco/molecules/alert';
import { LoadingPlaceholder } from '@g17eco/molecules/loader-container';


export interface AgreementCms {
  code: string;
  content: {
    html: string | null;
  };
}

export const ClientAgreementQuery = gql`
  query ($code: String!) {
    clientAgreement (where: { code: $code } , stage: PUBLISHED) {
      code
      content {
        html
      }
    }
  }
`;

export const CustomAgreementRoute = () => {

  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const { data, loading, error } = useQuery<{ clientAgreement: AgreementCms }>(ClientAgreementQuery, {
    variables: { code },
    client: GraphQlClient,
  });

  const returnUrl = String(params.returnUrl);
  const url = returnUrl && returnUrl.startsWith('/') ? returnUrl : '/';
  const breadcrumbTitle = params.breadcrumbTitle ? String(params.breadcrumbTitle).replace(/[^a-zA-Z0-9\s]/g, '-') : 'Go back to home page';

  const BodyView = () => {
    if (error) {
      return <BasicAlert className={'mt-3'} type={'danger'}>There was an error loading the content</BasicAlert>;
    }
    if (!data?.clientAgreement?.content?.html) {
      return <BasicAlert className={'mt-3'} type={'danger'}>No content is available</BasicAlert>;
    }
    return <div className='solution-text' dangerouslySetInnerHTML={{ __html: data.clientAgreement.content.html }} />
  }

  return (
    <div className='legalWrapper'>
      <SEO />
      <div className='legalContainer'>
        <header>
          <img className='logo' src={Logo} alt='G17Eco' />
        </header>

        <div className='legalBodyContainer'>
          <div><Link to={url}><i className='fa fa-home mr-2' />{breadcrumbTitle}</Link></div>
          <div className='legal pt-2'>
            <LoadingPlaceholder height={300} isLoading={loading}>
              <BodyView />
            </LoadingPlaceholder>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

