import { useContext, useState } from 'react';
import { CustomDashboardContext } from '../../../routes/custom-dashboard/context/CustomDashboardWrapper';
import { MetricSelectorProps, HistoricalUtrsState, PreviewChartData } from '../types';
import { MultipleMetricsSelector } from './MultipleMetricsSelector';
import { PreviewModal } from './PreviewModal';
import { useToggle } from '../../../hooks/useToggle';
import { HistoricalUtrs } from '../../../api/insights';
import { SingleMetricSelector } from './SingleMetricSelector';
import { doSupportMultiple } from '../utils/dashboard-utils';
import { QUESTION } from '@constants/terminology';
import { ChartData, isDisaggregatedRendering } from '@routes/custom-dashboard/utils';
import { InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';

const initialState = {
  historicalUtrs: [],
  isLoading: false,
  errorMessage: '',
};

const HelpText = ({ isMultiple }: { isMultiple: boolean }) => {
  const text = isMultiple
    ? `Targets and baselines do not appear when tracking multiple ${QUESTION.PLURAL}.`
    : `The selected chart type only tracks one ${QUESTION.SINGULAR}`;

  return <p className='text-ThemeTextMedium text-xs mb-0'>{text}</p>;
};

function isPreviewChartData(chartData: ChartData): chartData is PreviewChartData {
  const { type, subType, metrics } = chartData;
  return Boolean(type && subType && metrics?.every(({ code }) => code));
}

export const MetricsSelector = (props: MetricSelectorProps) => {
  const { initiativeId, chartData, queryParams, survey, questionsMap, integrationsData } = props;
  const [isPreviewing, togglePreviewModal] = useToggle(false);
  const [historicalUtrsState, setHistoricalUtrsState] = useState<HistoricalUtrsState>(initialState);

  const { getHistoricalUtrsByCodes } = useContext(CustomDashboardContext);

  const isMultiple = doSupportMultiple(chartData.subType);
  const helpText = <HelpText isMultiple={isMultiple} />;
  const isDisaggregated = isDisaggregatedRendering({ initiativeIds: queryParams.initiativeIds });

  if (!chartData.type) {
    return <div className='mt-1'>{helpText}</div>;
  }

  const onTogglePreviewModal = () => {
    // handle integration
    if (!isPreviewing && isPreviewChartData(chartData)) {
      const utrCodes = chartData.metrics.map(({ code }) => code);
      setHistoricalUtrsState((prev) => ({ ...prev, isLoading: true }));
      if (chartData.type === InsightDashboardItemType.Integration) {
        setHistoricalUtrsState((prev) => ({
          ...prev,
          isLoading: false,
          historicalUtrs: integrationsData?.utrsData || [],
        }));
        togglePreviewModal();
        return;
      }
      getHistoricalUtrsByCodes({ initiativeId, utrCodes, queryParams })
        .then((result: HistoricalUtrs[]) => {
          setHistoricalUtrsState((prev) => ({ ...prev, isLoading: false, historicalUtrs: result }));
        })
        .catch((e) => {
          setHistoricalUtrsState((prev) => ({ ...prev, isLoading: false, errorMessage: e.message }));
        });
    } else {
      setHistoricalUtrsState(initialState);
    }
    togglePreviewModal();
  };

  const getSelector = () => {
    return isMultiple ? (
      <MultipleMetricsSelector
        {...props}
        togglePreviewModal={onTogglePreviewModal}
        questionsMap={questionsMap}
        helpText={helpText}
        isDisaggregated={isDisaggregated}
      />
    ) : (
      <SingleMetricSelector {...props} togglePreviewModal={onTogglePreviewModal} helpText={helpText} />
    );
  };

  return (
    <>
      {getSelector()}
      {isPreviewChartData(chartData) ? (
        <PreviewModal
          initiativeId={initiativeId}
          isDisaggregated={isDisaggregated}
          isOpen={isPreviewing}
          toggleOpen={togglePreviewModal}
          chartData={chartData}
          historicalUtrsState={historicalUtrsState}
          survey={survey}
        />
      ) : null}
    </>
  );
};
