import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export interface UserDropdownItem {
  label: string;
  clickHandler: () => void;
  disabledMessage?: string;
}

interface AdminUserDropdownProps {
  isOpen: boolean;
  toggle: () => void;
  dropdownItems: UserDropdownItem[];
}

export const AdminUserDropdown = ({ isOpen, toggle, dropdownItems }: AdminUserDropdownProps) => {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color='transparent' outline className='px-2'>
        <i className='fas fa-bars' />
      </DropdownToggle>
      <DropdownMenu>
        {dropdownItems.map((dropdownItem) => (
          <SimpleTooltip key={dropdownItem.label} text={dropdownItem.disabledMessage}>
            <DropdownItem
              onClick={dropdownItem.clickHandler}
              disabled={!!dropdownItem.disabledMessage}
            >
              {dropdownItem.label}
            </DropdownItem>
          </SimpleTooltip>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
