import WorkInProgressGif from '@g17eco/images/ai-reports/workingonit-transparent.gif';

export const WorkInProgress = ({ title }: { title: string | JSX.Element }) => {
  return (
    <div className='d-flex flex-column align-items-center w-100 mt-3'>
      {typeof title === 'string' ? <div className='text-center text-ThemeTextMedium'>{title}</div> : title}
      <img src={WorkInProgressGif} width={300} className='my-2' />
    </div>
  );
};
