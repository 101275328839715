import { SURVEY } from '@constants/terminology';
import { BulkSurveysResult } from '../survey-templates/types';
import { Table } from '@g17eco/molecules/table';
import './styles.scss';

interface BulkSurveysResultTableProps {
  erroredSurveys: BulkSurveysResult[];
}

export const BulkSurveysResultTable = (props: BulkSurveysResultTableProps) => {
  const { erroredSurveys } = props;

  return (
    <Table
      className='surveys-result__table'
      data={erroredSurveys}
      showHeader={false}
      columns={[
        {
          header: 'name',
          cell: ({ row }) => {
            const result = row.original;
            return (
              <>
                <i className='fa-solid fa-triangle-exclamation mr-2' />
                {result.initiative?.name ?? result.initiativeId}
              </>
            );
          },
        },
        {
          header: 'desc',
          cell: ({ row }) => {
            const result = row.original;
            return (
              <div className='text-ThemeDangerMedium'>
                {result.details?.message ?? `There was a problem creating ${SURVEY.SINGULAR}`}
              </div>
            );
          },
        },
      ]}
    />
  );
};
