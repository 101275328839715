export interface UserProfileForm {
  firstName: string;
  surname: string;
  telephoneNumber: string;
  jobTitle: string;
  title: string;
  oldPassword?: string;
  password?: string;
  rPassword?: string;
}

export enum UserFeatureCode {
  UserApiKey = 'user_api_key',
}

interface UserFeature {
  code: UserFeatureCode;
  name: string;
  description?: string;
  enabled: boolean;
}

interface ActiveFeature {
  code: UserFeatureCode;
  created: string;
}

interface UserPreferencesPlain {
  _id: string;
  userId: string;
  created: string;
  featureFlags: ActiveFeature[];
}

type EmptyUserPreferences = { userId: string };
export type UserPreferencesResponse = UserPreferencesPlain | EmptyUserPreferences
