import { ReportData } from '@g17eco/types/reportData';

export interface AntiCorruptionTraining {
  antiTraningPercentage: number;
}

export const getAntiCorruptionTraining = (questionData: ReportData[]): AntiCorruptionTraining => {
  const utrv = questionData.find(d => d.universalTracker.code === 'gri/2020/205-2/d');
  const data = utrv?.valueData?.table ?? [];

  const totalMembers = data.reduce((a, c) => {
    const column = c.find(col => col.code === 'board_members_region');
    if (!column || Number.isNaN(column.value)) {
      return a;
    }
    a += Number(column.value);
    return a;
  }, 0);

  const totalAntiCorruptionMembers = data.reduce((a, c) => {
    const column = c.find(col => col.code === 'anti_corruption_training_number');
    if (!column || Number.isNaN(column.value)) {
      return a;
    }
    a += Number(column.value);
    return a;
  }, 0);

  const antiTraningPercentage = totalMembers === 0 ? 0 : 100 * totalAntiCorruptionMembers / totalMembers;

  return {
    antiTraningPercentage
  };
}
