import { FileDropzoneVariant, ImportFileDropzone } from '@components/files/ImportFileDropZone';
import { ProgressLoader } from '@g17eco/atoms';

interface DocumentUploadProps {
  isUploading: boolean;
  handleFilesAdded: (files: File[]) => void;
}
export const DocumentUpload = ({ isUploading, handleFilesAdded }: DocumentUploadProps) => {
  return (
    <>
      {isUploading ? (
        <div className='text-center'>
          <ProgressLoader />
          <span>...uploading...</span>
        </div>
      ) : (
        <ImportFileDropzone
          variant={FileDropzoneVariant.Icon}
          importFile={undefined}
          accept={{ '*': [] }}
          onFilesAdded={handleFilesAdded}
          multiple={true}
        />
      )}
    </>
  );
};
