import { useMemo } from 'react';
import UniversalTracker from '../model/UniversalTracker';
import { QuestionList, ScopeQuestionGroup } from '../types/survey';
import { ScopeQuestion } from '../types/surveyScope';
import { useAppSettings } from './app/useAppSettings';
import { getLatestAlternativeCode } from '../components/survey/question/questionUtil';

export interface QuestionListItem {
  utrvId: string | undefined,
  utrId: string,
  groupCode?: string
  preferredAltCodes?: string[];
}

export const getAltCode = (currentQuestion: QuestionListItem | undefined, utr: UniversalTracker | undefined): string => {

  if (!currentQuestion || !utr) {
    return '';
  }

  // If we have preferred, used it in the order.
  if (Array.isArray(currentQuestion.preferredAltCodes)) {
    for (const code of currentQuestion.preferredAltCodes) {
      if (utr.hasAlternativeInfo(code)) {
        return code;
      }
    }
  }

  if (!utr.hasAlternativeInfo(currentQuestion.groupCode)) {
    // if it's root, use it otherwise use the latest alternative
    return utr.getType() === currentQuestion.groupCode ? utr.getType() : getLatestAlternativeCode({ utr });
  }
  return currentQuestion.groupCode ?? '';
};

const populateLists = (
  group: ScopeQuestionGroup | QuestionList,
  groupCode: string| undefined,
  disabledIds: string[],
  listItems: QuestionListItem[],
  questionListIds: string[]
) => {
  group.list.forEach((l) => {

    const q = {
      utrvId: l.utrv._id,
      utrId: l.universalTracker.getId(),
      groupCode,
      preferredAltCodes: 'groupData' in group ? group.groupData?.preferredAltCodes : undefined,
    };

    if (!disabledIds.includes(q.utrId)) {
      listItems.push(q);
      if (q.utrvId) {
        questionListIds.push(q.utrvId)
      }
    }

    return q;
  });
  return group.list.length;
};

function generateLists(surveyGroups: ScopeQuestionGroup[], disabledIds: string[]) {
  const questionList: QuestionListItem[] = [];
  const questionListIds: string[] = [];
  let totalQuestions = 0;

  surveyGroups.forEach((group) => {
    if (group.subGroups) {
      group.subGroups.forEach((subGroup) => {
        totalQuestions += populateLists(subGroup, group.groupCode, disabledIds, questionList, questionListIds);
      });
    } else {
      totalQuestions += populateLists(group, group.groupCode, disabledIds, questionList, questionListIds);
    }
  });
  return { questionList, questionListIds, totalQuestions };
}

export const useQuestionIds = (
  surveyGroups: ScopeQuestionGroup[],
  index: string | undefined,
  questionId: string,
  disabledIds: string[] = []
) => {
  const { questionList, questionListIds } = useMemo(() => {
    return generateLists(surveyGroups, disabledIds)
  }, [disabledIds, surveyGroups]);

  const { settingsRecommendedAddons: defaultAlternativeCodes } = useAppSettings();

  const utrMap = useMemo(() => {
    return surveyGroups.reduce((a, g) => {
      g.list.forEach((q) => {
        if (q.utrv) {
          a[q.utrv._id] = q;
        }
      });
      return a;
    }, {} as Record<string, ScopeQuestion>);
  }, [surveyGroups]);

  const question = utrMap[questionId];
  const utr = question?.universalTracker;
  const utrv = question?.utrv;

  const currentQuestionIndex = useMemo(() => {
    if (index !== undefined) {
      return index;
    }
    if (defaultAlternativeCodes.length === 0) {
      return;
    }

    for (const code of defaultAlternativeCodes) {
      const found = questionList.findIndex((q) => q.utrvId === utrv?._id && q.groupCode === code);
      if (found >= 0) {
        return found;
      }
    }
  }, [index, defaultAlternativeCodes, questionList, utrv?._id]);

  const currentQuestion = questionList[currentQuestionIndex as number];

  const altCode = getAltCode(currentQuestion, utr);

  return { questionListIds, utr, utrv, altCode, currentQuestionIndex: String(currentQuestionIndex ?? '') };
};
