import {
  GridDashboardChartItem,
  GridDashboardItem,
  GridDashboardSDGChartItem,
} from '@g17eco/types/insight-custom-dashboard';
import { getPeriodName } from '@utils/universalTracker';
import { displayTimeRange } from '@utils/date';
import { isChartType, isSDGTrackerType } from '@routes/custom-dashboard/utils';
import { MultiUtrsChartWidget } from '../multi-utrs-chart/MultiUtrsChartWidget';
import { DataFilters } from '@routes/custom-dashboard/types';
import { getSurveyTypeOptions } from '@utils/survey';
import './styles.scss';
import { getChartPlaceholderData } from './utils';

type Props = DataFilters & {
  item: GridDashboardItem;
  questionReference?: JSX.Element | null;
  actionButton?: JSX.Element | null;
};

export const NoData = (props: Props) => {
  const { item, period, surveyType, timeRange, dateRange, questionReference = null, actionButton } = props;

  const displayDataFilter = () => {
    const displayFilters = [];
    if (period) {
      displayFilters.push(getPeriodName(period, false));
    }
    if (surveyType) {
      displayFilters.push(getSurveyTypeOptions().find(o => o.value === surveyType)?.label);
    }
    if (timeRange) {
      displayFilters.push(displayTimeRange({ timeRange, dateRange }));
    }
    return displayFilters.join(' | ');
  };

  const isChart = isChartType(item);
  const isSDGTracker = isSDGTrackerType(item);

  const getChartPlaceholder = () => {
    if (!isChart && !isSDGTracker) {
      return null;
    }

    const mockData = getChartPlaceholderData(item.subType);
    if (!mockData) {
      return null;
    }

    const mockItems: GridDashboardChartItem | GridDashboardSDGChartItem = {
      ...item,
      variables: mockData.variables,
      calculation: mockData.calculation,
    };

    const placeholderChartStyles = {
      legend: 'none',
      hAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          color: 'transparent'
        }
      },
      vAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          color: 'transparent'
        }
      },
    };

    return (
      <MultiUtrsChartWidget
        readOnly={true}
        item={mockItems}
        utrsData={mockData.utrsData}
        initiativeId={''}
        altUnitText={isSDGTracker ? 'contribution' : ''}
        overrideOptions={{
          pie: placeholderChartStyles,
          line: placeholderChartStyles,
          column: placeholderChartStyles,
          stacked: placeholderChartStyles,
          bar: placeholderChartStyles,
        }}
      />
    );
  };

  return (
    <div className='not-chart-data-container position-relative h-100 d-flex justify-content-center align-items-center text-ThemeTextMedium'>
      <div className='not-data-details text-center z-1'>
        {questionReference ? (
          <>
            <div className='fs-5 fw-bold'>To see data, answer:</div>
            {questionReference}
          </>
        ) : (
          <>
            <div className='fs-5 fw-bold'>No data available in the specified period</div>
            <div className='fs-6 fw-medium'>{displayDataFilter()}</div>
          </>
        )}
        {actionButton ? <div className='text-right'>{actionButton}</div> : null}
      </div>
      <div className='not-data-chart-placeholder w-100 h-100 pe-none'>{getChartPlaceholder()}</div>
    </div>
  );
};
