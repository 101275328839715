import './style.scss';
import { Navigation, NavTab } from '@components/company-settings/Navigation';
import { DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { useTabs } from '@hooks/useTabs';
import { Configuration } from './Configuration';
import { useGetWorkgroupQuery } from '@api/workgroups';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Loader from '@components/loader';
import { ManageUsers } from './ManageUsers';

enum Tabs {
  Configuration = 'configuration',
  Users = 'users',
}

const getTabs = (isCreating: boolean): NavTab[] => [
  {
    code: Tabs.Configuration,
    label: 'Configuration',
  },
  {
    code: Tabs.Users,
    label: 'Manage workgroup users',
    disabled: isCreating,
  },
];

interface Props {
  workgroupId?: string;
  initiativeId: string;
}

export const CreateOrEditWorkgroup = ({ workgroupId, initiativeId }: Props) => {
  const { currentTab, handleChangeTab } = useTabs({ initialTab: Tabs.Configuration });

  const { data: workgroup, isFetching } = useGetWorkgroupQuery(
    workgroupId
      ? {
          initiativeId,
          workgroupId,
        }
      : skipToken
  );

  return (
    <>
      <DashboardSectionTitle title={`${workgroupId ? 'Edit' : 'Create'} workgroup`} />
      <DashboardSection>
        <Navigation navTabs={getTabs(!workgroupId)} page={currentTab} onClick={(tab) => handleChangeTab(tab as Tabs)} />
        {isFetching ? <Loader /> : null}
        {currentTab === Tabs.Configuration ? <Configuration key={workgroup?._id} workgroup={workgroup} initiativeId={initiativeId} /> : null}
        {currentTab === Tabs.Users && workgroup ? (
          <ManageUsers key={workgroup._id} workgroup={workgroup} initiativeId={initiativeId} />
        ) : null}
      </DashboardSection>
    </>
  );
};
