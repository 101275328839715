import React from 'react';
import Chart from 'react-google-charts';
import {
  CHART_DEFAULTS,
  GenerateChartsProps,
  chartReadyEvent,
  getReportEffectiveDate,
  DataSource,
} from './GenerateCharts';
import { getUnitText } from '@utils/units';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { getDataAsNumberTableSum } from '@routes/summary/insights/utils/helpers';

const WIDTH = 400;
const HEIGHT = 300;

export interface WasteGeneratedDataSource extends DataSource {
  hazardousWaste?: number;
  nonHazardousWaste?: number;
}

export const WasteGenerated = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/306-3/a';
  const utrv = questionData.find((d) => d.universalTracker.code === utrCode);

  const column = utrv?.universalTracker.valueValidation?.table?.columns?.find((c) => c.code === 'hazardous_waste');
  const unit = getUnitText({ valueType: UtrValueType.Number, unit: column?.unit, unitType: column?.unitType });

  const hazardousWaste = getDataAsNumberTableSum(questionData, utrCode, 'hazardous_waste');
  const nonHazardousWaste = getDataAsNumberTableSum(questionData, utrCode, 'non_hazardous');

  const reportData: WasteGeneratedDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    hazardousWaste,
    nonHazardousWaste,
  };

  const chartData = [
    ['Hazardous and non-hazardous', 'Hazardous', { role: 'annotation' }, 'Non-hazardous', { role: 'annotation' }],
    [
      getReportEffectiveDate(questionData, utrCode),
      hazardousWaste,
      hazardousWaste,
      nonHazardousWaste,
      nonHazardousWaste,
    ],
  ];

  return (
    <Chart
      chartType='ColumnChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Hazardous and non-hazardous.',
        chartArea: {
          top: 50,
          bottom: 50,
          left: 80,
          right: 20,
        },
        vAxis: {
          title: unit,
          format: 'decimal',
          gridlines: { count: 4 },
          minValue: 0,
        },
        bars: 'vertical',
        legend: {
          position: 'bottom',
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'wasteGenerated', data: reportData, handler: updateData })]}
    />
  );
});
