import { DocumentItem, DocumentSubType } from '@g17eco/types/document';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { formDataHeaders } from '@services/G17Client';

enum Tag {
  Documents = 'documents',
  Document = 'document',
}

const getUrl = (initiativeId: string) => `/initiatives/${initiativeId}/documents`;

export const DocumentsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(Tag),
  })
  .injectEndpoints({
    endpoints: (build) => ({
      uploadDocuments: build.mutation<
        { fulfilled: DocumentItem[]; rejected: any[] },
        { initiativeId: string; data: { files: File[] } }
      >({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.Documents, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, data }) => ({
          url: getUrl(initiativeId),
          headers: formDataHeaders,
          method: 'post',
          data: data,
        }),
      }),
      getDocuments: build.query<DocumentItem[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl(initiativeId),
          method: 'get',
        }),
        providesTags: (_result, _error, initiativeId) => {
          return [{ type: Tag.Documents, id: initiativeId }];
        },
      }),
      bulkUpdateDocument: build.mutation<
        {
          acknowledged: Boolean;
          matchedCount: number;
          modifiedCount: number;
          upsertedCount: number;
          upsertedIds: string[];
        },
        { initiativeId: string; data: { documentIds: string[]; title?: string; ownerSubType?: DocumentSubType } }
      >({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.Documents, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, data }) => ({
          url: getUrl(initiativeId),
          method: 'patch',
          data,
        }),
      }),
      updateDocument: build.mutation<
        DocumentItem,
        { initiativeId: string; documentId: string; data: { title?: string; ownerSubType?: DocumentSubType } }
      >({
        invalidatesTags: (_result, _error, { initiativeId, documentId }) => [
          { type: Tag.Documents, id: initiativeId },
          { type: Tag.Document, id: documentId },
        ],
        transformResponse,
        query: ({ initiativeId, documentId, data }) => ({
          url: `${getUrl(initiativeId)}/${documentId}`,
          method: 'patch',
          data,
        }),
      }),
      deleteDocument: build.mutation<DocumentItem, { initiativeId: string; documentId: string }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.Documents, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, documentId }) => ({
          url: `${getUrl(initiativeId)}/${documentId}`,
          method: 'delete',
        }),
      }),
    }),
  });

export const {
  useGetDocumentsQuery,
  useBulkUpdateDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
  useUploadDocumentsMutation,
} = DocumentsApi;
