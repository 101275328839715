import { UtrvStatus } from '../../constants/status';
import { ValueHistory } from '../../types/universalTrackerValue';
import { nl2br } from '../../utils';
import { getIconByStatus } from '../../utils/universalTrackerValue';
import { ReadonlyRichTextEditor, useGuardEditorState } from '@features/rich-text-editor';

const getActionDetails = ({ action }: { action: ValueHistory['action'] }) => {
  if (UtrvStatus.Updated === action) {
    return {
      title: 'Contributor Further explanation',
    };
  }

  if (UtrvStatus.Verified === action) {
    return {
      title: 'Verifier explanation - Approved',
    };
  }

  if (UtrvStatus.Rejected === action) {
    return {
      title: 'Verifier explanation - Rejected',
    };
  }

  return {
    title: 'Further explanation / notes',
  };
};

export const Comment = ({
  history,
  handleComments,
  canEdit,
  canUseRichTextEditor,
}: {
  history: Pick<ValueHistory, 'action' | 'note' | 'editorState'>;
  handleComments: (history: Pick<ValueHistory, 'note' | 'editorState'>) => void;
  canEdit: boolean;
  canUseRichTextEditor: boolean;
}) => {
  const { title } = getActionDetails(history);
  const iconStatus = getIconByStatus(history.action);
  const { guardEditorState } = useGuardEditorState(history.editorState);

  return (
    <div className={'comment-container'} key={history.action}>
      <div className={`comment-title-container row g-0 mt-3 pt-4 pb-3 ${history.action}`}>
        <div className={'status-icon'}>
          <i className={`fas mr-1 ${iconStatus}`} /> {title}
        </div>
        {canEdit ? (
          <div className={'comment-edit-button'}>
            <i className={'fas fa-edit'} onClick={() => handleComments(history)} />
          </div>
        ) : null}
      </div>

      {canUseRichTextEditor && history.editorState ? (
        <ReadonlyRichTextEditor editorState={guardEditorState} />
      ) : (
        <p className='dont_translate'>{nl2br(history.note ?? null)}</p>
      )}
    </div>
  );
};
