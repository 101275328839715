import { QUESTION } from '@constants/terminology';
import { getGroup } from '@g17eco/core';
import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { capitalise } from '@utils/index';
import { classifyMetricGroups } from '../utils';
import SDGIcon from '@components/sdg-icon/sdg-icon';
import { generateUrl } from '@routes/util';
import { MetricGroup } from '@g17eco/types/metricGroup';
import { RouteInterface } from '@g17eco/types/routes';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

const getValueTypeInfo = (valueType: string) => {
  if (valueType === 'valueList') {
    return 'Multiple Choice';
  }

  return capitalise(valueType);
};

const getAlternativeInfo = (id: string, alternatives: { [key: string]: { name: string } }) => {
  const alternativesInfo = [];

  for (const [key, value] of Object.entries(alternatives)) {
    const group = getGroup('standards', key);
    if (group) {
      alternativesInfo.push(`${group.name} - ${value.name}`);
    }
  }
  return alternativesInfo.map((altInfo, idx) => <div key={`alt-info-${id}-${idx}`}>{altInfo}</div>);
};

const getMetricGroupsInfo = (params: {
  metricGroups: MetricGroup[];
  utrId: string;
  baseRoute: RouteInterface;
  initiativeId: string;
}) => {
  const { metricGroups, utrId, baseRoute, initiativeId } = params;

  const renderedGroups = metricGroups.reduce((acc, group) => {
    if (group.universalTrackers?.includes(utrId)) {
      if (acc.length > 0) {
        acc.push(<span key={`${group._id}-separator`}> | </span>);
      }
      acc.push(
        <a
          href={generateUrl(baseRoute, { groupId: group._id, initiativeId, portfolioId: initiativeId })}
          target='_blank'
          key={group._id}
        >
          {group.groupName}
        </a>
      );
    }
    return acc;
  }, [] as JSX.Element[]);

  return renderedGroups;
};

const rowInfo = (title: string, data: any) => {
  if (!data) {
    return <></>;
  }
  return (
    <div className='mt-2'>
      <div className='strong text-ThemeAccentExtradark'>
        <i className={'fa fa-arrow-circle-right mr-2'} />
        {title}
      </div>
      <div className='d-flex text-ThemeTextPlaceholder'>
        <div>
          <i className={'fa fa-blank mr-2'} />
        </div>
        <div>{data}</div>
      </div>
    </div>
  );
};

interface Props {
  utr: UniversalTrackerPlain;
  metricGroups?: MetricGroup[];
  baseRoute?: RouteInterface;
  initiativeId?: string;
}

export const MetricDetail = ({ utr, metricGroups = [], baseRoute, initiativeId }: Props) => {
  const renderMetricGroupsInfo = () => {
    if (!metricGroups.length || !baseRoute || !initiativeId) {
      return null;
    }
    const { customGroups } = classifyMetricGroups(metricGroups);
    const hasCustomGroup = customGroups.some((group) => group.universalTrackers?.includes(utr._id));
    return rowInfo(
      'Assigned modules',
      hasCustomGroup
        ? getMetricGroupsInfo({ metricGroups: customGroups, utrId: utr._id, baseRoute, initiativeId })
        : 'Unassigned'
    );
  };

  const sdgs = utr.tags?.sdg?.map((c: string) => {
    const key = `metric-table-sdg-icon-${utr._id}-${c}`;
    return (
      <SimpleTooltip key={key} text={`SDG ${c}`}>
        <SDGIcon height='30' className='mr-2' code={c} />
      </SimpleTooltip>
    );
  });

  return (
    <>
      {rowInfo(QUESTION.CAPITALIZED_SINGULAR, utr.valueLabel)}
      {rowInfo('Instructions', utr.instructions)}
      {rowInfo('Value Type', getValueTypeInfo(utr.valueType))}
      {renderMetricGroupsInfo()}
      {rowInfo('Metric Unit', capitalise(utr.unitType ?? ''))}
      {rowInfo('Related SDGs', utr?.tags?.sdg && utr?.tags?.sdg.length > 0 ? sdgs : undefined)}
      {utr.alternatives ? rowInfo('Standards', getAlternativeInfo(utr._id, utr.alternatives)) : null}
    </>
  );
};
