import React from 'react';
import { Chart } from 'react-google-charts';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, DataSource } from './GenerateCharts';
import { getDataAsPercentageTableSum } from '@routes/summary/insights/utils/helpers';

const WIDTH = 300;
const HEIGHT = 300;

export interface GenderSplitPcDataSource extends DataSource {
  femaleEmployeesPc?: number;
  maleEmployeesPc?: number;
  genderRatio?: number;
}

export const GenderSplitPc = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/405-1/b';
  const male = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'employees_per_category',
    percentageColumn: 'male',
  });
  const female = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'employees_per_category',
    percentageColumn: 'female',
  });

  const total = male + female;

  const reportData: GenderSplitPcDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    femaleEmployeesPc: !total ? 0 : 100 * female / total,
    maleEmployeesPc: !total ? 0 : 100 * male / total,
    // Ratio of women to men
    genderRatio: !male ? female : female / male,
  };

  const chartData = [
    ['Gender', 'Count'],
    ['Female', female],
    ['Male', male],
  ];

  return (
    <Chart
      chartType='PieChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Percentage',
        chartArea: {
          top: 50,
          bottom: 20,
          left: 20,
          right: 20,
        },
        legend: {
          position: 'top',
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'genderSplitPc', data: reportData, handler: updateData })]}
    />
  );
});
