import { useState } from 'react';
import Dashboard, { DashboardSection, DashboardSectionTitle } from '../../components/dashboard';
import { GridDashboardItem, InsightDashboard, UtrvFilter } from '../../types/insight-custom-dashboard';
import { CompanyInsightsSidebar } from '../summary/insights/partials/sidebar/CompanyInsightsSidebar';
import { CustomDashboard } from './CustomDashboard';
import { CustomDashboardEditing } from './CustomDashboardEditing';
import { useHistory, useParams } from 'react-router-dom';
import {
  useDeleteInsightDashboardMutation,
  useDuplicateInsightDashboardMutation,
  useGetInsightDashboardByIdQuery,
  useGetInsightDashboardIntegrationsQuery,
  useUpdateInsightDashboardMutation,
  useUploadInsightDashboardMediaFilesMutation,
} from '@api/insight-dashboards';
import { generateUrl } from '../util';
import { ROUTES } from '../../constants/routes';
import Loader from '../../components/loader';
import { RootState, useAppSelector } from '../../reducers';
import { canManageCurrentLevel, getCurrentUser } from '../../selectors/user';
import { FeaturePermissions } from '../../services/permissions/FeaturePermissions';
import { getFirstValueListCode, hasUtrvHistoryModal } from './utils';
import {
  DashboardItemFilters,
  HistoricalUtrs,
  HistoricalUtrsQueryByCodeParams,
  useLazyGetHistoricalUtrsByCodesQuery,
} from '../../api/insights';
import UniversalTrackerModalService from '../../model/UniversalTrackerModalService';
import { Tab } from '../../components/utr-modal/ContentTabs';
import NotAuthorised from '../not-authorised';
import { API } from '../../constants/errors';
import { selectMostRecentSurveyByPeriodAndType } from '../../slice/initiativeSurveyListSlice';
import { useGetSurveyByIdQuery } from '../../api/surveys';
import { useGetAvailablePeriodsQuery } from '../../api/initiatives';
import { TOOLTIP_MESSAGE } from '../summary/insights/utils/sidebar';
import { CustomDashboardWrapper } from './context/CustomDashboardWrapper';
import { BasicAlert } from '@g17eco/molecules/alert';
import { LoadingPlaceholder } from '@g17eco/molecules/loader-container';
import { Filters as SurveyFilters } from '@components/utr-modal/SurveyFilters';
import { UtrvHistoryModalTemplate } from '@components/utr-modal/UtrvHistoryModalTemplate';
import { UtrModalBody } from '@components/utr-modal/UtrModalBody';
import { DateRangeType } from '@g17eco/types/common';
import { getDeletedDocumentIds, getNewFilesToUpload, mapFilesToItems } from '@features/custom-dashboard';
import { dashbboardMetricStatusOptions, DEFAULT_FILTERS, getTimeFramePeriod, getTimeFrameType } from './dashboard-settings/utils';
import {
  useInsightDashboardFilters,
  usePresetInsightDashboardFilters,
} from '@features/custom-dashboard/hooks/useInsightDashboardFilters';
import { generateErrorToast, generateToast } from '@components/toasts';
import { useSettingsSidebar } from './dashboard-settings/useSettingsSidebar';
import { useCTCustomDashboards } from '@hooks/useCTCustomDashboards';
import { EditingDashboardItem } from '@features/custom-dashboard/types';

export const CustomDashboardContainer = () => {
  const history = useHistory();
  const { initiativeId, dashboardId } = useParams<{ initiativeId: string; dashboardId: string }>();

  const { filters, setFilters } = useInsightDashboardFilters({ period: DEFAULT_FILTERS.period });
  const { period, timeFrame, dateRange } = filters;

  const {
    data: dashboard,
    isFetching,
    isError,
    error,
    refetch: refetchDashboard,
    // TODO: Should get the data based on subsidiaries filter
  } = useGetInsightDashboardByIdQuery({
    dashboardId,
    initiativeId,
    queryParams: { period, timeFrameType: timeFrame, dateRange },
  });

  const surveyType = dashboard?.filters.surveyType ?? DEFAULT_FILTERS.surveyType;

  const { data: availablePeriods = [] } = useGetAvailablePeriodsQuery(
    {
      initiativeId,
      surveyType,
    },
    { skip: !dashboard }
  );

  const [updateDashboard, { isLoading: isUpdating, isError: isUpdatingError }] = useUpdateInsightDashboardMutation();
  const [deleteDashboard, { isLoading: isDeleting, isError: isDeletingError }] = useDeleteInsightDashboardMutation();
  const [uploadFiles, { isLoading: isUploading }] = useUploadInsightDashboardMediaFilesMutation();
  const [duplicateDashboard] = useDuplicateInsightDashboardMutation();
  const canAccessIntegrations = useAppSelector(FeaturePermissions.canAccessAppIntegrations);
  const { data: integrationProviders } = useGetInsightDashboardIntegrationsQuery(initiativeId, {
    skip: !canAccessIntegrations,
  });
  const [getHistoricalUtrsByCodes, { isLoading: isLoadingUtrsData }] = useLazyGetHistoricalUtrsByCodesQuery();

  const canAccessCustomDashboards = useAppSelector(FeaturePermissions.canAccessCustomDashboards);
  const initiative = useAppSelector((state: RootState) => state.initiative.data);
  const currentUser = useAppSelector(getCurrentUser);
  const mostRecentSurvey = useAppSelector((state: RootState) =>
    selectMostRecentSurveyByPeriodAndType(state, period, surveyType)
  );

  const { data: latestSurvey } = useGetSurveyByIdQuery(mostRecentSurvey?._id ?? '', {
    skip: !mostRecentSurvey?._id,
  });

  const [selectingUtrData, setSelectingUtrData] = useState<UniversalTrackerModalService | undefined>(undefined);
  const [firstValueListCode, setFirstValueListCode] = useState<string | undefined>(undefined);
  const [editingItem, setEditingItem] = useState<EditingDashboardItem | undefined>(undefined);

  const isCurrentLevelDashboard = initiativeId === dashboard?.initiativeId;
  const canManage = useAppSelector(canManageCurrentLevel);

  usePresetInsightDashboardFilters({ filters, setFilters, dashboard });

  const settingsSidebarProps = useSettingsSidebar({ initiativeId });
  const { handleAddNew } = settingsSidebarProps;
  const {
    currentPage,
    options,
    isFetchingDashboards,
    isEditing,
    setIsEditing,
    handleClickOption,
    handleNavigateCustom,
  } = useCTCustomDashboards({ initiativeId, dashboardId, handleAddNew });
  const commonSidebarProps = {
    initiativeId,
    availablePeriods,
    ...settingsSidebarProps,
    currentPage,
    options,
    handleClickOption,
    handleNavigateCustom,
  };

  if (error?.message === API.NOT_PERMITTED) {
    return <NotAuthorised />;
  }

  if (!dashboard || !initiative || isFetchingDashboards) {
    return (
      <Dashboard className='profile-dashboard insights-dashboard' hasSidebar>
        <CompanyInsightsSidebar alignButtonRow={false} {...commonSidebarProps} />
        <LoadingPlaceholder height={600} />
      </Dashboard>
    );
  }

  const handleGetHistoricalUtrsByCodes = (params: HistoricalUtrsQueryByCodeParams): Promise<HistoricalUtrs[]> => {
    return getHistoricalUtrsByCodes(params).unwrap();
  };

  const handleClickEdit = () => setIsEditing(true);
  const handleCancel = () => setIsEditing(false);

  const handleSave = async (changes: Partial<InsightDashboard>, keepEditing?: boolean) => {
    const newItems = changes.items ?? dashboard.items; // Fallback to existing dashboard items when saving settings to avoid clear all items.
    const newFiles = getNewFilesToUpload(newItems);

    const uploadedFiles = Object.keys(newFiles).length
      ? await uploadFiles({ files: newFiles, initiativeId, dashboardId }).unwrap()
      : [];

    // Map media back to items with new url, documentId properties.
    const itemsWithMedia = mapFilesToItems(uploadedFiles, newItems);

    const deletedDocumentIds = getDeletedDocumentIds(dashboard.items, newItems);
    await updateDashboard({ ...dashboard, ...changes, items: itemsWithMedia, initiativeId, deletedDocumentIds });

    if (!keepEditing) {
      setIsEditing(false);
    }

    // Sync with surveyPeriod, surveyType and timeFrame
    if (changes.filters) {
      const { type: timeFrame, startDate, endDate } = changes.filters.timeFrame ?? DEFAULT_FILTERS.timeFrame;
      setFilters({
        period: changes.filters?.period ?? DEFAULT_FILTERS.period,
        timeFrame: timeFrame,
        dateRange: { startDate, endDate },
      });
    }
  };

  const handleDelete = async () => {
    return deleteDashboard({ dashboardId, initiativeId })
      .unwrap()
      .then(() => {
        generateToast({
          color: 'info',
          title: (
            <>
              <i className='fal fa-trash text-sm mr-2'></i>Dashboard deleted
            </>
          ),
          message: `${dashboard.title} has been deleted successfully.`,
        });
        history.push(generateUrl(ROUTES.SUMMARY, { dashboardId, initiativeId }));
      })
      .catch((error) => {
        generateErrorToast(error);
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  const handleDuplicateDashboard = () => {
    return duplicateDashboard({ dashboardId, initiativeId }).unwrap();
  };

  const generateSelectingUtrData = ({ utrData, activeTab }: { utrData: HistoricalUtrs; activeTab?: Tab['navId'] }) => {
    const modalService = new UniversalTrackerModalService(utrData.utr);
    modalService.setUniversalTrackerValues(utrData.utrvs);
    modalService.setInitiativeId(initiativeId || dashboard.initiativeId);
    modalService.setActiveTabId(activeTab);

    setSelectingUtrData(modalService);
  };

  const handleOpenUtrvHistoryModal = ({
    item,
    utrData,
    activeTab,
  }: {
    item: GridDashboardItem;
    utrData?: HistoricalUtrs;
    activeTab?: Tab['navId'];
  }) => {
    if (!hasUtrvHistoryModal(item) || !utrData) {
      return;
    }
    setFirstValueListCode(getFirstValueListCode(item));
    generateSelectingUtrData({ utrData, activeTab });
  };

  const reloadUtrvs = async (filters: SurveyFilters) => {
    await handleChangeUtrvHistoryModalFilters(filters);
    await refetchDashboard();
  };

  const handleChangeUtrvHistoryModalFilters = (filters: SurveyFilters) => {
    if (!selectingUtrData) {
      return;
    }

    const queryParams: DashboardItemFilters = {
      ...dashboard.filters,
      ...filters,
    };

    return handleGetHistoricalUtrsByCodes({
      initiativeId,
      utrCodes: [selectingUtrData.getUniversalTracker().getCode()],
      queryParams,
    }).then((result) => {
      if (!result[0]) {
        return;
      }

      return generateSelectingUtrData({ utrData: result[0] });
    });
  };

  const handleChangeDateRange = (dateRange: DateRangeType, timeFrame: string | number) => {
    setFilters({ timeFrame: getTimeFrameType(timeFrame), dateRange });
  };

  const renderCustomDashboard = () => {
    if (!canAccessCustomDashboards) {
      return (
        <Dashboard className='w-100'>
          <DashboardSection>
            <DashboardSectionTitle title={TOOLTIP_MESSAGE.NOT_AVAILABLE_PLAN} />
          </DashboardSection>
        </Dashboard>
      );
    }

    return (
      <CustomDashboardWrapper
        integrationProviders={integrationProviders}
        getHistoricalUtrsByCodes={handleGetHistoricalUtrsByCodes}
        duplicateDashboard={handleDuplicateDashboard}
      >
        {isFetching || isUpdating || isDeleting || isLoadingUtrsData || isUploading ? <Loader /> : null}
        {selectingUtrData ? (
          <UtrvHistoryModalTemplate toggle={() => setSelectingUtrData(undefined)}>
            <UtrModalBody
              canAccessCustomDashboards={canAccessCustomDashboards}
              reloadUtrvs={reloadUtrvs}
              modalService={selectingUtrData}
              firstValueListCode={firstValueListCode}
              currentUser={currentUser}
              canManage={canManage}
              disableTargetBaselineAdding={isEditing}
              activeTabId={selectingUtrData.getActiveTabId()}
              initialFilters={{ surveyType, period: period ?? 'all' }}
              onChangeFilters={handleChangeUtrvHistoryModalFilters}
              isOpenFilterByDefault
            />
          </UtrvHistoryModalTemplate>
        ) : null}
        {isEditing ? (
          <CustomDashboardEditing
            editingItem={editingItem}
            setEditingItem={setEditingItem}
            dashboard={dashboard}
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleDelete={handleDelete}
            handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
            initiative={initiative}
            survey={latestSurvey}
            availablePeriods={availablePeriods}
            period={period}
            surveyType={surveyType}
            metricStatusOptions={dashbboardMetricStatusOptions}
          />
        ) : (
          <CustomDashboard
            setEditingItem={setEditingItem}
            dashboard={dashboard}
            handleClickEdit={handleClickEdit}
            handleSave={handleSave}
            handleDelete={handleDelete}
            canManage={canManage}
            isCurrentLevelDashboard={isCurrentLevelDashboard}
            handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
            initiative={initiative}
            survey={latestSurvey}
            availablePeriods={availablePeriods}
            period={period}
            setPeriod={(period) => setFilters({ period })}
            dateRange={dateRange}
            onChangeDateRange={handleChangeDateRange}
            timeRange={getTimeFramePeriod(timeFrame)}
            currentPage={currentPage}
            dashboardOptions={options}
            handleClickOption={handleClickOption}
            metricStatusOptions={dashbboardMetricStatusOptions}
          />
        )}
      </CustomDashboardWrapper>
    );
  };

  return (
    <Dashboard className='profile-dashboard insights-dashboard' hasSidebar>
      <CompanyInsightsSidebar
        key={`company-insights-sidebar-${isEditing}`}
        alignButtonRow={false}
        {...commonSidebarProps}
      />
      {isError || isUpdatingError || isDeletingError ? <BasicAlert type={'danger'}>Something wrong</BasicAlert> : null}
      {renderCustomDashboard()}
    </Dashboard>
  );
};
