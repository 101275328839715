import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateTemplateScopeMutation } from '../../api/survey-templates';
import { Action } from '../../constants/action';
import { addSiteAlert } from '../../slice/siteAlertsSlice';
import Dashboard, { DashboardSection } from '../dashboard';
import { viewOptions, ViewValues } from '../survey-overview-sidebar/viewOptions';
import { processScopeData } from '../survey-scope/scopeSelection';
import SurveyScopeCardGrid, { ScopeCardGridProps } from '../survey-scope/SurveyScopeCardGrid';
import { convertToFilters } from '../survey/utils/useScopeFilters';
import { TemplateDashboardHeader } from './partials/TemplateDashboardHeader';
import { TemplateSettingsHeader } from './partials/TemplateSettingsHeader';
import { TemplateContext, TemplateContextLoadedProps } from './TemplateContainer';

interface TemplateScopeProps {
  isDisabled?: boolean;
  renderHeader: () => JSX.Element;
  renderBackButton?: () => JSX.Element;
  title?: string;
}

export const TemplateScope = ({ isDisabled = false, renderBackButton, renderHeader, title }: TemplateScopeProps) => {
  const templateContext = useContext(TemplateContext) as TemplateContextLoadedProps;
  const { templateData, breadcrumbs, sidebarSettings, materiality } = templateContext;
  const { groupBy } = sidebarSettings;
  const dispatch = useDispatch();
  const [updateTemplateScope] = useUpdateTemplateScopeMutation();

  const [isLoading, setLoading] = useState(false);

  const view = groupBy[0] as ViewValues;
  const scopeType = viewOptions.map(v => v.value).includes(view) ? view : ViewValues.QuestionPacks;
  const cardGroup = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].cardGroup : scopeType;

  const updateScope = (data: string[], method: Action) => {
    const filters = convertToFilters(view, breadcrumbs);
    const scopeGroups = processScopeData(scopeType, data, filters, materiality);
    setLoading(true);
    updateTemplateScope({ templateId: templateData._id, action: method, scopeGroups })
      .then(() => {
        setLoading(false);
      })
      .catch((e: Error) => {
        dispatch(
          addSiteAlert({
            content: e.message,
            timeout: 5000,
          })
        );
        setLoading(false);
      });
  };

  const templateScopeCardProps: ScopeCardGridProps = {
    view,
    scopeType,
    cardGroup,
    surveyData: templateData,
    reloadSurvey: () => Promise.resolve(),
    isLoading,
    updateScope,
    ...templateContext,
  };

  return (
    <Dashboard>
      {renderBackButton ? renderBackButton() : <TemplateSettingsHeader />}
      <DashboardSection header={<TemplateDashboardHeader title={title} />}>
        {renderHeader()}
        <SurveyScopeCardGrid
          {...templateScopeCardProps}
          isLoading={isLoading}
          updateScope={updateScope}
          disabled={isDisabled}
        />
      </DashboardSection>
    </Dashboard>
  );
};
