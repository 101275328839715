import { SURVEY } from '@constants/terminology';
import { canAccessCustomScopeGroup } from '../../constants/groups';
import { CustomScope } from '../../types/initiative';
import { naturalSort } from '../../utils';
import { CardGridGroup, CardGridItemProps } from './CardGrid';

export enum CardGroupName {
  Free = `Free ${SURVEY.ADJECTIVE} modules`,
  Premium = `Premium ${SURVEY.ADJECTIVE} modules`,
}

export const primaryCards = ['gri2021', 'tcfd_standard', 'sasb', 'ungc', 'rspo', 'ca100', 'iris', 'wef', 'sec', 'wba', 'sfdr'];

export const sortByPurchased = (premiumCards: CardGridItemProps[], scopeConfig?: CustomScope[]) => {
  if (!scopeConfig) {
    return premiumCards;
  }

  const cardsWithAccess: CardGridItemProps[] = [];
  const cardsWithoutAccess: CardGridItemProps[] = [];

  for (const card of premiumCards) {
    const hasAccess = canAccessCustomScopeGroup(card.requiredTags, scopeConfig);
    if (hasAccess) {
      cardsWithAccess.push(card);
    } else {
      cardsWithoutAccess.push(card);
    }
  }

  return [...cardsWithAccess, ...cardsWithoutAccess];
};

// Sort custom metrics and prioritize Custom Metrics over Assigned Metrics
// Cards with initiativeId equal current initiativeId is CustomMetrics
// Cards with initiativeId not equal current initiativeId is AssignedMetrics
export const sortCustomCards = (cards: CardGridItemProps[], curInitiativeId: string) => {
  return [...cards].sort((a, b) => {
    if (!a.initiativeId || !b.initiativeId) {
      return 0;
    }

    if (a.initiativeId === curInitiativeId && b.initiativeId !== curInitiativeId) {
      return -1;
    }

    if (a.initiativeId !== curInitiativeId && b.initiativeId === curInitiativeId) {
      return 1;
    }

    return 0;
  });
}

export const getSortedCustomMetricCards = (customMetrics: CardGridGroup[], initiativeId: string) => {
  const cards: CardGridItemProps[] = [];

  customMetrics.forEach((cardGroup) => {
    cardGroup.cards.forEach((card) => cards.push(card));
  });

  return sortCustomCards(cards, initiativeId);
}

const sortInScopeOrPartialCards = (a:CardGridItemProps, b: CardGridItemProps) => {
  if ((a.inScope || a.isPartial) && !(b.inScope || b.isPartial)) {
    return -1;
  }
  if (!(a.inScope || a.isPartial) && (b.inScope || b.isPartial)) {
    return 1;
  }
  return naturalSort(a.sortTitle, b.sortTitle);
}

const sortCoreCards = (a:CardGridItemProps, b: CardGridItemProps) => {
  if (a.isMandatory && !b.isMandatory) {
    return -1;
  }
  if (!a.isMandatory && b.isMandatory) {
    return 1;
  }
  return naturalSort(a.sortTitle, b.sortTitle);
}

const sortPrimaryCards = (a:CardGridItemProps, b: CardGridItemProps) => {
  if (primaryCards.includes(a.scopeTag) && !primaryCards.includes(b.scopeTag)) {
    return -1;
  }
  if (!primaryCards.includes(a.scopeTag) && primaryCards.includes(b.scopeTag)) {
    return 1;
  }
  return naturalSort(a.sortTitle, b.sortTitle);
}

// The order of priority is in-scope cards, primary cards, normal cards
export const getSortedCards = (cards: CardGridItemProps[], groupName?: CardGroupName) => {
  const isCoreCards = !groupName;
  const isFreeCards = groupName === CardGroupName.Free;
  const inScopeSortedCards = [...cards];

  return inScopeSortedCards.sort((a, b) => {
    // sort prioritize in-scope and partial cards
    if (a.inScope || a.isPartial || b.inScope || b.isPartial) {
      return sortInScopeOrPartialCards(a, b);
    }

    // sort prioritize core cards: requiredAddons & recommendedAddons
    if (isCoreCards && (a.isMandatory || b.isMandatory)) {
      return sortCoreCards(a, b);
    }

    // sort prioritize primary cards
    if (isFreeCards && (primaryCards.includes(a.scopeTag) || primaryCards.includes(b.scopeTag))) {
      return sortPrimaryCards(a, b);
    }

    return naturalSort(a.sortTitle, b.sortTitle);
  });
}
