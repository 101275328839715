import { DecimalInput, DecimalInputProps } from '@components/utr-decimal/DecimalInput';
import { UnitTypes } from '@utils/units';
import { OverrideUnitForm, UnitOverrideProps } from './OverrideUnitForm';
import { InputOverrideType } from '../utils';
import { QUESTION } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules/alert';

interface Props extends DecimalInputProps, UnitOverrideProps {
  type: InputOverrideType;
  showEmptyAlert?: boolean;
}

export const InputOverrideField = (props: Props) => {
  const { type, unitTypeMap, showEmptyAlert = false } = props;

  /*
    filter the right properties based on type
    type: numberScale => 'numberScale'
    type: unit => 'mass', 'volume', 'time', etc.
  */
  const filteredUnitTypeMap = new Map(
    Array.from(unitTypeMap.entries()).filter(([key, _]) => {
      if (type === InputOverrideType.Unit) {
        return key !== UnitTypes.numberScale;
      }
      return key === UnitTypes.numberScale;
    })
  );

  if (filteredUnitTypeMap.size === 0) {
    return showEmptyAlert ? (
      <BasicAlert type='info'>Selected {QUESTION.PLURAL} has no units to override</BasicAlert>
    ) : null;
  }

  switch (type) {
    case InputOverrideType.NumberScale:
    case InputOverrideType.Unit:
      return <OverrideUnitForm {...props} unitTypeMap={filteredUnitTypeMap} />;
    case InputOverrideType.Decimal:
      return <DecimalInput {...props} />;
    default:
      return null;
  }
};
