import { getBorders, spacer } from '@components/report-output/document-structure';
import { SafeTextRun } from '@utils/docx';
import {
  Paragraph,
  AlignmentType,
  TableRow,
  TableCell,
  Table,
  WidthType,
  TableLayoutType,
  IShadingAttributesProperties,
  VerticalAlign,
} from 'docx';
import {
  AssessmentData,
  MaterialityAssessmentScope,
  MaterialityBoundary,
  MaterialPillar,
  UtrMapping,
} from '@apps/materiality-tracker/api/materiality-assessment';
import { createArrayOfNumbers } from '@utils/array';
import { Framework, frameworks, Standard, standards } from '@g17eco/core';
import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';
import { loggerMessage } from '../../../../../logger';

const TABLE_WIDTH = '100%';
const STYLES = {
  TABLE_BORDER_COLOUR: '6a6c70',
  TABLE_CELL: {
    size: 20,
    color: '434343',
    alignment: AlignmentType.LEFT,
  },
  TABLE: {
    width: {
      size: TABLE_WIDTH,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.AUTOFIT,
  },
  APPENDIX_TABLE_HEADER: {
    textStyles: { color: 'ffffff' },
    cellStyles: {
      shading: { fill: '351c75' },
    },
  },
};

const MARGINS_SM = {
  left: 75,
  right: 75,
  top: 75,
  bottom: 75,
};

const boundariesMap = {
  [MaterialityBoundary.Leadership]: 'Leadership',
  [MaterialityBoundary.ResearchAndDevelopment]: 'R&D',
  [MaterialityBoundary.SupplyChain]: 'SCM',
  [MaterialityBoundary.ProductAndServices]: 'Service',
  [MaterialityBoundary.Distribution]: 'Distribution',
  [MaterialityBoundary.Communities]: 'Community',
  [MaterialityBoundary.Experiences]: 'Experience',
};

const pillarShadingMap = {
  [MaterialPillar.People]: {
    main: '38761d',
    sub: 'b6d7a8',
  },
  [MaterialPillar.Partnership]: {
    main: '0b5394',
    sub: '9fc5e8',
  },
  [MaterialPillar.Planet]: {
    main: 'bf9000',
    sub: 'ffe599',
  },
  [MaterialPillar.Prosperity]: {
    main: '85200c',
    sub: 'dd7e6b',
  },
  [MaterialPillar.Principle]: {
    main: '351c75',
    sub: 'b4a7d6',
  },
};

const topTopicShadingMap: { [index: number]: string } = {
  0: 'b4a7d6',
  1: 'd2cae9',
  2: 'eae5f5',
};

const topicLengthMap: { [key in MaterialityAssessmentScope]: number } = {
  [MaterialityAssessmentScope.Startup]: 3,
  [MaterialityAssessmentScope.Solopreneur]: 3,
  [MaterialityAssessmentScope.Micro]: 3,
  [MaterialityAssessmentScope.SME]: 4,
  [MaterialityAssessmentScope.MidCap]: 4,
  [MaterialityAssessmentScope.MNC]: 5,
  [MaterialityAssessmentScope.Large]: 5,
};

export class AssessmentTableGenerator {
  /** Top x by scores topics for each materiality pillar based on sizeScope */
  private topicsByPillarsMap: Map<string, AssessmentData[]>;
  /** Unique topics sorted by scores */
  private sortedUniqueTopics: AssessmentData[];
  /** Top x by scores topics for each materiality boundary based on unique topics based on sizeScope */
  private topicsByBoundariesMap: Map<string, AssessmentData[]>;
  /** All topics scopes */
  private topicScopes: Map<string, Standard | Framework>;
  /** Blueprint for question lookup */
  private blueprintQuestions: Map<string, UniversalTrackerBlueprintMin>;
  /** Max topic length based on assessment's size scope */
  private topicLength: number;

  constructor({
    result,
    questions,
    sizeScope
  }: {
    readonly result: AssessmentData[];
    readonly questions: UniversalTrackerBlueprintMin[];
    readonly sizeScope: MaterialityAssessmentScope;
  }) {
    // According to the template notes, need to use topics with pillars as a original data source
    this.topicLength = topicLengthMap[sizeScope];
    this.topicsByPillarsMap = this.getTopicsByPillarMap(result);
    this.sortedUniqueTopics = this.getSortedUniqueTopics();
    this.topicsByBoundariesMap = this.getTopicsByBoundaryMap(this.sortedUniqueTopics);
    this.topicScopes = new Map<string, Standard | Framework>([
      ...Object.entries(standards),
      ...Object.entries(frameworks),
    ]);
    this.blueprintQuestions = new Map<string, UniversalTrackerBlueprintMin>(questions.map((q) => [q.code, q]));
  }

  private getTopicsByPillar(result: AssessmentData[], pillar: MaterialPillar) {
    return result
      .filter((topic) => topic.categories?.materialPillar?.includes(pillar))
      .sort((a, b) => b.score - a.score)
      .slice(0, this.topicLength);
  }

  private getTopicsByPillarMap(result: AssessmentData[]) {
    return new Map(
      Object.values(MaterialPillar).map<[string, AssessmentData[]]>((pillar) => [
        pillar,
        this.getTopicsByPillar(result, pillar),
      ])
    );
  }

  private getTopicsByBoundary(result: AssessmentData[], boundary: MaterialityBoundary) {
    return result.filter((topic) => topic.categories?.boundary?.includes(boundary)).sort((a, b) => b.score - a.score);
  }

  private getTopicsByBoundaryMap(result: AssessmentData[]) {
    return new Map(
      Object.values(MaterialityBoundary).map<[string, AssessmentData[]]>((boundary) => [
        boundary,
        this.getTopicsByBoundary(result, boundary),
      ])
    );
  }

  private getSortedUniqueTopics() {
    return Array.from(this.topicsByPillarsMap.values())
      .flat()
      .reduce<AssessmentData[]>((acc, topic) => {
        if (acc.some((t) => t.code === topic.code)) {
          return acc;
        }
        acc.push(topic);
        return acc;
      }, []).sort((a, b) => b.score - a.score);
  }

  private createTableCell({
    text,
    cellWidth,
    textStyles: { alignment, color, bold } = {},
    cellStyles: { shading, verticalAlign = VerticalAlign.TOP, columnSpan } = {},
  }: {
    text?: string | string[];
    cellWidth: string;
    textStyles?: {
      alignment?: AlignmentType;
      color?: string;
      bold?: boolean;
    };
    cellStyles?: {
      shading?: IShadingAttributesProperties;
      verticalAlign?: VerticalAlign;
      columnSpan?: number;
    }
  }) {
    const mergedStyles = { ...STYLES.TABLE_CELL, ...(color ? { color } : {}) };
    const paragraph = new Paragraph({
      children: Array.isArray(text)
        ? text.map((subText) => new SafeTextRun({ ...mergedStyles, text: subText, bold }))
        : [new SafeTextRun({ ...mergedStyles, text, bold })],
      alignment,
    });
    return new TableCell({
      width: { type: WidthType.PERCENTAGE, size: cellWidth },
      children: [paragraph],
      margins: MARGINS_SM,
      borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
      shading,
      verticalAlign,
      columnSpan,
    });
  }

  private createMappedMetricCell({
    cellWidth,
    utrMapping,
  }: {
    cellWidth: string;
    utrMapping?: UtrMapping[];
  }) {
    if (!utrMapping || utrMapping.length === 0) {
      return new TableCell({
        width: { type: WidthType.PERCENTAGE, size: cellWidth },
        children: [
          new Paragraph({
            children: [
              new SafeTextRun({ ...STYLES.TABLE_CELL, text: 'No relevant metrics', italics: true, color: '666666' }),
            ],
          }),
        ],
        margins: MARGINS_SM,
        borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
      });
    }
    const paragraphs = utrMapping.map((utr) => {
      const { title, description } = this.getQuestionInfo(utr);
      return new Paragraph({
        children: [
          new SafeTextRun({ ...STYLES.TABLE_CELL, text: title, bold: true }),
          new SafeTextRun({ ...STYLES.TABLE_CELL, text: description }),
        ],
      });
    });
    return new TableCell({
      width: { type: WidthType.PERCENTAGE, size: cellWidth },
      children: paragraphs,
      margins: MARGINS_SM,
      borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
    });
  }

  private createActionCell({
    cellWidth,
    action,
  }: {
    cellWidth: string;
    action?: string;
  }) {
    if (!action) {
      return new TableCell({
        width: { type: WidthType.PERCENTAGE, size: cellWidth },
        children: [
          new Paragraph({
            children: [
              new SafeTextRun({ ...STYLES.TABLE_CELL, text: 'No relevant actions', italics: true, color: '666666' }),
            ],
          }),
        ],
        margins: MARGINS_SM,
        borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
      });
    }
    const paragraphs = action.split('\n').filter(Boolean).map((text) => {
      return new Paragraph({
        children: [
          new SafeTextRun({ ...STYLES.TABLE_CELL, text }),
        ],
      });
    });
    return new TableCell({
      width: { type: WidthType.PERCENTAGE, size: cellWidth },
      children: paragraphs,
      margins: MARGINS_SM,
      borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
    });
  }

  private getTopicName(topic: AssessmentData) {
    return topic.name || topic.code;
  }

  private getLatestStandardCode(question: UniversalTrackerBlueprintMin) {
    const standard = this.topicScopes.get(question.type);
    const alternativeStandard = Object.keys(question.alternatives || {}).reduce<(Standard | Framework)[]>((acc, code) => {
      const alternative = this.topicScopes.get(code);
      if (alternative) {
        acc.push(alternative);
      }
      return acc;
    }, []).find(s => s.versionGroupCode === standard?.versionGroupCode);

    if (alternativeStandard?.version && standard?.version && alternativeStandard.version > standard.version) {
      return alternativeStandard.code;
    }

    return standard?.code || question.type;
  }

  private getQuestionScope(utr: UtrMapping) {
    const question = this.blueprintQuestions.get(utr.code);
    if (!question) {
      return '';
    }
    const code = this.getLatestStandardCode(question);
    // Expected output: [UTR standard]
    return this.topicScopes.get(code)?.name || '';
  }

  private getQuestionInfo(utr: UtrMapping) {
    const question = this.blueprintQuestions.get(utr.code);
    if (!question) {
      loggerMessage('Question not found', { level: 'error', questionCode: utr.code });
      return {
        title: '',
        description: '',
      };
    }
    // Expected output: [UTR standard]: [URT Title]
    return {
      title: `${this.getQuestionScope(utr)} [${question.typeCode}]: `,
      description: question.valueLabel,
    };
  }

  public getStandardsTables() {
    return Object.values(MaterialPillar).flatMap((pillar) => {
      const { main, sub } = pillarShadingMap[pillar];
      const initialRows = [
        new TableRow({
          children: [
            this.createTableCell({
              cellWidth: '100%',
              text: pillar.toUpperCase(),
              textStyles: {
                alignment: AlignmentType.CENTER,
                color: 'ffffff',
              },
              cellStyles: {
                shading: { fill: main },
                columnSpan: 2,
              }
            }),
          ],
        }),
        new TableRow({
          children: [
            this.createTableCell({ text: 'MATERIAL TOPIC', cellWidth: '66%', cellStyles: { shading: { fill: sub } } }),
            this.createTableCell({
              text: 'STANDARDS & FRAMEWORKS',
              cellWidth: '34%',
              cellStyles: { shading: { fill: sub } },
            }),
          ],
        }),
      ];
      return [
        new Table({
          ...STYLES.TABLE,
          rows: initialRows.concat(
            createArrayOfNumbers(0, this.topicLength - 1).map((index) => {
              const topic = this.topicsByPillarsMap.get(pillar)?.[index];
              const topicName = this.createTableCell({ cellWidth: '66%', text: topic?.name || topic?.code });
              const uniqueStandards = (topic?.utrMapping || []).reduce<string[]>((acc, utr) => {
                const standard = this.getQuestionScope(utr);
                if (!standard || acc.includes(standard)) {
                  return acc;
                }
                acc.push(standard);
                return acc;
              }, []);
              const mappedScopes = this.createTableCell({ cellWidth: '34%', text: uniqueStandards.join(', ') });
              return new TableRow({
                children: [topicName, mappedScopes],
              });
            })
          ),
        }),
        spacer(),
      ];
    });
  }

  public getBoundariesTables() {
    return Object.values(MaterialPillar).flatMap((pillar) => {
      const { main, sub } = pillarShadingMap[pillar];
      const initialRows = [
        new TableRow({
          children: [
            this.createTableCell({
              cellWidth: '100%',
              text: pillar.toUpperCase(),
              textStyles: {
                alignment: AlignmentType.CENTER,
                color: 'ffffff',
              },
              cellStyles: {
                shading: { fill: main },
                columnSpan: 8,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            this.createTableCell({ cellWidth: '30%', text: 'MATERIAL TOPIC', cellStyles: { shading: { fill: sub } } }),
            ...Object.values(boundariesMap).map((boundary) =>
              this.createTableCell({
                cellWidth: '10%',
                text: boundary,
                textStyles: {
                  alignment: AlignmentType.CENTER,
                },
                cellStyles: {
                  shading: { fill: sub },
                },
              })
            ),
          ],
        }),
      ];

      return [
        new Table({
          ...STYLES.TABLE,
          rows: initialRows.concat(
            createArrayOfNumbers(0, this.topicLength - 1).map((index) => {
              const topic = this.topicsByPillarsMap.get(pillar)?.[index];
              const topicName = this.createTableCell({ cellWidth: '30%', text: topic?.name || topic?.code });

              const boundaryValues = Object.keys(boundariesMap).map((boundary) => {
                const isIncluded = topic?.categories?.boundary?.includes(boundary as MaterialityBoundary);
                return this.createTableCell({
                  cellWidth: '10%',
                  text: isIncluded ? '✓' : '',
                  textStyles: { alignment: AlignmentType.CENTER },
                  cellStyles: { verticalAlign: VerticalAlign.CENTER },
                });
              });

              return new TableRow({
                children: [topicName, ...boundaryValues],
              });
            })
          ),
        }),
        spacer(),
      ];
    });
  }

  public getTopTopicsPerBoundaryTable() {
    const topFiveScoreTopicCodes = this.sortedUniqueTopics.slice(0, 5).map((topic) => topic.code);
    const initialRows = [
      new TableRow({
        children: Object.values(boundariesMap).map((boundary) =>
          this.createTableCell({
            cellWidth: '14%',
            text: boundary,
            ...STYLES.APPENDIX_TABLE_HEADER
          })
        ),
      }),
    ];
    const maxRowCount = Math.max(...Array.from(this.topicsByBoundariesMap.values()).map((topics) => topics.length));

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        createArrayOfNumbers(0, maxRowCount - 1).map((index) => {
          return new TableRow({
            children: Object.keys(boundariesMap).map((boundary) => {
              const topic = this.topicsByBoundariesMap.get(boundary)?.[index];
              const isBoldText = topic?.code ? topFiveScoreTopicCodes.includes(topic.code) : false;
              return this.createTableCell({
                cellWidth: '14%',
                text: topic?.name || topic?.code,
                cellStyles: { shading: { fill: topTopicShadingMap[Math.floor(index / 5)] } },
                textStyles: { bold: isBoldText }
              });
            }),
          });
        })
      ),
    });
  }

  public getTopicScoresWithDescTable() {
    const initialRows = [
      new TableRow({
        children: [
          this.createTableCell({
            cellWidth: '10%',
            text: 'SCORE',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
          this.createTableCell({
            cellWidth: '24%',
            text: 'TOPIC',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
          this.createTableCell({
            cellWidth: '66%',
            text: 'DESCRIPTION',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
        ],
      }),
    ];

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        this.sortedUniqueTopics.map((topic) => {
          return new TableRow({
            children: [
              this.createTableCell({ cellWidth: '10%', text: `${topic?.relativeScore || 0}%` }),
              this.createTableCell({ cellWidth: '24%', text: this.getTopicName(topic) }),
              this.createTableCell({ cellWidth: '66%', text: topic.description }),
            ],
          });
        })
      ),
    });
  }

  public getTopicMappedMetricsTable() {
    const initialRows = [
      new TableRow({
        children: [
          this.createTableCell({
            cellWidth: '34%',
            text: 'TOPIC',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
          this.createTableCell({
            cellWidth: '66%',
            text: 'MAPPED METRIC',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
        ],
      }),
    ];

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        this.sortedUniqueTopics.map((topic) => {
          return new TableRow({
            children: [
              this.createTableCell({ cellWidth: '34%', text: this.getTopicName(topic) }),
              this.createMappedMetricCell({
                cellWidth: '66%',
                utrMapping: topic?.utrMapping,
              }),
            ],
          });
        })
      ),
    });
  }

  public getTopicActionsTable() {
    const initialRows = [
      new TableRow({
        children: [
          this.createTableCell({
            cellWidth: '34%',
            text: 'TOPIC',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
          this.createTableCell({
            cellWidth: '66%',
            text: 'ACTION',
            ...STYLES.APPENDIX_TABLE_HEADER
          }),
        ],
      }),
    ];

    return new Table({
      ...STYLES.TABLE,
      rows: initialRows.concat(
        this.sortedUniqueTopics.map((topic) => {
          return new TableRow({
            children: [
              this.createTableCell({ cellWidth: '34%', text: this.getTopicName(topic) }),
              this.createActionCell({ cellWidth: '66%', action: topic.action }),
            ],
          });
        })
      ),
    });
  }
}
