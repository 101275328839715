import { UserWithInfo, Workgroup } from '@g17eco/types/workgroup';
import { g17ecoApi, transformResponse } from './g17ecoApi';

enum Tag {
  Workgroups = 'workgroups',
  Workgroup = 'workgroup',
}

const getUrl = (initiativeId: string) => `/initiatives/${initiativeId}/workgroups`;
export type CreateWorkgroup = Pick<Workgroup, 'name' | 'description' | 'icon' | 'color'>;

export const WorkgroupsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(Tag),
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getWorkgroups: build.query<Workgroup[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl(initiativeId),
          method: 'get',
        }),
        providesTags: (_result, _error, initiativeId) => {
          return [{ type: Tag.Workgroups, id: initiativeId }];
        },
      }),
      getWorkgroup: build.query<Workgroup<UserWithInfo>, { initiativeId: string; workgroupId: string }>({
        transformResponse,
        query: ({ initiativeId, workgroupId }) => ({
          url: `${getUrl(initiativeId)}/${workgroupId}`,
          method: 'get',
        }),
        providesTags: (_result, _error, { workgroupId }) => {
          return [{ type: Tag.Workgroup, id: workgroupId }];
        },
      }),
      createWorkgroup: build.mutation<Workgroup, { initiativeId: string; data: CreateWorkgroup }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.Workgroups, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, data }) => ({
          url: getUrl(initiativeId),
          method: 'post',
          data,
        }),
      }),
      updateWorkgroup: build.mutation<Workgroup, { initiativeId: string; workgroupId: string; data: CreateWorkgroup }>({
        invalidatesTags: (_result, _error, { initiativeId, workgroupId }) => [
          { type: Tag.Workgroups, id: initiativeId },
          { type: Tag.Workgroup, id: workgroupId },
        ],
        transformResponse,
        query: ({ initiativeId, workgroupId, data }) => ({
          url: `${getUrl(initiativeId)}/${workgroupId}`,
          method: 'put',
          data,
        }),
      }),
      deleteWorkgroup: build.mutation<Workgroup, { initiativeId: string; workgroupId: string }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.Workgroups, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, workgroupId }) => ({
          url: `${getUrl(initiativeId)}/${workgroupId}`,
          method: 'delete',
        }),
      }),
      duplicateWorkgroup: build.mutation<Workgroup, { initiativeId: string; workgroupId: string }>({
        invalidatesTags: (_result, _error, { initiativeId }) => [{ type: Tag.Workgroups, id: initiativeId }],
        transformResponse,
        query: ({ initiativeId, workgroupId }) => ({
          url: `${getUrl(initiativeId)}/${workgroupId}/duplicate`,
          method: 'post',
        }),
      }),
      addWorkgroupUsers: build.mutation<Workgroup, { initiativeId: string; workgroupId: string; userIds: string[] }>({
        invalidatesTags: (_result, _error, { initiativeId, workgroupId }) => [
          { type: Tag.Workgroups, id: initiativeId },
          { type: Tag.Workgroup, id: workgroupId },
        ],
        transformResponse,
        query: ({ initiativeId, workgroupId, userIds }) => ({
          url: `${getUrl(initiativeId)}/${workgroupId}/users`,
          method: 'put',
          data: { userIds },
        }),
      }),
      removeWorkgroupUser: build.mutation<Workgroup, { initiativeId: string; workgroupId: string; userId: string }>({
        invalidatesTags: (_result, _error, { initiativeId, workgroupId }) => [
          { type: Tag.Workgroups, id: initiativeId },
          { type: Tag.Workgroup, id: workgroupId },
        ],
        transformResponse,
        query: ({ initiativeId, workgroupId, userId }) => ({
          url: `${getUrl(initiativeId)}/${workgroupId}/users/${userId}`,
          method: 'delete',
        }),
      }),
    }),
  });

export const {
  useGetWorkgroupsQuery,
  useGetWorkgroupQuery,
  useCreateWorkgroupMutation,
  useUpdateWorkgroupMutation,
  useDeleteWorkgroupMutation,
  useDuplicateWorkgroupMutation,
  useAddWorkgroupUsersMutation,
  useRemoveWorkgroupUserMutation,
} = WorkgroupsApi;
