import { ValueStages } from '@services/aggregation/ValueStage';
import { UniversalTrackerPlain, UtrType } from './universalTracker';
import { GeneratedUtr, IntegrationProvider } from './integration';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { UniversalTrackerValuePlain } from './surveyScope';
import { NumberScale, SupportedMeasureUnits } from './units';

export enum CalculationType {
  Direct = 'direct', // 'a'
  Formula = 'formula', // {a}
  Stages = 'stages', // JSONata
}
export enum CalculationGroupValueType {
  Numeric = 'numeric',
  Text = 'text',
}
export interface Variable {
  code: string;
  valueListCode?: string;
  /**
   * Integration service provider code
   */
  integrationCode?: string;
  groupCode?: string;
  subGroupCode?: string;
}

type CalculationVariableGroup = {
  name: string;
  colour?: string;
  link?: string;
  icon?: string;
};

export type UnitNumberScale = { unit?: string; numberScale: string };
export type NumericVariable = { value: number; utrvId?: string } & UnitNumberScale;
type NumericData = {
  value: number;
  variables: Record<string, NumericVariable>;
};
export type NumericCalculationData = (NumericData & { surveyId?: string })[];

export type TextVariable = { value: string; utrvId?: string };
type TextData = {
  value: string;
  variables: Record<string, TextVariable>;
};
export type TextCalculationData = (TextData & { surveyId?: string })[];

export interface CalculationBase extends UnitNumberScale {
  // Common properties for all types
  _id: string;
  name: string;
  description?: string;
  variables: Record<string, Variable>;
  data: NumericCalculationData | TextCalculationData;

  // Custom resolved group
  group?: CalculationVariableGroup;

  type: CalculationType;
}

interface DirectCalculation extends CalculationBase {
  type: CalculationType.Direct;
  direct: string;
}

interface FormulaCalculation extends CalculationBase {
  type: CalculationType.Formula;
  formula: string;
}

interface StagesCalculation extends CalculationBase {
  type: CalculationType.Stages;
  stages: ValueStages;
}

export type Calculation = DirectCalculation | FormulaCalculation | StagesCalculation;

interface CalculationGroup {
  _id: string;
  /** internal name like "Number of female employees" */
  name: string;
  /** Explain where it can be used */
  description?: string;
  valueType: CalculationGroupValueType;
  unitType?: SupportedMeasureUnits;
  numberScale?: NumberScale;
  calculations: Calculation[];
}

export interface UniversalTrackerConnection {
  _id: string;
  utrCode: string;
  // If targeting column
  valueListCode?: string;

  calculationGroups: CalculationGroup[];
}

export type CalculationUtr = Pick<UniversalTrackerPlain, '_id' | 'code' | 'valueValidation' | 'type' | 'name' | 'alternatives'>;
export type CalculationIntegrationUtr = GeneratedUtr & {
  type: UtrType.Generated,
  integrationCode: string;
  provider?: Pick<IntegrationProvider, 'shortName' | 'logo' | 'icon'>;
};

export type CalculationUtrv = SurveyModelMinimalUtrv & Pick<UniversalTrackerValuePlain, 'unit' | 'numberScale'>;
