import { SurveyStatsFilterProps, SurveyStatsStatus } from '../types';
import { DebouncedSearchBox } from '@g17eco/molecules/search';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { Toggle } from '@g17eco/molecules/toggle';
import { OnChangeValue } from 'react-select';
import { SurveyStats } from '@api/initiative-stats';
import { getFullName } from '../../../utils/user';
import { useMemo } from 'react';
import { getMonth, getMonthName, getYear } from '../../../utils/date';
import { naturalSort } from '../../../utils';
import { SURVEY } from '@constants/terminology';

interface Props {
  surveyStats: SurveyStats[];
  isCompleteFilter: boolean;
  setFilter: React.Dispatch<React.SetStateAction<SurveyStatsFilterProps>>;
}

type FilterOption = Option<string>;

export const SurveyStatsFilters = (props: Props) => {
  const { surveyStats, isCompleteFilter, setFilter } = props;

  const filterOptions = useMemo(() => {
    const users = surveyStats.reduce<FilterOption[]>((acc, { creator }) => {
      if (!creator || acc.some(({ value }) => value === creator._id)) {
        return acc;
      }
      acc.push({ label: getFullName(creator), value: creator._id });
      return acc;
    }, []);

    const subsidiaries = surveyStats.reduce<FilterOption[]>((acc, { initiativeId, initiativeName }) => {
      if (acc.some(({ value }) => value === initiativeId)) {
        return acc;
      }
      acc.push({ label: initiativeName, value: initiativeId });
      return acc;
    }, []);

    const { years, months } = surveyStats.reduce<{ years: FilterOption[]; months: FilterOption[] }>(
      (acc, { effectiveDate }) => {
        const month = getMonth(effectiveDate).toString();
        const year = getYear(effectiveDate).toString();

        if (!acc.months.some(({ value }) => value === month)) {
          acc.months.push({ label: getMonthName(Number(month) + 1), value: month });
        }

        if (!acc.years.some(({ value }) => value === year)) {
          acc.years.push({ label: year, value: year });
        }

        return acc;
      },
      { years: [], months: [] }
    );

    return {
      users: users.sort((a, b) => naturalSort(a.label.toString(), b.label.toString())),
      subsidiaries: subsidiaries.sort((a, b) => naturalSort(a.label.toString(), b.label.toString())),
      years: years.sort((a, b) => naturalSort(a.value, b.value)),
      months: months.sort((a, b) => naturalSort(a.value, b.value)),
    };
  }, [surveyStats]);

  const handleToggle = () => {
    const status = isCompleteFilter ? SurveyStatsStatus.Pending : SurveyStatsStatus.Completed;
    setFilter((prev) => ({ ...prev, status }));
  };

  const onFilterChange = (key: keyof SurveyStatsFilterProps) => (value?: string) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const onDropdownChange =
    (key: keyof Omit<SurveyStatsFilterProps, 'searchText'>) => (selection: OnChangeValue<FilterOption, false>) => {
      if (selection === null) {
        return onFilterChange(key)(undefined);
      }
      onFilterChange(key)(selection.value);
    };

  return (
    <div>
      <div className='d-flex align-items-center gap-4 mb-3'>
        <DebouncedSearchBox
          placeholder='Search...'
          onTextChange={onFilterChange('searchText')}
          classNames={{ wrapper: 'flex-fill' }}
        />
        <Toggle
          className={{ form: 'd-flex align-items-center pl-0 mb-0 justify-content-end' }}
          checked={isCompleteFilter}
          onChange={handleToggle}
          label={`Completed ${SURVEY.PLURAL}`}
        />
      </div>
      <div className='d-flex gap-3 mb-3'>
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          placeholder={'Subsidiary'}
          className='w-100'
          onChange={onDropdownChange('byInitiativeId')}
          options={filterOptions.subsidiaries}
          isSearchable
          isClearable
        />
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          placeholder={'Created by'}
          className='w-100'
          onChange={onDropdownChange('byUserId')}
          options={filterOptions.users}
          isSearchable
          isClearable
        />
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          placeholder={'Month'}
          className='w-100'
          onChange={onDropdownChange('byMonth')}
          options={filterOptions.months}
          isSearchable
          isClearable
        />
        <SelectFactory
          selectType={SelectTypes.SingleSelect}
          placeholder={'Year'}
          className='w-100'
          onChange={onDropdownChange('byYear')}
          options={filterOptions.years}
          isSearchable
          isClearable
        />
      </div>
    </div>
  );
};
