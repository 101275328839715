import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

export interface AdminBreadcrumbsProps {
  breadcrumbs: BreadcrumbItemType[];
  initiativeId: string;
}

export interface BreadcrumbItemType {
  label: string;
  url?: string;
  isDisabled?: boolean;
}

const BreadcrumbItem = ({ label, url, isDisabled }: BreadcrumbItemType) => {
  const history = useHistory();

  if (!url || isDisabled) {
    return <span>{label}</span>;
  }

  return (
    <Button className='d-inline-flex p-0' color='link' onClick={() => history.push(url)}>
      {label}
    </Button>
  );
};

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbItemType[];
  rootLabel: string;
  rootUrl: string;
}

export const Breadcrumbs = ({ breadcrumbs, rootLabel, rootUrl }: BreadcrumbsProps) => {
  const list = breadcrumbs.reduce<JSX.Element[]>((items, item, index) => {
    const isDisabled = index === breadcrumbs.length - 1;
    items.push(
      <div key={`divider-${index}`} className='mx-2'>
        &#47;
      </div>
    );
    items.push(
      <React.Fragment key={index}>
        <BreadcrumbItem label={item.label} url={item.url} isDisabled={isDisabled} />
      </React.Fragment>
    );
    return items;
  }, []);

  return (
    <div className={'w-100 d-flex justify-content-between align-items-center'} data-testid='app-breadcrumb'>
      <div className='ml-2 d-flex flex-row align-items-center text-ThemeTextMedium'>
        <BreadcrumbItem label={rootLabel} url={rootUrl} />
        {list}
      </div>
    </div>
  );
};
