import { g17ecoApi, transformResponse } from '@api/g17ecoApi';
import { SafeUser, SurveyUserRoles, UserMin } from '@constants/users';
import { OnboardingStatus } from '@g17eco/types/onboarding';

export type User = UserMin &
  Pick<SafeUser, 'email' | 'isStaff'> & {
    status?: OnboardingStatus;
    initiativeRoles?: string[];
    surveyRoles?: string[];
    count?: { verified: number; contributed: number };
  };

export type DelegatedUsers = { contributors: User[]; verifiers: User[]; admins: User[] };

enum Tag {
  SurveyUsers = 'survey-users',
}

type MutationUsersParams = {
  surveyId: string;
  role: SurveyUserRoles;
  userId: string;
  onboardingEmail?: string;
};

const getMutationUrl = ({ surveyId, role, userId, onboardingEmail }: MutationUsersParams) => {
  return onboardingEmail
    ? `surveys/${surveyId}/users/${role}/${onboardingEmail}/onboard`
    : `surveys/${surveyId}/users/${role}/${userId}`;
};

export const surveyUsersApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(Tag),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSurveyUsers: builder.query<DelegatedUsers, { surveyId: string; utrvIds: string[] }>({
        transformResponse,
        query: ({ surveyId, utrvIds }) => ({
          url: `surveys/${surveyId}/universal-tracker-values/users`,
          method: 'post',
          data: { utrvIds },
        }),
        providesTags: (_result, _error, args) => {
          return [{ type: Tag.SurveyUsers, id: args.surveyId }];
        },
      }),
      addSurveyUser: builder.mutation<User, MutationUsersParams>({
        invalidatesTags: (_result, _error, { surveyId }) => [{ type: Tag.SurveyUsers, id: surveyId }],
        transformResponse,
        query: ({ surveyId, role, userId, onboardingEmail }) => ({
          url: getMutationUrl({ surveyId, role, userId, onboardingEmail }),
          method: 'patch',
        }),
      }),
      removeSurveyUser: builder.mutation<User, MutationUsersParams>({
        invalidatesTags: (_result, _error, { surveyId }) => [{ type: Tag.SurveyUsers, id: surveyId }],
        transformResponse,
        query: ({ surveyId, role, userId, onboardingEmail }) => ({
          url: getMutationUrl({ surveyId, role, userId, onboardingEmail }),
          method: 'delete',
        }),
      }),
    }),
  });

export const { useGetSurveyUsersQuery, useAddSurveyUserMutation, useRemoveSurveyUserMutation } = surveyUsersApi;
