import LogoInverted from '../../images/g17eco-icon.svg';
import Sdg1 from '../../images/sdg/sdg1.svg';
import Sdg2 from '../../images/sdg/sdg2.svg';
import Sdg3 from '../../images/sdg/sdg3.svg';
import Sdg4 from '../../images/sdg/sdg4.svg';
import Sdg5 from '../../images/sdg/sdg5.svg';
import Sdg6 from '../../images/sdg/sdg6.svg';
import Sdg7 from '../../images/sdg/sdg7.svg';
import Sdg8 from '../../images/sdg/sdg8.svg';
import Sdg9 from '../../images/sdg/sdg9.svg';
import Sdg10 from '../../images/sdg/sdg10.svg';
import Sdg11 from '../../images/sdg/sdg11.svg';
import Sdg12 from '../../images/sdg/sdg12.svg';
import Sdg13 from '../../images/sdg/sdg13.svg';
import Sdg14 from '../../images/sdg/sdg14.svg';
import Sdg15 from '../../images/sdg/sdg15.svg';
import Sdg16 from '../../images/sdg/sdg16.svg';
import Sdg17 from '../../images/sdg/sdg17.svg';
import Sdg18 from '../../images/sdg/sdg18.svg';
import OutlineSdg1 from '../../images/sdg/sdg1-outline.svg';
import OutlineSdg2 from '../../images/sdg/sdg2-outline.svg';
import OutlineSdg3 from '../../images/sdg/sdg3-outline.svg';
import OutlineSdg4 from '../../images/sdg/sdg4-outline.svg';
import OutlineSdg5 from '../../images/sdg/sdg5-outline.svg';
import OutlineSdg6 from '../../images/sdg/sdg6-outline.svg';
import OutlineSdg7 from '../../images/sdg/sdg7-outline.svg';
import OutlineSdg8 from '../../images/sdg/sdg8-outline.svg';
import OutlineSdg9 from '../../images/sdg/sdg9-outline.svg';
import OutlineSdg10 from '../../images/sdg/sdg10-outline.svg';
import OutlineSdg11 from '../../images/sdg/sdg11-outline.svg';
import OutlineSdg12 from '../../images/sdg/sdg12-outline.svg';
import OutlineSdg13 from '../../images/sdg/sdg13-outline.svg';
import OutlineSdg14 from '../../images/sdg/sdg14-outline.svg';
import OutlineSdg15 from '../../images/sdg/sdg15-outline.svg';
import OutlineSdg16 from '../../images/sdg/sdg16-outline.svg';
import OutlineSdg17 from '../../images/sdg/sdg17-outline.svg';
import { cdnUrl, readFilePromise } from '@utils/files';
import { getImageBase64FromSVGBase64 } from '@utils/image';

const iconRegistry = [
  Sdg1,
  Sdg2,
  Sdg3,
  Sdg4,
  Sdg5,
  Sdg6,
  Sdg7,
  Sdg8,
  Sdg9,
  Sdg10,
  Sdg11,
  Sdg12,
  Sdg13,
  Sdg14,
  Sdg15,
  Sdg16,
  Sdg17,
  Sdg18,
];
const iconRegistryOutline = [
  OutlineSdg1,
  OutlineSdg2,
  OutlineSdg3,
  OutlineSdg4,
  OutlineSdg5,
  OutlineSdg6,
  OutlineSdg7,
  OutlineSdg8,
  OutlineSdg9,
  OutlineSdg10,
  OutlineSdg11,
  OutlineSdg12,
  OutlineSdg13,
  OutlineSdg14,
  OutlineSdg15,
  OutlineSdg16,
  OutlineSdg17,
  Sdg18,
];

export const getSrc = (
  profile: string | undefined,
  code: string | number | undefined,
  outline: boolean = false,
  withText: boolean = false
) => {
  if (profile) {
    return profile;
  }

  if (!code) {
    return LogoInverted;
  }

  const bits = String(code).split('.');
  if (bits.length === 2) {
    if (withText) {
      return `${cdnUrl}/sdg/targets/TARGET_${String(code).toUpperCase()}.svg`;
    }
    return `${cdnUrl}/sdg/sdg_${bits[0]}_${bits[1]}.svg`;
  }

  if (outline) {
    return iconRegistryOutline[Number(code) - 1];
  }

  return iconRegistry[Number(code) - 1];
};

const getSDGImgAsBase64 = async (code: number | string, outline: boolean = false) => {
  const sdgImgSrc = getSrc(undefined, code, outline);
  const img = await readFilePromise(sdgImgSrc);

  if (img === 'data:') {
    return;
  }

  return getImageBase64FromSVGBase64({ imgBase64: img });
}

export const getImgGoalsB64 = () => {
  return Promise.all(
    Array(17)
      .fill('')
      .map((k, i) => getSDGImgAsBase64(i + 1, false))
  );
};

export const getImgGoalsB64Inverted = () => {
  return Promise.all(
    Array(17)
      .fill('')
      .map((k, i) => getSDGImgAsBase64(i + 1, true))
  );
};
