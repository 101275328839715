import React from 'react';
import { Chart } from 'react-google-charts';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, DataSource } from './GenerateCharts';
import { getDataAsPercentageTableSum } from '@routes/summary/insights/utils/helpers';

const WIDTH = 300;
const HEIGHT = 300;

export interface GenderSplitDataSource extends DataSource {
  femaleEmployees?: number;
  maleEmployees?: number;
}

export const GenderSplit = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/405-1/b';
  const male = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'employees_per_category',
    percentageColumn: 'male',
  });
  const female = getDataAsPercentageTableSum({
    reportData: questionData,
    utrCode,
    tableColumn: 'employees_per_category',
    percentageColumn: 'female',
  });

  const reportData: GenderSplitDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    femaleEmployees: female,
    maleEmployees: male,
  };

  const chartData = [
    ['Gender', 'Employees', { role: 'label' }],
    ['Female', female, female],
    ['Male', male, male],
  ];

  return (
    <Chart
      chartType='PieChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Total staff',
        pieSliceText: 'value',
        chartArea: {
          top: 50,
          bottom: 20,
          left: 20,
          right: 20,
        },
        legend: {
          position: 'top',
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'genderSplit', data: reportData, handler: updateData })]}
    />
  );
});
