import { Button } from 'reactstrap';
import {
  GridDashboardItemBase,
  GridDashboardItemCreate,
  GridDashboardTempItem,
  InsightDashboardItemType,
  InsightDashboardTempItemType,
} from '@g17eco/types/insight-custom-dashboard';
import { AddWidgetBtn } from './AddWidgetBtn';
import { FloatingToolbar } from '../../floating-toolbar';
import { ExtraFeature, FeatureStability } from '@g17eco/molecules/feature-stability';

interface Props {
  openAddingModal: (item: Pick<GridDashboardItemBase | GridDashboardTempItem, 'type'>) => void;
  saveDashboardChanges: () => void;
  handleCancel: () => void;
  isLoadingPageBreaks: boolean;
  pageBreaks: number[];
  toggleShowHideBreakpoints: () => void;
  updateItem: (updatingItem: GridDashboardItemCreate) => Promise<void>;
}

const defaultSpaceWidget: GridDashboardItemCreate = {
  type: InsightDashboardItemType.Space,
};

export const CustomDashboardToolbar = (props: Props) => {
  const {
    saveDashboardChanges,
    openAddingModal,
    handleCancel,
    isLoadingPageBreaks,
    pageBreaks,
    toggleShowHideBreakpoints,
    updateItem,
  } = props;

  const addItemButtons = (
    [
      InsightDashboardTempItemType.TempChart,
      InsightDashboardItemType.Table,
      InsightDashboardItemType.Media,
      InsightDashboardItemType.Text,
    ] as const
  ).map((type) => <AddWidgetBtn key={type} type={type} handleClick={() => openAddingModal({ type })} />);

  const toggleBreakpoints = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleShowHideBreakpoints();
    e.currentTarget.blur();
  };

  const addSpaceButton = (
    <AddWidgetBtn type={InsightDashboardItemType.Space} handleClick={() => updateItem(defaultSpaceWidget)} />
  );

  const breakpointsButton = (
    <Button color='secondary' disabled={isLoadingPageBreaks} onClick={toggleBreakpoints}>
      <i className='fa-light fa-grid-dividers mr-2'></i>
      {pageBreaks.length > 0 ? 'Hide page breaks' : 'Show page breaks'}
      <FeatureStability color='inherit' feature={ExtraFeature.ExportDashboardPdf} />
    </Button>
  );

  const saveButton = (
    <Button color='primary' onClick={() => saveDashboardChanges()}>
      Save
    </Button>
  );

  return (
    <>
      <FloatingToolbar
        handleClose={handleCancel}
        isOpen
        items={[...addItemButtons, addSpaceButton, breakpointsButton, saveButton]}
      />
    </>
  );
};
