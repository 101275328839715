import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SurveyDuplicateForm from './SurveyDuplicateForm'
import { SurveyType } from '../../types/survey';
import { SURVEY } from '../../constants/terminology';

interface SurveyDuplicateProps {
  handleClose: () => void,
  display: boolean
  survey: any
}


const SurveyDuplicateModal = ({ display, handleClose, survey }: SurveyDuplicateProps) => {

  if (!display) {
    return null
  }

  const reportType = survey.type === SurveyType.Aggregation ? `combined ${SURVEY.SINGULAR}` : SURVEY.SINGULAR;

  return (
    <Modal isOpen={display} toggle={handleClose} backdrop='static'>
      <ModalHeader toggle={handleClose}>
        <i className='fas fa-copy mr-2' />
        {`Duplicate existing ${reportType}`}
      </ModalHeader>
      <ModalBody>
        <SurveyDuplicateForm toggle={handleClose} survey={survey} />
      </ModalBody>
    </Modal>
  );
}

export default SurveyDuplicateModal;
