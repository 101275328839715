import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { BasicAlert, BasicAlertParams } from '@g17eco/molecules/alert';

interface QueryErrorProps extends Omit<BasicAlertParams, 'children' | 'type'> {
  error: FetchBaseQueryError | SerializedError;
  type?: BasicAlertParams['type'];
}

const getMsg = (error: QueryErrorProps['error']) => {
  if ('error' in error) {
    return error.error;
  }

  if ('message' in error) {
    return error.message;
  }

  return 'Generic Error';
};

export const QueryError = (props: QueryErrorProps) => {
  const { error, type = 'danger', ...rest } = props;

  if (!error) {
    return null;
  }

  return <BasicAlert type={type} {...rest}>{getMsg(error)}</BasicAlert>;
};
