import { useAddWorkgroupUsersMutation } from '@api/workgroups';
import Loader from '@components/loader';
import { Suggestion } from '@components/search/SearchComponent';
import UserSearch from '@components/search/UserSearch';
import { generateErrorToast, generateToast } from '@components/toasts';
import { UserWithInfo, Workgroup } from '@g17eco/types/workgroup';
import { useToggle } from '@hooks/useToggle';
import { useState } from 'react';
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  initiativeId: string;
  workgroup: Workgroup<UserWithInfo>;
}
export const AddUserBtn = ({ workgroup, initiativeId }: Props) => {
  const [isOpen, toggle] = useToggle(false);
  const [addingUsers, setAddingUsers] = useState<Suggestion[]>([]);

  const [addWorkgroupUsers, { isLoading: isAdding }] = useAddWorkgroupUsersMutation();
  const handleUserSelected = (suggestion?: Suggestion) => {
    if (suggestion) {
      setAddingUsers([...addingUsers, suggestion]);
    }
  };
  const ignoredIds = [...addingUsers, ...workgroup.users].map(({ _id }) => _id);
  const handleCancel = () => {
    setAddingUsers([]);
    toggle();
  };

  const handleSave = () => {
    addWorkgroupUsers({
      initiativeId,
      workgroupId: workgroup._id,
      userIds: addingUsers.map(({ _id }) => _id),
    })
      .unwrap()
      .then(() => {
        generateToast({
          title: 'Users added',
          color: 'success',
          message: 'Users have been added to the workgroup',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      })
      .finally(() => {
        handleCancel();
      });
  };

  return (
    <>
      <Button color='primary' onClick={handleCancel} className='text-nowrap'>
        <i className='fal fa-users-medical me-2' />
        Add users
      </Button>
      <Modal isOpen={isOpen} toggle={handleCancel} backdrop='static' returnFocusAfterClose={false}>
        <ModalHeader toggle={toggle}>Add user to workgroup</ModalHeader>
        <ModalBody>
          {isAdding ? <Loader /> : null}
          <p>
            Invite users to the workgroup using the search bar. If you are unable to find the user, please first ensure
            you have invited them to the platform via the manage users page.
          </p>
          <UserSearch
            name='workgroup-users'
            placeholder='Search for user'
            ignoredIds={ignoredIds}
            initiativeId={initiativeId}
            inputClassName={'form-control'}
            handleValueChange={() => {}}
            handleSuggestionSelected={handleUserSelected}
          />
          {addingUsers.map((user) => (
            <Badge
              color='primary'
              className='mr-2 mt-2 background-ThemeInfoDark text-md p-2 dont_translate'
              tag='div'
              key={user._id}
            >
              {user.name} ({user.email})
            </Badge>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color='link-secondary' onClick={handleCancel} className='mr-3'>
            Cancel
          </Button>
          <Button color='primary' onClick={handleSave} disabled={addingUsers.length === 0 || isAdding}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
