import { ColumnCode, Config, SurveyTemplate } from '@g17eco/types/custom-report';

export enum CustomReportTemplateType {
  Default = 'default',
  Transposed = 'transposed',
  Tabular = 'tabular',
}

export type Form = Required<Pick<Config, 'columns' | 'ordering' | 'templateType'>> &
  Pick<SurveyTemplate, 'initiativeIds' | 'surveyFilters'> & {
    _id?: string;
    name: string;
    description?: string;
    metricFilters: Omit<SurveyTemplate, 'initiativeIds' | 'surveyFilters'>;
  };

export type OnChangeForm = (form: Partial<Form>) => void;

export interface Option<HasCustom extends boolean = false> {
  label: string;
  value: HasCustom extends true ? ColumnCode | 'custom' : ColumnCode;
}
