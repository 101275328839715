import React from 'react';
import Chart from 'react-google-charts';
import { CHART_DEFAULTS, GenerateChartsProps, chartReadyEvent, DataSource } from './GenerateCharts';

const WIDTH = 500;
const HEIGHT = 330;

export interface GHGEmissionsDataSource extends DataSource {
  totalEmissions?: number;
}

export const GHGEmissions = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const scope1Utr = questionData.find((d) => d.universalTracker.code === 'gri/2020/305-1/a');
  const scope2aUtr = questionData.find((d) => d.universalTracker.code === 'gri/2020/305-2/a');
  const scope2bUtr = questionData.find((d) => d.universalTracker.code === 'gri/2020/305-2/b');
  const scope3Utr = questionData.find((d) => d.universalTracker.code === 'gri/2020/305-3/a');

  const scope1 = scope1Utr?.value ?? 0;
  const scope2 = (scope2aUtr?.value ?? 0) + (scope2bUtr?.value ?? 0);
  const scope3 = scope3Utr?.value ?? 0;

  const reportData: GHGEmissionsDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    totalEmissions: scope1 + scope2 + scope3,
  };

  const chartData = [
    ['Scope', 'Emissions', { role: 'style' }, { role: 'annotation' }],
    ['Scope 1', scope1, '#A6CFE5', scope1],
    ['Scope 2', scope2, '#D6F5DF', scope2],
    ['Scope 3', scope3, '#1A5A87', scope3],
  ];

  return (
    <Chart
      chartType='ColumnChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Scope 1, Scope 2, and Scope 3 emissions.',
      }}
      chartEvents={[chartReadyEvent({ key: 'ghgEmissions', data: reportData, handler: updateData })]}
    />
  );
});
