import { multiDateRenderer, MultiDateRendererParams } from './table/multiDateRenderer';
import { heading2 } from '../document-structure';

type RenderParams = Pick<MultiDateRendererParams, 'questionData' | 'targetMap' | 'preferredAltCodes' | 'visibilityStatus'>
interface DataGeneratorParams extends RenderParams {
  headerText: string;
  utrCodes: string[];
  displayUserInput?: boolean;
}


export function generateSGXQuestions(params: DataGeneratorParams) {

  const { questionData, targetMap, preferredAltCodes, utrCodes, visibilityStatus, headerText, displayUserInput = false } = params
  const [latestHistorical] = questionData;
  if (!latestHistorical) {
    return []
  }

  const historicalData = latestHistorical.reportData
    .filter(question => utrCodes.includes(question.universalTracker.code))
    .sort((a, b) => {
      return utrCodes.indexOf(a.universalTracker.code) - utrCodes.indexOf(b.universalTracker.code);
    });

  const dataASC = questionData.slice().reverse();
  const scopeGroupTables = historicalData.map(lastSurveyUtrvs => {
    return multiDateRenderer({
      currentData: lastSurveyUtrvs,
      questionData: dataASC,
      targetMap,
      preferredAltCodes,
      visibilityStatus,
      showEmptyTables: true,
      displayUserInput,
    })
  }).flat()

  if (scopeGroupTables.length === 0) {
    return [];
  }

  return [heading2(headerText), ...scopeGroupTables];
}
