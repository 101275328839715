import { resetTreeMap } from '../utils';

interface ExpandActionsProps {
  getInitiativeStructure: (ids?: string[]) => void;
  initiativeList: { _id: string }[];
  expandedNodes: string[];
  setExpandedNodes: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ExpandActions = ({
  getInitiativeStructure,
  initiativeList = [],
  expandedNodes,
  setExpandedNodes,
}: ExpandActionsProps) => {
  const handleExpand = () => {
    resetTreeMap();
    const expandedIds = initiativeList.map((initiative) => initiative._id);
    setExpandedNodes((prev) => [...prev, ...expandedIds]);
    getInitiativeStructure(expandedIds);
  };

  const handleCollapse = () => {
    resetTreeMap();
    const expandedIds = expandedNodes.filter((node) => !initiativeList.some((initiative) => initiative._id === node));
    setExpandedNodes(expandedIds);
    getInitiativeStructure(expandedIds);
  };

  return (
    <div className='col col-auto expand-actions'>
      <button onClick={handleExpand} className='fa-light fa-arrows-from-line'></button>
      <button onClick={handleCollapse} className='fa-light fa-arrows-to-line'></button>
    </div>
  );
};
