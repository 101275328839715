import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../styles.scss';
import { ValidationError, ValidationErrorType } from '@g17eco/types/background-jobs';
import { SURVEY } from '@constants/terminology';
import { useState } from 'react';
import { SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { ColumnDef, Table } from '@g17eco/molecules/table';

interface ImportIssuesModalProps {
  toggle: () => void;
  validationErrors: ValidationError[];
}

type FilteringOption = {
  value: ValidationErrorType | 'all';
  label: string;
};

const filteringOptions: FilteringOption[] = [
  {
    value: 'all',
    label: 'All errors',
  },
  {
    value: ValidationErrorType.Generic,
    label: 'Import data errors',
  },
  {
    value: ValidationErrorType.MissingSurvey,
    label: `Missing ${SURVEY.SINGULAR} errors`,
  },
  {
    value: ValidationErrorType.MissingInitiative,
    label: 'Missing subsidiary errors',
  },
];

const tableColumns: ColumnDef<ValidationError>[] = [
  {
    id: 'column',
    header: 'Column',
    cell: ({ row }) =>
      row.original.type === ValidationErrorType.Generic && row.original.column ? row.original.column : '-',
  },
  {
    id: 'row',
    header: 'Row',
    cell: ({ row }) => (row.original.type === ValidationErrorType.Generic && row.original.row ? row.original.row : '-'),
  },
  {
    id: 'message',
    header: 'Description',
    cell: ({ row }) => row.original.message,
  },
];

export const ImportIssuesModal = ({ validationErrors, toggle }: ImportIssuesModalProps) => {
  const [filteringErrorType, setFilteringErrorType] = useState<FilteringOption['value']>('all');

  const filteredErrors = validationErrors.filter((error) => {
    return filteringErrorType === 'all' || error.type === filteringErrorType;
  });

  return (
    <Modal isOpen={!!validationErrors.length} toggle={toggle} backdrop='static' className='confirm-modal'>
      <ModalHeader toggle={toggle}>View import errors</ModalHeader>
      <ModalBody>
        <SelectFactory
          options={filteringOptions}
          selectType={SelectTypes.SingleSelect}
          value={filteringOptions.find((option) => option.value === filteringErrorType)}
          onChange={(option) => setFilteringErrorType(option?.value ?? 'all')}
          isSearchable={false}
        />
        <Table columns={tableColumns} data={filteredErrors} className='mt-2' />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
