import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { reloadInitiative } from '@actions/initiative';
import {
  useAddManualBankMutation,
  useAddWorkflowMutation,
  useRemoveWorkflowMutation,
  useGetCountriesQuery,
  useGetListOfBanksQuery,
  useUpdateBankingSettingsMutation,
  AddWorkflowResponse,
  useAddAssignedMetricsMutation,
} from '@api/banking-settings';
import { getDefaultConfig } from '../../config/app-config';
import { useToggle } from '@hooks/useToggle';
import { useAppSelector } from '@reducers/index';
import { generateUrl } from '@routes/util';
import { currentInitiative } from '@selectors/initiative';
import { Bank, BankingAction, UpdateBankingSetting } from '@g17eco/types/banking-settings';
import { getOtherBanks, getPolularBanks } from '@utils/banking-settings';
import Dashboard, { DashboardRow, DashboardSection } from '@components/dashboard';
import Loader from '../../components/loader';
import { AddAssignedMetricsModal } from './add-assigned-metrics-modal';
import { AddManualBankResponse } from './manual-bank-modal';
import OtherBanks from './other-banks';
import PopularBanks from './popular-banks';
import './styles.scss';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { AdminBreadcrumbsProps } from '@g17eco/molecules/breadcrumbs';
import { RouteInterface } from '@g17eco/types/routes';

const NOTIFY_TIMEOUT = 8000;

interface Props {
  baseRoute: RouteInterface;
  BreadcrumbsComponent: (props: AdminBreadcrumbsProps) => JSX.Element;
}

const BankingSettings = (props: Props) => {
  const location = getDefaultConfig().locationWhitelist ?? [];
  const initiative = useAppSelector(currentInitiative);
  const { data: listOfBanks } = useGetListOfBanksQuery();
  const { data: countries } = useGetCountriesQuery({ countryCodes: location });
  const [handleUpdateBankingSettings] = useUpdateBankingSettingsMutation();
  const [addManualBank] = useAddManualBankMutation();
  const [addBankingWorkflow] = useAddWorkflowMutation();
  const [removeBankingWorkflow] = useRemoveWorkflowMutation();
  const [addAssignedMetrics] = useAddAssignedMetricsMutation();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { addSiteAlert } = useSiteAlert();

  const [openAddAssignedMetrics, toggleOpenAddAssignedMetrics] = useToggle(false);
  const [selectedBank, setSelectedBank] = useState<Bank>();

  if (!initiative || !listOfBanks) {
    return <Loader />;
  }

  const { baseRoute, BreadcrumbsComponent } = props;
  const breadcrumbs = [
    { label: 'Company Settings', url: generateUrl(baseRoute, { initiativeId: initiative._id }) },
    { label: 'Banking Settings' },
  ];

  const addWorkflow = async (updateBank: Bank) => {
    return addBankingWorkflow({ initiativeId: initiative._id, bankCode: updateBank.code })
      .unwrap()
      .then(({ assignedMetricGroupIds: groupIds, portfolio }: AddWorkflowResponse) => {
        if (portfolio) {
          addSiteAlert({
            content: (
              <>
                <strong>Success!</strong> {initiative.name} has been added to {portfolio.name}
              </>
            ),
            color: SiteAlertColors.Success,
            timeout: NOTIFY_TIMEOUT,
          });
        }

        if (groupIds.length > 0) {
          setSelectedBank(updateBank);
          toggleOpenAddAssignedMetrics();
        }
      })
      .catch((error) => {
        addSiteAlert({
          content: (
            <>
              <strong>Error!</strong> {error.message}
            </>
          ),
          color: SiteAlertColors.Danger,
        });
      });
  };

  const removeWorkflow = async (updateBank: Bank) => {
    return removeBankingWorkflow({ initiativeId: initiative._id, bankCode: updateBank.code })
      .unwrap()
      .then((portfolio) => {
        if (!portfolio) {
          return;
        }
        addSiteAlert({
          content: (
            <>
              <strong>Sucess!</strong> {initiative.name} has been removed from {portfolio.name}
            </>
          ),
          color: SiteAlertColors.Success,
          timeout: NOTIFY_TIMEOUT,
        });
      })
      .catch((error) => {
        addSiteAlert({
          content: (
            <>
              <strong>Error!</strong> {error.message}
            </>
          ),
          color: SiteAlertColors.Danger,
        });
      });
  };

  const updateBankingSettings = async ({ bankingSettings, updateBank, action }: UpdateBankingSetting) => {
    if (isLoading || !updateBank) {
      return;
    }
    setIsLoading(true);

    try {
      await handleUpdateBankingSettings({ initiativeId: initiative._id, bankingSettings });

      // process the banking workflow if the adding bank belongs to a portfolio
      switch (action) {
        case BankingAction.Add:
          await addWorkflow(updateBank);
          break;
        case BankingAction.Remove:
          await removeWorkflow(updateBank);
          break;
        default:
          break;
      }

      await dispatch(reloadInitiative());
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleAddManualBank = async (bankName: string): Promise<AddManualBankResponse | undefined> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const isDuplicated =
        (initiative.bankingSettings ?? []).some((item) => item.name === bankName) ||
        listOfBanks.some((item) => item.name === bankName);
      if (isDuplicated) {
        setIsLoading(false);
        return { error: 'Duplicate the bank name.' };
      }

      await addManualBank({ initiativeId: initiative._id, bankName });
      await dispatch(reloadInitiative());
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleAddAssignedMetrics = async () => {
    if (isLoading || !selectedBank) {
      return;
    }
    setIsLoading(true);

    try {
      await addAssignedMetrics({ initiativeId: initiative._id, bankCode: selectedBank.code })
        .unwrap()
        .then((response) => {
          addSiteAlert({
            content: (
              <>
                <strong>Success!</strong> {response.message}
              </>
            ),
            color: SiteAlertColors.Success,
            timeout: NOTIFY_TIMEOUT,
          });
        });
      setIsLoading(false);
    } catch (error) {
      addSiteAlert({
        content: (
          <>
            <strong>Error!</strong> {error.message}
          </>
        ),
        color: SiteAlertColors.Danger,
      });
    }
  };

  const popularBanks = getPolularBanks(listOfBanks, location);
  const popularCountries = (countries ?? []).map((item) => item.name).join(', ');
  const otherBanks = getOtherBanks(listOfBanks, location);

  return (
    <Dashboard className='banking-settings'>
      <DashboardRow>
        <BreadcrumbsComponent breadcrumbs={breadcrumbs} initiativeId={initiative?._id ?? ''} />
      </DashboardRow>
      <DashboardRow>
        <h3 className='pl-2'>Banking settings</h3>
      </DashboardRow>
      <DashboardSection>
        <Row className='justify-content-center'>
          <Col className='col-md-8 col-12'>
            <h3 className='text-strong text-black'>Select who you bank with</h3>
            {popularBanks.length > 0 ? (
              <>
                <h4 className='text-black mt-4'>{`Popular ${popularCountries} banks`}</h4>
                <PopularBanks
                  isLoading={isLoading}
                  bankingSettings={initiative.bankingSettings ?? []}
                  popularBanks={popularBanks}
                  updateBankingSettings={updateBankingSettings}
                />
              </>
            ) : null}
          </Col>
          <Col className='col-12 d-flex justify-content-center flex-wrap'>
            <OtherBanks
              initiative={initiative}
              otherBanks={otherBanks}
              isLoading={isLoading}
              updateBankingSettings={updateBankingSettings}
              addManualBank={handleAddManualBank}
            />
          </Col>
        </Row>
      </DashboardSection>
      <AddAssignedMetricsModal
        initiativeId={initiative._id}
        isOpen={openAddAssignedMetrics}
        handleClose={toggleOpenAddAssignedMetrics}
        addAssignedMetrics={handleAddAssignedMetrics}
      />
      {isLoading ? <Loader /> : null}
    </Dashboard>
  );
};

export default BankingSettings;
