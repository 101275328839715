import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { Table } from '@g17eco/molecules/table';
import { AddMemberPopup } from './AddMemberPopup';
import { AssurancePortfolioAssurer, UpdatePermissionData } from '@g17eco/types/assurance';
import { AssuranceUserRow } from './AssuranceUserRow';
import { AssuranceRoles } from '@constants/assurance';
import { ACTION, TeamMemberContext } from './TeamMemberContainer';
import { Action } from '@constants/action';

interface AddMemberPopupType {
  role: AssuranceRoles;
  canManage?: boolean;
  isLastAdmin?: boolean;
}

export const TeamMemberPopup = (props: AddMemberPopupType) => {
  const { role, canManage = false, isLastAdmin = false } = props;
  const { dispatch, portfolioData, updatePermissions } = useContext(TeamMemberContext);
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => {
    dispatch({
      type: ACTION.SET_SUBMIT_DATA,
      payload: { submitting: false, error: false, errorMessage: '' },
    });
    setOpen((prevState) => !prevState);
  };

  const getLabel = (role: string) => role.charAt(0).toUpperCase() + role.slice(1);

  const removePermission = (user: AssurancePortfolioAssurer, role: string) =>
    updatePermissions({
      userId: user._id,
      email: user.email,
      action: Action.Remove,
      role,
    } as UpdatePermissionData);

  return (
    <div className='user-container'>
      <div className='user-list'>
        <Table
          data={portfolioData.data.assurers[role]}
          columns={[
            {
              header: getLabel(role),
              id: 'name',
              cell: ({ row }) => (
                <AssuranceUserRow
                  user={row.original}
                  handleRemoveUser={removePermission}
                  role={role}
                  isAdmin={canManage}
                  isLastAdmin={isLastAdmin}
                />
              ),
            },
          ]}
        />
      </div>
      <div className='pt-2 text-right'>
        <Button disabled={!canManage} onClick={toggleModal}>
          Add {getLabel(role)}
        </Button>
      </div>
      <AddMemberPopup isOpen={isOpen} toggleModal={toggleModal} role={role} />
    </div>
  );
};
