import { HistoricalUtrs } from '@api/insights';
import { Calculation, CalculationType, ChartSubType, ValueRole } from '@g17eco/types/insight-custom-dashboard';
import { DataPeriods, UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { UniversalTrackerModalServiceUtrv } from '@reducers/universal-tracker-modal';
import { UtrVariables } from '@routes/summary/insights/utils/constants';
import { getMonthOfYear, getUTCEndOf } from '@utils/date';

const mockUtr: UniversalTrackerPlain = {
  _id: '',
  name: '',
  code: '',
  type: '',
  valueType: '',
  valueLabel: '',
  typeCode: '',
  created: '',
};

const mockUtrv: UniversalTrackerModalServiceUtrv = {
  _id: '',
  universalTrackerId: '',
  initiativeId: '',
  effectiveDate: '',
  period: DataPeriods.Yearly,
  type: 'actual',
  lastUpdated: '',
  stakeholders: {
    stakeholder: [],
    verifier: [],
  },
  status: '',
  initiative: {
    _id: '',
    name: '',
  }
};

const mockPieData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 30,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '2',
        code: 'b',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '2',
          value: 70,
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
    b: {
      code: 'b',
    },
  },
  calculation: {
    type: CalculationType.Formula,
    values: [
      { name: '', formula: '{a}' },
      { name: '', formula: '{b}' },
    ],
  },
};

const mockLineData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 15,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(1)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 20,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(2)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 18,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(3)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 22,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(4)).toISOString(),
        },
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 14,
          effectiveDate: getUTCEndOf('month', getMonthOfYear(5)).toISOString(),
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
  },
  calculation: {
    type: CalculationType.Formula,
    values: [{ name: '', formula: '{a}' }],
  },
};

const mockColumnData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 70,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '2',
        code: 'b',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '2',
          value: 100,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '3',
        code: 'c',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '3',
          value: 50,
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
    b: {
      code: 'b',
    },
    c: {
      code: 'c',
    },
  },
  calculation: {
    type: CalculationType.Formula,
    values: [
      { name: '', formula: '{a}' },
      { name: '', formula: '{b}' },
      { name: '', formula: '{c}' },
    ],
  },
};

const mockBarData = {
  utrsData: [
    {
      utr: {
        ...mockUtr,
        _id: '1',
        code: 'a',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '1',
          value: 70,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '2',
        code: 'b',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '2',
          value: 100,
        },
      ],
    },
    {
      utr: {
        ...mockUtr,
        _id: '3',
        code: 'c',
      },
      utrvs: [
        {
          ...mockUtrv,
          universalTrackerId: '3',
          value: 50,
        },
      ],
    },
  ],
  variables: {
    a: {
      code: 'a',
    },
    b: {
      code: 'b',
    },
    c: {
      code: 'c',
    },
  },
  calculation: {
    headers: [
      {
        name: '',
      },
      {
        name: '',
      },
      {
        role: ValueRole.Annotation,
      },
      {
        role: ValueRole.Style,
      },
    ],
    type: CalculationType.Formula,
    values: [
      { name: '', formula: '{a}', options: { tooltip: { formula: '' }, style: '#2871c2' } },
      { name: '', formula: '{b}', options: { tooltip: { formula: '' }, style: '#2e9fd6' } },
      { name: '', formula: '{c}', options: { tooltip: { formula: '' }, style: '#32d2d9' } },
    ],
  },
};

export const getChartPlaceholderData = (
  subType: ChartSubType
): { utrsData: HistoricalUtrs[]; variables: UtrVariables; calculation: Calculation } | undefined => {
  switch (subType) {
    case ChartSubType.FullPie:
    case ChartSubType.Pie:
    case ChartSubType.PieWithLegend:
      return mockPieData;
    case ChartSubType.Line:
      return mockLineData;
    case ChartSubType.Column:
    case ChartSubType.Stacked:
      return mockColumnData;
    case ChartSubType.Bar:
      return mockBarData;
    default:
      return;
  }
};
