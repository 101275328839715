import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { useState } from 'react';
import { EditorState } from 'lexical';
import { isNa, isNr } from '@utils/universalTrackerValue';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import UniversalTracker from '@models/UniversalTracker';
import { getAnalytics } from '@services/analytics/AnalyticsService';
import { AnalyticsEvents } from '@services/analytics/AnalyticsEvents';

const analytics = getAnalytics();
const getPayload = (utrv: Pick<SurveyModelMinimalUtrv, '_id' | 'initiativeId'>, utr: UniversalTracker) => ({
  utrvId: utrv._id,
  utrId: utr.getId(),
  utrCode: utr.getCode(),
  initiativeId: utrv.initiativeId,
  utrValueLabel: utr.getValueLabel(),
});

enum OmissionReason {
  NA = 'na',
  NRProhibitions = 'nrProhibitions',
  NRConstraints = 'nrConstraints',
  NRUnavailable = 'nrUnavailable',
}

type OmissionMap = Record<
  string,
  { label: string; value: OmissionReason; description: string; isRequiredNote: boolean }
>;

const omissionMap: OmissionMap = {
  na: {
    label: 'Not applicable',
    value: OmissionReason.NA,
    description: 'Explain why the disclosure or the requirement is considered not applicable.',
    isRequiredNote: false,
  },
  nrProhibitions: {
    label: 'Not reporting: Legal prohibitions',
    value: OmissionReason.NRProhibitions,
    description: 'Describe the specific legal prohibitions.',
    isRequiredNote: true,
  },
  nrConstraints: {
    label: 'Not reporting: Confidentiality constraints',
    value: OmissionReason.NRConstraints,
    description: 'Describe the specific confidentiality constraints.',
    isRequiredNote: false,
  },
  nrUnavailable: {
    label: 'Not reporting: Information unavailable / incomplete',
    value: OmissionReason.NRUnavailable,
    description:
      'Explain why the required information is unavailable or incomplete and describe the steps being taken and the expected time frame to obtain the information.',
    isRequiredNote: true,
  },
};

interface NotReportingModalProps {
  isOpen: boolean;
  toggle: () => void;
  utrv: SurveyModelMinimalUtrv;
  utr: UniversalTracker;
  comments?: string;
  isUtrvNoteRequired?: boolean;
  disabled: boolean;
  disabledNote?: boolean;
  handleNA: null | (() => void);
  handleNR: null | (() => void);
  handleComments: (comments: string, editorState?: EditorState) => void;
}

export const NotReportingModal = (props: NotReportingModalProps) => {
  const {
    isOpen,
    toggle,
    utrv,
    utr,
    comments,
    isUtrvNoteRequired = false,
    disabled,
    disabledNote = false,
    handleNA,
    handleNR,
    handleComments,
  } = props;

  const [omissionReason, setOmission] = useState<OmissionReason>();

  if (!handleNA && !handleNR) {
    return null;
  }

  const omissionOptions: Option<OmissionReason>[] = [
    {
      label: omissionMap.na.label,
      value: omissionMap.na.value,
      isDisabled: isNa(utrv) || !handleNA,
    },
    ...[omissionMap.nrProhibitions, omissionMap.nrConstraints, omissionMap.nrUnavailable].map(({ label, value }) => ({
      label,
      value,
      isDisabled: isNr(utrv) || !handleNR,
    })),
  ];

  const validateForm = () => {
    if (!omissionReason) {
      return false;
    }
    if (isUtrvNoteRequired || [OmissionReason.NRProhibitions, OmissionReason.NRUnavailable].includes(omissionReason)) {
      return !!comments;
    }
    return true;
  };

  const isValidForm = validateForm();

  const handleSubmit = () => {
    if (!isValidForm) {
      return;
    }
    if (omissionReason === OmissionReason.NA && handleNA) {
      analytics.track(AnalyticsEvents.SurveyMarkAsNA, getPayload(utrv, utr));
      handleNA();
    }
    if (omissionReason !== OmissionReason.NA && handleNR) {
      analytics.track(AnalyticsEvents.SurveyMarkAsNR, getPayload(utrv, utr));
      handleNR();
    }
    return toggle();
  };

  const isRequiredOmissionNote = omissionReason ? omissionMap[omissionReason].isRequiredNote : false;

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Not reporting</ModalHeader>
      <ModalBody className='not-reporting-form'>
        <div className='mb-2 text-medium'>Reason for omission</div>
        <SelectFactory
          options={omissionOptions}
          selectType={SelectTypes.SingleSelect}
          placeholder='Select a reason'
          value={omissionOptions.find((option) => option.value === omissionReason)}
          onChange={(option) => setOmission(option?.value)}
          isSearchable={false}
        />
        <div className='mt-4 text-medium'>
          Further explanation / notes {isUtrvNoteRequired || isRequiredOmissionNote ? '(required)' : '(optional)'}
        </div>
        {omissionReason ? <div>{omissionMap[omissionReason].description}</div> : null}
        <Input
          type='textarea'
          name='comments'
          className='w-100 form-control dont_translate mt-2'
          maxLength={5000}
          rows={6}
          onChange={(e) => handleComments(e.target.value)}
          disabled={disabledNote}
          value={comments}
        />
        <div className='d-flex align-items-center mt-3 text-ThemeTextMedium'>
          <i className='fa-light fa-circle-info mr-2' />
          <span className='text-sm'>
            Marking a metric as 'Not Reporting' will permanently record this in the provenance, which cannot be undone.
            To update the metric in the future, you can provide a new answer.
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Close
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={disabled || !isValidForm}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
