import { ValueRole, CalculationType } from '@g17eco/types/insight-custom-dashboard';
import {
  getActualUtrsDataGrouped,
  generateGroupedStageData,
  getLatestActualHistoricalUtrs,
} from '@services/aggregation/utrData';
import { DATE } from '@utils/date';
import { createExpression, FormulaVariables, tryCalculation } from '@utils/formula';
import { getActualUtrvsGroupByDate, getLatestActualUtrvs, getSumOfVariables, getVariablesWithValues } from './utils';
import { ChartUtrData, DataProps } from '../../types';
import { CHART_DECIMAL_PLACES } from '@routes/custom-dashboard/utils';
import { processCalculation } from '@services/aggregation/stageAggregation';
import { getDecimalAsNumber } from '@utils/number';
import { isNumericString } from '@utils/string';
import { ChartDataResponse } from '../PieChart';

export const getColumnChartData = async (props: DataProps<ChartUtrData>) => {
  const { utrsData, variables, calculation } = props;

  const fallback = 0;

  let chartData: (string | number | { role: ValueRole })[][] = [];
  switch (calculation.type) {
    case CalculationType.Stages: {
      chartData = await Promise.all(
        getActualUtrsDataGrouped(utrsData, DATE.MONTH_YEAR_SHORT).map(async ({ date, utrsData }) => {
          return generateGroupedStageData({
            fallback,
            date,
            calculation,
            variables,
            utrsData,
          });
        })
      );
      break;
    }
    case CalculationType.Formula: {
      const utrvsGroupByDate = getActualUtrvsGroupByDate(utrsData);
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });
        return [
          date,
          ...calculation.values.map((value) => {
            return tryCalculation({
              formula: value.formula ?? '',
              variables: variablesWithValues,
              fallback,
            });
          }),
        ];
      });
      break;
    }
  }

  const linesNames = calculation.values.map((value) => (value.role ? { role: value.role } : value.name));
  chartData.unshift(['Time', ...linesNames]);

  return { chartData };
};

export const getBarChartData = async ({ utrsData, variables, calculation }: DataProps<ChartUtrData>) => {
  const { headers = [] } = calculation;
  const chartDataHeaders = headers.map((header) => {
    if ('name' in header) {
      return header.name;
    }

    if ('role' in header) {
      return { role: header.role };
    }

    return '';
  });

  const fallback = 0;
  if (calculation.type === CalculationType.Stages) {
    const { effectiveDate, period, latestUtrsData } = getLatestActualHistoricalUtrs(utrsData);
    const acc = { result: fallback };
    const chartData = [
      chartDataHeaders,
      ...(await Promise.all(
        calculation.values.map(async ({ name, stages, options }) => [
          name,
          await processCalculation({
            variables,
            utrsData: latestUtrsData,
            stages,
            fallback,
            acc,
          }),
          createExpression(options?.tooltip.formula ?? '', acc, fallback),
          options?.style ?? '',
        ])
      )),
    ];

    return { chartData, effectiveDate, period, variablesWithValues: acc };
  }

  const { latestActualUtrvs = [], effectiveDate, period } = getLatestActualUtrvs(utrsData);
  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs });
  const variablesWithFormattedValues = Object.entries(variablesWithValues).reduce<FormulaVariables>(
    (acc, [key, value]) => {
      acc[key] = isNumericString(value) ? getDecimalAsNumber(value, CHART_DECIMAL_PLACES) : value;
      return acc;
    },
    {}
  );
  const chartData = [
    chartDataHeaders,
    ...calculation.values.map(({ name, formula = '', options }) => [
      name,
      tryCalculation({ formula, variables: variablesWithValues, fallback: 0 }),
      createExpression(options?.tooltip.formula ?? '', variablesWithFormattedValues, 0),
      options?.style ?? '',
    ]),
  ];

  return { chartData, effectiveDate, period, variablesWithValues };
};

export const getLineChartData = async ({
  utrsData,
  variables,
  calculation,
}: DataProps<ChartUtrData>) => {
  const utrvsGroupByDate = getActualUtrvsGroupByDate(utrsData);

  const fallback = 0;

  let chartData: (string | number)[][] = [];
  switch (calculation.type) {
    case CalculationType.Stages: {
      chartData = await Promise.all(getActualUtrsDataGrouped(utrsData, DATE.MONTH_YEAR_SHORT).map(async ({ date, utrsData }) => {
        return generateGroupedStageData({
          fallback,
          date,
          calculation,
          variables,
          utrsData
        });
      }));
      break;
    }
    case CalculationType.Sum:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });

        return [date, getSumOfVariables(variablesWithValues)];
      });

      break;
    case CalculationType.Percentage:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });
        const sum = getSumOfVariables(variablesWithValues);

        return [
          date,
          ...calculation.values.map((value) => {
            const calculatedValue = tryCalculation({
              formula: value.formula || '',
              variables: variablesWithValues,
              fallback,
              infinityFallback: null,
            });

            return (100 * calculatedValue) / sum;
          }),
        ];
      });

      break;
    case CalculationType.Formula:
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });

        return [
          date,
          ...calculation.values.map((value) => {
            return tryCalculation({
              formula: value.formula || '',
              variables: variablesWithValues,
              fallback,
              infinityFallback: null,
            });
          }),
        ];
      });

      break;
  }

  const linesNames = calculation.values.map((value) => value.name);
  chartData.unshift(['Time', ...linesNames]);

  return { chartData };
};

export const getPieChartData = async ({
  utrsData,
  variables,
  calculation,
}: DataProps<ChartUtrData>): Promise<ChartDataResponse> => {
  if (calculation.type === CalculationType.Stages) {
    const { effectiveDate, period, latestUtrsData } = getLatestActualHistoricalUtrs(utrsData);
    const chartData = [
      ['Key', 'Value'],
      ...(await Promise.all(
        calculation.values.map(async (value) => [
          value.name,
          await processCalculation({
            variables,
            utrsData: latestUtrsData,
            stages: value.stages,
            fallback: 0,
          }),
        ])
      )),
    ];
    return { chartData, effectiveDate, period };
  }

  const { latestActualUtrvs = [], effectiveDate, period } = getLatestActualUtrvs(utrsData);
  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs });
  const chartData = [
    ['Key', 'Value'],
    ...calculation.values.map((value) => [
      value.name,
      tryCalculation({ formula: value.formula ?? '', variables: variablesWithValues, fallback: 0 }),
    ]),
  ];
  return { chartData, effectiveDate, period };
};
