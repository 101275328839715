import { Document } from 'flexsearch';
import { SurveyStats, UserWithDelegation } from '@api/initiative-stats';
import { getPeriodName } from '@utils/universalTracker';
import { DocumentItem } from '@g17eco/types/document';

export const generateSurveysFlexSearchMap = (surveys: SurveyStats[] | undefined) => {
  const indexSearch = new Document({
    tokenize: 'full',
    preset: 'performance',
    document: {
      id: '_id',
      index: ['name', 'period', 'effectiveDate', 'initiativeName'],
    },
  });

  (surveys || []).forEach((survey) => {
    indexSearch.add({
      ...survey,
      period: getPeriodName(survey.period, false),
      id: survey._id,
    });
  });

  return indexSearch;
};

export const generateUsersFlexSearchMap = (users: UserWithDelegation[] | undefined) => {
  const indexSearch = new Document({
    tokenize: 'full',
    preset: 'performance',
    document: {
      id: '_id',
      index: ['firstName', 'surname', 'email', 'fullName'],
    },
  });

  (users || []).forEach((user) => {
    indexSearch.add({
      ...user,
      id: user._id,
      fullName: `${user.firstName ?? ''} ${user.surname ?? ''}`.trim(),
    });
  });

  return indexSearch;
};

export const generateDocumentsFlexSearchMap = (documents: DocumentItem[] | undefined) => {
  const indexSearch = new Document({
    tokenize: 'full',
    preset: 'performance',
    document: {
      id: '_id',
      index: ['title', 'format'],
    },
  });

  (documents || []).forEach((doc) => {
    indexSearch.add({
      ...doc,
      id: doc._id,
      title: doc.title, 
      format: doc.metadata?.extension ?? '',
    });
  });

  return indexSearch;
};
