import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BasicAlert } from '@g17eco/molecules/alert';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules/select/SelectFactory';
import { getBranchInitiativeNameText, getSubsidiaryOptions } from '../../utils';
import { InitiativePlain, RootInitiativeData } from '../../../../types/initiative';
import { useState } from 'react';
import { Tree } from '../../../../types/tree';
import { useReactivateSubsidiaryMutation } from '../../../../api/organisation-map';
import Loader from '../../../../components/loader';
import { useDispatch } from 'react-redux';
import { loadInitiativeTree } from '../../../../actions';
import { reloadCurrentUser } from '../../../../actions/user';
import { ArchivedReportingLevel } from '../../InitiativeStructureArchived';

interface ActivateSubsidiaryModalProps {
  selectedInitiative: ArchivedReportingLevel | undefined;
  activeInitiativeTree: Tree;
  initiativeTreeList: InitiativePlain[];
  rootOrg: RootInitiativeData;
  toggleModal: () => void;
}

export const ActivateSubsidiaryModal = (props: ActivateSubsidiaryModalProps) => {
  const { activeInitiativeTree, initiativeTreeList, toggleModal, selectedInitiative, rootOrg } = props;
  const [reportingParentId, setReportingParentId] = useState<string | undefined>(
    initiativeTreeList.find((initiative) => initiative._id === selectedInitiative?.parentId)?._id
  );

  const [reactivateSubsidiary, { isLoading, isError, error }] = useReactivateSubsidiaryMutation();
  const dispatch = useDispatch();

  const options = getSubsidiaryOptions({ fullTreeList: initiativeTreeList });

  const handleInitiativeChange = (option: Option<string> | null) => {
    if (option) {
      setReportingParentId(option.value);
    }
  };

  const handleSubmit = () => {
    if (selectedInitiative && reportingParentId) {
      reactivateSubsidiary({
        initiativeId: reportingParentId,
        archivedInitiativeId: selectedInitiative.initiativeId,
      }).then(() => {
        dispatch(loadInitiativeTree({ organization: rootOrg, forceRefresh: true, blocking: false }));
        dispatch(reloadCurrentUser());
      });
    }
  };

  const handleToggle = () => {
    toggleModal();
    setReportingParentId(undefined);
  };

  return (
    <Modal isOpen={!!selectedInitiative} toggle={handleToggle} backdrop='static' className='activate-subsidiary-modal'>
      <ModalHeader toggle={handleToggle}>
        <span className='text-ThemeHeadingMedium'>Activate business unit</span>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isError ? <BasicAlert type={'danger'}>{error?.message}</BasicAlert> : null}
            <p>
              By re-activating <span className='text-strong'>{selectedInitiative?.name}</span>, data contained within
              the business unit will become visible again and will contribute to auto-aggregation.
            </p>
            <p>If you still want to proceed, select the reporting parent for this business unit and click activate.</p>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              className='w-100'
              placeholder={'Select reporting parent'}
              options={options}
              onChange={(option) => handleInitiativeChange(option)}
              defaultValue={options.find((option) => option.value === reportingParentId) ?? null}
            />
            <div className='mt-1'>
              {getBranchInitiativeNameText({
                initiativeTree: activeInitiativeTree,
                initiativeId: reportingParentId,
                showInitiativeId: true,
              })}
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='transparent' onClick={handleToggle}>
          Cancel
        </Button>
        <Button color='primary' disabled={!reportingParentId} onClick={handleSubmit}>
          Activate
        </Button>
      </ModalFooter>
    </Modal>
  );
};
