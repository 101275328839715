import React from 'react';
import Chart from 'react-google-charts';
import {
  CHART_DEFAULTS,
  GenerateChartsProps,
  chartReadyEvent,
  getReportEffectiveDate,
  DataSource,
} from './GenerateCharts';
import { getUnitText } from '@utils/units';
import { UtrValueType } from '@g17eco/types/universalTracker';

const WIDTH = 300;
const HEIGHT = 250;

export interface EnergyConsumptionDataSource extends DataSource {
  consumption?: number;
  unit?: string;
}

export const EnergyConsumption = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const utrCode = 'gri/2020/302-1/e';
  const utrv = questionData.find((d) => d.universalTracker.code === utrCode);
  const utr = utrv?.universalTracker;
  const value = utrv?.value ?? 0;
  const unit = getUnitText({ valueType: UtrValueType.Number, unit: utr?.unit, unitType: utr?.unitType });

  const reportData: EnergyConsumptionDataSource = {
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    consumption: value,
    unit,
  };

  const chartData = [
    ['Energy Consumption', unit, { role: 'style' }, { role: 'annotation' }],
    [getReportEffectiveDate(questionData, utrCode), value, '#A6CFE5', value],
  ];

  return (
    <Chart
      chartType='ColumnChart'
      data={chartData}
      width={WIDTH}
      height={HEIGHT}
      options={{
        ...CHART_DEFAULTS.OPTIONS,
        title: 'Total Energy Consumption.',
        vAxis: {
          title: unit,
          format: 'decimal',
          gridlines: { count: 4 },
          minValue: 0,
        },
      }}
      chartEvents={[chartReadyEvent({ key: 'energyConsumption', data: reportData, handler: updateData })]}
    />
  );
});
